import React, { useEffect, useState } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useAppSelector } from "../../../store/hooks";
import { defineCardData } from "../../../utils/index";
import HTAAnalysisChart from "./HTAAnalysisChart";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import Loader from "../../../utils/Loader";

type chartData = {
  categories: any[];
  data: any[];
};

const HTAAnalysis = () => {
  const state = useAppSelector((state: any) => state);
  const [data] = useState<any[]>(state.productProfile.htaSummaryData);
  const [loading, setLoading] = useState<boolean>(false);
  const [economicPositivesArray, setEconomicPositivesArray] =
    useState<chartData>({
      categories: [],
      data: [],
    });
  const [economicNegativeArray, setEconomicNegativeArray] = useState<chartData>(
    {
      categories: [],
      data: [],
    }
  );
  const [clinicalPositivesArray, setClinicalPositivesArray] =
    useState<chartData>({
      categories: [],
      data: [],
    });
  const [clinicalNegativeArray, setClinicalNegativeArrayArray] =
    useState<chartData>({
      categories: [],
      data: [],
    });
  const [decisionRationaleArray, setDecisionRationaleArray] =
    useState<chartData>({
      categories: [],
      data: [],
    });
  const [comparatorArray, setComparatorArray] = useState<chartData>({
    categories: [],
    data: [],
  });

  const fetchChartData = () => {
    setLoading(true);
    let res = defineCardData(data);
    if (!!res && res.economicPositivesArray.length > 0) {
      let tmp = economicPositivesArray;
      res.economicPositivesArray.sort((a: any, b: any) => b.count - a.count);
      res.economicPositivesArray.map((item: any) => {
        tmp.categories.push(item.label);
        tmp.data.push(item.count);
      });

      setEconomicPositivesArray(tmp);
    }

    if (!!res && res.economicNegativesArray.length > 0) {
      let tmp = economicNegativeArray;
      res.economicNegativesArray.sort((a: any, b: any) => b.count - a.count);
      res.economicNegativesArray.map((item: any) => {
        tmp.categories.push(item.label);
        tmp.data.push(item.count);
      });

      setEconomicNegativeArray(tmp);
    }

    if (!!res && res.clinicalPositivesArray.length > 0) {
      let tmp = clinicalPositivesArray;
      res.clinicalPositivesArray.sort((a: any, b: any) => b.count - a.count);
      res.clinicalPositivesArray.map((item: any) => {
        tmp.categories.push(item.label);
        tmp.data.push(item.count);
      });

      setClinicalPositivesArray(tmp);
    }

    if (!!res && res.clinialNegativesArray.length > 0) {
      let tmp = clinicalNegativeArray;
      res.clinialNegativesArray.sort((a: any, b: any) => b.count - a.count);
      res.clinialNegativesArray.map((item: any) => {
        tmp.categories.push(item.label);
        tmp.data.push(item.count);
      });
      setClinicalNegativeArrayArray(tmp);
    }
    if (!!res && res.decisionRationaleArray.length > 0) {
      let tmp = decisionRationaleArray;
      res.decisionRationaleArray.sort((a: any, b: any) => b.count - a.count);
      res.decisionRationaleArray.map((item: any) => {
        tmp.categories.push(item.label);
        tmp.data.push(item.count);
      });
      setDecisionRationaleArray(tmp);
    }
    if (!!res && res.comparatorArray.length > 0) {
      let tmp = comparatorArray;
      res.comparatorArray.sort((a: any, b: any) => b.count - a.count);

      if (Object.keys(res.comparatorArray).length < 10) {
        res.comparatorArray.map((item: any) => {
          tmp.categories.push(item.label);
          tmp.data.push(item.count);
        });
      } else {
        for (var i = 0; i < 10; i++) {
          tmp.categories.push(res.comparatorArray[i].label);
          tmp.data.push(res.comparatorArray[i].count);
        }
      }
      setComparatorArray(tmp);
    }
    console.log(res, "res");
    setLoading(false);
  };

  useEffect(() => {
    fetchChartData();
  }, [data, loading]);

  return !loading ? (
    <div className="htaAnalysisContainer">
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography>Summary of clinical positives</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <HTAAnalysisChart
            data={clinicalPositivesArray.data}
            categories={clinicalPositivesArray.categories}
          />
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography>Summary of clinical negatives</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <HTAAnalysisChart
            data={clinicalNegativeArray.data}
            categories={clinicalNegativeArray.categories}
          />
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel3a-content"
          id="panel3a-header"
        >
          <Typography>Summary of economic positives</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <HTAAnalysisChart
            data={economicPositivesArray.data}
            categories={economicPositivesArray.categories}
          />
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel4a-content"
          id="panel4a-header"
        >
          <Typography>Summary of economic negatives</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <HTAAnalysisChart
            data={economicNegativeArray.data}
            categories={economicNegativeArray.categories}
          />
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel5a-content"
          id="panel5a-header"
        >
          <Typography>Payer's decision rationale for assessments</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <HTAAnalysisChart
            data={decisionRationaleArray.data}
            categories={decisionRationaleArray.categories}
          />
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel6a-content"
          id="panel6a-header"
        >
          <Typography>Comparators</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <HTAAnalysisChart
            data={comparatorArray.data}
            categories={comparatorArray.categories}
          />
        </AccordionDetails>
      </Accordion>
    </div>
  ) : (
    <Loader />
  );
};

export default HTAAnalysis;
