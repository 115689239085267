import React, { useEffect } from "react";
import ReactApexChart from "react-apexcharts";

interface HTAAnalysisChartProps {
  data: any[];
  categories: any[];
}

const HTAAnalysisChart = (props: HTAAnalysisChartProps) => {
  const { data, categories } = props;

  const chartHeight = (value: number) => {
    console.log(value, "value");
    if (value <= 10) return 300;
    else if (value > 10 && value <= 15) return 400;
    else if (value > 15 && value <= 20) return 500;
    else if (value > 20 && value <= 25) return 600;
    else return 700;
  };

  const chartSetting = {
    series: [
      {
        data,
      },
    ],
    options: {
      chart: {
        type: "bar",
        toolbar: {
          show: false,
        },
      },
      plotOptions: {
        bar: {
          horizontal: true,
        },
      },

      dataLabels: {
        enabled: true,
        style: {
          fontSize: "12px",
          colors: ["#fff"], // Set the color of the data labels
        },
      },
      stroke: {
        show: true,
        width: 1,
      },
      xaxis: {
        categories,
        axisBorder: {
          show: true,
          color: "black",
          borderType: "solid",
          width: 5,
          offsetX: 0,
          offsetY: 0,
        },
      },
      yaxis: {
        labels: {
          minWidth: "150px",
          maxWidth: "350px",
        },
      },
      grid: {
        yaxis: {
          lines: {
            show: false,
          },
        },
        xaxis: {
          lines: {
            show: true, //or just here to disable only x axis grids
          },
        },
      },
    },
  };

  return (
    <div>
      <ReactApexChart
        options={chartSetting.options}
        series={chartSetting.series}
        type="bar"
        height={600}
      />
    </div>
  );
};

export default HTAAnalysisChart;
