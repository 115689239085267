import React, { useState, useEffect, useContext } from "react";
import { Field } from "../../../../store/DataSourceMetaData";
import { EditorState } from "draft-js";
import FieldView from "../../FieldView";
import { RiDeleteBinLine } from "react-icons/ri";
import AddIcon from "@mui/icons-material/Add";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import {
  IselectedLookup,
  IselectedLookupGlobeSpecial,
} from "../../../interfaces/multiselect";
//import InfoIcon from "@mui/icons-material/Info";
import { FaInfoCircle } from "react-icons/fa";
import DoubleArrowIcon from "@mui/icons-material/DoubleArrow";
import { UserContext } from "../../Editor";
import { setSelectedLookupGlobe } from "../../../../store/MultiSelectSlice";

interface RegulatoryWatch_CoaInstrument {
  field: Field;
  values: any;
  fieldChanged: (name?: string, value?: string, obj?: any) => void;
  childRows: Field[];
  validateCriteria: (field: Field) => boolean;
  RichtextFieldChanged: (name: string, value: EditorState) => void;
  datasourcename: string;
  section: any;
  datasourceId: number;
  masterFieldTypes: number[];
  openModal: (ID: number, Name: string, title: string) => void;
}

interface IEndPoint {
  [key: string]: string;
}

interface IResult {
  [i: string]: IselectedLookup[];
}
const RegulatoryWatch_CoaInstrument = ({
  field,
  values,
  fieldChanged,
  childRows,
  validateCriteria,
  RichtextFieldChanged,
  datasourcename,
  section,
  datasourceId,
  masterFieldTypes,
  openModal,
}: RegulatoryWatch_CoaInstrument) => {
  const dispatch = useAppDispatch();
  const selectedLookupGlobe = useAppSelector(
    (state) => state.multiselect.selectedLookupGlobe
  );

  const selectedLookupGlobeSpecial = useAppSelector(
    (state) => state.multiselect.selectedLookupGlobeSpecial
  );

  const userContext = useContext(UserContext);

  useEffect(() => {
    const ep_keys: string[] = Object.keys(selectedLookupGlobe).filter((x) =>
      x.includes("COA_Endpoints.EndpointType.EndpointID")
    );

    const coa = values[field.name];

    ep_keys.forEach((x, i) => {
      const items = x.split("_");
      const coa_index = Number(items[items.length - 1]);
      const selectedEndPoints = selectedLookupGlobe[x] || [];
      // coa[coa_index]["endPoint"] = []
      selectedEndPoints.forEach((ep) => {
          if (coa[coa_index] && coa[coa_index]["endPoint"]) {
            const ep_names = coa[coa_index]["endPoint"].map(
              (x: any) => x["EndpointName"]
            );

            if (!ep_names.includes(ep.Value)) {
              coa[coa_index]["endPoint"].push({
                EndpointID: ep.Id,
                EndpointName: ep.Value,
                // Results: [...intervention, ...comparators],
                // RealtiveDifference: RealtiveDifference,
              });
            }
          } else {
            if(coa[coa_index])
              coa[coa_index]["endPoint"] = [
                {
                  EndpointID: ep.Id,
                  EndpointName: ep.Value,
                  // Results: [...intervention, ...comparators],
                  // RealtiveDifference: RealtiveDifference,
                },
            ];
          }
        });
    });

    if (coa != undefined) {
      fieldChanged(field.name, "", coa);
    }
  }, [selectedLookupGlobe, selectedLookupGlobeSpecial]);

  const resultChange = (
    id: string,
    i: string,
    currentItem: number,
    coa_index: number
  ) => {
    //
    //const ResultInfo : any[] = [...values[field.name][i]]
    let coa = values[field.name];
    if (!String(id).includes("_")) {
      let results: any[] = [...coa[coa_index]["endPoint"][i]["Results"]];

      for (let ri = 0; ri < results.length; ri++) {
        if (results[ri].Id == id && !results[ri]["ResultName"]) {
          results[ri] = {
            ...results[ri],
            ResultName: " ",
          };
          break;
        }
      }
      coa[coa_index]["endPoint"][i]["Results"] = [...results];
    } else {
      const n_id = id.replace("_", "");
      let RealtiveDifference: any[] = [
        ...coa[coa_index]["endPoint"][i]["RealtiveDifference"],
      ];

      for (let ri = 0; ri < RealtiveDifference.length; ri++) {
        if (
          RealtiveDifference[ri].Id == n_id &&
          !RealtiveDifference[ri]["ResultName"]
        ) {
          RealtiveDifference[ri] = {
            ...RealtiveDifference[ri],
            ResultName: " ",
          };
          break;
        }
      }

      coa[coa_index]["endPoint"][i]["RealtiveDifference"] = [
        ...RealtiveDifference,
      ];
    }

    fieldChanged(field.name, "", coa);
  };

  const onResultNameChange = (
    coa_index: number,
    ep_index: number,
    result_index: number,
    name?: string
  ) => {
    const coa = values[field.name];
    console.log("resultChange");

    if (name === undefined) {
      delete coa[coa_index]["endPoint"][ep_index]["Results"][result_index]
        .ResultName;
    } else {
      coa[coa_index]["endPoint"][ep_index]["Results"][result_index] = {
        ...coa[coa_index]["endPoint"][ep_index]["Results"][result_index],
        ResultName: name,
      };
    }

    fieldChanged(field.name, "", coa);
  };

  const onRelativeDifferenceNameChange = (
    coa_index: number,
    ep_index: number,
    result_index: number,
    name?: string
  ) => {
    const coa = values[field.name];
    console.log("resultChange");

    if (name === undefined) {
      delete coa[coa_index]["endPoint"][ep_index]["RealtiveDifference"][
        result_index
      ].ResultName;
    } else {
      coa[coa_index]["endPoint"][ep_index]["RealtiveDifference"][result_index] =
        {
          ...coa[coa_index]["endPoint"][ep_index]["RealtiveDifference"][
            result_index
          ],
          ResultName: name,
        };
    }

    fieldChanged(field.name, "", coa);
  };

  const updateCOA = (obj: any, index: number) => {
    const coaObj = [...values[field.name]];
    coaObj[index] = obj;
    fieldChanged(field.name, "", coaObj);
  };

  const addEndpointTypes = (coa_index: number) => {
    const id: number = childRows
      .filter((f) => f.name == "COA_Endpoints.EndpointType.EndpointID")
      .map((f) => f.lookupId)[0];

    openModal(
      id,
      "COA_Endpoints.EndpointType.EndpointID_" + String(coa_index),
      "Endpoint Type"
    );
  };

  const deleteInstrument = (coa_index: number) => {
    const msg = "Are you sure you want to delete this COA instrument?";
    const check = window.confirm(msg);
    if (check) {
      if (values[field.name].length == 1) fieldChanged(field.name, "", []);
      else {
        const coa_instruments: any[] = [...values[field.name]];
        fieldChanged(field.name, "", [
          ...coa_instruments.filter((item: any, xi: number) => xi != coa_index),
        ]);
      }
      let slg = { ...selectedLookupGlobe };
      for (let i = coa_index;i < values[field.name].length-1; i++){
        slg[`COA_Endpoints.Significances_${i}`] = slg[`COA_Endpoints.Significances_${i+1}`];
        slg[`COA_Endpoints.ImportanceID_${i}`] = slg[`COA_Endpoints.ImportanceID_${i+1}`];
        slg[`COA_Endpoints.EndpointType.EndpointID_${i}`] = slg[`COA_Endpoints.EndpointType.EndpointID_${i+1}`];
      }
      const coaLength =  values[field.name].length-1;
      delete slg[`COA_Endpoints.Significances_${coaLength}`];
      delete slg[`COA_Endpoints.ImportanceID_${coaLength}`];
      delete slg[`COA_Endpoints.EndpointType.EndpointID_${coaLength}`];
      dispatch(setSelectedLookupGlobe(slg));
    }
  };

  const deleteEndPoint = (coa_index : number,id: number,ep_index:number) => {

    const key = `COA_Endpoints.EndpointType.EndpointID_${coa_index}`;
    let _selectedLookupGlobe = {...selectedLookupGlobe};
    let obj = _selectedLookupGlobe[key];
    obj = obj.filter((x) => x.Id !== id );
    _selectedLookupGlobe[key] = obj;
    dispatch(setSelectedLookupGlobe(_selectedLookupGlobe));
    
    const coa = values[field.name];
    const endPointObj = coa[coa_index]["endPoint"].filter(
      (x: any, i: number) => i != ep_index
    );
    coa[coa_index]["endPoint"] = endPointObj;
    fieldChanged(field.name, "", coa);
  };

  return (
    <>
      {values[field.name] &&
        values[field.name].map((parent: any, index: number) => (
          <>
            <div className="float-left">
              <b> {"COA instrument " + String(index + 1)}</b>
            </div>
            <div className="float-right">
              <div className="row">
                {childRows
                  .filter(
                    (x) =>
                      masterFieldTypes.includes(x.fieldTypeId) &&
                      x.masterFieldId == field.id
                  )
                  .map((el) => (
                    <>
                      <label style={{color:"rgb(0, 170, 231)",marginRight:"52px",cursor:"pointer"}}
                        onClick={() => {
                          addEndpointTypes(index);
                        }}
                      >
                        <AddIcon className="instrument-endpoint-type-add" />&nbsp;
                        {el.label}
                      </label>
                    </>
                  ))}
                <label className="iqvia-color cursor-pointer margin-right-30" onClick={() => deleteInstrument(index)}>
                  <RiDeleteBinLine className="margin-bottom-3" /> Instrument

                </label>
              </div>
            </div>
            <div
            style={{
              width:"100%",
              height:"0.5px",
              marginTop: values[field.name][index].endPoint == undefined ? "33px": "79px",
              marginBottom: "1rem",
              border: "0",
              borderTop: "1px solid rgba(0,0,0,.1)"
            }}
          >   
          </div>
            <>
              <div
                  className="float-right "
                  style={{marginTop: "-75px", marginRight: "98px",marginBottom: "30px",marginLeft: "30px",width: "150px",height: values[field.name][index].endPoint == undefined ? "none" : "55px", overflowY: "scroll"}}
              >
              {!!values[field.name][index].endPoint &&
                values[field.name][index].endPoint.map((el: any, i: number) => (
                  
                    <div className="col-sm-12">
                      <label>{el.EndpointName}
                        <label style={{ color: "#337ab7", marginRight: "30px" }}
                          onClick={() => deleteEndPoint(index, el.EndpointID, i)}>
                          <RiDeleteBinLine style={{fontSize:"medium"}} />
                        </label></label>

                    </div>
                  
                ))}
              </div>
            </>
            {childRows
              .filter(
                (x) =>
                  !masterFieldTypes.includes(x.fieldTypeId) &&
                  x.masterFieldId == field.id &&
                  x.name !== "COA_Endpoints.Result"
              )
              .map((row) => (
                <>
                  {/* {validateCriteria(row) && ( */}
                  <FieldView
                    field={row}
                    values={values}
                    fieldChanged={fieldChanged}
                    RichtextFieldChanged={RichtextFieldChanged}
                    datasourcename={datasourcename}
                    section={section.name}
                    datasourceId={datasourceId}
                    parentObject={values[field.name][index]}
                    setObj={updateCOA}
                    index={index}
                  />
                  {/* )} */}
                </>
              ))}

            {/* {!!values[field.name][index].endPoint &&
              values[field.name][index].endPoint.map((el: any, i: number) => (
                <>
                  <div style={{ display: "flow-root" }}>
                    <div className="float-left">
                      <b>Endpoint type - {el.EndpointName}</b>
                    </div>
                    <div className="float-right">
                      <select
                        value={""}
                        disabled={
                          !!el["Results"] &&
                          [
                            ...(el["Results"] || []),
                            ...(el["RealtiveDifference"] || []),
                          ].some((x: any) => !x["ResultName"])
                            ? false
                            : true
                        }
                        onChange={(e) => {
                          resultChange(
                            e.target.value,
                            String(i),
                            e.target.selectedIndex,
                            index
                          );
                        }}
                      >
                        <option value={""} disabled selected>
                          +results
                        </option>
                        <optgroup label="Individual result">
                          {!!el["Results"] &&
                            el["Results"].map((item: any, ri: number) => (
                              <>
                                {!item["ResultName"] && (
                                  <option key={ri} value={item.Id}>
                                    {item.Value}
                                  </option>
                                )}
                              </>
                            ))}
                        </optgroup>
                        <optgroup label="Relative Difference">
                          {!!el["RealtiveDifference"] &&
                            el["RealtiveDifference"].map(
                              (item: any, ri: number) => (
                                <>
                                  {!item["ResultName"] && (
                                    <option
                                      key={ri}
                                      value={String(item.Id) + "_"}
                                    >
                                      {item.Value}
                                    </option>
                                  )}
                                </>
                              )
                            )}
                        </optgroup>
                      </select>
                      <label>
                        <AddIcon /> Add all results
                      </label>
                      <label onClick={() => deleteEndPoint(index, i)}>
                        <RiDeleteBinLine /> Endpoint type
                      </label>
                    </div>
                    <div>
                      {childRows
                        .filter(
                          (x) =>
                            x.name ==
                            "COA_Endpoints.EndpointType.COAEndpointName"
                        )
                        .map((item) => (
                          <>
                            <br />
                            <div
                              className="float-left col-sm-12 row form-group "
                              style={{ marginBottom: "0rem" }}
                            >
                              <label
                                className="col-sm-4 text-right control-label"
                                style={{ paddingRight: "4px" }}
                              >
                                {item.label}
                                {
                                  <FaInfoCircle
                                    onClick={() => {
                                      userContext.showAlert(
                                        <>
                                          <span
                                            style={{
                                              fontSize: "70%",
                                              paddingLeft: "2px",
                                            }}
                                          >
                                            {datasourcename}
                                            <DoubleArrowIcon
                                              style={{
                                                fontSize: "18px",
                                                paddingBottom: "2px",
                                              }}
                                            />
                                            {section}
                                            <DoubleArrowIcon
                                              style={{
                                                strokeWidth: "5",
                                                fontSize: "18px",
                                                paddingBottom: "2px",
                                              }}
                                            />
                                            {field.label}
                                          </span>
                                        </>,
                                        "info",
                                        field.description,
                                        "https://google.com",
                                        true
                                      );
                                    }}
                                    style={{
                                      fontSize: "12px",
                                      marginLeft: "3px",
                                      cursor: "pointer",
                                    }}
                                  />
                                }
                              </label>
                              <input
                                className="col-sm-7 form-control"
                                style={{ marginLeft: "15px" }}
                                value={
                                  values[field.name][index]["endPoint"][i][
                                    item.name
                                  ] || ""
                                }
                                onChange={(e) => {
                                  const obj = values[field.name];
                                  obj[index]["endPoint"][i][item.name] =
                                    e.target.value;
                                  fieldChanged(field.name, "", obj);
                                }}
                              />
                            </div>
                          </>
                        ))}
                      {!!values[field.name][index]["endPoint"][i]["Results"] &&
                        values[field.name][index]["endPoint"][i]["Results"].map(
                          (res: any, resi: number) => (
                            <>
                              {res["ResultName"] && (
                                <div
                                  className="float-left col-sm-12 row form-group "
                                  style={{ marginBottom: "0rem" }}
                                >
                                  <label
                                    className="col-sm-4 text-right control-label"
                                    style={{
                                      paddingRight: "4px",
                                      paddingTop: 10,
                                    }}
                                  >
                                    {res.Value && res.Value}

                                    {
                                      <FaInfoCircle
                                        onClick={() => {
                                          userContext.showAlert(
                                            <>
                                              <span
                                                style={{
                                                  fontSize: "70%",
                                                  paddingLeft: "2px",
                                                }}
                                              >
                                                {datasourcename}
                                                <DoubleArrowIcon
                                                  style={{
                                                    fontSize: "18px",
                                                    paddingBottom: "2px",
                                                  }}
                                                />
                                                {section}
                                                <DoubleArrowIcon
                                                  style={{
                                                    strokeWidth: "5",
                                                    fontSize: "18px",
                                                    paddingBottom: "2px",
                                                  }}
                                                />
                                                {field.label}
                                              </span>
                                            </>,
                                            "info",
                                            field.description,
                                            "https://google.com",
                                            true
                                          );
                                        }}
                                        style={{
                                          fontSize: "12px",
                                          marginLeft: "3px",
                                          cursor: "pointer",
                                        }}
                                      />
                                    }
                                  </label>
                                  <input
                                    className="col-sm-7 form-control"
                                    value={res["ResultName"]}
                                    type="text"
                                    style={{
                                      marginLeft: "15px",
                                      marginTop: "15px",
                                    }}
                                    onChange={(e) =>
                                      onResultNameChange(
                                        index,
                                        i,
                                        resi,
                                        e.target.value
                                      )
                                    }
                                  />
                                  <div
                                    onClick={() => {
                                      onResultNameChange(index, i, resi);
                                    }}
                                  >
                                    <RiDeleteBinLine
                                      style={{
                                        marginTop: "22px",
                                        marginLeft: "5px",
                                      }}
                                    />
                                  </div>
                                </div>
                              )}
                            </>
                          )
                        )}
                      <p>Relative difference(s) / Effect estimate(s) :</p>

                      {!!values[field.name][index]["endPoint"][i][
                        "RealtiveDifference"
                      ] &&
                        values[field.name][index]["endPoint"][i][
                          "RealtiveDifference"
                        ].map((res: any, resi: number) => (
                          <>
                            {res["ResultName"] && (
                              <div
                                className="float-left col-sm-12 row form-group "
                                style={{ marginBottom: "0rem" }}
                              >
                                <label
                                  className="col-sm-4 text-right control-label"
                                  style={{
                                    paddingRight: "4px",
                                    paddingTop: 10,
                                  }}
                                >
                                  {res.Value && res.Value}

                                  {
                                    <FaInfoCircle
                                      onClick={() => {
                                        userContext.showAlert(
                                          <>
                                            <span
                                              style={{
                                                fontSize: "70%",
                                                paddingLeft: "2px",
                                              }}
                                            >
                                              {datasourcename}
                                              <DoubleArrowIcon
                                                style={{
                                                  fontSize: "18px",
                                                  paddingBottom: "2px",
                                                }}
                                              />
                                              {section}
                                              <DoubleArrowIcon
                                                style={{
                                                  strokeWidth: "5",
                                                  fontSize: "18px",
                                                  paddingBottom: "2px",
                                                }}
                                              />
                                              {field.label}
                                            </span>
                                          </>,
                                          "info",
                                          field.description,
                                          "https://google.com",
                                          true
                                        );
                                      }}
                                      style={{
                                        fontSize: "12px",
                                        marginLeft: "3px",
                                        cursor: "pointer",
                                      }}
                                    />
                                  }
                                </label>
                                <input
                                  className="col-sm-7 form-control"
                                  value={res["ResultName"]}
                                  type="text"
                                  style={{
                                    marginLeft: "15px",
                                    marginTop: "15px",
                                  }}
                                  onChange={(e) =>
                                    onRelativeDifferenceNameChange(
                                      index,
                                      i,
                                      resi,
                                      e.target.value
                                    )
                                  }
                                />
                                <div
                                  onClick={() => {
                                    onRelativeDifferenceNameChange(
                                      index,
                                      i,
                                      resi
                                    );
                                  }}
                                >
                                  <RiDeleteBinLine
                                    style={{
                                      marginTop: "22px",
                                      marginLeft: "5px",
                                    }}
                                  />
                                </div>
                              </div>
                            )}
                          </>
                        ))}
                    </div>
                  </div>
                </>
              ))} */}

            {values[field.name][index].endPoint &&
              values[field.name][index].endPoint.length == 0 && (
                <>
                  <br />
                  <div
                    className="col-sm-12"
                    style={{
                      backgroundColor: "whitesmoke",
                      textAlign: "center",
                    }}
                  >
                    No endpoints have been specified
                  </div>
                  <br />
                </>
              )}
          </>
        ))}
      {(!values[field.name] || values[field.name]?.length == 0) && (
        <>
          <br />
          <div
            className="col-sm-12"
            style={{ backgroundColor: "whitesmoke", textAlign: "center" }}
          >
            No further COA information was provided
          </div>
          <br />
          <div
            style={{
              width:"100%",
              height:"0.5px",
              marginTop: "20px",
              marginBottom: "1rem",
              border: "0",
              borderTop: "1px solid rgba(0,0,0,.1)"
            }}
          ></div>    
        </>
      )}
    </>
  );
};

export default RegulatoryWatch_CoaInstrument;
