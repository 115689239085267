// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `ul {
  padding: 0;
  list-style-type: none;
}
.news-list {
  /* display: -webkit-box; */
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
.client-news-modal .modal-content {
  border-radius: 0;
  height: 300px;
}
.modal .client-news-modal.modal-dialog {
  max-width: 1000px !important;
  width: 986px !important;
  height: 600px;
}
.client-news-title {
  color: rgb(0, 170, 231);
}
.client-news-title:hover {
  color: #0056b3;
  text-decoration: underline;
}
.news-title {
  text-align: center;
  margin-top: 2rem;
  border-top: 6px solid lightgray;
  padding: 1rem;
}
.news--title {
  text-align: center;
  padding-top: 1rem;
}
`, "",{"version":3,"sources":["webpack://./src/components/ClientNewsArticles/clientNews.css"],"names":[],"mappings":"AAAA;EACE,UAAU;EACV,qBAAqB;AACvB;AACA;EACE,0BAA0B;EAC1B,qBAAqB;EACrB,4BAA4B;EAC5B,gBAAgB;EAChB,uBAAuB;AACzB;AACA;EACE,gBAAgB;EAChB,aAAa;AACf;AACA;EACE,4BAA4B;EAC5B,uBAAuB;EACvB,aAAa;AACf;AACA;EACE,uBAAuB;AACzB;AACA;EACE,cAAc;EACd,0BAA0B;AAC5B;AACA;EACE,kBAAkB;EAClB,gBAAgB;EAChB,+BAA+B;EAC/B,aAAa;AACf;AACA;EACE,kBAAkB;EAClB,iBAAiB;AACnB","sourcesContent":["ul {\n  padding: 0;\n  list-style-type: none;\n}\n.news-list {\n  /* display: -webkit-box; */\n  -webkit-line-clamp: 2;\n  -webkit-box-orient: vertical;\n  overflow: hidden;\n  text-overflow: ellipsis;\n}\n.client-news-modal .modal-content {\n  border-radius: 0;\n  height: 300px;\n}\n.modal .client-news-modal.modal-dialog {\n  max-width: 1000px !important;\n  width: 986px !important;\n  height: 600px;\n}\n.client-news-title {\n  color: rgb(0, 170, 231);\n}\n.client-news-title:hover {\n  color: #0056b3;\n  text-decoration: underline;\n}\n.news-title {\n  text-align: center;\n  margin-top: 2rem;\n  border-top: 6px solid lightgray;\n  padding: 1rem;\n}\n.news--title {\n  text-align: center;\n  padding-top: 1rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
