import React, { useEffect, useState } from "react";
import Chart from "react-apexcharts";

interface PricingChartProps {
  listPrice: number[];
  manufacturerPrice: number[];
  retailPrice: number[];
  hospitalPrice: number[];
  dateList: string[];
  amnogPrice: number[];
  amnogPriceChange: string[];
  amongPriceChangePercentage: number[];
}

const PricingChart = (props: PricingChartProps) => {
  const {
    listPrice,
    manufacturerPrice,
    retailPrice,
    hospitalPrice,
    dateList,
    amnogPrice,
    amnogPriceChange,
    amongPriceChangePercentage
  } = props;

  // console.log(dateList, '!!', manufacturerPrice)
  // console.log("hospitalPrice", hospitalPrice, "retailPrice", retailPrice, "manufacturerPrice", manufacturerPrice);

  const [chartOptions, setChartOptions] = useState({
    chart: {
      id: "line-chart",
      type: "line",
      toolbar: {
        show: false,
      },
    },
    xaxis: {
      // categories: ["January", "February", "March", "April", "May", "June"],
      categories: dateList,
      type: "category",
      tickAmount: 20,
    },
    yaxis: {
      labels: {
        formatter: function (val: any) {
          return val;
        },
        offsetX: -5, // To adjust the position of the Y-axis labels
      },
      title: {
        style:{fontWeight:"100"},
        text: "Price in USD ($)",
        offsetX: 5, // To adjust the position of the currency legend
      },
    },
    colors: ["#065280", "#34b2e3", "#ff530d", "#027223"],
    legend: {
      position: "right",
      offsetY: 5,
      offsetX: -25,
    },
    tooltip: {
      x: {
        show: true, // Show the x-axis value in the tooltip
      },
    },
  });

  const [series, setSeries] = useState([
    {
      name: "Manufacturer Selling Price",
      // data: [10, 12, 8, 14, 6, 10],
      data: manufacturerPrice,
    },
    {
      name: "List Price",
      // data: [8, 9, 7, 11, 5, 9],
      data: listPrice,
    },
    {
      name: "Retail Price",
      // data: [12, 10, 9, 11, 8, 7],
      data: retailPrice,
    },
    {
      name: "Price to Hospital",
      // data: [9, 7, 10, 11, 6, 8],
      data: hospitalPrice,
    },
  ]);

  const defineChartData = () => {
    if (amnogPriceChange.length > 0 || amnogPriceChange.length > 0) {
      let tmpColors = [...chartOptions.colors, "#6fb730", "#fe8a12", "#fe8a12"];

      setChartOptions({
        ...chartOptions,
        colors: tmpColors,
      });

      let tmpSeries = [
        ...series,
        { name: "AMNOG Price", data: amnogPrice },
        { name: "AMNOG Price Change", data: amnogPriceChange },
        { name: "AMNOG Price Change (%)", data: amongPriceChangePercentage },
      ];
      setSeries(tmpSeries);
    }
  };

  useEffect(() => {
    defineChartData();
  }, [amnogPrice, amnogPriceChange]);

  return (
    <div>
      <Chart options={chartOptions} series={series} type="line" height={350} />
    </div>
  );
};

export default PricingChart;
