import React, { useEffect, useContext, useState, useCallback } from "react";
import apiCall from "../service/apiCall";


export const GetDependentFieldName = (fieldName: string) => {
    switch (fieldName) {
        case "HTARecord_Drug":
            return 'HTARecord_Drug_Combination';
        case "HTARecord_DrugComparator":
            return 'HTARecord_DrugComparator_Combination';
        case "ClinicalData_Drug":
            return 'ClinicalData_Drug_Combination';
        case "ClinicalData_DrugControl":
            return 'ClinicalData_DrugControl_Combination';
        default:
            "";
        //     let dependentFieldName = await apiCall(`/api/GetDependentField/${fieldName}`);
        //     if (dependentFieldName && dependentFieldName.data && dependentFieldName.data.length > 0) {
        //     return dependentFieldName.data[0].Name;
        //     }
    }
}

export const GetCorporationForDrug = (
    drugIds: string
  ) => {
    apiCall(`/api/getCorporationForDrug/${drugIds}`).then(
        (resp) => {
          if (resp.data == true) {
            return;
          }
        }
      );
  }

export const DataSourceListTypes = {
    HTARecord: 1,
    ClinicalData: 2,
    RegulatoryWatch: 3,
    Pricing: 4,
    Policy: 5,
    Agency: 6,
    Country: 7,
    DrugData: 20,
    Agreement: 23,
    ReimbursementStatus: 25,
    Subscription : 274,
}

export const FormFieldType = {
    Text: 1,
    Decimal: 2,
    Integer: 3,
    Dropdown: 4,
    Accordion: 5,
    Textarea: 6,
    MultiSelect: 7,
    AgencySelect: 8,
    CountrySelect: 9,
    Checkbox: 10,
    ComboboxMultiple: 11,
    DrugSelect: 12,
    ComboboxSingle: 13,
    ComboboxSingleDisplayValue: 14,
    DatePicker: 15,
    StaticLabel: 16,
    Image: 17,
    TimePointSelector: 18,
    Hidden: 19,
    None: 20,
    HtaRecordSelect: 21,
    PolicySelect: 22,
    PricingSelect: 23,
    DeviceSelect: 24,
    DiseaseSelect: 25,
    ClinicalDataSelect: 26,
    TherapeuticAreaSelect: 27,
    DropdownRelatedData: 28,
    UserSelect: 29,
    ClinicalDataPoints: 30,
    SearchColumn: 31,
    Hyperlink: 32,
    DependentDropdown: 33,
    DrugDropdownRelatedData: 34,
    DeviceDropdownRelatedData: 35,
    ClinicalDropdownRelatedData: 36,
    LookupDisplay: 37,
    PricingDetailsDisplay: 38,
    DateRangePicker: 39,
    LookupAutoComplete: 41,
    DecimalUptoTwoDigit: 45,
    TextareaRichtext: 48,
    NumberRange: 55,
    WebLinks : 56,
    DecimalMonthRange:60,
    Number : 65,
}


export const Status = {
    RC: 1,
    A: 2,
    AR:3,
    WIP: 4,
    WIPR: 5,
    SFR: 6,
    SFRR: 7,
    R: 8,
    SFF: 9,
    F: 10,
    ALL: 11
}

export const  DefineValidator = 
{
     HTAStatusPlanned : "134",
     HTAStatusOngoing : "135",
     HTAStatusOngoingDraft : "136",
     HTAStatusCompleted : "137",
     HTAStatusPublished : "138",
     HTAStatusCancelled : "139",
     HTAStatusSuspended : "140",
     AgencyHas : "45",
     AgencyGBA : "41",
     AgencyIQWiG : "52",
     AgencyNiceUK : "96",
     CountryIdGermany : "10",
     CountryIdFrance : "9",
     HTAAssessmentTypeId : "18",
     Single_drug_assessment : "141",
     Multipl_drug_assessment : "142",
     Medical_device_assessment : "143",
     Procedureintervention_assessment : "144",
     NoRecommendation : "205",
     PositiveRecommendation : "206",
     PositiveWRestrictions : "207",
     NegativeRecommendation : "208",
     MultipleRecommendations : "3162",
}

export const UserRoles = {
    QAdmin: 1,
    QReviewer: 2,
    QAssociate: 3,
    QInternal: 4,
    EDataEntry: 5,
    EClient: 6,
    EExpert: 7,
    QTeamLead:11
}


export const LookupIds = {
    AppJobRole: 1,
    AccessSchemeType: 2,
    Asmr: 3,
    AssessmentType: 4,
    BenefitRating: 5,
    CareSettings: 6,
    CeDrivers: 7,
    ChangeAfterPrelimReasons: 8,
    ClinicalNegatives: 9,
    ClinicalPositives: 10,
    ClinicalTrials: 11,
    ComparativeUnits: 12,
    Comparator: 13,
    Currency: 14,
    DataType: 15,
    DecisionRationale: 16,
    Design: 17,
    DrugClass: 18,
    DrugEconomicType: 19,
    DrugEconomicModelType: 20,
    EconomicNegatives: 21,
    EconomicPositives: 22,
    EconomincUncertainty: 23,
    EndPoints: 24,
    EvidenceSynthesis: 25,
    EvidenceType: 26,
    HtaStatus: 27,
    HtaType: 28,
    Ia: 29,
    Impact: 30,
    IndependentReviewers: 31,
    InternventionType: 32,
    Language: 33,
    LevelOfEvidenceRating: 34,
    Manufacturer: 35,
    Masking: 36,
    Observational: 37,
    OncospecificDrugTypes: 38,
    OsAdjustmentMethods: 39,
    OsExtrapolationTypes: 40,
    Perspectives: 41,
    Population: 42,
    ProcedureIntervention: 43,
    ProMeasures: 44,
    PValues: 45,
    QolInstruments: 46,
    Recommendations: 47,
    Regulators: 48,
    RegulatoryApprovalTypes: 49,
    RegulatoryStatus: 50,
    ReimbursementRatesFrance: 51,
    RejectionDrivers: 52,
    ReportLength: 53,
    RestrictionTypes: 54,
    Routes: 55,
    RwdAreas: 56,
    Smr: 57,
    SpecialConsiderations: 58,
    StaType: 59,
    StudyAim: 60,
    StudyControl: 61,
    TherapeuticArea: 62,
    TherapeuticAreas: 63,
    Timepoints: 64,
    TimeUnit: 65,
    TreatmentAllocation: 66,
    TrialPhase: 67,
    Unit1: 68,
    Unit2: 69,
    Units: 70,
    UtilityMeasure: 71,
    UtilitySource: 72,
    CountryType: 73,
    ZinlListing: 74,
    EconomicAnalysisTypes: 75,
    EconomicModelTypes: 76,
    DrugtoMarket: 77,
    PackSizeUnit: 78,
    DoseUnit: 79,
    StatisticalDesign: 80,
    Conclusion: 81,
    AgencyTier: 82,
    HTABreadth: 83,
    HTADrugs: 84,
    AnalyticalTechnique: 85,
    ModelingTechnique: 86,
    SAMethods: 87,
    MSDocuments: 88,
    AgencyScope: 89,
    AgencyImpact: 90,
    Implication: 91,
    AgencyPerspective: 92,
    HTAArchetype: 93,
    SystemArchetype: 94,
    HTAAgencyEstablished: 95,
    DegreeofCentralization: 96,
    HTAAgencyIndependence: 97,
    HTAImpactonPR: 98,
    AvailabilityofClinicalPracticeGuidelines: 99,
    HTAPolicyFocus: 100,
    PharmacoeconomicGuidelines: 101,
    HTAProcessTransparency: 102,
    ManufacturerEngagement: 103,
    TherapeuticBenefit: 104,
    IncorporationofPatientBenefit: 105,
    InnovativeCharacteristics: 106,
    PharmacoeconomicHEModel: 107,
    BudgetImpactAnalysis: 108,
    PublicHealthImpact: 109,
    RDTechnologyTransfer: 110,
    ReferencePriceLevel: 111,
    CountryRegion: 112,
    CountryGrouping: 113,
    YesNoNA: 114,
    DrugGeneric: 115,

    // Data Source Related
    DS_Country: 116,
    DS_Agency: 117,
    DS_Drug: 118,
    DS_Disease: 119,
    DS_TherapeuticArea: 120,
    DS_Status: 121,
    DS_DrugGeneric: 122,
    DS_DeviceGeneric: 123,
    DS_DiseaseSubType: 124,
    DS_Device: 125,
    DS_UserModifiedBy: 126,
    DS_Client: 127,
    DS_AgencyAbbreviation: 128,
    DS_User: 130,
    DS_Role: 132,
    DS_ClinicalTrialsIncluded: 133,
    Agency_Search: 134,
    ClinicalData_Search: 135,
    Country_Search: 136,
    HTARecord_Search: 137,
    Policy_Search: 138,
    Pricing_Search: 139,
    Regulatory_Search: 140,
    DS_ReviewerUser: 141,
    DS_HTARecord: 143,
    DS_SuperSearchDisease: 169,
    DS_Drug_ClientNews: 193,
    DS_PackSize: 199,
    DS_CountryPricing  : 200,
    DS_DrugPricing: 201,
    SafetyConcerns_MedDRA: 322,
    FullIndications: 329,
    HtaRecordCOA_Instrument: 334,
    SafetyConcern_Indication: 336,
    ApprovedRegulatoryInfo: 338,
    DS_InternalProjectCode: 350,
    ActiveSubstance: 351,
    DS_GlobalBrandName: 352,
//    DS_SuperSearchCategory: 372,
//    DS_SuperSearchCategory: 373,
    PayerAcceptance: 377,
    RWEDatasource: 378,
    TypeofDatasource: 379,
    Rationale: 380,
    RWE_Clinical_Positive: 381,
    RWE_Clinical_Negative: 382,
    RWE_Economic_Positives: 383,
    RWE_Economic_Negatives: 384,
    InCountryRegion: 392,
    RegionalBrandName: 394,
    ReimbursementCondition: 398,
    AgreementBenefitForPayer: 402,
    AgreementBenefitForManufacturer: 403,
    AgreementOutcomesTracked: 409,
    AgreementPatientsIncluded: 410,
    ProductDosage: 411,
    SourceOfComparator: 414,
    Extrapolation: 415,
    DS_Country_RegulatoryBodyAbbr: 418,
    DS_Country_ReimbursementAbbr: 419,
    DS_Country_PricingBodyAbbr: 420,
    DS_Country_HTABody: 421,
    RapidReview: 426,
    HTA_Drug_Combination: 427,
    DS_DrugGenericNonReviewed: 434,
    DS_AgreementHTARecord: 453,
    DS_AgreementReimbursementStatus: 454,
    DS_AgreementRelatedAgreements: 455
}


export const isCommaSeparatedNumbers: (input: string) => boolean = (input: string) => {
    if(typeof input != "string" || input == '')
      return false;
    // Split the input string by commas
    const elements = input.split(',');

    // Check if every element is a number
    const allNumbers = elements.every((element: any) => {
        // Trim the element to remove any extra whitespace
        const trimmedElement = element.trim();

        // Check if the trimmed element is a number
        return !isNaN(trimmedElement) && trimmedElement !== '';
    });

    return allNumbers;
}