import React, { useContext, useEffect, useState } from "react";
import { useAppSelector } from "../../../../store/hooks";
import Element from "./Utility";

interface IHTARecord_SubGroupProp {
  fieldID: number;
  datasourceName: string;
  section: string;
}

const HTARecord_SubGroup = ({
    fieldID,
    datasourceName,
    section,
  }:IHTARecord_SubGroupProp) => {
    const [subGroup_data, setSubGroupData] = useState<any>([]);
    const subPageDate = useAppSelector((state) => state.multiselect.subpageData);
    
      useEffect(() => {
        setSubGroupData(subPageDate["sub_group_details"]);
      }, [subPageDate]);

      // console.log('field id: ', fieldID);
      // console.log('subGroup_data: ', subGroup_data);


      return(
        <>
        {subGroup_data && subGroup_data.length > 0 && 
        subGroup_data.map((el: any, index: number) => (
        <div className="col-sm-12">
        <>
        <Element
        label={`Subgroup ${index+1}`}
        value={String(el["SubGroupIDText"])}
        datasourceName={datasourceName}
        section={section}
        showInfoIcon = {false}
        />
        </>
        {fieldID == 526 && el["Recommendation"] != null && (
           <>
           <Element
           label={`Recommendation - Subgroup ${index+1}`}
           value={String(el["Recommendation"])}
           datasourceName={datasourceName}
           section={section}
           showInfoIcon = {false}
           />
           </> 
        )}
        {fieldID == 526 && el["SMR"] != null && (
           <>
           <Element
           label={`SMR - Subgroup ${index+1}`}
           value={String(el["SMR"])}
           datasourceName={datasourceName}
           section={section}
           showInfoIcon = {false}
           />
           </> 
        )}
        {fieldID == 526 && el["ASMR"] != null && (
           <>
           <Element
           label={`ASMR - Subgroup ${index+1}`}
           value={String(el["ASMR"])}
           datasourceName={datasourceName}
           section={section}
           showInfoIcon = {false}
           />
           </> 
        )}
        {fieldID == 526 && el["BenifitRating"] != null && (
           <>
           <Element
           label={`BenifitRating - Subgroup ${index+1}`}
           value={String(el["BenifitRating"])}
           datasourceName={datasourceName}
           section={section}
           showInfoIcon = {false}
           />
           </> 
        )}
        </div>
        ))}
        </>
      )
}

export default HTARecord_SubGroup;