import React from "react";
import ArmsAndCohorts from "./ClinicalData_ArmAndCohorts";
import CoaInstrumentForClinicalData from "./ClinicalData_CoaInstrument";
import EndpointClinicalData from "./ClinicalData_Endpoints";
import DrugData_BrandLicense from "./DrugData_BrandLicense";
import CoaInstrumentForHTA from "./HTARecord_CoaInstrument";
import RWE from "./HTARecord_RWE";
import CoaInstrumentForRegulatoryWatch from "./RegulatoryWatch_CoaInstrument";
import Regulatory_DosageAndMode from "./Regulatory_DosageAndMode";
import ProductStrengthForReimbursement from "./ReimbursementStatus_ProductStrength";
import RegulatoryWatch_ApprovedIndications from "./RegulatoryWatch_ApprovedIndications";
import HTARecord_ExtrapolationForm from "../Forms/HTARecord_ExtrapolationForm";
import { useAppSelector } from "../../../../store/hooks";
import { EditorState } from "draft-js";
import DrugData_Lov from "./DrugData_Lov";

interface ISubPageViewSummaryProp {
  rows: any[];
  el: any;
  dataSourceID: number;
  datasourceName: string;
  validateCriteria : any;
  values:any;
  RichtextFieldChanged: (name: string, value: EditorState) => void;
  fieldChanged:(name?: string, value?: string, obj?: any) => void;
}

const SubPageViewSummary = ({
  rows,
  el,
  dataSourceID,
  datasourceName,
  validateCriteria,
  values,
  RichtextFieldChanged,
  fieldChanged
}: ISubPageViewSummaryProp) => {
  const selectedLookupGlobe = useAppSelector(
    (state) => state.multiselect.selectedLookupGlobe
);
  return (
    <>
      {el.name == "Clinical outcome assessments" && dataSourceID == 1 && (
        <CoaInstrumentForHTA
          fieldInfo={rows.filter(
            (x) => x.sectionId == el.id
            // &&
            // validateCriteria(x)
          )}
          datasourceName={datasourceName}
          section={el.name}
        />
      )}
      {el.name == "Real world evidence (RWE)" && dataSourceID == 1 && (
        <RWE
          fieldInfo={rows.filter(
            (x) => x.sectionId == el.id
            // &&
            // validateCriteria(x)
          )}
          datasourceName={datasourceName}
          section={el.name}
        />
      )}
            {
        el.name == "Economic analysis" && selectedLookupGlobe.HTARecord_ExtrapolationMultiselet?.length > 0 &&
        <HTARecord_ExtrapolationForm
          values={values}
          fieldChanged={()=>{}}
          RichtextFieldChanged={RichtextFieldChanged}
          datasourcename={datasourceName}          
          datasourceId={dataSourceID}
          formMode={"read"}
 />
        //HTARecord_ExtrapolationMultiselet
      }
      {el.name == "Clinical outcome assessments" && dataSourceID == 2 && (
        <CoaInstrumentForClinicalData
          fieldInfo={rows.filter(
            (x) => x.sectionId == el.id
            // &&
            // validateCriteria(x)
          )}
          datasourceName={datasourceName}
          section={el.name}
        />
      )}
      {el.name == "Arms and cohorts" && dataSourceID == 2 && (
        <ArmsAndCohorts
          fieldInfo={rows.filter(
            (x) => x.sectionId == el.id
            // &&
            // validateCriteria(x)
          )}
          datasourceName={datasourceName}
          section={el.name}
        />
      )}
      {el.name.toLowerCase() == "endpoints and results" &&
        dataSourceID == 2 && (
          <EndpointClinicalData
            fieldInfo={rows.filter(
              (x) => x.sectionId == el.id
              //  &&
              // validateCriteria(x)
            )}
            datasourceName={datasourceName}
            section={el.name}
          />
        )}
      {el.name.toLowerCase() == "dosage and treatment mode" &&
        dataSourceID == 3 && (
          <Regulatory_DosageAndMode
            fieldInfo={rows.filter((x) => x.sectionId == el.id)}
            datasourceName={datasourceName}
            section={el.name}
          />
        )}
      {el.name.toLowerCase() == "clinical outcome assessments" &&
        dataSourceID == 3 && (
          <CoaInstrumentForRegulatoryWatch
            fieldInfo={rows.filter((x) => x.sectionId == el.id)}
            datasourceName={datasourceName}
            section={el.name}
          />
        )}
      {el.name.toLowerCase() == "intervention" && dataSourceID == 25 && (
        <ProductStrengthForReimbursement
          fieldInfo={rows.filter((x) => x.sectionId == el.id)}
          datasourceName={datasourceName}
          section={el.name}
        />
      )}
      {el.name.toLowerCase() == "regional information" &&
        dataSourceID == 20 && (
          <DrugData_BrandLicense
            fieldInfo={rows.filter((x) => x.sectionId == el.id)}
            datasourceName={datasourceName}
            section={el.name}
          />
        )}
      {el.name.toLowerCase() == "patent information" &&
        dataSourceID == 20 && (
          <DrugData_Lov
            fieldInfo={rows.filter((x) => x.sectionId == el.id)}
            datasourceName={datasourceName}
            section={el.name}
          />
        )}
      {el.name.toLowerCase() == "approved indications" && dataSourceID == 3 && (
        <RegulatoryWatch_ApprovedIndications
          fieldInfo={rows.filter(
            (x) => x.sectionId == el.id && validateCriteria(x)
          )}
          datasourceName={datasourceName}
          section={el.name}
        />
      )}
    </>
  );
};

export default SubPageViewSummary;