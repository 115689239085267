import React, { useState, useEffect } from "react";
import Chart from "react-apexcharts";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMedkit } from "@fortawesome/free-solid-svg-icons";
import { useAppSelector } from "../../../store/hooks";
import Loader from "../../../utils/Loader";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";

type cardObj = {
  country?: string;
  currency?: string;
  priceForCountry?: string;
  priceNameForCountry?: string;
  recentDate?: string;
};

const PricingAnalysis = () => {
  const state = useAppSelector((state: any) => state);
  const [data] = useState<any[]>(state.productProfile.pricingData);
  const [loading, setLoading] = useState<boolean>(false);
  const [selection, setSelection] = useState<any[]>([]);
  const [value, setValue] = useState<string>("");
  const [cards, setCards] = useState<any[]>([]);
  const [dateList, setDateList] = useState<any[]>([]);
  const [chartOptions, setChartOptions] = useState<any>({});
  const [series, setSeries] = useState<any[]>([]);

  const [defaultChartOption] = useState({
    chart: {
      id: "line-chart",
      type: "line",
      toolbar: {
        show: false,
      },
      animations: {
        enabled: false,
      },
    },
    xaxis: {
      // categories: ["January", "February", "March", "April", "May", "June"],
      categories: [],
      type: "category",
      tickAmount: 20,
      title: {
        text: "Time",
      },
    },
    yaxis: {
      min: 0,
      max: 150,
      labels: {
        formatter: function (val: any) {
          if (val !== null) {
            return val + "%";
          } else return "No data";
        },
        offsetX: -5, // To adjust the position of the Y-axis labels
      },
      title: {
        text: "Percentage price change from launch price in the respective market",
        offsetX: -2, // To adjust the position of the currency legend
      },
    },
    colors: ["#065280"],
    legend: {
      position: "top",
      offsetY: 5,
      show: true,
      showForSingleSeries: true,
      horizontalAlign: "right",
    },
    tooltip: {
      x: {
        show: true, // Show the x-axis value in the tooltip
      },
    },
  });

  const [dateRef] = useState<any[]>([
    "PricePointsDate15",
    "PricePointsDate14",
    "PricePointsDate13",
    "PricePointsDate12",
    "PricePointsDate11",
    "PricePointsDate10",
    "PricePointsDate9",
    "PricePointsDate8",
    "PricePointsDate7",
    "PricePointsDate6",
    "PricePointsDate5",
    "PricePointsDate4",
    "PricePointsDate3",
    "PricePointsDate2",
    "PricePointsDateMostRecent",
  ]);

  const formatData = () => {
    setLoading(true);
    setChartOptions(defaultChartOption);
    setSeries([]);

    const filteredData = data.filter(
      (item: any) => item.ParallelImport === false
    );
    let sortedData = [...filteredData];
    // sort the data by country name
    sortedData.sort((a: any, b: any) => {
      let textA = a.Country.toUpperCase();
      let textB = b.Country.toUpperCase();
      return textA < textB ? -1 : textA > textB ? 1 : 0;
    });

    let radioList: any[] = [];
    for (let i = 0; i < sortedData.length; i++) {
      // console.log(sortedData[i], "sortedData[i]");
      if (sortedData[i].Name) {
        let value = sortedData[i].Name.split(/[-]+/).pop().trim();
        if (!radioList.includes(value)) radioList.push(value);
      }
    }
    setValue(radioList[radioList.length - 1]);
    setSelection(radioList);
    filterData(radioList[radioList.length - 1]);
    setLoading(false);
  };

  useEffect(() => {
    formatData();
  }, []);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue((event.target as HTMLInputElement).value);
    setSeries([]);
    setChartOptions({});
    setLoading(true);
    setTimeout(() => {
      filterData(event.target.value);
    }, 30);
  };

  const createCardObj = (item: any) => {
    let tmpCardsData: any[] = [];
    for (let i = 0; i < item.length; i++) {
      let cardObj: cardObj = {};
      if (item[i].Country == "United States of America")
        cardObj.country = "USA";
      else if (item[i].Country == "United Kingdom") cardObj.country = "UK";
      else cardObj.country = item[i].Country;
      cardObj.currency = item[i].Currency;
      cardObj.priceForCountry = (
        item[i].PricePointsMSPMostRecent !== null
          ? Number(
              parseFloat(item[i].PricePointsMSPMostRecent.toFixed(2))
            ).toLocaleString("en", { minimumFractionDigits: 2 })
          : item[i].PricePointsLPMostRecent !== null
          ? Number(
              parseFloat(item[i].PricePointsLPMostRecent.toFixed(2))
            ).toLocaleString("en", { minimumFractionDigits: 2 })
          : item[i].PricePointsRetailPharmacyPriceMostRecent !== null
          ? Number(
              parseFloat(
                item[i].PricePointsRetailPharmacyPriceMostRecent.toFixed(2)
              )
            ).toLocaleString("en", { minimumFractionDigits: 2 })
          : Number(
              parseFloat(
                item[i].PricePointsPriceToHospitalMostRecent.toFixed(2)
              )
            ).toLocaleString("en", { minimumFractionDigits: 2 })
      ).toString();
      cardObj.priceNameForCountry =
        item[i].PricePointsMSPMostRecent !== null
          ? "MSP"
          : item[i].PricePointsLPMostRecent !== null
          ? "LP"
          : item[i].PricePointsRetailPharmacyPriceMostRecent !== null
          ? "RPP"
          : "PTH";
      let mm = new Date(item[i].PricePointsDateMostRecent).getMonth() + 1;
      let yyyy = new Date(item[i].PricePointsDateMostRecent).getFullYear();
      let paddedMm: string = mm < 10 ? `0${mm}` : `${mm}`;
      cardObj.recentDate = paddedMm + "/" + yyyy;

      tmpCardsData.push(cardObj);
    }
    // console.log(tmpCardsData, "tmpCardsData");
    setCards(tmpCardsData);
  };

  const filterData = (value: string) => {
    // reset chart
    setLoading(true);
    setDateList([]);
    setChartOptions(defaultChartOption);
    setSeries([]);

    // find data
    let filteredData = [...data];
    filteredData = filteredData.filter((item: any) => {
      if (item.Name.includes(value) && item.ParallelImport === false) {
        return item;
      }
    });

    let sortData = filteredData.sort((a: any, b: any) => {
      return a.ID - b.ID;
    });

    // console.log(sortData)

    const removeDuplicates = sortData.filter((obj, index) => {
      return index === sortData.findIndex((o) => obj.Country === o.Country);
    });
    // console.log(removeDuplicates, "removeDuplicates", series);

    createCardObj(removeDuplicates);
    let dateArray = createDateList(removeDuplicates);
    createChartData(removeDuplicates, dateArray);
    // return filteredData;
  };

  const createDateList = (item: any) => {
    let tmpDateList: any[] = [];

    for (let i = 0; i < dateRef.length; i++) {
      for (let j = 0; j < item.length; j++) {
        if (item[j][dateRef[i]] !== null) {
          let date = item[j][dateRef[i]];
          let newDate = addDate(date);
          if (!tmpDateList.includes(newDate)) tmpDateList.push(newDate);
        }
      }
    }

    let sortedData = tmpDateList.sort((date1, date2) => {
      const [month1, year1] = date1.split("/").map(Number);
      const [month2, year2] = date2.split("/").map(Number);

      if (year1 !== year2) {
        return year1 - year2;
      } else {
        return month1 - month2;
      }
    });

    setDateList(sortedData);
    let tmp = defaultChartOption;
    tmp.xaxis.categories = sortedData;
    setChartOptions(tmp);
    // console.log(sortedData, "sortedData", sortedData.length);
    return sortedData;
  };

  const createChartData = (item: any, dateArray: any) => {
    setSeries([]);
    setChartOptions((prevOptions: any) => ({
      ...prevOptions,
      xaxis: {
        ...prevOptions.xaxis,
        categories: [],
      },
    }));
    let pricePointsMSP = [
      "PricePointsMSP15",
      "PricePointsMSP14",
      "PricePointsMSP13",
      "PricePointsMSP12",
      "PricePointsMSP11",
      "PricePointsMSP10",
      "PricePointsMSP9",
      "PricePointsMSP8",
      "PricePointsMSP7",
      "PricePointsMSP6",
      "PricePointsMSP5",
      "PricePointsMSP4",
      "PricePointsMSP3",
      "PricePointsMSP2",
      "PricePointsMSPMostRecent",
    ];

    let pricePointsLP = [
      "PricePointsLP15",
      "PricePointsLP14",
      "PricePointsLP13",
      "PricePointsLP12",
      "PricePointsLP11",
      "PricePointsLP10",
      "PricePointsLP9",
      "PricePointsLP8",
      "PricePointsLP7",
      "PricePointsLP6",
      "PricePointsLP5",
      "PricePointsLP4",
      "PricePointsLP3",
      "PricePointsLP2",
      "PricePointsLPMostRecent",
    ];

    let pricePointsRetailPharmacyPrice = [
      "PricePointsRetailPharmacyPrice15",
      "PricePointsRetailPharmacyPrice14",
      "PricePointsRetailPharmacyPrice13",
      "PricePointsRetailPharmacyPrice12",
      "PricePointsRetailPharmacyPrice11",
      "PricePointsRetailPharmacyPrice10",
      "PricePointsRetailPharmacyPrice9",
      "PricePointsRetailPharmacyPrice8",
      "PricePointsRetailPharmacyPrice7",
      "PricePointsRetailPharmacyPrice6",
      "PricePointsRetailPharmacyPrice5",
      "PricePointsRetailPharmacyPrice4",
      "PricePointsRetailPharmacyPrice3",
      "PricePointsRetailPharmacyPrice2",
      "PricePointsRetailPharmacyPriceMostRecent",
    ];

    let pricePointsPriceToHospital = [
      "PricePointsPriceToHospital15",
      "PricePointsPriceToHospital14",
      "PricePointsPriceToHospital13",
      "PricePointsPriceToHospital12",
      "PricePointsPriceToHospital11",
      "PricePointsPriceToHospital10",
      "PricePointsPriceToHospital9",
      "PricePointsPriceToHospital8",
      "PricePointsPriceToHospital7",
      "PricePointsPriceToHospital6",
      "PricePointsPriceToHospital5",
      "PricePointsPriceToHospital4",
      "PricePointsPriceToHospital3",
      "PricePointsPriceToHospital2",
      "PricePointsPriceToHospitalMostRecent",
    ];

    let tmpSeries: any[] = [];
    for (let i = 0; i < item.length; i++) {
      let obj: any = {};
      if (item[i].Country == "United States of America") obj["name"] = "USA";
      else if (item[i].Country == "United Kingdom") obj["name"] = "UK";
      else obj["name"] = item[i].Country;
      obj["data"] = [];
      obj["dateList"] = [];
      for (let j = 0; j < pricePointsMSP.length; j++) {
        if (item[i].PricePointsMSPMostRecent !== null) {
          let refNumber = findRefNumber(item[i], pricePointsMSP);
          let percentage =
            item[i][pricePointsMSP[j]] !== null
              ? calculatePercentage(refNumber, item[i][pricePointsMSP[j]])
              : null;
          if (percentage !== null) obj["data"].push(percentage);
          if (addDate(item[i][dateRef[j]]) !== null)
            obj["dateList"].push(addDate(item[i][dateRef[j]]));
        } else if (item[i].PricePointsLPMostRecent !== null) {
          let refNumber = findRefNumber(item[i], pricePointsLP);
          let percentage =
            item[i][pricePointsLP[j]] !== null
              ? calculatePercentage(refNumber, item[i][pricePointsLP[j]])
              : null;
          if (percentage !== null) obj["data"].push(percentage);
          if (addDate(item[i][dateRef[j]]) !== null)
            obj["dateList"].push(addDate(item[i][dateRef[j]]));
        } else if (item[i].PricePointsRetailPharmacyPriceMostRecent !== null) {
          let refNumber = findRefNumber(
            item[i],
            pricePointsRetailPharmacyPrice
          );
          let percentage =
            item[i][pricePointsRetailPharmacyPrice[j]] !== null
              ? calculatePercentage(
                  refNumber,
                  item[i][pricePointsRetailPharmacyPrice[j]]
                )
              : null;
          if (percentage !== null) obj["data"].push(percentage);
          if (addDate(item[i][dateRef[j]]) !== null)
            obj["dateList"].push(addDate(item[i][dateRef[j]]));
        } else if (item[i].PricePointsPriceToHospitalMostRecent !== null) {
          let refNumber = findRefNumber(item[i], pricePointsPriceToHospital);
          let percentage =
            item[i][pricePointsPriceToHospital[j]] !== null
              ? calculatePercentage(
                  refNumber,
                  item[i][pricePointsPriceToHospital[j]]
                )
              : null;
          if (percentage !== null) obj["data"].push(percentage);
          if (addDate(item[i][dateRef[j]]) !== null)
            obj["dateList"].push(addDate(item[i][dateRef[j]]));
        }
      }
      tmpSeries.push(obj);
    }

    if (dateArray.length === 1) {
      let singleDataPointArray: any[] = [];
      for (let i = 0; i < tmpSeries[0].data.length; i++) {
        if (tmpSeries[0].data[i] !== null) {
          singleDataPointArray.push(tmpSeries[0].data[i]);
        }
      }
      // console.log(singleDataPointArray);
      if (singleDataPointArray.length === 1) {
        tmpSeries[0].data = singleDataPointArray;
      }
    }
    
    if (dateArray.length > 15) {
      for (let i = 0; i < tmpSeries.length; i++) {
        let dateIndices = tmpSeries[i].dateList.map((date: any) =>
          dateArray.indexOf(date)
        );
        let dateArrayLength = dateArray.length;

        // Initialize a new array with null values
        let newDataArray = new Array(dateArrayLength).fill(null);

        // Populate the new array with actual data values at dateIndices
        for (let j = 0; j < dateIndices.length; j++) {
          newDataArray[dateIndices[j]] = tmpSeries[i].data[j];
        }

        // Replace the data array with the new populated array
        tmpSeries[i].data = newDataArray;
      }
    }

    console.log(tmpSeries, "tmpSeries", dateArray);
    setSeries(tmpSeries);
    chartLineColor(tmpSeries.length);
    setChartOptions((prevOptions: any) => ({
      ...prevOptions,
      xaxis: {
        ...prevOptions.xaxis,
        categories: dateArray,
      },
    }));
    if (series.length > 0) setLoading(false);
  };

  const findRefNumber = (item: any, pricePoints: any) => {
    for (let i = 0; i < pricePoints.length; i++) {
      if (item[pricePoints[i]] !== null) {
        return item[pricePoints[i]];
      }
    }
  };

  const calculatePercentage = (refNumber: number, currentNumber: number) => {
    let percentage = Math.round((currentNumber / refNumber) * 100);
    return percentage;
  };

  const chartLineColor = (num: number) => {
    let tmp = defaultChartOption;
    let tmpColor = tmp.colors;
    if (num >= 1) tmpColor[1] = "#34b2e3";
    if (num >= 2) tmpColor[2] = "#6fb730";
    if (num >= 3) tmpColor[3] = "#fe8a12";
    if (num >= 4) tmpColor[4] = "#027223";
    if (num >= 5) tmpColor[5] = "#ff530d";
    setChartOptions((prevOptions: any) => ({
      ...prevOptions,
      colors: tmpColor,
    }));
  };

  const addDate = (date: string) => {
    if (date === null || date === undefined) return null;
    const splitDate = date.split("T")[0].split("-");
    let month = Number(splitDate[1]);
    let year = Number(splitDate[0]);
    let day = Number(splitDate[2]);
    const lastDayOfMonth = new Date(year, month, 0).getDate();
    const lastDayOfYear = new Date(year, 11, 31).getDate();
    let newDate = "";
    if (day === lastDayOfMonth) {
      if (month === 12) {
        newDate = `${1}/${year + 1}`;
      } else {
        newDate = `${month + 1}/${year}`;
      }
    } else if (day === lastDayOfYear && month === 12) {
      newDate = `${1}/${year + 1}`;
    } else {
      newDate = `${month}/${year}`;
    }
    return newDate;
  };

  return (
    <div className="pricingAnalysisContainer">
      <div className="pricingAnalysisHeader">
        <span>
          <FontAwesomeIcon icon={faMedkit} />
        </span>
        <span className="title"> Pack size(s) </span>
      </div>

      {!loading &&
        series.length > 0 &&
        chartOptions.xaxis.categories.length > 0 && (
          <div className="pricingAnalysisBody">
            <div className="selectionBox">
              <FormControl>
                <RadioGroup
                  row
                  aria-labelledby="demo-controlled-radio-buttons-group"
                  name="controlled-radio-buttons-group"
                  value={value}
                  onChange={handleChange}
                >
                  {selection.map((item, index) => (
                    <FormControlLabel
                      key={index}
                      value={item}
                      control={<Radio />}
                      label={item}
                    />
                  ))}
                </RadioGroup>
              </FormControl>
            </div>
            <div className="cardSection">
              {cards.map((item, index) => (
                <div className="cardList" key={item.country + "_" + index}>
                  <div className="cardHeader">
                    <span className="country">{item.country}</span>
                  </div>
                  <div className="cardBody">
                    <div className="price">
                      <span style={{ fontWeight: 700 }}>
                        {item.priceNameForCountry}:{" "}
                      </span>
                      <span>
                        {item.priceForCountry} {item.currency}
                      </span>
                    </div>
                    <div className="date">
                      <span style={{ fontWeight: 700 }}> Latest date: </span>
                      <span className="recentDate">{item.recentDate}</span>
                    </div>
                  </div>
                </div>
              ))}
            </div>
            <div style={{ marginTop: 40, marginLeft: 50 }}>
              <Chart
                options={chartOptions}
                series={series.length > 0 ? series : []}
                type="line"
                height={450}
                width={"85%"}
              />
            </div>
          </div>
        )}
    </div>
  );
};

export default PricingAnalysis;
