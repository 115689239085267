// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.potential-implications table {
    width: 100%;
}
.potential-implications table, .potential-implications th, .potential-implications td {
    border: 1px solid #ddd;
    padding: 0.75rem;
    vertical-align: top;
}

.potential-implications thead {
    /* background-color: #457CB3 !important; */
    font-weight: bold;
    font-size: 13px;
    background-image: none;
}

.potential-implications table td:nth-child(2){
    text-align: center;
}

#legends {
    padding: 10px;
    display: flex;
    justify-content: space-between;
}
.potential-implications tr:nth-child(odd) {
    background-color: #E7E9EF;
}`, "",{"version":3,"sources":["webpack://./src/components/Forms/SubPages/Views/Policy_PotentialImplications.css"],"names":[],"mappings":"AAAA;IACI,WAAW;AACf;AACA;IACI,sBAAsB;IACtB,gBAAgB;IAChB,mBAAmB;AACvB;;AAEA;IACI,0CAA0C;IAC1C,iBAAiB;IACjB,eAAe;IACf,sBAAsB;AAC1B;;AAEA;IACI,kBAAkB;AACtB;;AAEA;IACI,aAAa;IACb,aAAa;IACb,8BAA8B;AAClC;AACA;IACI,yBAAyB;AAC7B","sourcesContent":[".potential-implications table {\n    width: 100%;\n}\n.potential-implications table, .potential-implications th, .potential-implications td {\n    border: 1px solid #ddd;\n    padding: 0.75rem;\n    vertical-align: top;\n}\n\n.potential-implications thead {\n    /* background-color: #457CB3 !important; */\n    font-weight: bold;\n    font-size: 13px;\n    background-image: none;\n}\n\n.potential-implications table td:nth-child(2){\n    text-align: center;\n}\n\n#legends {\n    padding: 10px;\n    display: flex;\n    justify-content: space-between;\n}\n.potential-implications tr:nth-child(odd) {\n    background-color: #E7E9EF;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
