interface IdiseaseLevel {
  [key: string]: string;
};

export const diseaseLevel: IdiseaseLevel = {
  0: "T",
  1: "D",
  2: "D1",
  3: "D2",
  4: "D3",
  5: "D4",
};

export const findChild = (text: string, array: any[]) => {
  const getNodes = (result: any, object: any) => {
    if (object.label === text) {
      return object.children;
    }
    if (Array.isArray(object.children)) {
      const nodes = object.children.reduce(getNodes, []);
      if (nodes?.length) return nodes;
    }
    return result;
  };

  return array.reduce(getNodes, []);
};

export const findChildIds = (arr: any[]) => {
  const fci = (r: any[], object: any) => {
    r.push(object.value);
    if (Array.isArray(object.children)) {
      const ids = object.children.reduce(fci, []);
      r.concat(ids);
    }
    return r;
  };
  return arr.reduce(fci, []);
};

