import React, { useEffect, useState } from "react";
import { Field } from "../../../../store/DataSourceMetaData";
import AddIcon from "@mui/icons-material/Add";
// import DeleteIcon from "@mui/icons-material/Delete";
import RemoveIcon from "@mui/icons-material/Remove";
import { useFetchLookupItemsQuery } from "../../../../store/LookupApiSlice";
import AutocompleteWithAdd from "../../AutocompleteWithAdd";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import CircularProgress from "@mui/material/CircularProgress";
import { IForm } from "../../Editor";
// import apiCall from "../../../service/apiCall";
import DraggablePopover from "../../../Popups/Popup_MaterialUI";
import PostCall from "../../../service/postCall";

interface HTA_SubgroupProps {
  field: Field;
  values: any;
  fieldChanged: (name?: string, value?: string, obj?: any) => void;
  // subGroup: any[];
  // agencyId: number;
  // countryId: number;
}

interface lookupItemT {
  id: string;
  label: string;
}

const HTARecord_Form_Subgroup = ({
  field,
  values,
  fieldChanged,
}: HTA_SubgroupProps) => {
  // 184 -
  // 47 - Recommendation

  const [countryId, setCountryId] = useState(null);
  const [agencyId, setAgencyId] = useState(null);
  const [subGroup, setSubGroup] = useState([]);
  const [items, setItems] = useState<lookupItemT[]>([]);
  const [reimbursementRateItems, setReimbursementRateItems] = useState<lookupItemT[]>([]);
  const [smrItems, setSmrItems] = useState<lookupItemT[]>([]);
  const [asmrItems, setAsmrItems] = useState<lookupItemT[]>([]);
  const [benefitRatingItems, setBenefitRatingItems] = useState<lookupItemT[]>([]);
  const [openRecommendation, setOpenRecommendation] = useState<boolean[]>([]);
  const [openReimbursementRate, setOpenReimbursementRate] = useState<boolean[]>(
    []
  );
  const [openSMR, setOpenSMR] = useState<boolean[]>([]);
  const [openASMR, setOpenASMR] = useState<boolean[]>([]);
  const [openBenifitRating, setOpenBenifitRating] = useState<boolean[]>([]);
  const [lookupLoading, setLookupLoading] = useState<boolean>(false);
  const [showSubgroupModal, setShowSubgroupModal] =
    React.useState<HTMLButtonElement | null>(null);

  // const getLookupIdByName = async (name: string) => {
  //     const result = await apiCall(`/api/lookup/getLookupIdByName/${name}`);
  //     console.log('result lookup id: ', result);
  //     return result.data;
  // };

  if (field) {
    let lookupID: number;
    if (field.name == "IsSubgroupEnabled") {
      lookupID = 184; // subgroup
      // lookupID = getLookupIdByName("Subgroups");
    } else {
      lookupID = field.lookupId; // recommendation
    }
    const { data, isLoading } = useFetchLookupItemsQuery({
      lookup_id: lookupID,
      datasource_id: 1,
    });
    const fetchReimbursementRate = useFetchLookupItemsQuery({
      lookup_id: 51,
      datasource_id: 1,
    });
    const fetchSmr = useFetchLookupItemsQuery({
      lookup_id: 57,
      datasource_id: 1,
    });
    const fetchAsmr = useFetchLookupItemsQuery({
      lookup_id: 3,
      datasource_id: 1,
    });
    const fetchBenefitRating = useFetchLookupItemsQuery({
      lookup_id: 5,
      datasource_id: 1,
    });

    useEffect(() => {
      setLookupLoading(isLoading);

      if (data) {
        let lookupItems: lookupItemT[] = [];
        for (let x of data) {
          lookupItems.push({ label: String(x.Value), id: String(x.ID) });
        }

        setItems(lookupItems);
      }
    }, [isLoading]);

    useEffect(() => {
      if (fetchReimbursementRate.data && fetchReimbursementRate.data.length > 0) {
        let arr: lookupItemT[] = [];
        for (let c of fetchReimbursementRate.data) {
          arr.push({
            label: String(c.Value),
            id: String(c.ID),
          });
        }
        setReimbursementRateItems(arr);
      }
    }, [fetchReimbursementRate.isLoading]);

    useEffect(() => {
      if (fetchSmr.data && fetchSmr.data.length > 0) {
        let arr: lookupItemT[] = [];
        for (let c of fetchSmr.data) {
          arr.push({
            label: String(c.Value),
            id: String(c.ID),
          });
        }
        setSmrItems(arr);
      }
    }, [fetchSmr.isLoading]);

    useEffect(() => {
      if (fetchAsmr.data && fetchAsmr.data.length > 0) {
        let arr: lookupItemT[] = [];
        for (let c of fetchAsmr.data) {
          arr.push({
            label: String(c.Value),
            id: String(c.ID),
          });
        }
        setAsmrItems(arr);
      }
    }, [fetchAsmr.isLoading]);

    useEffect(() => {
      if (fetchBenefitRating.data && fetchBenefitRating.data.length > 0) {
        let arr: lookupItemT[] = [];
        for (let c of fetchBenefitRating.data) {
          arr.push({
            label: String(c.Value),
            id: String(c.ID),
          });
        }
        setBenefitRatingItems(arr);
      }
    }, [fetchBenefitRating.isLoading]);
  }

  useEffect(() => {
    if (values.Country_ID && values.Agency_ID) {
      if (
        values.IsSubgroupEnabled == true &&
        (!values.hasOwnProperty("SubGroup") || values["SubGroup"].length == 0)
      ) {
        fieldChanged("SubGroup", "", [{}]);
      } else if (values.IsSubgroupEnabled == false) {
        fieldChanged("SubGroup", "", []);
      }
    }
    if (countryId != values.Country_ID || agencyId != values.Agency_ID) {
      setCountryId(values.Country_ID);
      setAgencyId(values.Agency_ID);
    }
  }, [values]);

  const handleCloseRecDel = () => {
    setShowSubgroupModal(null);
  };

  const getLookupLabel = (name: string, index: number): string => {
    if (name == null || name == "") {
      return "";
    }

    let _value = values["SubGroup"] ? values["SubGroup"][index][name] : null;

    if (_value == null || _value == "") return "";
    const lookupItem = items.find((opt) => opt.id == _value);
    console.log("lookupItem: ", lookupItem);
    if (name == "ReimbursementRate") {
      const lookupItemRR = reimbursementRateItems.find((opt) => opt.id == _value);
      if (lookupItemRR)
        return lookupItemRR.label;
    }
    if (name == "SMR") {
      const lookupItemSmr = smrItems.find((opt) => opt.id == _value);
      if (lookupItemSmr)
        return lookupItemSmr.label;
    }
    if (name == "ASMR") {
      const lookupItemAsmr = asmrItems.find((opt) => opt.id == _value);
      if (lookupItemAsmr)
        return lookupItemAsmr.label;
    }
    if (name == "BenifitRating") {
      const lookupItemBr = benefitRatingItems.find((opt) => opt.id == _value);
      if (lookupItemBr)
        return lookupItemBr.label;
    }

    let label = "";

    if (lookupItem) label = lookupItem.label;
    return label;
  };

  const getValue = (name: string, index: number) => {
    return values["SubGroup"] ? values["SubGroup"][index][name] : "";
  };

  const updateSubGroup = (obj: any, index: number) => {
    const subgroupObj = [...values["SubGroup"]];
    subgroupObj[index] = obj;
    fieldChanged("SubGroup", "", subgroupObj);
  };

  const AddSubgroupOnClick = () => {
    if (values["SubGroup"]) {
      fieldChanged("SubGroup", "", [...values["SubGroup"], {}]);
    } else {
      fieldChanged("SubGroup", "", [{}]);
    }
  };

  const deleteSubGroup = (subgroup_index: number) => {
    if (values["SubGroup"].length == 1) fieldChanged("SubGroup", "", []);
    else {
      let subgroups: any[] = [...values["SubGroup"]];
      subgroups = subgroups.filter((item: any, xi: number) => xi != subgroup_index);
      fieldChanged("SubGroup", "",subgroups);
    }
    setItems([...items]);
  };

  const onFieldChange = (
    name: string,
    value: any,
    parentObject: any,
    index: number
  ) => {
    if (parentObject !== undefined) {
      let child_obj = { ...parentObject };
      child_obj[name] = value;
      child_obj["CountryID"] = values["Country_ID"];
      child_obj["AgencyID"] = values["Agency_ID"];
      child_obj["IsCustom"] = 0;
      if (name == "SubGroupID") {
        const lookupItem = items.find((opt) => opt.id == value);
        child_obj["SubGroupIDText"] = !!lookupItem && lookupItem.label;
      }
      updateSubGroup(child_obj, index || 0);
    }
  };

  const HanldeCheck = (name: string, index: number, value: boolean) => {
    if (name == "Recommendation") {
      setOpenRecommendation((prevState) =>
        prevState.map((item, idx) => (idx === index ? value : item))
      );
    } else if (name == "ReimbursementRate") {
      setOpenReimbursementRate((prevState) =>
        prevState.map((item, idx) => (idx === index ? value : item))
      );
    } else if (name == "SMR") {
      setOpenSMR((prevState) =>
        prevState.map((item, idx) => (idx === index ? value : item))
      );
    } else if (name == "ASMR") {
      setOpenASMR((prevState) =>
        prevState.map((item, idx) => (idx === index ? value : item))
      );
    } else if (name == "BenifitRating") {
      setOpenBenifitRating((prevState) =>
        prevState.map((item, idx) => (idx === index ? value : item))
      );
    }
  };

  const renderOptions = (
    props: React.HTMLAttributes<HTMLElement>,
    option: Partial<any>
  ) => {
    return (
      <li {...props} key={option.id}>
        {option.label}
      </li>
    );
  };

  const additems = (item: lookupItemT) => {
    setItems([...items, item]);
  };

  return (
    <div>
      {!!values["SubGroup"] &&
        values["SubGroup"].map((parent: any, index: number) => (
          <>
            {field.name == "IsSubgroupEnabled" && (
              <>
                <div
                  className={`col-sm-12 row form-group field-type-48 field-name-SubGroupID-${index}`}
                  style={{ marginBottom: "0rem" }}
                >
                  <label
                    className={"col-sm-4 text-right control-label "}
                    title={"SubGroupID"}
                    style={{ paddingRight: "4px", paddingTop: 10 }}
                  >
                    {`SubGroup ${index + 1}`}
                  </label>
                  <div
                    className="col-sm-8 row"
                    style={{ marginLeft: "1px", marginTop: "8px" }}
                  >
                    <div className="col-sm-5">
                      <AutocompleteWithAdd
                        field={field}
                        items={items}
                        setItems={setItems}
                        onFieldChange={onFieldChange}
                        isHTASubGroup={true}
                        parentObject={values["SubGroup"][index]}
                        index={index}
                        values={values}
                      />
                    </div>
                    <div className="col-sm-3" style={{ paddingTop: "8px" }}>
                      <span
                        onClick={(event: React.MouseEvent<HTMLButtonElement>) =>
                          setShowSubgroupModal(event.currentTarget)
                        }
                        style={{ color: "rgb(0, 170, 231)", cursor: "pointer" }}
                      >
                        Create subgroup
                      </span>
                    </div>

                    <div className="col-sm-8">
                      <button
                        type="button"
                        className="btn btn-small"
                        onClick={AddSubgroupOnClick}
                      >
                        <AddIcon />
                      </button>
                      {values["SubGroup"]?.length > 1 && (
                        <button
                          type="button"
                          className="btn btn-small"
                          onClick={() => {
                            deleteSubGroup(index);
                          }}
                        >
                          <RemoveIcon />
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              </>
            )}

            {field.name == "FinalRecommendation_ID" && values["SubGroup"] && (
              <>
                <div
                  className={`col-sm-12 row form-group field-type-48 field-name-SubGroupID-Name-${index}`}
                  style={{ marginBottom: "0rem" }}
                  key={`SubGroupID-Name-${index}`}
                >
                  <label
                    className={"col-sm-4 text-right control-label "}
                    title={"SubGroupIDText"}
                    style={{ paddingRight: "4px", paddingTop: 10 }}
                  >
                    {`SubGroup ${index + 1}`}
                  </label>
                  <div className="col-sm-8">
                    <input
                      className="form-control"
                      type="text"
                      readOnly
                      value={getValue("SubGroupIDText", index)}
                      title={getValue("SubGroupIDText", index)}
                    />
                  </div>
                </div>
                {(() => {
                  if (agencyId == 45 && countryId == 9) {
                    return (
                      <>
                        <div
                          className={`col-sm-12 row form-group field-type-48 field-name-SubGroup-ReimbursementRate-${index}`}
                          style={{ marginBottom: "0rem" }}
                        >
                          <label
                            className={"col-sm-4 text-right control-label "}
                            title={"ReimbursementRate"}
                            style={{ paddingRight: "4px", paddingTop: 15 }}
                          >
                            {`ReimbursementRate - Subgroup ${index + 1}`}
                          </label>
                          <div className="col-sm-8">
                            <Autocomplete
                              freesolo
                              disablePortal
                              id={`ReimbursementRate - Subgroup ${index}`}
                              open={openReimbursementRate[index]}
                              onOpen={() => {
                                HanldeCheck("ReimbursementRate", index, true);
                              }}
                              onClose={() => {
                                HanldeCheck("ReimbursementRate", index, false);
                              }}
                              options={reimbursementRateItems}
                              key={`ReimbursementRate - ${index}`}
                              loading={lookupLoading}
                              isOptionEqualToValue={(option, value) =>
                                option.id === value.id
                              }
                              loadingText="Loading..."
                              sx={{ width: 300 }}
                              value={getLookupLabel("ReimbursementRate", index)}
                              onChange={(event, newValue) => {
                                if (newValue != null) {
                                  onFieldChange(
                                    "ReimbursementRate",
                                    newValue.id,
                                    values["SubGroup"][index],
                                    index
                                  );
                                } else {
                                  onFieldChange(
                                    "ReimbursementRate",
                                    "",
                                    values["SubGroup"][index],
                                    index
                                  );
                                }
                              }}
                              renderInput={(params) => (
                                <TextField
                                  required={field.required}
                                  {...params}
                                  placeholder="Select an Option"
                                  variant="outlined"
                                  InputProps={{
                                    ...params.InputProps,
                                    endAdornment: (
                                      <>
                                        {lookupLoading ? (
                                          <CircularProgress
                                            color="inherit"
                                            size={20}
                                          />
                                        ) : null}
                                        {params.InputProps.endAdornment}
                                      </>
                                    ),
                                  }}
                                />
                              )}
                              renderOption={renderOptions}
                            />
                          </div>
                        </div>
                        <div
                          className={`col-sm-12 row form-group field-type-48 field-name-SubGroup-SMR-${index}`}
                          style={{ marginBottom: "0rem" }}
                        >
                          <label
                            className={"col-sm-4 text-right control-label "}
                            title={"SMR"}
                            style={{ paddingRight: "4px", paddingTop: 15 }}
                          >
                            {`SMR - Subgroup ${index + 1}`}
                          </label>
                          <div className="col-sm-8">
                            <Autocomplete
                              freeSolo
                              disablePortal
                              id={`SMR - Subgroup ${index}`}
                              open={openSMR[index]}
                              onOpen={() => {
                                HanldeCheck("SMR", index, true);
                              }}
                              onClose={() => {
                                HanldeCheck("SMR", index, false);
                              }}
                              options={smrItems}
                              key={`SMR - ${index}`}
                              loading={lookupLoading}
                              isOptionEqualToValue={(option, value) =>
                                option.id === value.id
                              }
                              loadingText="Loading..."
                              sx={{ width: 300 }}
                              value={getLookupLabel("SMR", index)}
                              onChange={(event, newValue) => {
                                if (newValue != null) {
                                  onFieldChange(
                                    "SMR",
                                    newValue.id,
                                    values["SubGroup"][index],
                                    index
                                  );
                                } else {
                                  onFieldChange(
                                    "SMR",
                                    "",
                                    values["SubGroup"][index],
                                    index
                                  );
                                }
                              }}
                              renderInput={(params) => (
                                <TextField
                                  required={field.required}
                                  {...params}
                                  placeholder="Select an Option"
                                  variant="outlined"
                                  InputProps={{
                                    ...params.InputProps,
                                    endAdornment: (
                                      <>
                                        {lookupLoading ? (
                                          <CircularProgress
                                            color="inherit"
                                            size={20}
                                          />
                                        ) : null}
                                        {params.InputProps.endAdornment}
                                      </>
                                    ),
                                  }}
                                />
                              )}
                              renderOption={renderOptions}
                            />
                          </div>
                        </div>
                        <div
                          className={`col-sm-12 row form-group field-type-48 field-name-SubGroup-ASMR-${index}`}
                          style={{ marginBottom: "0rem" }}
                        >
                          <label
                            className={"col-sm-4 text-right control-label "}
                            title={"ASMR"}
                            style={{ paddingRight: "4px", paddingTop: 15 }}
                          >
                            {`ASMR - Subgroup ${index + 1}`}
                          </label>
                          <div className="col-sm-8">
                            <Autocomplete
                              freeSolo
                              disablePortal
                              id={`ASMR - Subgroup ${index}`}
                              open={openASMR[index]}
                              onOpen={() => {
                                HanldeCheck("ASMR", index, true);
                              }}
                              onClose={() => {
                                HanldeCheck("ASMR", index, false);
                              }}
                              options={asmrItems}
                              key={`ASMR - ${index}`}
                              loading={lookupLoading}
                              isOptionEqualToValue={(option, value) =>
                                option.id === value.id
                              }
                              loadingText="Loading..."
                              sx={{ width: 300 }}
                              value={getLookupLabel("ASMR", index)}
                              onChange={(event, newValue) => {
                                if (newValue != null) {
                                  onFieldChange(
                                    "ASMR",
                                    newValue.id,
                                    values["SubGroup"][index],
                                    index
                                  );
                                } else {
                                  onFieldChange(
                                    "ASMR",
                                    "",
                                    values["SubGroup"][index],
                                    index
                                  );
                                }
                              }}
                              renderInput={(params) => (
                                <TextField
                                  style={{ padding: 0 }}
                                  required={field.required}
                                  {...params}
                                  placeholder="Select an Option"
                                  variant="outlined"
                                  InputProps={{
                                    ...params.InputProps,
                                    endAdornment: (
                                      <>
                                        {lookupLoading ? (
                                          <CircularProgress
                                            color="inherit"
                                            size={20}
                                          />
                                        ) : null}
                                        {params.InputProps.endAdornment}
                                      </>
                                    ),
                                  }}
                                />
                              )}
                              renderOption={renderOptions}
                            />
                          </div>
                        </div>
                      </>
                    );
                  } else if (countryId == 10) {
                    return (
                      <>
                        <div
                          className={`col-sm-12 row form-group field-type-48 field-name-SubGroup-BenifitRating-${index}`}
                          style={{ marginBottom: "0rem" }}
                        >
                          <label
                            className={"col-sm-4 text-right control-label "}
                            title={"BenifitRating"}
                            style={{ paddingRight: "4px", paddingTop: 15 }}
                          >
                            {`BenifitRating - Subgroup ${index + 1}`}
                          </label>
                          <div className="col-sm-8">
                            <Autocomplete
                              freeSolo
                              disablePortal
                              id={`BenifitRating - Subgroup ${index}`}
                              open={openBenifitRating[index]}
                              onOpen={() => {
                                HanldeCheck("BenifitRating", index, true);
                              }}
                              onClose={() => {
                                HanldeCheck("BenifitRating", index, false);
                              }}
                              options={benefitRatingItems}
                              key={`BenifitRating - ${index}`}
                              loading={lookupLoading}
                              isOptionEqualToValue={(option, value) =>
                                option.id === value.id
                              }
                              loadingText="Loading..."
                              sx={{ width: 300 }}
                              value={getLookupLabel("BenifitRating", index)}
                              onChange={(event, newValue) => {
                                if (newValue != null) {
                                  onFieldChange(
                                    "BenifitRating",
                                    newValue.id,
                                    values["SubGroup"][index],
                                    index
                                  );
                                } else {
                                  onFieldChange(
                                    "BenifitRating",
                                    "",
                                    values["SubGroup"][index],
                                    index
                                  );
                                }
                              }}
                              renderInput={(params) => (
                                <TextField
                                  required={field.required}
                                  {...params}
                                  placeholder="Select an Option"
                                  variant="outlined"
                                  InputProps={{
                                    ...params.InputProps,
                                    endAdornment: (
                                      <>
                                        {lookupLoading ? (
                                          <CircularProgress
                                            color="inherit"
                                            size={20}
                                          />
                                        ) : null}
                                        {params.InputProps.endAdornment}
                                      </>
                                    ),
                                  }}
                                />
                              )}
                              renderOption={renderOptions}
                            />
                          </div>
                        </div>
                      </>
                    );
                  } else {
                    return (
                      <>
                        <div
                          className={`col-sm-12 row form-group field-type-48 field-name-SubGroup-Recommendation-${index}`}
                          style={{ marginBottom: "0rem" }}
                        >
                          <label
                            className={"col-sm-4 text-right control-label "}
                            title={"Recommendation"}
                            style={{ paddingRight: "4px", paddingTop: 15 }}
                          >
                            {`Recommendation - Subgroup ${index + 1}`}
                          </label>
                          <div className="col-sm-8">
                            <Autocomplete 
                              freeSolo
                              disablePortal
                              id={`Recommendation - Subgroup ${index}`}
                              open={openRecommendation[index]}
                              onOpen={() => {
                                HanldeCheck("Reimbursement", index, true);
                              }}
                              onClose={() => {
                                HanldeCheck("Reimbursement", index, false);
                              }}
                              options={items}
                              key={`Recommendation - ${index}`}
                              loading={lookupLoading}
                              isOptionEqualToValue={(option, value) =>
                                option.id === value.id
                              }
                              loadingText="Loading..."
                              sx={{ width: "300" }}
                              value={getLookupLabel("Recommendation", index)}
                              onChange={(event, newValue) => {
                                if (newValue != null) {
                                  onFieldChange(
                                    "Recommendation",
                                    newValue.id,
                                    values["SubGroup"][index],
                                    index
                                  );
                                } else {
                                  onFieldChange(
                                    "Recommendation",
                                    "",
                                    values["SubGroup"][index],
                                    index
                                  );
                                }
                              }}
                              renderInput={(params) => (
                                <TextField
                                  required={field.required}
                                  {...params}
                                  placeholder="Select an Option"
                                  variant="outlined"
                                  InputProps={{
                                    ...params.InputProps,
                                    endAdornment: (
                                      <>
                                        {lookupLoading ? (
                                          <CircularProgress
                                            color="inherit"
                                            size={20}
                                          />
                                        ) : null}
                                        {params.InputProps.endAdornment}
                                      </>
                                    ),
                                  }}
                                />
                              )}
                              renderOption={renderOptions}
                            />
                          </div>
                        </div>
                      </>
                    );
                  }
                })()}
              </>
            )}
          </>
        ))}
      {showSubgroupModal && (
        <CreateSubgroupPopup
          showDeviceBrandNameModal={showSubgroupModal}
          handleCloseRecDel={handleCloseRecDel}
          additems={additems}
        />
      )}

      {/* if (agencyId == 45 && countryId == 9)
            {
                //Reimbursement Rate - Subgroup 
                //SMR - Subgroup 
                //ASMR - Subgroup 
                //
            }
            else if (countryId == 10)
            {
                //Benefit Rating - Subgroup
            }
            else{
                //Recommendation - Subgroup 
            } */}
    </div>
  );
};

interface ICreateSubgroupPopup {
  showDeviceBrandNameModal: any;
  handleCloseRecDel: any;
  additems: (val: lookupItemT) => void;
}

const CreateSubgroupPopup = ({
  showDeviceBrandNameModal,
  handleCloseRecDel,
  additems,
}: ICreateSubgroupPopup) => {
  const [values, setValues] = useState<IForm>({});
  const [name, setname] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const saveDevice = async () => {
    setLoading(true);

    if (!values.name) setname(true);
    else setname(false);
    if (values.name) {
      const res = await PostCall("/api/create_subgroup", {
        value: values.name,
      });
      if (res.data) {
        additems(res.data[0]);
      }

      setLoading(false);
      handleCloseRecDel();
    } else {
      setLoading(false);
    }
  };

  return (
    <>
      <DraggablePopover
        title="Sub Form"
        anchorEl={showDeviceBrandNameModal}
        popupBody={
          <div>
            <h5>Create Subgroup</h5>

            <div className="row">
              <label
                className="control-label col-md-4"
                style={{ paddingLeft: "200px" }}
              >
                Name
              </label>
              <input
                className="form-control col-md-6"
                onChange={(e) =>
                  setValues({ ...values, ["name"]: e.target.value })
                }
              />
              {name && (
                <span
                  style={{
                    color: "#dc3545",
                    paddingLeft: "320px",
                    fontWeight: "bold",
                  }}
                >
                  The Value field is required.
                </span>
              )}
            </div>

            {loading && (
              <div style={{ paddingLeft: "430px", paddingTop: "10px" }}>
                <CircularProgress color="inherit" size={20} />
                <span>Loading</span>
              </div>
            )}
          </div>
        }
        buttonsArray={["Save"]}
        btnCallbacks={[saveDevice]}
        onPopupClose={handleCloseRecDel}
        keyName="SubGroup"
      ></DraggablePopover>
    </>
  );
};

export default HTARecord_Form_Subgroup;
