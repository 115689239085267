export const col_items: IcolItems = {
  1: "HTARecord_ID",
  2: "ClinicalData_ID",
  3: "RegulatoryWatch_ID",
  4: "Pricing_ID",
  5: "Policy_ID",
  6: "Agency_ID",
  7: "Country_ID",
  20: "DrugData_ID",
  23: "Agreement_ID",
  25: "ReimbursementStatus_ID",
  27: "TreatmentCost_ID",
};

export const datasourceName: IcolItems = {
  1: "HTARecord",
  2: "ClinicalData",
  3: "Regulatory",
  4: "Pricing",
  5: "Policy",
  6: "Agency",
  7: "Country",
  20: "Drug",
  23: "Agreement",
  25: "ReimbursementStatus",
  27: "TreatmentCost",
};

export const DataSourceToSearchDS = (id: number) => {
  switch (id) {
    case 1:
      return 11;
    case 2:
      return 9;
    case 3:
      return 14;
    case 4:
      return 13;
    case 5:
      return 12;
    case 6:
      return 8;
    case 7:
      return 10;
    case 25:
      return 26;
    case 23:
      return 24;
    case 20:
      return 22;
  }
};

interface IcolItems {
  [num: number]: string;
}
