import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { emptySplitApi } from "./emptyApiSlice";


export interface NewsResultState {
    searchAuditLogData: any[];
  }
  
  const initialState: NewsResultState = {
    searchAuditLogData: [],
  };

  export const AuditLogChangeSlice = createSlice({
    name: "AuditLogChange",
    initialState,
    reducers: {
      saveAuditLogChangeData: (state, action: PayloadAction<any[]>) => {
        console.log("searchAuditLogData :", action.payload);
        state.searchAuditLogData = action.payload;
      },
    },
  });
  export const { saveAuditLogChangeData } = AuditLogChangeSlice.actions;

  export default AuditLogChangeSlice.reducer;

  export const AuditLogChangeApiSlice = emptySplitApi.injectEndpoints({
    endpoints(build) {
      return {
        fetchAuditLogChangeData: build.mutation({
          query: (Id) => {
            return {
              url: `api/auditLogUpdate/${Id}`,
              method: "GET",
            };
          },
        }),
      };
    },
  });
  export const {
    useFetchAuditLogChangeDataMutation
  } = AuditLogChangeApiSlice;
  