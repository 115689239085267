import React, { useEffect, useState } from "react";
import Tree from "./Tree";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import {
  faChevronLeft,
  faChevronRight,
  faSquare,
  faCheck,
  faSquareMinus,
} from "@fortawesome/free-solid-svg-icons";
import { useAppDispatch, useAppSelector } from "../../store/hooks";

interface ITreeNode {
  node: any;
  seen: number[];
  setSeen: (val: number[]) => void;
  checkedValues: number[];
  setCheckedValues: (val: number[]) => void;
  partiallyCheckedValues: number[];
  setPartiallyCheckedValues: (val: number[]) => void;
  expandedValues: number[];
  setExpandedValues: (val: number[]) => void;
  index: string;
  filter: boolean;
  // searchTerm : string;
}

function TreeNode({
  node,
  seen,
  setSeen,
  checkedValues,
  setCheckedValues,
  partiallyCheckedValues,
  setPartiallyCheckedValues,
  expandedValues,
  setExpandedValues,
  index,
  filter
  // searchTerm
}: ITreeNode) {
  const { children, label, value } = node;
  //const currDepth = depth;
  const [showChildren, setShowChildren] = useState(false);
  const [expandShow, setExpandShow] = useState(true);
  const [noChildren, setNoChildren] = useState(false);
  const [checked, setChecked] = useState(false);
  const [unChecked, setUnchecked] = useState(true);
  const [partiallyChecked, setPartiallychecked] = useState(false);
  const nodes = useAppSelector((state) => state.multiselect.SuperDisease);
  const [count, setCount] = useState<number>(0);
  const nodesFiltered = useAppSelector((state) => state.multiselect.FilteredNodes);
  useEffect(() => {
    if ((children && children.length == 0) || typeof children == "undefined") {
      setNoChildren(true);
    }
  }, [children]);

  // useEffect(() => {
  //   console.log(seen);
  // },[seen])

  //changing state of checkbox based on the checkedvalues list
  useEffect(() => {
    if (checkedValues && checkedValues.includes(value)) {
      setChecked(true);
      setUnchecked(false);
      setPartiallychecked(false);
    } else if (
      partiallyCheckedValues &&
      partiallyCheckedValues.includes(value)
    ) {
      setChecked(false);
      setUnchecked(false);
      setPartiallychecked(true);
    } else {
      setChecked(false);
      setUnchecked(true);
      setPartiallychecked(false);
    }

    const ids = getChildIdsOnly(children || []);
    const count_ = ids.reduce(childCount, 0);
    // dispatch(setChildCount({...childCounts,[label]:count_}));
    setCount(count_);
  }, [checkedValues, expandedValues]);

  //changing state of partially checked boxes

  useEffect(() => {
    if (partiallyCheckedValues && partiallyCheckedValues.includes(value)) {
      setPartiallychecked(true);
    }
  }, [partiallyCheckedValues]);

  useEffect(() => {
    if (expandedValues && expandedValues.includes(value)) {
      setExpandShow(false);
      setShowChildren(true);
    } else {
      setExpandShow(true);
      setShowChildren(false);
    }
  }, [expandedValues]);

  const handleClick = () => {
    if (children.length > 0) {
      let seenValues: number[] = children.map((x: any) => x.value);
      seenValues.push(value);
      let newValues = seenValues.filter((x) => !seen.includes(x));
      if (expandShow) {
        setSeen([...seen, ...newValues]);
      }
      setShowChildren(!showChildren);
      setExpandShow(!expandShow);
    }
  };

  //get child ids only without parent id included

  const getChildIdsOnly = (data_: any[]) => {
    let ids: number[] = [];
    data_.forEach((el) => {
      ids.push(el.value);
      if (!!el.children && el.children.length > 0) {
        const c_ids: number[] = getChildIdsOnly(el.children);
        ids = ids.concat([...c_ids]);
      }
    });
    return ids;
  };

  // get count of selected values

  const childCount = (val: number, curr: number) => {
    return checkedValues.includes(curr) ? val + 1 : val;
  };

  //executed while checking the node
  const onClickCheck = (val: number) => {
    // let f_c_v : any[] = [];
    // if(!searchTerm ||  searchTerm.length < 2)
    let f_c_v = getFirstChildNodes(node["children"]);
    let c_v = getChildValues(node).filter((x) => x != value);
    // if(!searchTerm ||  searchTerm.length < 2)
    c_v = c_v.filter((x) => f_c_v.includes(x) || seen.includes(x));
    let lv = index.split("_").map((x) => parseInt(x));
    let p_v = lv.length > 1 ? getParentIdsUsingRec(0, filter ?nodesFiltered : nodes, lv) : [];
    let parent_ids = p_v[0];
    let p_c_ids = p_v[1];

    let t_c_v = [value];
    t_c_v = t_c_v.concat(c_v);

    let half_check_temp = [];
    t_c_v = t_c_v.concat([...checkedValues]);
    let h_c: any = [];

    t_c_v = t_c_v.filter((x) => !checkedValues.includes(x));
    setCheckedValues([...checkedValues, ...t_c_v]);
    let item = [...partiallyCheckedValues]
      .filter((x) => x != value)
      item = item.concat(parent_ids);
    t_c_v.concat([...checkedValues]);
    item = item.filter((x) => !t_c_v.includes(x));
    setPartiallyCheckedValues(item);
  };

  let half_check = [];
  //executed while unchecking
  const onClickUnCheck = (val: number) => {
    let unCheckedIds = getChildValues(node);
    let lv = index.split("_").map((x) => parseInt(x));
    let p_v = lv.length > 1 ? getParentIdsUsingRec(0, filter ?nodesFiltered : nodes, lv) : [];
    let parent_ids = p_v[0] ? p_v[0] : [];
    let p_c_ids = p_v[1] ? p_v[1] : [];
    let t_c_v = [...checkedValues];
    let t_h_c_v: number[] = [];
    t_c_v = t_c_v.filter((x) => !unCheckedIds.includes(x));

    if (parent_ids) {
      parent_ids
        .slice()
        .reverse()
        .forEach((el: number) => {
          if (
            p_c_ids[el].some(
              (x: number) => t_c_v.includes(x) || t_h_c_v.includes(x)
            )
          ) {
            t_h_c_v.push(el);
            t_c_v = t_c_v.filter((x) => x !== el);
          } else if (p_c_ids[el].some((x: number) => t_h_c_v.includes(x))) {
            t_h_c_v.push(el);
          } else {
            t_c_v = t_c_v.filter((x) => x !== el);
            unCheckedIds.push(el);
          }
        });
    }

    t_c_v = t_c_v.filter(
      (x) => !t_h_c_v.includes(x) && !unCheckedIds.includes(x)
    );

    setPartiallyCheckedValues(t_h_c_v);
    setCheckedValues(t_c_v);
  };

  //get parent ids based on index string

  const getParentIdsFunc = () => {
    let node_indexes = index.split("_").map((x) => Number(x));
    let num = 0;
    let i = node_indexes[num];
    let parentIds: any = [];

    const getParentIds = (Data: any, index: number) => {
      if (node_indexes.length - 1 > num) {
        if (
          addingValuesToHalfCheck(Data[index]["value"], Data[index]["children"])
        ) {
          parentIds.push(Data[index]["value"]);
        }

        if (Data[index]["children"] && Data[index]["children"].length > 0) {
          num = num + 1;
          if (node_indexes.length - 1 > num) {
            getParentIds(Data[index]["children"], node_indexes[num]);
          }
        }
      }

      return parentIds;
    };
    return getParentIds(nodes, i);
  };

  const addingValuesToHalfCheck = (id: number, node: any) => {
    let f_c_nodes = getFirstChildNodes(node);
    const found = f_c_nodes.some((n) => checkedValues.includes(n));
    return found;
  };

  let parentids: number[] = [];
  let parent_child_ids: any = {};

  //getting parentids using the recursion function, recursion will stop when it reaches the child index
  const getParentIdsUsingRec = (
    i: number,
    child_nodes: any,
    level: number[]
  ) => {
    parent_child_ids[child_nodes[level[i]]["value"]] = child_nodes[level[i]][
      "children"
    ].map((item: any) => item["value"]);
    parentids.push(child_nodes[level[i]]["value"]);
    if (
      child_nodes[level[i]]["children"] &&
      child_nodes[level[i]]["children"].length > 0 &&
      i + 2 < level.length
    ) {
      getParentIdsUsingRec(i + 1, child_nodes[level[i]]["children"], level);
    }
    return [parentids, parent_child_ids];
  };

  let f_ids: number[] = [];

  //getting first child values for parent node
  const getFirstChildNodes = (arr: any[]) => {
    if (!!arr && arr.length > 0) {
      arr.forEach((item) => {
        f_ids.push(item["value"]);
      });
      return f_ids;
    } else return [];
  };

  let all_child_ids: number[] = [];

  //getting all child node values
  const getChildValues = (data: any) => {
    all_child_ids.push(data["value"]);
    if (data["children"] && data["children"].length > 0) {
      data["children"].forEach((item: any) => {
        getChildValues(item);
      });
    }
    return all_child_ids;
  };

  //checking the all childs values in checked State
  const allChildExist = (check: boolean, p_data: any) => {
    let f_c_v = getFirstChildNodes(p_data);
    return f_c_v.every((x) => checkedValues.includes(x));
  };

  return (
    <>
      <div style={{ marginBottom: "10px" }}>
        <FontAwesomeIcon
          onClick={handleClick}
          style={{
            fontSize: "14px",
            padding: "2px",
            color: "black",
            display:
              expandShow === true && noChildren === false ? "inline" : "none",
          }}
          icon={faChevronRight as IconProp}
          size="sm"
        />
        <FontAwesomeIcon
          onClick={handleClick}
          style={{
            fontSize: "14px",
            padding: "2px",
            color: "black",
            display:
              expandShow === false && noChildren === false ? "inline" : "none",
          }}
          icon={faChevronLeft as IconProp}
          size="sm"
        />
        <FontAwesomeIcon
          style={{
            padding: "2px",
            color: "white",
            display: noChildren === true ? "inline" : "none",
          }}
          icon={faChevronRight as IconProp}
          size="sm"
        />
        <FontAwesomeIcon
          icon={faCheck}
          style={{
            display: checked === true ? "inline" : "none",
            marginRight: "7px",
            marginLeft: "7px",
            color: "blue",
            padding: "2px",
            border: "1px solid rgb(0, 170, 231)",
          }}
          onClick={() => onClickUnCheck(value)}
        />
        <FontAwesomeIcon
          icon={faSquare}
          style={{
            display: partiallyChecked === true ? "inline" : "none",
            padding: "2px",
            color: "#13a5e2",
            marginRight: "7px",
            marginLeft: "7px",
          }}
          onClick={() => onClickCheck(value)}
        />
        <FontAwesomeIcon
          icon={faSquare}
          style={{
            display:
              unChecked === true && !partiallyChecked ? "inline" : "none",
            padding: "2px",
            color: "white",
            marginRight: "7px",
            marginLeft: "7px",
            border: "1px solid rgb(0, 170, 231)",
          }}
          onClick={() => onClickCheck(value)}
        />
        <span
          style={{ padding: "2px" }}
          dangerouslySetInnerHTML={{ __html: label }}
        ></span>
        <span> {count > 0 ? `(${count})` : ""}</span>
      </div>
      <ul style={{ paddingLeft: "130px" }}>
        {showChildren && (
          <Tree
            Data={children}
            seen={seen}
            setSeen={setSeen}
            checkedValues={checkedValues}
            setCheckedValues={setCheckedValues}
            partiallyCheckedValues={partiallyCheckedValues}
            setPartiallyCheckedValues={setPartiallyCheckedValues}
            expandedValues={expandedValues}
            setExpandedValues={setExpandedValues}
            index={index}
            filter = {filter}
            // searchTerm = {searchTerm}
          />
        )}
      </ul>
    </>
  );
}

export default TreeNode;
