import React from "react";
import { ICellRendererParams } from "ag-grid-community";
import GroupsIcon from "@mui/icons-material/Groups";
import PersonIcon from "@mui/icons-material/Person";

interface LinkCellRendererParams extends ICellRendererParams {
  link: string;
  recordIdField: string;
  // datasourceId?: string;
  isSavedSearch? :boolean;
  newusername?:string
}

const NameLink = (props: LinkCellRendererParams) => {
  const recordId = props.data[`${props.recordIdField}`];
  // const dsId = props.data[`${props.datasourceId}`];
  const targetURL = `${props.link}/${recordId}`;
  // const newTargetURL = `${props.link}/${dsId}/${recordId}`;

  return (
    <>{
      props.isSavedSearch && props.data[props.newusername]?.toLocaleLowerCase().includes("(c)") && <GroupsIcon style={{marginRight:"7px"}}/>
 
    }
    {
      props.isSavedSearch && props.data[props.newusername]?.toLocaleLowerCase().includes("(cg)") && <GroupsIcon style={{marginRight:"7px"}}/>
 
    }
    {
      props.isSavedSearch && props.data[props.newusername]?.toLocaleLowerCase().includes("(u)") && <PersonIcon style={{marginRight:"7px"}}/>
 
    }
    <a target={"_blank"} href={targetURL} style={{color: "rgb(0, 170, 231)"}}> 
      {props.value} </a>
    {/* {props.isSavedSearch ? (<a target={"_blank"} href={newTargetURL} style={{color: "rgb(0, 170, 231)"}}> 
        {props.value}
    </a>) : (<a target={"_blank"} href={targetURL} style={{color: "rgb(0, 170, 231)"}}> 
      {props.value} </a>)
    } */}
    </>

  );
};
export default NameLink;
