import React, { useContext, useEffect, useState } from "react";
import { useAppSelector } from "../../../../store/hooks";
import { UserContext } from "../../Editor";
import DoubleArrowIcon from "@mui/icons-material/DoubleArrow";
import { FaInfoCircle } from "react-icons/fa";

interface IDrugData_lov {
  fieldInfo: any[];
  datasourceName: string;
  section: string;
  showInfoIcon?: boolean;
}

const DrugData_Lov = ({
  fieldInfo,
  datasourceName,
  section,
}: IDrugData_lov) => {

const userContext = useContext(UserContext);
const [field, setField] = useState<any[]>([]);
const [loeDate, setLoeDate] = useState<any[]>([]);
const subPageDate = useAppSelector((state) => state.multiselect.subpageData);
  

useEffect(() => {
if (field.length == 0 && fieldInfo.length > 0) {
    setField(fieldInfo);
}
}, [fieldInfo]);

useEffect(() => {
setLoeDate(subPageDate["loe_drugdata"]);
}, [subPageDate]);

const fieldBrand = field.filter((x) => x.name === "DrugData_Lov.DrugData_Lov_BrandName");
const fieldLoeDate = field.filter((x) => x.name === "DrugData_Lov.LovDate");
const fieldRegulator = field.filter((x) => x.name === "DrugData_Lov.DrugData_Lov_Regulator");
const fieldGeography = field.filter((x) => x.name === "DrugData_Lov.DrugData_Lov_Geography");

const renderInfoIcon = (fieldObj: any) => {
    console.log(fieldObj)
    return (
        <>
            <FaInfoCircle
                onClick={() => {
                userContext.showAlert(
                    <>
                    <span style={{ fontSize: "70%", paddingLeft: "2px" }}>
                        {datasourceName}
                        <DoubleArrowIcon
                        style={{ fontSize: "18px", paddingBottom: "2px" }}
                        />
                        {section}
                        <DoubleArrowIcon
                        style={{
                            strokeWidth: "5",
                            fontSize: "18px",
                            paddingBottom: "2px",
                        }}
                        />
                    {fieldObj[0].label}
                    </span>
                    </>,
                    "info",
                    fieldObj[0].descriptionClient? fieldObj[0].descriptionClient : "",
                    "https://google.com",
                    true
                );
                }}
                style={{
                fontSize: "12px",
                marginLeft: "3px",
                cursor: "pointer",
                }}
            />
        </>
    );
};

return (
<>
    <hr />
    <b>LOE data</b>

    <div className="row">
        <div className="col-sm-12 row top-padding" style={{marginTop:"10px"}}>
            <div className="col-sm-3" style={{marginLeft: "3em"}}><label className=""></label>Brand name {renderInfoIcon(fieldBrand)}</div>
            <div className="col-sm-3" style={{maxWidth: "23%"}}>LOE date {renderInfoIcon(fieldLoeDate)}</div>
            <div className="col-sm-3"><label className=""></label>Regulator {renderInfoIcon(fieldRegulator)}</div>
            <div className="col-sm-3" style={{maxWidth: "23%"}}>LOE geography {renderInfoIcon(fieldGeography)}</div>
        </div>
    </div>
    {!!loeDate && loeDate.length > 0 && loeDate.map((el: any, index: number) => (
            <>
            <br />
            <div className="col-sm-12">
                <hr />
                <div className="col-sm-12 row form-group">
                    {field.filter((x) => x.name.split('.')[1] === "DrugData_Lov_BrandName").length > 0 &&
                        field.filter((x) => x.name.split('.')[1] === "DrugData_Lov_BrandName").map((val) => (
                            <div className="col-sm-3" style={{ paddingTop: "10px",paddingLeft:"2em" }}>
                                {el[val.name.split('.')[1]] != null ? String(el[val.name.split('.')[1]]).split(",")
                                    .map((element) => (
                                        <>
                                        <label>{element}</label>
                                        <br />
                                        </>
                                )) : ''}
                            </div>
                    ))}
                    {field
                        .filter((x) => x.name.split('.')[1] === "LovDate").length > 0 &&
                        field
                        .filter((x) => x.name.split('.')[1] === "LovDate").map((val) => (
                            <div className="col-sm-2" style={{ paddingTop: "10px",paddingLeft:"3em" }}>
                                <label>
                                    {(el[val.name.split('.')[1]] != '' && el[val.name.split('.')[1]] != null 
                                    && el[val.name.split('.')[1]] != undefined) ? new Date(el[val.name.split('.')[1]]).toLocaleDateString('en-GB') : ''}</label>
                            </div>
                    ))}
                    {field
                        .filter((x) => x.name.split('.')[1] === "DrugData_Lov_Regulator").length > 0 &&
                        field
                        .filter((x) => x.name.split('.')[1] === "DrugData_Lov_Regulator").map((val) => (
                            <div className="col-sm-3" style={{ paddingTop: "10px",paddingLeft:"10em" }}>
                                {el[val.name.split('.')[1]] != null ? String(el[val.name.split('.')[1]]).split(",")
                                    .map((element) => (
                                        <>
                                        <label>{element}</label>
                                        <br />
                                        </>
                                )) : ''}
                            </div>
                    ))}
                    {field
                        .filter((x) => x.name.split('.')[1] === "DrugData_Lov_Geography").length > 0 &&
                        field
                        .filter((x) => x.name.split('.')[1] === "DrugData_Lov_Geography").map((val) => (
                            <div className="col-sm-3" style={{ paddingTop: "10px",paddingLeft:"11em" }}>
                                {el[val.name.split('.')[1]] != null ? String(el[val.name.split('.')[1]]).split(",")
                                    .map((element) => (
                                        <>
                                        <label>{element}</label>
                                        <br />
                                        </>
                                )) : ''}
                            </div>
                    ))}
                    <div className="col-sm-1" style={{ float: "right", marginLeft: "auto", marginRight: "-12px" }}>
                        <div
                            style={{
                            padding: "10px",
                            borderLeft: "1px dotted gray",
                            }}
                        >
                            <label>{index + 1}</label>
                        </div>
                    </div>
                    {loeDate.length == 0 && (
                        <div
                            className="sol-sm-12"
                            style={{
                            backgroundColor: "whitesmoke",
                            textAlign: "center",
                            height: "55px",
                            }}
                        >
                            No LOE data available
                        </div>
                    )}
                </div>
            </div>
        </>
    ))}

</>
);
};

export default DrugData_Lov;
