import React, { useContext, useState, useEffect } from "react";
//import InfoIcon from "@mui/icons-material/Info";
import { FaInfoCircle } from "react-icons/fa";
import DoubleArrowIcon from "@mui/icons-material/DoubleArrow";
import { UserContext } from "../../Editor";
import { RiDeleteBinLine } from "react-icons/ri";
// import Tooltip, { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";
// import { styled } from "@mui/material/styles";
// import Typography from "@mui/material/Typography";
import { Field } from "../../../../store/DataSourceMetaData";
import { EditorState } from "draft-js";
import FieldView from "../../FieldView";
import apiCall from "../../../service/apiCall";
import { brandLicenceFormObj } from "../../../../utils";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import { BiEdit } from "react-icons/bi";
import { setSelectedLookupGlobe, setLoeBrandName } from "../../../../store/MultiSelectSlice";


interface DrugData_BrandLicenseFormProps {
  // rowsArray: any;
  // deleteRows: Function;
  fieldInfo: Field;
  values: any;
  fieldChanged: (name?: string, value?: string, obj?: any) => void;
  childRows: Field[];
  RichtextFieldChanged: (name: string, value: EditorState) => void;
  datasourcename: string;
  section: any;
  datasourceId: number;
  masterFieldTypes: number[];
}

const DrugData_BrandLicenseForm = (props: DrugData_BrandLicenseFormProps) => {
  const {
    // rowsArray,
    // deleteRows,
    fieldInfo,
    values,
    fieldChanged,
    childRows,
    RichtextFieldChanged,
    datasourcename,
    section,
    datasourceId,
    masterFieldTypes,
  } = props;
  const userContext = useContext(UserContext);

  const dispatch = useAppDispatch();
  const selectedLookupGlobe = useAppSelector(
    (state) => state.multiselect.selectedLookupGlobe
  );
  const loeBrandName = useAppSelector((state) => state.multiselect.loeBrandName);

  const updateRegionalBrand = (obj: any, index: number) => {
    let regionalBrandData = [...values[fieldInfo.name]];
    // regionalBrandData = [...values['DrugData_BrandLicense.Brand']];
    regionalBrandData[index] = obj;
    if (Object.keys(obj).includes('DrugData_BrandLicense.Brand')) {
      fieldChanged('DrugData_BrandLicense.Brand', "", regionalBrandData);
    }
    else {
      fieldChanged(fieldInfo.name, "", regionalBrandData);
    }
    
  };

  const deleteRegionalBrand = (pro_index: number) => {
    let _selectedLookupGlobe = {...selectedLookupGlobe};

    for ( let i = pro_index; i < values[fieldInfo.name].length-1 ; i++) {
      _selectedLookupGlobe[`DrugData_BrandLicense.DrugData_BrandLicense_Geography_${i}`] =
          _selectedLookupGlobe[`DrugData_BrandLicense.DrugData_BrandLicense_Geography_${i+1}`];
    }

    const regionalBrandLength = values[fieldInfo.name].length - 1;
    delete _selectedLookupGlobe[`DrugData_BrandLicense.DrugData_BrandLicense_Geography_${regionalBrandLength}`];

    dispatch(setSelectedLookupGlobe(_selectedLookupGlobe));
    let brand: any[] = [...values[fieldInfo.name]];
    if (values[fieldInfo.name].length === 1) {
      fieldChanged(fieldInfo.name, "", []);
      setTimeout(() => {
        fieldChanged(fieldInfo.name, "", [{}]);
      }, 20);
    } else {
      
      fieldChanged(fieldInfo.name, "", [
        ...brand.filter((item: any, xi: number) => xi != pro_index),
      ]);
    }
    let initialState =
      loeBrandName === undefined
        ? []
        : [...loeBrandName];
    let currBrand = [...brand.filter((item: any, xi: number) => xi == pro_index)];
    initialState = initialState.filter(function( obj ) {
      return obj.ID !== Number(currBrand[0]['DrugData_BrandLicense.RegionalDrug_ID_Text'].Id);
    });
    dispatch(setLoeBrandName(initialState));
  };
  
  const [field, setField] = useState<any[]>([]);
  // Methods:
  const initialFetch = () => {
    // setRows(rowsArray);
    getData();
  };

  // useEffect(() => {
  //   initialFetch();
  // }, [rowsArray]);


  const getData = async () => {
    if (field.length == 0 && childRows.length > 0) {
      (async () => {
        const res = await apiCall("/api/sub-page/drugdata/brandlicense/fields");
        console.log(res, "res");
        if (res.data.length > 0) {
          let x = brandLicenceFormObj(res.data);
          setField(x);
        }
      })();
    }
  };

  const showInfoIcon = (field: string, description?: string, fId?: number) => {
    return (
      <FaInfoCircle
        onClick={() => {
          const showAlertFunc = async() => {
          const res = await apiCall(`/api/admin/fetch/description/${fId}/${values["Status_ID"] || 1}/${0}/${'write'}`);
          userContext.showAlert(
            <>
              <span style={{ fontSize: "70%", paddingLeft: "2px" }}>
                Drug
                <DoubleArrowIcon
                  style={{ fontSize: "18px", paddingBottom: "2px" }}
                />
                Patent information
                <DoubleArrowIcon
                  style={{
                    strokeWidth: "5",
                    fontSize: "18px",
                    paddingBottom: "2px",
                  }}
                />
                {field}
              </span>
            </>,
            "info",
            res.data,
            `/admin/instructions/${fId}`,
            true
          );
        }
        showAlertFunc()
        }}
        style={{ fontSize: "12px", marginLeft: "1px", cursor: "pointer" }}
      />
    );
  };

  const createDate = (value: any) => {
    let day = value.$D;
    let month = value.$M + 1;
    let year = value.$y;
    let date = `${month}-${day}-${year}`;
    return date;
  };

  const handleDates = (value: any, index: number, propertyName: string) => {
    // console.log(value, "value", id);
    if (value !== null) {
      let formatedDate = createDate(value);
      let obj = rowData;
      if (obj[index]) {
        obj[index][propertyName] = formatedDate;
      } else {
        obj[index] = {
          [propertyName]: formatedDate,
        };
      }
      setRowData(obj);

      let dateObj = datePickerValue;
      if (dateObj[index]) {
        dateObj[index][propertyName] = value;
      } else {
        dateObj[index] = {
          [propertyName]: value,
        };
      }
      setDatePickerValue(dateObj);
      console.log("row", rowData, "datePickerValue", datePickerValue);
      console.log("DatePickerValue",datePickerValue)
    }
  };

  const deleteRow = (num: number) => {
    // delete rowData[num];
    // delete datePickerValue[num];
    
    console.log("rowData", rowData);
    setResetValue(true)
    // deleteRows(num, "brandLicence");
    /* setForceUpdate((k) => k + 1); */
    // let tmp = rows;
    // tmp.splice(num, 1);
    // console.log("deleteRow", tmp);
    // setRows(tmp);
  };

  const renderAutoPopulateGeneric = (ind: number) => {
    const regionalBrand = selectedLookupGlobe['DrugData_BrandLicense.RegionalDrug_ID_Text_' + ind][0].Id;
    console.log('rb: ', regionalBrand);
    apiCall(
      `/api/GetBrandLicenseForAutoPopulate/${regionalBrand}`
    ).then((resp) => {
      if (!!resp && !!resp.data.brand) {
        console.log(resp.data.brand);
      }
      if (!!resp && !!resp.data.brandGeo && resp.data.brandGeo.length > 0) {
        console.log(resp.data.brandGeo);
      }
    });
  }

  // const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
  //   <Tooltip {...props} classes={{ popper: className }} />
  // ))(({ theme }) => ({
  //   [`& .${tooltipClasses.tooltip}`]: {
  //     backgroundColor: "#f5f5f9",
  //     color: "rgba(0, 0, 0, 0.87)",
  //     maxWidth: 220,
  //     fontSize: theme.typography.pxToRem(12),
  //     border: "1px solid #dadde9",
  //   },
  // }));
  return (
    <div className="DrugData_BrandLicenseFormContainer">
      <div
        style={{
          height: 1,
          width: "100%",
          backgroundColor: "#d9d9d9",
          margin: "15px 0",
        }}
      ></div>

      <div className="headerDrugData_Brand">
        <div className="title" style={{ fontWeight: "bold",fontSize: "12px",marginLeft:"17px",marginBottom:"5px"}}>
          Additional brands/license partners by region
        </div>
        <div className="row mb-3">
          <div className="col-sm-2 required">
            Regional brand {showInfoIcon("Regional brand", "", childRows.filter(
              (x) =>
                x.name ==
                "DrugData_BrandLicense.RegionalDrug_ID_Text"
            ).map(({id}) => id)[0])}
          </div>
          <div className="col-sm-2">
            Corporation / license partner
            {showInfoIcon("Corporation / license partner", "", childRows.filter(
              (x) =>
                x.name ==
                "DrugData_BrandLicense.Corporation_ID"
            ).map(({id}) => id)[0])}
          </div>
          <div className="col-sm-2">
            Start date {showInfoIcon("Start date", "", childRows.filter(
              (x) =>
                x.name ==
                "DrugData_BrandLicense.StartDate"
            ).map(({id}) => id)[0])}
          </div>
          <div className="col-sm-2">
            End date {showInfoIcon("End date", "", childRows.filter(
              (x) =>
                x.name ==
                "DrugData_BrandLicense.EndDate"
            ).map(({id}) => id)[0])}
          </div>
          <div className="col-sm-2">
            Geography {showInfoIcon("Geography", "", childRows.filter(
              (x) =>
                x.name ==
                "DrugData_BrandLicense.DrugData_BrandLicense_Geography"
            ).map(({id}) => id)[0])}
          </div>
          <div className="col-sm-2">
            Deal type {showInfoIcon("Deal type", "", childRows.filter(
              (x) =>
                x.name ==
                "DrugData_BrandLicense.DealType_ID"
            ).map(({id}) => id)[0])}
          </div>
        </div>
      </div>
      {values[fieldInfo.name]?.length > 0 &&
        values[fieldInfo.name] &&
        values[fieldInfo.name].map((el: any, index: number) => (
          <span key={index}>
            <div className="row mt-3">
                {childRows
                  .map((row) => (
                    <div className="col-sm-2" style={{ paddingLeft:"2em" }}>
                      <FieldView
                        field={row}
                        values={values}
                        fieldChanged={fieldChanged}
                        RichtextFieldChanged={RichtextFieldChanged}
                        datasourcename={datasourcename}
                        section={section.name}
                        datasourceId={datasourceId}
                        parentObject={values[fieldInfo.name][index]}
                        setObj={updateRegionalBrand}
                        index={index}
                        hideTitleLabel={true}
                      />
                      {row.name.indexOf('RegionalDrug_ID_Text') > 0 && row.fieldTypeId == 41 &&
                      <BiEdit className={index.toString()} onClick={() => renderAutoPopulateGeneric(index)} />
                    }
                    </div>
                  ))}
            </div>
            <div style={{ textAlign: "right", marginTop: "35px", marginBottom: 10 }}>
              <span>
                <RiDeleteBinLine className="iqvia-color Delete-Icon-styles cursor-pointer" title="Remove this brand license"
                    onClick={() => deleteRegionalBrand(index)}
                  />
              </span>
              <span style={{ padding: 10, borderLeft: "1px dotted gray" }}>
                {index + 1}
              </span>
            </div>
          </span>
        ))}
    </div>
  );
};

export default DrugData_BrandLicenseForm;
