import React from "react";
import { _token } from "./apiCall";
import axiosInstance from "./axiosInstance";
// import Cookies from "js-cookie";

const PostCall = async(url: string, body?: any) => {
  const data = body;
  return await axiosInstance.post(url,data).then((response) => {
    return response;
  }).catch((res) => {
    throw res
  } )
};

export default PostCall;
