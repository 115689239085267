import React, { useEffect, useState } from "react";
import { useAppSelector, useAppDispatch } from "../store/hooks";
import { saveUser } from "../store/UserProfileApiSlice";
interface welcomeProps {
  setUserEmail: Function;
}
function Welcome(props: welcomeProps) {
  const [userName, setUserName] = useState();
  const _token = localStorage.getItem("token");
  const userProfile = useAppSelector((state) => state.userProfile);
  const dispatch = useAppDispatch();

  const integratePendo = (value: any) => {
    // console.log("integratePendo", value, userProfile)
    // This function creates anonymous visitor IDs in Pendo unless you change the visitor id field to use your app's values
    // This function uses the placeholder 'ACCOUNT-UNIQUE-ID' value for account ID unless you change the account id field to use your app's values
    // Call this function after users are authenticated in your app and your visitor and account id values are available
    // Please use Strings, Numbers, or Bools for value types.
    window?.pendo.initialize({
      visitor: {
        id: value.userid, // Required if user is logged in, default creates anonymous ID
        email: value.email, // Recommended if using Pendo Feedback, or NPS Email
        role: userProfile.accountType,
        full_name: value["first_name"] + " " + value["last_name"],
        // You can add any additional visitor level key-values here,
        // as long as it's not one of the above reserved names.
      },

      account: {
        client_name: userProfile.userAccountData.length > 0 ? userProfile.userAccountData[0].AccountName : "",
        client_id: userProfile.userAccountData.length > 0 ? userProfile.userAccountData[0].id : "", 
        // id: 'ACCOUNT-UNIQUE-ID' // Required if using Pendo Feedback, default uses the value 'ACCOUNT-UNIQUE-ID'
        /// Ajouter === id: client_id / name: client_name
        // name:         // Optional
        // is_paying:    // Recommended if using Pendo Feedback
        // monthly_value:// Recommended if using Pendo Feedback
        // planLevel:    // Optional
        // planPrice:    // Optional
        // creationDate: // Optional
        // You can add any additional visitor level key-values here,
        // as long as it's not one of the above reserved names.
      },
    });
  };

  useEffect(() => {
    if (_token) {
      const tokenData = JSON.parse(atob(_token.split(".")[1]));
      if (Object.entries(tokenData).length > 0) {
        setUserName(tokenData.first_name);
        integratePendo(tokenData);
        dispatch(saveUser(tokenData));
      }
    }
  }, [_token, userProfile]);
  
  return (
    <p className="text-dark">
      Welcome <strong style={{ color: "rgb(0,84,134)", fontWeight: "bold", fontSize: 12 }} >{userName}!</strong>
    </p>
  );
}
export default Welcome;
