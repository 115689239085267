// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.AdminSavedSearch 
 .ag-theme-alpine 
.ag-row-even{
 background-color: white !important;
}
.AdminSavedSearch
.ag-theme-alpine 
.ag-header-viewport {
  background-color: white !important;
}
.AdminSavedSearch
.ag-theme-alpine 
.ag-row-odd{
 background-color: white !important;
}`, "",{"version":3,"sources":["webpack://./src/components/Admin/AdminSavedSearch.css"],"names":[],"mappings":"AAAA;;;CAGC,kCAAkC;AACnC;AACA;;;EAGE,kCAAkC;AACpC;AACA;;;CAGC,kCAAkC;AACnC","sourcesContent":[".AdminSavedSearch \n .ag-theme-alpine \n.ag-row-even{\n background-color: white !important;\n}\n.AdminSavedSearch\n.ag-theme-alpine \n.ag-header-viewport {\n  background-color: white !important;\n}\n.AdminSavedSearch\n.ag-theme-alpine \n.ag-row-odd{\n background-color: white !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
