import { createSlice,PayloadAction } from '@reduxjs/toolkit'

export interface ColumnFormat {
    ColumnOrder: string,
    FieldCss: string,
    FieldRenderMethod: string,
    FormFieldType: number,
    Hint: string,
    ID: number,
    IsAdditional: boolean,
    IsDefault: boolean,
    IsVisible: boolean,
    Label: string,
    Name: string,
};

export interface SearchResultData {
 Item1: any[],
 Item2: Array<ColumnFormat>,
 Item3: any[],
 Item4: any[],
}

export interface SearchResultState {
  showSearchResult: boolean,
  searchResultData: SearchResultData,
  layoutName: string,
}

const initialState: SearchResultState = {
  showSearchResult: false,
  searchResultData: {Item1:[], Item2:[], Item3:[], Item4:[]},
  layoutName: 'Default',
}

export const SearchResultSlice = createSlice({
  name: 'SearchResult',
  initialState,
  reducers: {
    saveSearchResultData: (state, action:PayloadAction<SearchResultData>) => {
        state.searchResultData = action.payload;
    },
    setShowSearchResult: (state, action:PayloadAction<boolean>) => {
        state.showSearchResult = action.payload;
    },
    resetSearchResult: (state) => {
      state.showSearchResult = false;
      state.searchResultData= {Item1:[], Item2:[], Item3:[], Item4:[]};
    }
  },
})

// Action creators are generated for each case reducer function
export const { saveSearchResultData, setShowSearchResult, resetSearchResult } = SearchResultSlice.actions

export default SearchResultSlice.reducer