// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.label-with-image {
    display: inline-block;
    max-width: 100%; 
}
  
.label-with-image img {
    max-width: 100%; 
    display: block;  
}

.label-with-image table {
    max-width: -webkit-fill-available !important;
}
`, "",{"version":3,"sources":["webpack://./src/components/Forms/FieldView.css"],"names":[],"mappings":"AAAA;IACI,qBAAqB;IACrB,eAAe;AACnB;;AAEA;IACI,eAAe;IACf,cAAc;AAClB;;AAEA;IACI,4CAA4C;AAChD","sourcesContent":[".label-with-image {\n    display: inline-block;\n    max-width: 100%; \n}\n  \n.label-with-image img {\n    max-width: 100%; \n    display: block;  \n}\n\n.label-with-image table {\n    max-width: -webkit-fill-available !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
