import React, { useEffect, useState } from "react";
import TreeNode from "./TreeNode";

function Tree({
  Data,
  seen,
  setSeen,
  checkedValues,
  setCheckedValues,
  partiallyCheckedValues ,
  setPartiallyCheckedValues ,
  expandedValues ,
  setExpandedValues, 
  parent,
  index
}: any) {
  return (
    <ul key={index}>
      {Data && Data.length > 0 && Data.map((node: any, i:number) => (
        <div key={i}>

        <TreeNode
          node={node}
          seen={seen}
          setSeen= {setSeen}
          checkedValues = {checkedValues}
          setCheckedValues = {setCheckedValues}
          partiallyCheckedValues = {partiallyCheckedValues}
          setPartiallyCheckedValues = {setPartiallyCheckedValues}
          expandedValues = {expandedValues}
          setExpandedValues = {setExpandedValues}
          index= {index == "" ? `${i}` : `${index}_${i}`}
          //parent= {parent}
        />
        </div>
      ))}
    </ul>
  );
}

export default Tree;
