import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import logo from "../../img/hta-logo.png";
import "./Logout.css";
import { useLocation } from "react-router-dom";
import Cookies from "js-cookie";

const Logout = () => {
  const search = useLocation().search;
  const _email = Cookies.get("email");
  const validUser = new URLSearchParams(search).get("valid");

  useEffect(() => {
    sessionStorage.clear();
    localStorage.clear();
  }, []);

  return (
    <div style={{ paddingTop: "10vw" }}>
      <div className="container container-global" data-v-857fd648="">
        <div className="wrap-form" data-v-857fd648="">
          <div className="form-signin main-overlay" data-v-857fd648="">
            <div className="mb-4 text-center" data-v-857fd648="">
              <img src={logo} alt="IQVIA | HTA Accelerator" />
            </div>
            <form method="post">
              <div className="form-group form-group-cs mb-3">
                {validUser && validUser == "false" ? (
                  <span>
                    Access denied for :{" "}
                    {!!_email && _email.replace(/^"|"$/g, "")}
                  </span>
                ) : (
                  <span>You are logged out!</span>
                )}
              </div>

              <div>
                <Link className="log-out" to="/Login">
                  Login again
                </Link>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Logout;
