import { emptySplitApi } from "./emptyApiSlice";
import { _token } from "../components/service/apiCall";

export const SavedSearchAlertSlice = emptySplitApi.injectEndpoints({
  endpoints(build) {
    return {
      fetchSavedSearchAlertData: build.mutation({
        query: () => {
          return ({
            method: "get",
            url: `api/savedSearch/find`,
            
          });
        },
      }),
      fetchSavedSelectionData: build.mutation({
        query: () => {
          return {
            method: "get",
            url: `api/savedSelection/find`,
           
          };
        },
      }),
      fetchUserAccountData: build.mutation({
        query: () => {
          return {
            method: "get",
            url: `api/user/useraccount/userId`,
            
          };
        },
      }),
      fetchToptenRecords: build.mutation({
        query: (params: any) => {
          const { datasource_id } = params;
          return {
            method: "get",
            url: `api/latestsearchTableData/${datasource_id}`,
           
          };
        },
      }),
      updateSavedSearch: build.mutation<any, { record_id: number }>({
        query: (args: { record_id: any; }) => {
          const { record_id } = args;
          return {
            url: `api/updateSavedSearch/${record_id}`,
            method: "put",
          };
        },
      }),
      updateSavedSelection: build.mutation<any, { record_id: number }>({
        query: (args: { record_id: any; }) => {
          const { record_id } = args;
          return {
            url: `api/updateSavedSlection/${record_id}`,
            method: "put",
          };
        },
      }),
    };
  },
});

export const {
  useFetchSavedSearchAlertDataMutation,
  useFetchSavedSelectionDataMutation,
  useFetchUserAccountDataMutation,
  useFetchToptenRecordsMutation,
  useUpdateSavedSearchMutation,
  useUpdateSavedSelectionMutation,
} = SavedSearchAlertSlice;
