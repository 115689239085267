import React, { useEffect, createContext, useContext, useState } from "react";
import { useRouteMatch } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import NewAlert from "../AlertBox/NewAlert";
import NewModal, { ILookupData } from "../Popup/NewModal";
import { EditorState } from "draft-js";
import { useHistory, useLocation } from "react-router-dom";
import apiCall from "../service/apiCall";
import AdminGrid from "../Table/AdminGrid";



function AdminLanding() {
    let dataSourceID: number = 0;
    let recordID: number = 0;
    const routeMatch:any = useRouteMatch();
    dataSourceID = Number(routeMatch.params.dataSourceID);
    recordID = Number(routeMatch.params.recordID);

    return (<>
        <AdminGrid dataSourceID={dataSourceID} recordID ={recordID} Title={""} isAdminData={true}></AdminGrid>
    </>);
}

export default AdminLanding;