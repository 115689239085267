// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.container {
    top: 10%;
    left: 13%;
    width: 100%;
}

.text{
    display: inline;
    width: 100%;
}

.read-or-hide {
    color: rgb(0, 170, 231);
    cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/components/AlertBox/ReadMore.css"],"names":[],"mappings":"AAAA;IACI,QAAQ;IACR,SAAS;IACT,WAAW;AACf;;AAEA;IACI,eAAe;IACf,WAAW;AACf;;AAEA;IACI,uBAAuB;IACvB,eAAe;AACnB","sourcesContent":[".container {\n    top: 10%;\n    left: 13%;\n    width: 100%;\n}\n\n.text{\n    display: inline;\n    width: 100%;\n}\n\n.read-or-hide {\n    color: rgb(0, 170, 231);\n    cursor: pointer;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
