import axiosInstance from "./axiosInstance";

export const _token = localStorage.getItem("token");
export const _userId = sessionStorage.getItem("userid");

const apiCall = async (url: string) => {
  try {
    const response = await axiosInstance.get(url);
    return response;
  } catch (error: any) {
    return error;
  }
};

export default apiCall;
