import { IconditionalFormFields } from "../Editor";

const AdminReportFormValidation = (values : any,datasourceId: number) => {
      
  const requiredFields: IconditionalFormFields = {};

  const defaultErrorMessage = "This field is required.";

  //Your date range cannot be longer than 1 year.
  
  if(datasourceId == 268){
    const date1 = new Date(values.DateAccessed);
    const date2 = new Date(values.EndDate);
    const diffDays = parseInt((date2 - date1) / (1000 * 60 * 60 * 24)); //gives day difference 
    //one_day means 1000*60*60*24
    //one_hour means 1000*60*60
    //one_minute means 1000*60
    //one_second means 1000
    if(date1 > date2)
      requiredFields.DateAccessed = "The selected end date must be greater than start date.";
    else if(diffDays > 365)
      requiredFields.DateAccessed = "Your date range cannot be longer than 1 year.";
      
  }
  else   if(datasourceId == 269 || datasourceId == 271){
    const date1 = new Date(values.StartDate
      );
    const date2 = new Date(values.EndDate);
    const diffDays = parseInt((date2 - date1) / (1000 * 60 * 60 * 24)); //gives day difference 
    //one_day means 1000*60*60*24
    //one_hour means 1000*60*60
    //one_minute means 1000*60
    //one_second means 1000
    if(date1 > date2)
      requiredFields.StartDate
       = "The selected end date must be greater than start date.";
    else if(diffDays > 365)
      requiredFields.StartDate = "Your date range cannot be longer than 1 year.";
      
  }
  if (Object.keys(requiredFields).length == 0) {
    return [true,[]];
  }
  return [false,requiredFields];

}


export default AdminReportFormValidation;