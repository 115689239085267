import React, { useState, useEffect } from "react";
import {
  Collapse,
  Container,
  Navbar,
  NavbarBrand,
  NavbarToggler,
  NavItem,
  NavLink,
  Dropdown,
  DropdownToggle,
  DropdownItem,
  DropdownMenu,
} from "reactstrap";
import { Link } from "react-router-dom";
import "./NavMenu.css";
import AutocompleteSearch from "./AutocompleteModule/AutocompleteSearch";
import UserProfile from "./UserProfile";
import Welcome from "./Welcome";
import SearchIcon from "@mui/icons-material/Search";
import logo from "../img/hta-logo.png";
import {
  useFetchSavedSearchAlertDataMutation,
  useFetchSavedSelectionDataMutation,
  useFetchUserAccountDataMutation,
  useUpdateSavedSearchMutation,
  useUpdateSavedSelectionMutation,
} from "../store/SavedSearchAlertApiSlice";
import apiCall from "./service/apiCall";
import { useAppDispatch, useAppSelector } from "../store/hooks";
import {
  saveUserAccountData,
  saveAccountType,
  setIsValid,
  saveSavedSearchCount,
  savedSelectionCount,
  saveCurrentRole,
  saveCurrentUserId,
  saveAccountId,
  saveSubscribedDs,

} from "../store/UserProfileApiSlice";
import Filter from "./Dashboard/filter";
import { useLocation } from "react-router-dom";
import { styled } from "@mui/material/styles";
import Tooltip, { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";
import { setSearchRecordID, setRecordIdData, setReacordIDDataLoading, setRecordIdSearch } from "../store/SearchSlice";
import GroupsIcon from "@mui/icons-material/Groups";
import PersonIcon from "@mui/icons-material/Person";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import EmailIcon from "@mui/icons-material/Email";

import Modal from "react-bootstrap/Modal";
import CloseIcon from "@mui/icons-material/Close";
import Cookies from "js-cookie";
import { useGetRecordIdDataMutation } from "../store/SearchResultApiSlice";
import { setInitiateSearch } from "../store/SearchSlice";
// interface SavedSearchProps {
//   record_id: number;
// }


const NavMenu = () => {
  //  public state = {
  //     isOpen: false,
  //     email: "",
  //     isCreateMenuOpen: false,
  //     isAdminMenuOpen: false,
  //     isAdminSubMenu1Open: false,
  //     isAccountMenuOpen: false,
  //     isSavedSearchOpen: false,
  //     isSavedSelectionOpen: false,
  const [isOpen, setIsOpen] = useState(false);
  const [email, setEmail] = useState("");
  const [isCreateMenuOpen, setIsCreateMenuOpen] = useState(false);
  const [isAdminMenuOpen, setIsAdminMenuOpen] = useState(false);
  const [isAdminSubMenu1Open, setIsAdminSubMenu1Open] = useState(false);
  const [isAdminSubMenu2Open, setIsAdminSubMenu2Open] = useState(false);
  const [isAccountMenuOpen, setIsAccountMenuOpen] = useState(false);
  const [isSavedSearchOpen, setIsSavedSearchOpen] = useState(false);
  const [isSavedSelectionOpen, setIsSavedSelectionOpen] = useState(false);
  const [role, setRole] = useState(0);
  const search = useLocation().search;
  const validUser = new URLSearchParams(search).get("accountId");
  const dispatch = useAppDispatch();
  const isValid = useAppSelector((state) => state.userProfile.isValid);
  const [roleId, setRoleId] = React.useState<number>(1);

  const [deleteID, setDeleteID] = useState("");
  const [show, setShow] = useState(false);
  let _token = localStorage.getItem("token");
  // const searchRecordID =recordIdDataLoading
  const searchRecordID = useAppSelector((state) => state.search.searchRecordID);

  useEffect(() => {
    if (_token) {
      const tokenData = JSON.parse(atob(_token.split(".")[1]));
      setRoleId(tokenData.role);
      dispatch(saveCurrentRole(tokenData.role));
      dispatch(saveCurrentUserId(tokenData.userid));
      dispatch(saveAccountId(tokenData.accountId));
    }
  }, [_token]);

  //const search = useLocation().search;

  const _identity_manager_id = localStorage.getItem("email");
  const _userid = localStorage.getItem("CurrUserId");

  const savedUserAccountData = useAppSelector(
    (state) => state.userProfile.userAccountData
  );
  const currSavedSearchCount = useAppSelector(
    (state) => state.userProfile.savedSearchCount
  );
  const currSavedSelectionCount = useAppSelector(
    (state) => state.userProfile.selectionCount
  );
  const initiateSearch = useAppSelector((state) => state.search.initiateSearch);

  const toggle = () => {
    setIsOpen(!isOpen);
  };
  const setUserEmail = (userEmail: string) => {
    setEmail(userEmail);
  };
  const toggleCreateMenu = () => {
    setIsCreateMenuOpen(!isCreateMenuOpen);
  };
  const toggleAdminMenu = () => {
    setIsAdminMenuOpen(!isAdminMenuOpen);
  };
  const toggleAdminSubMenu1 = () => {
    setIsAdminSubMenu1Open(!isAdminSubMenu1Open);
    if(isAdminMenuOpen && isAdminSubMenu1Open)
      setIsAdminMenuOpen(!isAdminMenuOpen);
  };
  const toggleAdminSubMenu2 = () => {
    setIsAdminSubMenu2Open(!isAdminSubMenu2Open);
    if(isAdminMenuOpen && isAdminSubMenu2Open)
    setIsAdminMenuOpen(!isAdminMenuOpen);
  };
  const toggleAccountMenu = () => {
    setIsAccountMenuOpen(!isAccountMenuOpen);
  };
  const toggleSavedSearchMenu = () => {
    setIsSavedSearchOpen(!isSavedSearchOpen);
  };

  const toggleSavedSelectionMenu = () => {
    setIsSavedSelectionOpen(!isSavedSelectionOpen);
  };
  const [showNavmenu, setShowNavmenu] = useState(false);
  const location = useLocation();

  useEffect(() => {
    if (
      location.pathname === "/LoggedOut" ||
      location.pathname === "/Login" ||
      location.pathname==="/help" ||
      location.pathname === "/cb" ||
      location.pathname.match(/accountSwitch/)
    ) {
      setShowNavmenu(false);
    } else {
      setShowNavmenu(true);
    }
  }, [location]);
  if (!isValid) {
    dispatch(setIsValid(true));
  }

  const [fetchSavedSearchAlertData, { data: savedSearchAlertData }] =
    useFetchSavedSearchAlertDataMutation();

  const GetSavedSearchAlertData = async () => {
    const savedSearchAlertResult : any = await fetchSavedSearchAlertData({});
    savedSearchAlertResult &&
      dispatch(saveSavedSearchCount(savedSearchAlertResult?.data?.length));
  };

  const [fetchSavedSelectionData, { data: savedSelectionData }] =
    useFetchSavedSelectionDataMutation();

  const GetSavedSelectionData = async () => {
    const savedSelectionResult : any = await fetchSavedSelectionData({});
    savedSelectionResult &&
      dispatch(savedSelectionCount(savedSelectionResult?.data?.length));
  };

  const [fetchUserAccountData, { data: userAccountData }] =
    useFetchUserAccountDataMutation();

  const GetUserAccountData = async () => {
    const userAccountResult = await fetchUserAccountData({});
    !userAccountResult.hasOwnProperty("error") &&
      userAccountResult.hasOwnProperty("data") &&
      dispatch(saveUserAccountData(userAccountResult));
  };

  useEffect(() => {
    savedUserAccountData.length > 0 &&
      dispatch(saveAccountType(savedUserAccountData[0]));
  }, [savedUserAccountData]);

  useEffect(() => {
    setTimeout(() => {
      apiCall("/api/currentrole")
        .then((resp) => {
          if (Number(resp.data) > 4 && Number(resp.data) < 8) {
            apiCall("/api/subscriptions").then((resp1) => {
              if (resp1.data) {
                dispatch(saveSubscribedDs(resp1.data));
              }
            });
          }
          else {
            dispatch(saveSubscribedDs("1,2,3,4,5,6,7,20,23,25"));
          }
          setRole(resp.data);
          dispatch(saveCurrentRole(resp.data));
        })
        .catch((error) => {
          // console.log("test");
          // console.log(typeof error.response.status);
          // console.log(error);
          localStorage.removeItem("CurrUserId");
          localStorage.removeItem("CurrRoleId");
          localStorage.removeItem("CurrUserId");
          // localStorage.clear();
          sessionStorage.clear();
        });
    }, 1000);
  }, []);

  useEffect(() => {
    GetUserAccountData();
    //GetSavedSearch();
  }, []);

  useEffect(() => {
    GetSavedSearchAlertData();
  }, [currSavedSearchCount]);
  useEffect(() => {
    GetSavedSelectionData();
  }, [currSavedSelectionCount]);

  const [updateSavedSearch] = useUpdateSavedSearchMutation();
  const updateSavedSearchData = async () => {
    if (deleteID){
      const saveSearchData = await updateSavedSearch({
        record_id: Number(deleteID),
      });
      if(saveSearchData && saveSearchData.error.data == "Success") 
      {
        GetSavedSearchAlertData();
      }
    }
  };
  const [updateSavedSelection] = useUpdateSavedSelectionMutation();
  const updateSavedSelectionData = async () => {
    if (deleteID){
      const saveSelectionData = await updateSavedSelection({
        record_id: Number(deleteID),
      });
      if(saveSelectionData && saveSelectionData.error.data == "Success") 
      {
        GetSavedSelectionData();
      }
    }
  };
  
  //For fetching the record id values:
  const [getRecordIdData] = useGetRecordIdDataMutation();


  const searchByRecordID = async() => {
    //set true
    dispatch(setRecordIdSearch(true));
    dispatch(setInitiateSearch(false));
    if (searchRecordID && searchRecordID !== null){
      dispatch(setReacordIDDataLoading(true));
      const recordIdData : any = await getRecordIdData({searchId: searchRecordID});
      dispatch(setRecordIdData(recordIdData.data));
      // apiCall(`/api/record/${searchRecordID}`)
      //   .then((resp) => {
      //     dispatch(setRecordIdData(resp.data));
      //   })
      //   .catch((error) => {
      //     console.log(error);
      //   });
    }
  };
  const handleClose = () => {
    setShow(false);
  };

  const handleClickDelete = (id) => {
    setDeleteID(id);
    setShow(true);
  };
  const handleDeleteSavedSearch = () => {
    updateSavedSearchData();
    GetSavedSearchAlertData();
    setShow(false);
  };
  const handleDeleteSavedSelection = () => {
    updateSavedSelectionData();
    GetSavedSelectionData()
    setShow(false);
  };

  const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.common.white,
      color: "rgba(0, 0, 0, 0.87)",
      boxShadow: theme.shadows[1],
      fontSize: 11,
    },
  }));
  return (
    <header>
      {show == true && (
        <Modal size="sm" show={open} onHide={handleClose} centered>
          <Modal.Header style={{fontSize:"18px",fontWeight:"bold"}}>
            <Modal.Title>Delete confirmation</Modal.Title>
            <CloseIcon className="Close-icon" onClick={handleClose} />
          </Modal.Header>
          <Modal.Body>
            {savedSearchAlertData &&
              savedSearchAlertData
                .filter((obj) => obj.ID == deleteID)
                .map((el) => (
                  <>
                    {el.ClientID == null ? (
                      <div key={el.ID} className="bodyTop">
                        <p>
                          Are you sure you want to delete this group search?
                        </p>
                      </div>
                    ) : (
                      <div key={el.ID} className="bodyTop">
                        <p>
                          Are you sure you want to delete this group search?
                          This will affect all the users of the group.
                        </p>
                      </div>
                    )}
                  </>
                ))
            } 
            {savedSelectionData &&
              savedSelectionData
                .filter((obj1) => obj1.Id == deleteID)
                .map((el1) => (
                  <>
                  {
                  el1.SelectionName !="" &&
                  <div key={el1.Id} className="bodyTop">
                      <p>Are you sure you want to delete this selection?</p>
                  </div>
                  }                
                  </>
                ))
            }
          </Modal.Body>
          <Modal.Footer style={{ cursor: "pointer",color:"rgb(0, 170, 231)" }}>
          {savedSelectionData &&
              savedSelectionData
                .filter((obj1) => obj1.Id == deleteID)
                .map((el1) => (
                  <>
                  {
                  el1.SelectionName !="" &&
                  <span className="spanBtn" onClick={handleDeleteSavedSelection}>Yes</span>
                  }                
                  </>
                ))
          } 
          {
            savedSearchAlertData && savedSearchAlertData
            .filter((obj) => obj.ID == deleteID)
            .map((el) => (
              <>
              {
                el.Name != "" &&
                <span className="spanBtn" onClick={handleDeleteSavedSearch}>
                  Yes
                </span>
              }
              </>
            ))
          }
            <span>|</span>
            <span className="spanBtn" onClick={handleClose}>
              Cancel
            </span>
          </Modal.Footer>
        </Modal>
      )}
      {location.pathname !== "/profiler/profile" ? (
        <>
          {showNavmenu && (
            <Navbar
              className="navbar-expand-sm navbar-toggleable-sm mb-2"
              light
            >
              <div className="col-sm-12 main-nav-menu main-nav-menu-padding">
                <NavbarBrand href="/" style={{ minWidth: "fit-content"}} key="profile">
                  <img
                    src={logo}
                    alt="IQVIA | HTA Accelerator"
                    style={{ width: "24em",marginLeft: "10px" }}
                  />
                </NavbarBrand>
                <div className="float-right">
                  <ul
                    className="navbar-nav  float-right"
                    style={{ height: "45px", marginRight:"88px" }}
                  >
                    <NavItem key="userEmail">
                      <NavLink className="text-dark">
                        <Welcome setUserEmail={setUserEmail} />
                      </NavLink>
                    </NavItem>
                    <NavItem style={{ marginTop: "2px" }} key="accounts">
                      <Dropdown
                        isOpen={isAccountMenuOpen}
                        color="link"
                        toggle={toggleAccountMenu}
                      >
                        <DropdownToggle
                          tag="button"
                          className="dropdown-toggle btn btn-link iqvia-color btn-font"
                          caret
                        >
                          User accounts
                          <sup>
                            {userAccountData ? userAccountData.length : 0}
                          </sup>
                        </DropdownToggle>
                        <DropdownMenu>
                          {userAccountData && userAccountData.length > 0 ? (
                            userAccountData.map((item: any) => (
                              <DropdownItem
                                className="user-acc-name"
                                // className={
                                //   item.roleid == role ? "user-acc-name" : ""
                                // }
                                key={item.id}
                                tag={Link}
                                // onClick={() => {
                                //   accountSwitchHandler(item.id);
                                // }}
                                to={"/accountSwitch/" + item.id + "/" + item.roleid}
                              >
                                <Tooltip
                                  title={item.AccountName}
                                  placement="right"
                                >
                                  <span>{item.AccountName}</span>
                                </Tooltip>
                              </DropdownItem>
                            ))
                          ) : (
                            <DropdownItem>
                              <span>0 User accounts</span>
                            </DropdownItem>
                          )}
                        </DropdownMenu>
                      </Dropdown>
                    </NavItem>

                    <NavItem className="txt" key="help">
                      <NavLink tag={Link} className="iqvia-color txt" to={`/help`} target="_blank">
                        {/* <NavLink
                        tag={Link}
                        className="iqvia-color"
                        to={`/WorkInProgress`}
                      > */}
                       Help
                      </NavLink>
                    </NavItem>
                    {/* <NavItem>
                                    <NavLink tag={Link} className='text-dark' to={`/help2/${this.state.email}`}>Help2</NavLink>
                                </NavItem> */}
                    <NavItem className="txt" key="logout">
                      <NavLink
                        tag={Link}
                        className="iqvia-color txt"
                        to={`/LoggedOut`}
                      >
                        Logout
                      </NavLink>
                    </NavItem>
                    {!(role > 4 && role < 8) && (
                      <NavItem style={{ marginTop: "5px" }} key="idSearch">
                        <div>
                          <input
                            type="number"
                            placeholder="Record ID..."
                            value={searchRecordID===null?"":searchRecordID}
                            onChange={(e) => {
                              console.log(e.target.value);
                              dispatch(setSearchRecordID(e.target.value));
                              console.log(searchRecordID)
                            }}
                            onKeyPress={(event) => {
                              if (event.key === "Enter") {
                                searchByRecordID();
                              }
                            }}
                            style={{ padding: "5px 4px", height:"32px" }}
                          />
                            <svg onClick={searchByRecordID} className="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-i4bv87-MuiSvgIcon-root" style={{position: 'absolute', top:"5px", right:"99px", width:"30px",height:"60%", borderRadius:"50%", cursor: 'pointer', paddingRight:"4px",marginRight:"57px", }} focusable="false" aria-hidden="true" viewBox="0 0 24 24" data-testid="SearchIcon"><path d="M15.5 14h-.79l-.28-.27C15.41 12.59 16 11.11 16 9.5 16 5.91 13.09 3 9.5 3S3 5.91 3 9.5 5.91 16 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z"></path></svg> 
                            
                          
                        </div>
                      </NavItem>
                    )}
                  </ul>
                  <ul
                    className="navbar-nav float-right"
                    style={{ clear: "both", marginBottom: "-14px" }}
                  >
                    <NavItem className="txt" key="home">
                      <NavLink className="iqvia-color txt" href="/">
                        Home
                      </NavLink>
                    </NavItem>
                    <NavItem className="txt" key="productProfile">
                      <NavLink
                        tag={Link}
                        className="iqvia-color txt"
                        to="/profiler/profile"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Product profile
                      </NavLink>
                    </NavItem>
                    <NavItem key="savedSearch">
                      <Dropdown
                        isOpen={isSavedSearchOpen}
                        color="link"
                        toggle={toggleSavedSearchMenu}
                      >
                        <DropdownToggle
                          tag="button"
                          className="dropdown-toggle btn btn-link iqvia-color btn-font"
                          caret
                        >
                          Saved searches and alerts
                          <sup>{currSavedSearchCount}</sup>
                        </DropdownToggle>
                        <DropdownMenu
                          style={{
                            maxHeight: "300px",
                            overflowX: "hidden",
                            overflowY: "auto",
                            minWidth: "320px",
                          }}
                        >
                          {savedSearchAlertData &&
                          savedSearchAlertData.length !== 0 ? (
                            savedSearchAlertData.map((item:any,ssi:number) => (
                              // <a to={`/savedSearch/${item.id}`} href={`/savedSearch/${item.id}`} target='_blank'>{item.name}</a>
                              <DropdownItem
                                key={`${item.ID}_${ssi}`}
                                tag={Link}
                                to={`/savedSearch/${item.ID}`}
                              >
                                <div>
                                  {item.UserID != null &&
                                  item.ClientID == null ? (
                                    <PersonIcon></PersonIcon>
                                  ) : (
                                    <GroupsIcon></GroupsIcon>
                                  )}

                                  <div>
                                    <p className="dropdown-menu-item">
                                      <Tooltip
                                        title={item.Name}
                                        placement="right"
                                      >
                                        <span>{item.Name}</span>
                                      </Tooltip>
                                    </p>
                                  </div>
                                  {(item.DatasourceID == 1 ||
                                    item.DatasourceID == 3) &&
                                    item.IsSubscribed == true && (
                                      <div
                                        style={{
                                          marginLeft: "280px",
                                          marginTop: "-30px",
                                        }}
                                      >
                                        <EmailIcon />
                                      </div>
                                    )}
                                  {(item.IsSubscribed == false ||
                                    item.IsSubscribed == null) &&
                                    (item.DatasourceID == 1 ||
                                      item.DatasourceID == 3) && (
                                      <div
                                        style={{
                                          marginLeft: "280px",
                                          marginTop: "-20px",
                                        }}
                                      >
                                        <MailOutlineIcon />
                                      </div>
                                    )}
                                  {item.CreatedBy == _userid && (
                                    <div
                                      style={{
                                        marginLeft: "310px",
                                        marginTop: "-20px",
                                      }}
                                    >
                                      <LightTooltip title="Delete">
                                        <CloseIcon
                                          style={{ fontSize: "20px", }}
                                          onClick={(e) => {
                                            e.preventDefault();
                                            handleClickDelete(item.ID);
                                          }}
                                        />
                                      </LightTooltip>
                                    </div>
                                  )}
                                </div>
                              </DropdownItem>
                            ))
                          ) : (
                            <DropdownItem>
                              <span>0 Saved searches</span>
                            </DropdownItem>
                          )}
                        </DropdownMenu>
                      </Dropdown>
                    </NavItem>
                    <NavItem key="savedSelection">
                      <Dropdown
                        isOpen={isSavedSelectionOpen}
                        color="link"
                        toggle={toggleSavedSelectionMenu}
                      >
                        <DropdownToggle
                          tag="button"
                          className="dropdown-toggle btn btn-link iqvia-color btn-font"
                          caret
                        >
                          Saved selection
                          <sup>{currSavedSelectionCount}</sup>
                        </DropdownToggle>
                        <DropdownMenu 
                        title="Saved selection"
                        style={{maxHeight: "300px",overflowX: "hidden",overflowY: "auto",minWidth: "250px"}}
                        >
                          {savedSelectionData &&
                          savedSelectionData.length !== 0 ? (
                            savedSelectionData.map((item: any) => (
                              <DropdownItem
                                key={item.Id}
                                tag={Link}
                                to={`/savedSelection/${item.Id}`}
                              >
                                <div>
                                  <div>
                                  <p className="dropdown-menu-item-new">
                                    <span>{item.SelectionName}</span>
                                  </p>
                                  <CloseIcon
                                          style={{ fontSize: "20px",marginLeft:"210px",marginTop:"-10px" }}
                                          onClick={(e) => {
                                          e.preventDefault();
                                          handleClickDelete(item.Id);
                                          }}
                                  />
                                  </div>
                                </div>
                              </DropdownItem>
                            ))
                          ) : (
                            <DropdownItem>
                              <span>0 Saved selections</span>
                            </DropdownItem>
                          )}
                        </DropdownMenu>
                      </Dropdown>
                    </NavItem>
                    <NavItem className="txt" key="dashboard">
                      <NavLink
                        tag={Link}
                        className="iqvia-color txt"
                        to="/reports/home"
                      >
                        Dashboards
                      </NavLink>
                    </NavItem>
                    {!(role > 4 && role < 8) && (
                      <NavItem key="createRecord">
                        <Dropdown
                          isOpen={isCreateMenuOpen}
                          color="link"
                          toggle={toggleCreateMenu}
                        >
                          <DropdownToggle
                            tag="button"
                            className="dropdown-toggle btn btn-link iqvia-color btn-font"
                            caret
                          >
                            Create record
                          </DropdownToggle>
                          <DropdownMenu>
                            <DropdownItem
                              key={0}
                              tag={Link}
                              to="/form/1"
                              target="_blank"
                            >
                              HTA
                            </DropdownItem>
                            <DropdownItem
                              key={1}
                              tag={Link}
                              to="/form/2"
                              target="_blank"
                            >
                              Clinical
                            </DropdownItem>
                            <DropdownItem
                              key={2}
                              tag={Link}
                              to="/form/3"
                              target="_blank"
                            >
                              Regulatory
                            </DropdownItem>
                            <DropdownItem
                              key={3}
                              tag={Link}
                              to="/form/5"
                              target="_blank"
                            >
                              Policy
                            </DropdownItem>
                            <DropdownItem
                              key={4}
                              tag={Link}
                              to="/form/6"
                              target="_blank"
                            >
                              Agency
                            </DropdownItem>
                            <DropdownItem
                              key={5}
                              tag={Link}
                              to="/form/7"
                              target="_blank"
                            >
                              Country
                            </DropdownItem>
                            <DropdownItem
                              key={6}
                              tag={Link}
                              to="/form/20"
                              target="_blank"
                            >
                              Drug
                            </DropdownItem>
                            <DropdownItem
                              key={7}
                              tag={Link}
                              to="/form/23"
                              target="_blank"
                            >
                              Agreements
                            </DropdownItem>
                            <DropdownItem
                              key={8}
                              tag={Link}
                              to="/form/25"
                              target="_blank"
                            >
                              Reimbursement status
                            </DropdownItem>
                          </DropdownMenu>
                        </Dropdown>
                      </NavItem>
                    )}
                    {role == 1 && (
                      <NavItem key="admin">
                        <Dropdown
                          isOpen={isAdminMenuOpen}
                          color="link"
                          toggle={toggleAdminMenu}
                        >
                          <DropdownToggle
                            tag="button"
                            className="dropdown-toggle btn btn-link iqvia-color btn-font"
                            caret
                          >
                            Admin
                          </DropdownToggle>
                          <DropdownMenu right>
                            <DropdownItem key={1} tag={Link} to={"/admin/list/251"} target="_blank" >
                              Clients
                            </DropdownItem>
                            <Dropdown
                              isOpen={isAdminSubMenu1Open}
                              color="btn"
                              toggle={toggleAdminSubMenu1}
                              direction="left"
                            >
                              <DropdownToggle
                                tag="button"
                                className="dropdown-toggle btn btn-font"
                              >
                                Complex lookup lists
                              </DropdownToggle>
                              <DropdownMenu title="Complex lookup lists">
                                <DropdownItem key={0} tag={Link} to={"/admin/list/253"} target="_blank">
                                  Device
                                </DropdownItem>
                                <DropdownItem key={1} tag={Link} to = {"/admin/list/254"} target="_blank">
                                 Display names                               
                                </DropdownItem>
                                <DropdownItem key={2} tag={Link} to = {"/admin/list/255"} target="_blank">
                                 Disease                          
                                </DropdownItem>
                                <DropdownItem key={3} tag={Link} to = {"/admin/list/256"} target="_blank">
                                  Disease subtype 1                                 
                                </DropdownItem>
                                <DropdownItem key={4} tag={Link} to = {"/admin/list/257"} target="_blank">
                                  Disease subtype 2                                 
                                </DropdownItem>
                                <DropdownItem key={5} tag={Link} to = {"/admin/list/258"} target="_blank">
                                  Disease subtype 3                                
                                </DropdownItem>
                                <DropdownItem key={6} tag={Link} to = {"/admin/list/259"} target="_blank">
                                  Disease subtype 4                                
                                </DropdownItem>
                                <DropdownItem key={7} tag={Link} to = {"/admin/list/260"} target="_blank">
                                  Therapeutic area                                
                                </DropdownItem>
                                <DropdownItem key={8} tag={Link} to = {"/admin/list/261"} target="_blank">
                                  Workflow reasons                             
                                </DropdownItem>
                                <DropdownItem key={9} tag={Link} to = {"/admin/list/262"} target="_blank">
                                  COA instruments                                 
                                </DropdownItem>
                              </DropdownMenu>
                            </Dropdown>

                            <DropdownItem key={3}  tag={Link} to = {"/admin/list/263"} target="_blank">
                              Datasources
                            </DropdownItem>
                            <DropdownItem key={4} tag={Link} to = {"/admin/list/265"} target="_blank">
                              Disable export options
                            </DropdownItem>
                            <DropdownItem disabled={true}>
                              Excel templates
                            </DropdownItem>
                            <DropdownItem key={5} tag={Link} to = {"/admin/list/266"} target="_blank">
                              Lookup lists
                            </DropdownItem>
                            <DropdownItem  key={6} tag={Link} to = {`/news/list`}>
                              News
                              {/* <a style={{ color: "#212529" }} href="/news/list" target="_blank">News</a> */}
                            </DropdownItem>

                            <Dropdown
                              isOpen={isAdminSubMenu2Open}
                              color="btn"
                              toggle={toggleAdminSubMenu2}
                              direction="left"
                            >
                              <DropdownToggle
                                tag="button"
                                className="dropdown-toggle btn btn-font"
                              >
                                Dashboards
                              </DropdownToggle>
                              <DropdownMenu title="Dashboards">
                                <DropdownItem key = {0} tag={Link} to = {"/admin/form/268"} target="_blank">
                                  User access logs
                                </DropdownItem>
                                <DropdownItem key = {1} tag={Link} to = {"/admin/form/269"} target="_blank">
                                  Workflow audit logs
                                </DropdownItem>
                                <DropdownItem key = {2} tag={Link} to={`/admin/list/270`} target="_blank">
                                  {/* <a style={{ color: "#212529" }} href="/admin/list/270" ></a> */}
                                  Dashboards
                                </DropdownItem>
                                <DropdownItem key = {3} tag={Link} to = {"/admin/form/271"} target="_blank">
                                  User download reports
                                </DropdownItem>
                                <DropdownItem key = {4} tag={Link} to = {"/admin/form/272"} target="_blank">
                                  Product profile readiness
                                </DropdownItem>
                              </DropdownMenu>
                            </Dropdown>

                            {/* <DropdownItem tag={Link} to="/admin/list/273">
                              Saved searches and alerts
                            </DropdownItem> */}
                            <DropdownItem key = {9} tag = {Link} to = {"/AdminSavedSearchAlert"} target="_blank">
                              Saved searches and alerts
                            </DropdownItem>
                            <DropdownItem key = {10} tag = {Link} to = {"/admin/list/274"} target="_blank">
                             Subscriptions
                            </DropdownItem>
                            <DropdownItem key = {11} tag = {Link} to = {"/admin/list/275"} target="_blank">
                              Users                         
                            </DropdownItem>
                          </DropdownMenu>
                        </Dropdown>
                      </NavItem>
                    )}
                  </ul>
                </div>
              </div>
            </Navbar>
          )}
          <div
            style={{
              width: "89%",
              margin: "0 auto",
              height: "0.5px",
              backgroundColor: "#ccc",
              marginBottom: 15,
            }}
          ></div>
        </>
      ) : (
        <>
          <Navbar className="navbar-expand-sm navbar-toggleable-sm mb-2" light>
            <div className="col-sm-12 row justify-content-between">
              <NavbarBrand
                href="/"
                style={{
                  minWidth: "fit-content",
                  paddingLeft: 50,
                  paddingTop: 10,
                }}
                key="other"
              >
                <img
                  src={logo}
                  alt="IQVIA | HTA Accelerator"
                  // style={{ width: "20em" }}
                  style={{height:"45px",marginTop: "0px",marginBottom: "-7px",marginLeft:"10px"}}
                />
              </NavbarBrand>
              <div
                className="col-3"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  textAlign: "right",
                }}
              >
                <span style={{ fontSize: "large", marginRight: "-28px" }}>Product Profile</span>
              </div>
            </div>
          </Navbar>
          <div
            style={{
              height: "1px",
              backgroundColor: "#ebebeb",
              marginBottom: 15,
            }}
          ></div>
        </>
      )}
    </header>
  );
};

export default NavMenu;
