import React from "react";
import { ICellRendererParams } from "ag-grid-community";
import MailOutlineIcon from "@mui/icons-material/MailOutline";

interface LinkCellRendererParams extends ICellRendererParams {
  recordIdField: string;
  isSavedSearch? :boolean;
  IsSubscribed? :string;
  DatasourceID?:string
}

const AdminSavedSearchDeactiveData = (props: LinkCellRendererParams) => {
  const recordId = props.data[`${props.recordIdField}`];
  const targetURL = `${props.link}/${recordId}`;

  return (
    <>
    {      
      props.isSavedSearch && (props.data[props.DatasourceID] == 1 || props.data[props.DatasourceID] == 3)  && <MailOutlineIcon style={{opacity:"0.5"}}/>
    }
    </>
  );
};
export default AdminSavedSearchDeactiveData;
