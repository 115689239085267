import React, { useContext } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
//import InfoIcon from "@mui/icons-material/Info";
import { FaInfoCircle } from "react-icons/fa";
import DoubleArrowIcon from "@mui/icons-material/DoubleArrow";
import AddIcon from "@mui/icons-material/Add";
import { UserContext } from "../Forms/Editor";
import { calculateAmnogPercentage } from "../../utils/";

interface PricingTableProps {
  tableData: any[];
  additionalField: boolean;
}

const PricingTable = (props: PricingTableProps) => {
  const { tableData, additionalField } = props;
  const userContext = useContext(UserContext);
  const currRoleId = localStorage.getItem("CurrRoleId");

  const showInfoIcon = (field: string, description?: string) => {
    return (
      <FaInfoCircle
        onClick={() => {
          userContext.showAlert(
            <>
              <span style={{ fontSize: "70%", paddingLeft: "2px" }}>
                Pricing
                <DoubleArrowIcon
                  style={{ fontSize: "18px", paddingBottom: "2px" }}
                />
                Price points
                <DoubleArrowIcon
                  style={{
                    strokeWidth: "5",
                    fontSize: "18px",
                    paddingBottom: "2px",
                  }}
                />
                {field}
              </span>
            </>,
            "info",
            description,
            "https://google.com",
            true
          );
        }}
        style={{ fontSize: "18px", marginLeft: "6px", cursor: "pointer" }}
      />
    );
  };

  const formatDate = (date: string) => {
    const dateObj = new Date(date);
    const month = dateObj.toLocaleString("EN", { month: "short" });
    const year = dateObj.getFullYear();
    return `${month} ${year}`;
  };

  return (
    <div className="pricingTable">
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650, border: "none" }} aria-label="simple table">
          <TableHead sx={{ border: "none" }}>
            <TableRow>
              <TableCell sx={{ fontWeight: 700 }}>
                Date{" "}
                {showInfoIcon(
                  "Date",
                  "This is the date when the price was published."
                )}
              </TableCell>
              <TableCell sx={{ fontWeight: 700 }} align="center">
                MSP{" "}
                {showInfoIcon(
                  "MSP",
                  "This is the manufacturer selling list price (MSP)."
                )}
              </TableCell>
              <TableCell sx={{ fontWeight: 700 }} align="center">
                LP {showInfoIcon("LP", "This is the list price (LP).")}
              </TableCell>
              {additionalField && (
                <>
                  <TableCell sx={{ fontWeight: 700 }} align="center">
                    AMNOG price{" "}
                    {showInfoIcon(
                      "AMNOG price",
                      "This field specifies the AMNOG price. Please note this field is specific to German price records."
                    )}
                  </TableCell>
                  <TableCell sx={{ fontWeight: 700 }} align="center">
                    AMNOG price change{" "}
                    {showInfoIcon(
                      "This field specifies the AMNOG price change.  Please note this field is specific to German price records."
                    )}
                  </TableCell>
                  <TableCell sx={{ fontWeight: 700 }} align="center">
                    AMNOG price change (%){" "}
                    {showInfoIcon(
                      "AMNOG price change (%) ",
                      "This field specifies the AMNOG price change in percentage (5).  Please note this field is specific to German price records."
                    )}
                  </TableCell>
                </>
              )}
              <TableCell sx={{ fontWeight: 700 }} align="center">
                Retail pharmacy price{" "}
                {showInfoIcon(
                  "Retail pharmacy price",
                  "This field specifies the retail pharmacy price."
                )}
              </TableCell>
              <TableCell sx={{ fontWeight: 700 }} align="center">
                Price to hospital{" "}
                {showInfoIcon(
                  "Price to hospital",
                  "This field specifies the price to hospital."
                )}
              </TableCell>
              <TableCell sx={{ fontWeight: 700 }} align="center">
                Comments{" "}
                {showInfoIcon(
                  "Comments",
                  "This field provides any additional comments about the price points."
                )}
              </TableCell>
              <TableCell sx={{ fontWeight: 700 }}> </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {tableData.map((row: any, index: number) => (
              <TableRow
                key={row.Date + "_" + index}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  {formatDate(row.Date)}
                </TableCell>
                <TableCell align="center">
                  {row.MSP !== null
                    ? row.MSP.toLocaleString("en-US", {
                        minimumFractionDigits: 2,
                      })
                    : "-"}
                </TableCell>
                <TableCell align="center">
                  {row.LP !== null
                    ? row.LP.toLocaleString("en-US", {
                        minimumFractionDigits: 2,
                      })
                    : "-"}
                </TableCell>
                {additionalField && (
                  <>
                    <TableCell align="center">
                      {row.AMNOG_Ex_Manufacturer !== null
                        ? row.AMNOG_Ex_Manufacturer.toLocaleString("en-US", {
                            minimumFractionDigits: 2,
                          })
                        : "-"}
                    </TableCell>
                    <TableCell align="center">
                      {row.AMNOG_Rebate !== null
                        ? row.AMNOG_Rebate.toLocaleString("en-US", {
                            minimumFractionDigits: 2,
                          })
                        : "-"}
                    </TableCell>
                    <TableCell align="center">
                      {calculateAmnogPercentage(row.AMNOG_Rebate, row.MSP)}
                    </TableCell>
                  </>
                )}
                <TableCell align="center">
                  {row.RetailPharmacyPrice !== null
                    ? row.RetailPharmacyPrice.toLocaleString("en-US", {
                        minimumFractionDigits: 2,
                      })
                    : "-"}
                </TableCell>
                <TableCell align="center">
                  {row.PriceToHospital !== null
                    ? row.PriceToHospital.toLocaleString("en-US", {
                        minimumFractionDigits: 2,
                      })
                    : "-"}
                </TableCell>
                <TableCell align="center">{row.Comments}</TableCell>
                {!(currRoleId > 4 && currRoleId < 8) && (
                  <TableCell>
                    <span
                      className="iqvia-color"
                      style={{ textAlign: "center" }}
                    >
                      <AddIcon
                        sx={{
                          fontWeight: "bold",
                          cursor: "pointer",
                          fontSize: 22,
                        }}
                      />
                    </span>
                  </TableCell>
                )}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default PricingTable;
