import React,{useState} from 'react';
import { ICellRendererParams } from 'ag-grid-community';
import { useFetchAuditLogChangeDataMutation } from './AuditLogChangeApiSlice';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import CloseIcon from "@mui/icons-material/Close";

export default (props: ICellRendererParams) => {
  const {data: gridData} = props;
  const [oldChanges, setOldChanges] = useState<any>({});
  const [newChanges, setNewChanges] = useState<any>({});

  const [fetchAuditLogChangeData] =
  useFetchAuditLogChangeDataMutation();

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);

  const buttonClicked = async () => {
    setShow(true)
    const {ID, Field} = gridData;
    const fieldArray = Field.split(',');
    const searchResult : any = await fetchAuditLogChangeData(ID);
    const firstRecord = searchResult.data[0];
    const secondRecord = searchResult.data[1];
    const oldChangesObj: any = {};
    const newChangesObj: any = {};
    fieldArray.forEach((field: string) => {
      oldChangesObj[field] = firstRecord[field];
      newChangesObj[field] = secondRecord[field];
    });
    setOldChanges(oldChangesObj);
    setNewChanges(newChangesObj);
  };

  return (
    <span>
      <Button variant="primary" onClick={() => buttonClicked()}>Changes</Button>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        centered
        size="lg"
      >
        <Modal.Header>
        <Modal.Title>Change details</Modal.Title>
        <input style={{width:"200px",textAlign:"left"}} type="text" id="search-fieldName-id" placeholder="Search field name" />
        <CloseIcon className="Close-icon" onClick={() => handleClose()} />
        </Modal.Header>
        <Modal.Body>
          <div className='form-group'>
            <div className="col-sm-4 control-label no-padding-right" data-toggle="tooltip" data-placement="right">
              <h5 className="pull-right" style={{fontWeight: "bold", paddingRight: "13%"}}>Field</h5>
            </div>
            <div className="col-sm-4 top-padding">
              <h5 style={{fontWeight: "bold", paddingLeft: "30%"}}>Old value</h5>
            </div>
            <div className="col-sm-4 top-padding">
              <h5 style={{fontWeight: "bold", paddingLeft: "20%"}}>New value</h5>
            </div>
          </div>
          {Object.keys(oldChanges).map((key) => (
            <div id="change-details-outer-div">
            <div className="modal-body no-padding-bottom">
                    <div id="" className="form-group">
                        <label className="col-sm-3 control-label no-padding-right" data-toggle="tooltip" data-placement="right">
                            {key} &nbsp;&nbsp;:
                            <span id="oldText">-</span>
                        </label>
                        <div className="col-sm-4 top-padding">
                            <span id="oldText" dangerouslySetInnerHTML={{ __html: oldChanges[key] }}></span>
                        </div>
                        <div className="col-sm-4 top-padding">
                            <span id="newText" dangerouslySetInnerHTML={{ __html: newChanges[key] }}></span>
                        </div>
                    </div>
                </div>
            </div>
          ))
          } 
        </Modal.Body>
        <Modal.Footer>
          <span className="spanBtn" style={{color:"skyblue"}} onClick={() => handleClose()}>
          Cancel
        </span>

        </Modal.Footer>
      </Modal>
    </span>
  );
};
