import React, { useContext, useEffect, useState } from "react";
import DoubleArrowIcon from "@mui/icons-material/DoubleArrow";
//import InfoIcon from "@mui/icons-material/Info";
import { FaInfoCircle } from "react-icons/fa";
import { UserContext } from "../../Editor";
import { useAppSelector } from "../../../../store/hooks";
import Element from "./Utility";

interface ICoaInstrumentForHTAProp {
  fieldInfo: any[];
  datasourceName: string;
  section: string;
}

const CoaInstrumentForHTA = ({
  fieldInfo,
  datasourceName,
  section,
}: ICoaInstrumentForHTAProp) => {
  const userContext = useContext(UserContext);
  
  const [field, setField] = useState<any[]>([]);
  const [CoaInstrument, setCoaInstrument] = useState<any>([]);
  const subPageDate = useAppSelector((state) => state.multiselect.subpageData);

  useEffect(() => {
    if (field.length == 0 && fieldInfo.length > 0) {
      setField(fieldInfo);
    }
  }, [fieldInfo]);

  useEffect(() => {
    setCoaInstrument(subPageDate["coa_instrument"]);
  }, [subPageDate]);

  return (
    <>
      {!!CoaInstrument && CoaInstrument.map((el: any, index: number) => (
        <div className="col-sm-12">
          <br></br>
          <b>COA instrument {index + 1}</b>
          <hr />
          <>
            {field
              .filter((x) => x.name === "COA_Endpoints.InstrumentID").length > 0 &&
              field
              .filter((x) => x.name === "COA_Endpoints.InstrumentID").map((val) => (
                <Element
                  label={val.label}
                  fieldType={val.fieldTypeId}
                  value={
                    el["InstrumentAbbreviations"] +
                    "||" +
                    el["Instrument_Name"] +
                    "+" +
                    el["Type_of_COA"]
                  }
                  datasourceName={datasourceName}
                  section={section}
                  description={val.description}
                />
              ))}
          </>

          <>
            {field
              .filter((x) => x.name === "COA_Endpoints.Significances").length > 0 &&
              el["HTARecord_COA_Endpoint_Significance"] != null &&
              field
              .filter((x) => x.name === "COA_Endpoints.Significances").map((val) => (
                <Element
                  label={val.label}
                  value={String(el["HTARecord_COA_Endpoint_Significance"])
                    .split("`")
                    .map((element) => (
                      <>
                        <label>{element}</label>
                        <br />
                      </>
                    ))}
                  fieldType={val.fieldTypeId}
                  datasourceName={datasourceName}
                  section={section}
                  description={val.description}
                />
              ))}
          </>

          <>
            {field
              .filter((x) => x.name === "COA_Endpoints.ImportanceID").length > 0 &&
              el["HTARecord_COA_Endpoint_Importance"] != null &&
              field
              .filter((x) => x.name === "COA_Endpoints.ImportanceID").map((val) => (
                <Element
                  label={val.label}
                  value={String(el["HTARecord_COA_Endpoint_Importance"])
                    .split("`")
                    .map((element) => (
                      <>
                        <label>{element}</label>
                        <br />
                      </>
                    ))}
                  fieldType={val.fieldTypeId}
                  datasourceName={datasourceName}
                  section={section}
                  description={val.description}
                />
              ))}
          </>

          <>
            {field
              .filter((x) => x.name === "COA_Endpoints.Discussiondetails").length > 0 && 
              field
              .filter((x) => x.name === "COA_Endpoints.Discussiondetails").map((val) => (
                <>
                  {el["Discussiondetails"] && (
                    <Element
                      label={val.label}
                      value={el["Discussiondetails"]}
                      fieldType={val.fieldTypeId}
                      datasourceName={datasourceName}
                      section={section}
                      description={val.description}
                    />
                  )}
                </>
              ))}
          </>
          {el && el[el["ID"]] == undefined && (
            <>
              <br/>
              <div className="col-sm-12" style={{backgroundColor: "whitesmoke", textAlign: "center"}}>
                No endpoints have been specified
              </div>
              <br/>
            </>
          )}
          {!!el[el["ID"]] && el[el["ID"]].map((val: any) => (
            <>
              {Object.keys(val).map((key, index) => (
                <>
                  <b>Endpoint type - {key?.split("_")?.[0]} </b>
                  {!!Object.keys(val[key]) && Object.keys(val[key]).map((x: any) => {
                    // console.log("test...",val[key][x]);
                    return (
                      <>
                        {
                          x !== "null" && 
                          <Element
                          label={"COA endpoint name"}
                          value={x}
                          datasourceName={datasourceName}
                          section={section}
                        />
                        }
                        
                         {
                          val[key][x]["results"].map((y:any) => (
                            <> 
                            {y["Result"] && y["Result"] !='' && (
                              <Element
                                label={`Result - ${y["Value"]}`}
                                value={y["Result"]}
                                datasourceName={datasourceName}
                                section={section}
                              />
                            )}
                            </>
                          ))
                          
                        }
                        {
                          val[key][x]["relatives"].length > 0 &&  <p>Relative difference(s) / Effect estimate(s) :</p>
                        }
                       
                        {                   

                          val[key][x]["relatives"].map((y:any) => (
                            <> 
                            {y["Result"] && y["Result"] !='' && (
                              <Element
                                label={y["Value"]}
                                value={y["Result"]}
                                datasourceName={datasourceName}
                                section={section}
                              />
                            )}
                            </>
                          ))
                        }
                      </>
                    );
                  })}
                </>
              ))}
            </>
          ))}
        </div>
      ))}
      {CoaInstrument && CoaInstrument.length == 0 && (
        <><div className="col-sm-12" style={{ backgroundColor: "whitesmoke", textAlign: "center" }}>
          No further COA information was provided
        </div>
        <br />
        <div style={{width:"100%",height:"0.5Px",marginTop: "0rem", marginBottom: "1rem", border: "0", borderTop: "1px solid rgba(0,0,0,.1)" }}> </div></>
      )}
    </>
  );
};

export default CoaInstrumentForHTA;
