import { useAppSelector } from "../../store/hooks";
import {
  IselectedLookup,
  IselectedLookupGlobe,
} from "../interfaces/multiselect";
import {
  DataSourceListTypes,
  DefineValidator,
  Status,
} from "../Shared/Utility";
import { IconditionalFormFields } from "./Editor";
import { clinicalDataContentLimit, userContentLimit } from "./Utility";

const checkDisease = (
  disSource: any[],
  parent: IselectedLookup[],
  child: IselectedLookup[],
  type: string,
  requiredFields: IconditionalFormFields
) => {
  const errMessage =
    "The value is not aligned to a proper parent value. Please check and correct the data";

  child.forEach((el) => {
    let ID = el.Id;
    let Parent_ID = disSource[ID][0]["ParentID"];
    if (Parent_ID && parent.length > 0) {
      if (Parent_ID > 10100000) {
        Parent_ID = Parent_ID - 101000000;
      }
      const Parent_IDs = parent.map((x) => x.Id);
      if (!Parent_IDs.includes(Parent_ID)) {
        requiredFields[type] = errMessage;
      }
    } else {
      requiredFields[type] = errMessage;
    }
  });
};

const ValidateDisease = (
  selectedLookupGlobe: IselectedLookupGlobe,
  requiredFields: IconditionalFormFields,
  lookupSource: any,
  prefix: string
) => {
  let diseaseSource: any = {};

  lookupSource.forEach((el : any) => {
    diseaseSource[el["value"]] = [el];
  });

  const diseasesubtype4: IselectedLookup[] =
    selectedLookupGlobe[prefix + "DiseaseSubtype4"] || [];
  const diseasesubtype3: IselectedLookup[] =
    selectedLookupGlobe[prefix + "DiseaseSubtype3"] || [];
  const diseasesubtype2: IselectedLookup[] =
    selectedLookupGlobe[prefix + "DiseaseSubtype2"] || [];
  const diseasesubtype: IselectedLookup[] =
    selectedLookupGlobe[prefix + "DiseaseSubtype"] || [];
  const disease: IselectedLookup[] =
    selectedLookupGlobe[prefix + "Disease_ID"] || [];
  const therapeuticArea: IselectedLookup[] =
    selectedLookupGlobe[prefix + "TherapeuticArea"] || [];

  if (diseasesubtype4.length > 0)
    checkDisease(
      diseaseSource,
      diseasesubtype3,
      diseasesubtype4,
      prefix + "DiseaseSubtype4",
      requiredFields
    );
  if (diseasesubtype3.length > 0)
    checkDisease(
      diseaseSource,
      diseasesubtype2,
      diseasesubtype3,
      prefix + "DiseaseSubtype3",
      requiredFields
    );
  if (diseasesubtype2.length > 0)
    checkDisease(
      diseaseSource,
      diseasesubtype,
      diseasesubtype2,
      prefix + "DiseaseSubtype2",
      requiredFields
    );
  if (diseasesubtype.length > 0)
    checkDisease(
      diseaseSource,
      disease,
      diseasesubtype,
      prefix + "DiseaseSubtype",
      requiredFields
    );

  if (disease.length > 0)
    checkDisease(
      diseaseSource,
      therapeuticArea,
      disease,
      "Disease_ID",
      requiredFields
    );

  return requiredFields;
};

export const ValidateFormForSFRandSFRR = (
  dataSourceID: Number,
  nextStatus: Number,
  selectedLookupGlobe: IselectedLookupGlobe,
  values: any,
  setConditionalFormFields: any,
  valid : Boolean,
  lookupSource: any
) => {
  console.log("nextStatus", nextStatus);

  const requiredFields: IconditionalFormFields = {};

  const defaultErrorMessage = "This field is required";

  if (
    dataSourceID == DataSourceListTypes.HTARecord &&
    (nextStatus == Status.SFR || nextStatus == Status.SFRR)
  ) {
    //AssessmentType_ID

    if (values["AssessmentType_ID"] == DefineValidator.HTAAssessmentTypeId) {
      if (
        !selectedLookupGlobe["HTARecord_SubmittingCompany"] ||
        (selectedLookupGlobe["HTARecord_SubmittingCompany"]?.length == 0 &&
          (values["HTAStatus_ID"] == DefineValidator.HTAStatusCompleted ||
            values["HTAStatus_ID"] == DefineValidator.HTAStatusPublished ||
            values["HTAStatus_ID"] == DefineValidator.HTAStatusOngoingDraft ||
            values["HTAStatus_ID"] == DefineValidator.HTAStatusPlanned ||
            values["HTAStatus_ID"] == DefineValidator.HTAStatusOngoing ||
            values["HTAStatus_ID"] == DefineValidator.HTAStatusCancelled ||
            values["HTAStatus_ID"] == DefineValidator.HTAStatusSuspended))
      ) {
        requiredFields["HTARecord_SubmittingCompany"] = defaultErrorMessage;
      }
      if (
        (values["Decision"] == null || values["Decision"]?.length == 0) &&
          (values["HTAStatus_ID"] == DefineValidator.HTAStatusCompleted ||
            values["HTAStatus_ID"] == DefineValidator.HTAStatusPublished)
      ) {
        requiredFields["Decision"] = defaultErrorMessage;
      }

      if (
        (values["ReasonForCancelation"] == null || values["ReasonForCancelation"]?.length == 0) &&
          (values["HTAStatus_ID"] == DefineValidator.HTAStatusCancelled ||
            values["HTAStatus_ID"] == DefineValidator.HTAStatusSuspended)
      ) {
        requiredFields["ReasonForCancelation"] = defaultErrorMessage;
      }

      if (
        values["HTAStatus_ID"] == DefineValidator.HTAStatusCancelled ||
        values["HTAStatus_ID"] == DefineValidator.HTAStatusOngoingDraft
      ) {
        if (values["Published"] != "") {
          if (values["Published"] == true) {
            if (!values["PublishedDate"] || values["PublishedDate"] == "") {
              requiredFields["PublishedDate"] = defaultErrorMessage;
            }
          }
        } else {
          requiredFields["Published"] = defaultErrorMessage;
        }
      }
    } else {
      if (values["HTAStatus_ID"] != "") {
        if (values["Published"] != "") {
          if (values["Published"] == true) {
            if (!values["PublishedDate"] || values["PublishedDate"] == "") {
              requiredFields["PublishedDate"] = defaultErrorMessage;
            }
          }
        }
      }
    }

    //HTAType_ID

    if (
      values["HTAType_ID"] == DefineValidator.Single_drug_assessment ||
      values["HTAType_ID"] == DefineValidator.Multipl_drug_assessment
    ) {
      if (!values["Drug_Intervention"] || values["Drug_Intervention"] != true) {
        requiredFields["Drug_Intervention"] = defaultErrorMessage;
      } else if (selectedLookupGlobe["HTARecord_Drug"] == undefined) {
        requiredFields["HTARecord_Drug"] = defaultErrorMessage;
      }
    }

    if (values["HTAType_ID"] == DefineValidator.Medical_device_assessment) {
      if (
        !values["DeviceIntervention"] ||
        values["DeviceIntervention"] != true
      ) {
        requiredFields["DeviceIntervention"] = defaultErrorMessage;
      } else if (selectedLookupGlobe["HTARecord_Device"] == undefined) {
        requiredFields["HTARecord_Device"] = defaultErrorMessage;
      }
    }

    if (
      values["HTAType_ID"] == DefineValidator.Procedureintervention_assessment
    ) {
      if (
        !values["ProcedureIntervention"] ||
        values["ProcedureIntervention"] != true
      ) {
        requiredFields["ProcedureIntervention"] = defaultErrorMessage;
      } else if (selectedLookupGlobe["HTARecord_ProcedureIntervention"] == undefined) {
        requiredFields["HTARecord_ProcedureIntervention"] = defaultErrorMessage;
      }
    }

    // AssessmentType_ID

    if (values["AssessmentType_ID"] == DefineValidator.HTAAssessmentTypeId) {
      if (
        values?.Drug_Intervention == true &&
        (values?.HTAStatus_ID == DefineValidator.HTAStatusCompleted ||
          values?.HTAStatus_ID == DefineValidator.HTAStatusPublished ||
          values?.HTAStatus_ID == DefineValidator.HTAStatusOngoingDraft ||
          values?.HTAStatus_ID == DefineValidator.HTAStatusPlanned ||
          values?.HTAStatus_ID == DefineValidator.HTAStatusOngoing ||
          values?.HTAStatus_ID == DefineValidator.HTAStatusCancelled ||
          values?.HTAStatus_ID == DefineValidator.HTAStatusSuspended)
      ) {
        if (selectedLookupGlobe["HTARecord_Drug"] == undefined) {
          requiredFields["HTARecord_Drug"] = defaultErrorMessage;
        }
      }

      if (
        values?.DeviceIntervention == true &&
        (values?.HTAStatus_ID == DefineValidator.HTAStatusCompleted ||
          values?.HTAStatus_ID == DefineValidator.HTAStatusPublished ||
          values?.HTAStatus_ID == DefineValidator.HTAStatusOngoingDraft ||
          values?.HTAStatus_ID == DefineValidator.HTAStatusPlanned ||
          values?.HTAStatus_ID == DefineValidator.HTAStatusOngoing ||
          values?.HTAStatus_ID == DefineValidator.HTAStatusCancelled ||
          values?.HTAStatus_ID == DefineValidator.HTAStatusSuspended)
      ) {
        if (selectedLookupGlobe["HTARecord_Device"] == undefined) {
          requiredFields["HTARecord_Device"] = defaultErrorMessage;
        }
      }
    }

    // Reviewed Indication and Manufacturer’s Claims

    if (
      values?.AssessmentType_ID == DefineValidator.HTAAssessmentTypeId &&
      values?.HTAStatus_ID != DefineValidator.HTAStatusOngoingDraft &&
      (!values?.SpecifyIndication || values?.SpecifyIndication == "")
    ) {
      requiredFields["SpecifyIndication"] = defaultErrorMessage;
    }

    if (values?.AssessmentType_ID == DefineValidator.HTAAssessmentTypeId) {
      if (
        !values.PreliminaryRecommendationExists &&
        // values?.PreliminaryRecommendationExists == "" &&
        values?.HTAStatus_ID == DefineValidator.HTAStatusOngoingDraft
      ) {
        requiredFields["PreliminaryRecommendationExists"] = defaultErrorMessage;
      }

      if (
        values.PreliminaryRecommendationExists &&
        // values?.PreliminaryRecommendationExists == "" &&
        values?.HTAStatus_ID == DefineValidator.HTAStatusOngoingDraft
      ) {
        if (
          !values.PreliminaryRecommendation_ID ||
          values.PreliminaryRecommendation_ID == ""
        ) {
          requiredFields["PreliminaryRecommendation_ID"] = defaultErrorMessage;
        }

        if (
          !values.RecommendationExplanation ||
          values.RecommendationExplanation == ""
        ) {
          requiredFields["RecommendationExplanation"] = defaultErrorMessage;
        }

        if (!values.SpecifyReimbursement || values.SpecifyReimbursement == "") {
          if (
            values.PreliminaryRecommendation_ID ==
              DefineValidator.PositiveRecommendation ||
            values.PreliminaryRecommendation_ID ==
              DefineValidator.PositiveRecommendation ||
            values.PreliminaryRecommendation_ID ==
              DefineValidator.MultipleRecommendations
          ) {
            requiredFields["SpecifyReimbursement"] = defaultErrorMessage;
          }
        }
      }
    }

    //section ID 66

    if (values?.AssessmentType_ID == DefineValidator.HTAAssessmentTypeId) {
      if (
        (values.Tagline == "" || !values.Tagline) &&
        (values.HTAStatus_ID == DefineValidator.HTAStatusCompleted ||
          values.HTAStatus_ID == DefineValidator.HTAStatusPublished)
      ) {
        requiredFields["Tagline"] = defaultErrorMessage;
      }

      if (valid == false) {
        if (
          (values.FinalRecommendation_ID == "" ||
          !values.FinalRecommendation_ID) &&
            (values.HTAStatus_ID == DefineValidator.HTAStatusCompleted ||
              values.HTAStatus_ID == DefineValidator.HTAStatusPublished)
        ) {
          if (values.Agency_ID == DefineValidator.AgencyHas) {
            requiredFields["FinalRecommendation_ID"] =
              defaultErrorMessage +
              " Also all SMR and ASMR fields are mandatory.";
          } else if (values.Country_ID == DefineValidator.CountryIdGermany) {
            requiredFields["FinalRecommendation_ID"] =
              defaultErrorMessage +
              " Also all benefit rating fields are mandatory.";
          }
        } else if (
          values.FinalRecommendation_ID != "" &&
          (values.HTAStatus_ID == DefineValidator.HTAStatusCompleted ||
            values.HTAStatus_ID == DefineValidator.HTAStatusPublished)
        ) {
          if (values.Agency_ID == DefineValidator.AgencyHas) {
            requiredFields["FinalRecommendation_ID"] =
              "Also all SMR and ASMR fields are mandatory.";
          } else if (values.Country_ID == DefineValidator.CountryIdGermany) {
            requiredFields["FinalRecommendation_ID"] =
              "All benefit rating fields are mandatory.";
          }
        }
      } else {
        if (
          (!values.FinalRecommendation_ID ||
            values.FinalRecommendation_ID == "") &&
          (values.HTAStatus_ID == DefineValidator.HTAStatusCompleted ||
            values.HTAStatus_ID == DefineValidator.HTAStatusPublished)
        ) {
          requiredFields["FinalRecommendation_ID"] = defaultErrorMessage;
        }
      }
    } else {
      if (
        (values.Tagline == "" || !values.Tagline) &&
        (values.HTAStatus_ID == DefineValidator.HTAStatusCompleted ||
          values.HTAStatus_ID == DefineValidator.HTAStatusPublished)
      ) {
        requiredFields["Tagline"] = defaultErrorMessage;
      }
    }

    if (
      values.AssessmentType_ID == DefineValidator.HTAAssessmentTypeId &&
      values.IsSubgroupEnabled == false &&
      values.Agency_ID == DefineValidator.AgencyHas &&
      (values.Status_ID == DefineValidator.HTAStatusCompleted ||
        values.Status_ID == DefineValidator.HTAStatusPublished)
    ) {
      if (values.SMRFrance_ID == "" || !values.SMRFrance_ID) {
        requiredFields.SMRFrance_ID = defaultErrorMessage;
      }
      if (values.ASMRFrance_ID == "" || !values.ASMRFrance_ID) {
        requiredFields.ASMRFrance_ID = defaultErrorMessage;
      }
    }

    if (
      values.AssessmentType_ID == DefineValidator.HTAAssessmentTypeId &&
      values.IsSubgroupEnabled == false &&
      values.Country_ID == DefineValidator.CountryIdGermany &&
      (values.Agency_ID == DefineValidator.AgencyGBA ||
        values.Agency_ID == DefineValidator.AgencyIQWiG) &&
      (values.Status_ID == DefineValidator.HTAStatusCompleted ||
        values.Status_ID == DefineValidator.HTAStatusPublished)
    ) {
      if (values.BenefitRatingGermany_ID == "" || !values.BenefitRatingGermany_ID) {
        requiredFields.BenefitRatingGermany_ID = defaultErrorMessage;
      }
    }
  }

  if (dataSourceID == DataSourceListTypes.HTARecord) {
    ValidateDisease(selectedLookupGlobe, requiredFields, lookupSource, "HTARecord_Primary");
    ValidateDisease(selectedLookupGlobe, requiredFields, lookupSource, "HTARecord_Secondary");
  }

  if (dataSourceID == DataSourceListTypes.ClinicalData) {
    ValidateDisease(selectedLookupGlobe, requiredFields, lookupSource, "ClinicalData_");
  }

  // if (dataSourceID == DataSourceListTypes.RegulatoryWatch) {
  //   ValidateDisease(selectedLookupGlobe, requiredFields, "RegulatoryWatch_");
  // }

  if (Object.keys(requiredFields).length == 0) {
    return [true,[]];
  }

  // setConditionalFormFields(requiredFields);
  return [false, requiredFields];
};

const getContentLimit = (datasourceId : number) => {
  if(datasourceId == 2){
    return clinicalDataContentLimit;
  }else if (datasourceId == 275){
    return userContentLimit;
  }
  return {};
}

export const ValidateFormData = (datasourceId : number, values:any, allFields?: any) => {
  let ContentLimit : {[key : string] : number} = getContentLimit(datasourceId);
  let requiredFields : any = {};
  let defaultMessage : string = "Maximum length of characters is: " 
  let check = true;
  const numFields = allFields.filter((x:any) => (x.fieldTypeId == 2 || x.fieldTypeId == 55));
  let _values = { ...values };
  if (numFields.length > 0) {
    numFields.forEach((field:any) => {
      if(_values[field.name] != null && _values[field.name] != undefined && _values[field.name]?.toString().indexOf(',') > -1) {
        _values = { ..._values, [field.name]: _values[field.name].replaceAll(',', '') };
      }
      if(_values[field.name + '_Max'] != null && _values[field.name + '_Max'] != undefined && _values[field.name + '_Max']?.toString().indexOf(',') > -1) {
        _values = { ..._values, [field.name + '_Max']: _values[field.name + '_Max'].replaceAll(',', '') };
      }
    });
  }
  if(Object.keys(ContentLimit)?.length > 0){
    Object.keys(ContentLimit).forEach((key) => {
      if(values[key]?.length > ContentLimit[key])
        requiredFields[key] = defaultMessage + String(ContentLimit[key]);
    });
  }
  if(Object.keys(requiredFields).length > 0)
    check = false;
  return [check, requiredFields, _values];

}

