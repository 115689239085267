import axiosInstance from './axiosInstance';

const UpdateCall = (url:string, Id:number, body:any) => {
      const data  = body
      return axiosInstance.put( url + `/${Id}`,data).then((resp) => {
            return resp
      }).catch((error) =>{
            return error
      })
}

export default UpdateCall;