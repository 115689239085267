import { ColumnFormat } from '../../store/SearchResultSlice';

export interface SearchDTO {
    searchterm: string;
    searchkey: string;
    datasourceid: number;
    userid: number;
    roleid: number;
    layoutname: string;
  };

export const ColumnFormats = {
    ColumnOrder: "1",
    FieldCss: "all",
    FieldRenderMethod: "QHTASearch_mRenderTitle",
    FormFieldType: 6,
    Hint: "The standardised title of the health technology assessment report in English with the following format: &lsquo;&rsquo;Generic (Brand) for indication, which typically includes <span style=\"color: #626262;\">line of treatment, </span><span style=\"color: #626262;\">disease, </span><span style=\"color: #626262;\">patient population, and it indicates if this assessment is an original, </span>extension of indication, resubmission, renewal or new formulation.<br /><br />Variations exist for horizon scan reports.",
    ID: 832,
    IsAdditional: false,
    IsDefault: true,
    IsVisible: false,
    Label: "Title",
    Name: "Name",
};


export const TableData = {
    AgencyAbbreviation: "NHSC",
    AgencyName: "National Horizon Scanning Centre",
    CountryName: "United Kingdom",
    CreatedBy: 0,
    CreatedDate: "0001-01-01T00:00:00",
    Decision: "2015-09-01T00:00:00",
    Direct_Link: "http://hta-test.quintiles.com/HTA/View/0",
    FinalRecommendationLIValue: "No recommendation",
    HTARecord_ID: 0,
    ID: 19147,
    Name: "Pembrolizumab (Keytruda) for advanced or metastatic urothelial cancer – second line",
    PrimaryDiseaseNames: "Bladder cancer",
    PrimaryTherapeuticAreaNames: "Oncology",
    RecordDrugNames: "Keytruda",
    Status_ID: 0,
}

export interface Data {
    AgencyAbbreviation: string,
    AgencyName: string,
    CountryName: string,
    CreatedBy: number,
    CreatedDate: Date,
    Decision: Date,
    Direct_Link: string,
    FinalRecommendationLIValue: string,
    HTARecord_ID: number,
    ID: number,
    Name: string,
    PrimaryDiseaseNames: string,
    PrimaryTherapeuticAreaNames: string,
    RecordDrugNames: string,
    Status_ID: number,
}

// interface Data {
//     calories: number;
//     carbs: number;
//     fat: number;
//     name: string;
//     protein: number;
//   }

export interface HeadCell {
    disablePadding: boolean;
    id: string// keyof Data;
    label: string;
    numeric: boolean;
  }


export const createHeaderCell = (columnData: ColumnFormat[]) => {
    let createdHeaderCells: HeadCell[] = [];
    if(columnData.length > 0){
        createdHeaderCells = columnData.map((tableColumn) => {
            const headerCell:HeadCell = {
                disablePadding: true,
                id: tableColumn.Name,
                label: tableColumn.Label,
                numeric: false
            };
            return headerCell;
        })
    }
    return createdHeaderCells;
}

export const formatHeaderCell = (item: object) => {
    let createdHeaderCells: HeadCell[] = [];
    if(item){
        for (var key in item) {
            if (item.hasOwnProperty(key)) {
                const headerCell:HeadCell = {
                    disablePadding: true,
                    id: key,
                    label: key.charAt(0).toUpperCase() + key.slice(1),
                    numeric: false
                };
                createdHeaderCells.push(headerCell);
            }
        }
    }
    return createdHeaderCells;
}
