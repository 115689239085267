import React, { useEffect, useState } from "react";
import apiCall from "../service/apiCall";
import Tree from "./Tree";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { setLookupSource, setSuperDisease } from "../../store/MultiSelectSlice";
import "../Disease/DiseaseHierarchy.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";

//export const Data = useAppSelector((state) => state.multiselect.SuperDisease);
const policyHierarchy = [
  "Policy Category",
  "Policy Sub category", 
];

interface IcheckboxRule {
  [name: number]: boolean;
}

function PolicyCategoryHierarchy({
  checkedValues,
  setCheckedValues,
  partiallyCheckedValues,
  setPartiallyCheckedValues,
  clearAll,
  setClearAll,
  showSelected,
}: any) {
  const checkBoxeRuleData = {
    0: false,
    1: false,
   
  };
  const [seen, setSeen] = useState<number[]>([]);
  const [filterText, setFilterText] = useState("");

  const [checkBoxChecked, setCheckBoxChecked] = useState<boolean>(true);
  const [checkboxRule, setCheckBoxRule] = useState<IcheckboxRule>({
    0: false,
    1: false,
   
  });
  const nodes = useAppSelector((state) => state.multiselect.SuperDisease);
  const version_id = useAppSelector(
    (state) => state.multiselect.recordVersionId
  );
//   const indicationId = useAppSelector(
//     (state) => state.multiselect.indicationId
//   );
  const currentUrl = window.location.href;
  const isRecordViewPage = currentUrl.toString().includes("/view/");

  const [nodesFiltered, setNodesFiltered] = useState([]);
  const [expandedValues, setExpandedValues] = useState<number[]>([]);
  const dispatch = useAppDispatch();
  const [filter, setFilter] = useState(false);
  const lookupSource = useAppSelector(
    (state) => state.multiselect.lookupSource
  );

  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchterm] = useState<string>("");

  const [checkStatus, setCheckStatus] = useState<number[]>([]);


  useEffect(() => {
    if (nodes.length == 0) {
      apiCall("/api/policy_category").then((resp) => {
        let response = resp.data;
        response = response.filter((el: any) => el["children"].length > 0);
        console.log(resp.data);
        dispatch(setSuperDisease(response));
        setLoading(false);
      });

      apiCall("/api/all/policy_category").then((resp) => {
        dispatch(setLookupSource(resp.data));
      });

    }
    else {
      setLoading(false);
    }
  }, []);


  useEffect(() => {
    if (clearAll) {
      ClearAllFunc();
    }
  }, [clearAll]);


  useEffect(() => {
    if (showSelected) {
      showSelectedFunc();
    } else {
      showAll();
    }
  }, [showSelected]);

  const ClearAllFunc = () => {
    setCheckedValues([]);
    setSeen([]);
    setPartiallyCheckedValues([]);
    setExpandedValues([]);
    setSearchterm("");
    setClearAll(false);
    setFilter(false);
    setNodesFiltered(nodes);
    setCheckBoxRule({
      0: false,
      1: false,
     
    });
  }
  const setRule = (key: number) => {
    let newData = { ...checkBoxeRuleData, [key]: true };
    setCheckBoxRule(newData);
    setCheckBoxChecked(false);
  };

  let e_ids: number[] = [];
  const expandTill = (index: number, limit: number, data: any) => {
    data.map((el: any) => {
      e_ids.push(el["value"]);
      if (index + 1 < limit) {
        expandTill(index + 1, limit, el["children"]);
      }
    });
    setExpandedValues(e_ids);
  };

  let checked_ids: number[] = [];
  let half_check_ids: number[] = [];

  const selectIdsOnLevel = (
    index: number,
    limit: number,
    data: any,
    status: boolean
  ) => {
    data.map((el: any) => {
      if (!partiallyCheckedValues.includes(el["value"])) {
        if (index == limit) {
          checked_ids.push(el["value"]);
        } else if (el["children"].length > 0) {
          half_check_ids.push(el["value"]);
        } else {
          checked_ids.push(el["value"]);
        }
        if (index < limit) {
          selectIdsOnLevel(index + 1, limit, el["children"], status);
        }
      }
    });
    if (status) {
      setCheckedValues([...checkedValues, ...checked_ids]);
      setPartiallyCheckedValues([...partiallyCheckedValues, ...half_check_ids]);
    } else {
      checked_ids = checked_ids.filter((x) => !checkedValues.includes(x));
      half_check_ids = half_check_ids.filter(
        (x) => !partiallyCheckedValues.includes(x)
      );
      setCheckedValues(checked_ids);
      setPartiallyCheckedValues([...partiallyCheckedValues, ...half_check_ids]);
    }
  };

  ///FILTER LOGIC /////
  const onFilterChange = (e: any) => {
    if (e.target.value.length >= 2) {
      setFilterText(e.target.value);
      // if (e.target.value) {
      //   filterTree();
      //   setFilter(true);
      // }
    } else {
      setFilterText("");
      setFilter(false);
      setExpandedValues([]);
      setCheckStatus([]);
    }
  };
  useEffect(() => {
    if (filterText.length >= 2) {
      filterTree();
      setFilter(true);
    }
  }, [filterText]);

  const filterNodes = (filtered: any, node: any) => {
    const children = (node.children || []).reduce(filterNodes, []);
    if (
      // Node's label matches the search string
      node.label.toLocaleLowerCase().indexOf(filterText.toLocaleLowerCase()) >
        -1 ||
      // Or a children has a matching node
      children.length
    ) {
      filtered.push({ ...node, ...(children.length && { children }) });
    }
    return filtered;
  };
  const capitalize = (value: string) => {
    const word = value;
    const firstLetter = word.charAt(0);
    const firstLetterCap = firstLetter.toUpperCase();
    const remainingLetters = word.slice(1);
    return firstLetterCap + remainingLetters.toLowerCase();
  };

  const changeName = (array: any[], txt: string, status: number[]) => {
    const s = (r: any, { children, ...object }: any) => {
      if (
        String(object.label)
          .toLocaleLowerCase()
          .includes(txt.toLocaleLowerCase())
      ) {
        const labelValue = String(object.label);
        const location = labelValue
          .toLocaleLowerCase()
          .indexOf(txt.toLocaleLowerCase());
        const before = labelValue.substr(0, location);
        const newval = labelValue.substr(location, txt.length);
        const after = labelValue.substr(
          location + txt.length,
          labelValue.length
        );
        if (!status.includes(object.Level)) status.push(object.Level);
        object.label = `${before}<b>${newval}</b>${after}`;
      }
      if (!!children) children = children.reduce(s, []);
      else children = [];
      r.push({ ...object, children: children });
      return r;
    };
    return [array.reduce(s, []),status];
  };
  
  const search = (array: any[], txt: string) => {
    let status :number[] = [];
    let e_nodes: number[] = [];
    const s = (r: any, { children, ...object }: any) => {
      if (
        String(object.label)
          .toLocaleLowerCase()
          .includes(txt.toLocaleLowerCase())
      ) {
        const labelValue = String(object.label);
        const location = labelValue
          .toLocaleLowerCase()
          .indexOf(txt.toLocaleLowerCase());
        const before = labelValue.substr(0, location);
        const newval = labelValue.substr(location, txt.length);
        const after = labelValue.substr(
          location + txt.length,
          labelValue.length
        );
        object.label = `${before}<b>${newval}</b>${after}`;
        if (!status.includes(object.Level)) status.push(object.Level);

        if (!!children) {
          [children,status] = changeName(children, txt,status);
        }
        r.push({ ...object, children: children });
      } else {
        if (children) {
          children = children.reduce(s, []);
          if (children.length) {
            e_nodes.push(object.value);
            r.push({ ...object, children });
          }
        }
      }
      return r;
    };
    return [array.reduce(s, []), e_nodes,status];
  };


  const searchById = (array: any) => {
    let e_nodes: number[] = [];
    const s = (r: any, object: any) => {
      if (checkedValues.includes(object.value)) {
        r.push({ ...object, children: object.children });
        //e_nodes.push(object.value);
        return r;
      }
      // e_nodes.push(object.value);
      if (!!object.children && object.children.length) {
        let children = object.children.reduce(s, []);
        r.push({ ...object, children });
        //e_nodes.push(object.value);
      }
      return r;
    };

    if (array.length > 0) return [array.reduce(s, []), e_nodes];
    else return [[], []];
  };

  const filterForShowSelected = (array: any[]) => {
    let e_nodes: number[] = [];
    const getNodes = (result: any, object: any) => {
      if (checkedValues.includes(object.value)) {
        result.push({ ...object });
        if (object.ParentId != null) e_nodes.push(object.value);
        return result;
      }

      if (partiallyCheckedValues.includes(object.value))
        e_nodes.push(object.value);

      if (Array.isArray(object.children)) {
        const nodes = object.children.reduce(getNodes, []);
        if (nodes.length) result.push({ ...object, children: nodes });
      }

      return result;
    };

    return [array.reduce(getNodes, []), e_nodes];
  };

  const filterTree = () => {
    // Reset nodes back to unfiltered state
    if (!filterText || filterText === "" || filterText.length === 0) {
      setNodesFiltered(nodes);
      return;
    }

    const [nodesFiltered, expanded_nodes,status] :any = search(nodes, filterText);
    setNodesFiltered(nodesFiltered);
    setExpandedValues(expanded_nodes);
    setCheckStatus(status);
  };

  const showSelectedFunc = () => {
    setFilter(true);
    const [nodesFiltered, expanded_nodes] = filterForShowSelected(nodes);
    // console.log("nodesFiltered", nodesFiltered);
    // console.log("expanded_nodes", expanded_nodes);
    setNodesFiltered(nodesFiltered);
    // if(!(indicationId !=0 && expandedValues.length > 0))
    setExpandedValues(expanded_nodes);
  };

  const showAll = () => {
    setFilter(false);
    setExpandedValues([]);
  };


  return (
    <>
      {loading ? (
        <div style={{ paddingLeft: "40%" }}>
          <FontAwesomeIcon icon={faSpinner} spinPulse />{" "}
          <h6 style={{ color: "black", display: "inline" }}>Loading...</h6>{" "}
        </div>
      ) : (
        <div key="items6">
          <input
            key={"item1"}
            style={{
              width: "60%",
              marginTop: "15px",
              marginBottom: "10px",
              padding: 10,
              border: "1px solid #d5d5d5",
            }}
            type="text"
            placeholder="Enter Search Terms"
            value={searchTerm}
            onChange={(e) => {
              setSearchterm(e.target.value);
              onFilterChange(e);
            }}
            className="diseaseInput"
          />
          <ol
            key={"item2"}
            className="cd-breadcrumb triangle custom-icons"
            id="pathway-breadcrumb"
          >
            {policyHierarchy.map((x, i) => {
              return (
                <>
                  <li key="items1{i}">
                    <a
                      key="items2{i}"
                      style={{ minWidth: "130px" }}
                      onClick={() => {
                        if (checkboxRule[i] == false) {
                          setRule(i);
                        }
                        if (i > 0) {
                          expandTill(0, i, nodes);
                        } else {
                          setExpandedValues([]);
                        }
                      }}
                    >
                      <input
                        key="items3{i}"
                        type="checkbox"
                        checked={checkBoxChecked}
                        style={{
                          display: checkboxRule[i] == true ? "inline" : "none",
                          paddingTop: "2px",
                          zIndex: "10px",
                        }}
                        onChange={() => {}}
                        onClick={(e) => {
                          setCheckBoxChecked(!checkBoxChecked);
                          selectIdsOnLevel(0, i, nodes, !checkBoxChecked);
                        }}
                      />
                      {checkStatus.includes(i) ? <b>{x}</b> : x}
                    </a>
                  </li>
                </>
              );
            })}
          </ol>
          <div
            key={"item3"}
            style={{
              minHeight: "200px",
              width: "900px",
              height: "400px",
              overflow: "auto",
            }}
          >
            <Tree
              Data={filter ? nodesFiltered : nodes}
              seen={seen}
              setSeen={setSeen}
              checkedValues={checkedValues}
              setCheckedValues={setCheckedValues}
              partiallyCheckedValues={partiallyCheckedValues}
              setPartiallyCheckedValues={setPartiallyCheckedValues}
              expandedValues={expandedValues}
              setExpandedValues={setExpandedValues}
              parent={false}
              till={0}
              index={""}
            />
          </div>
        </div>
      )}
    </>
  );
}

export default PolicyCategoryHierarchy;
