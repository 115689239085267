// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.commentModal .modal-dialog {
  width: 800px !important;
  max-width: 800px !important;
}

.commentModal .modal-dialog .modal-body .bodyContainer {
  padding: 10px;
}

.commentModal .modal-dialog .modal-body .bodyContainer .title {
  font-weight: 700;
  color: #000 !important;
}

.commentModal
  .modal-dialog
  .modal-body
  .bodyContainer
  .autocomplete
  .MuiAutocomplete-root
  .MuiFormControl-root {
  width: 350px !important;
}

.commentModal .modal-dialog .modal-footer .spanBtn {
  color: #3bbeed;
  cursor: pointer;
}

`, "",{"version":3,"sources":["webpack://./src/components/Popup/CommentModal.css"],"names":[],"mappings":"AAAA;EACE,uBAAuB;EACvB,2BAA2B;AAC7B;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,gBAAgB;EAChB,sBAAsB;AACxB;;AAEA;;;;;;;EAOE,uBAAuB;AACzB;;AAEA;EACE,cAAc;EACd,eAAe;AACjB","sourcesContent":[".commentModal .modal-dialog {\n  width: 800px !important;\n  max-width: 800px !important;\n}\n\n.commentModal .modal-dialog .modal-body .bodyContainer {\n  padding: 10px;\n}\n\n.commentModal .modal-dialog .modal-body .bodyContainer .title {\n  font-weight: 700;\n  color: #000 !important;\n}\n\n.commentModal\n  .modal-dialog\n  .modal-body\n  .bodyContainer\n  .autocomplete\n  .MuiAutocomplete-root\n  .MuiFormControl-root {\n  width: 350px !important;\n}\n\n.commentModal .modal-dialog .modal-footer .spanBtn {\n  color: #3bbeed;\n  cursor: pointer;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
