// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.home-height {
  min-height: 67em;
}
.rightactionpane {
  border: 5px solid #ededed;
  padding-right: 0px;
}
.clientNewsTitle {
  margin-top: 20px;
  margin-bottom: 10px;
  font-size: 26px;
  font-weight: normal;
  line-height: 1.2;
}
.clientNewsDate {
  margin-top: 25px;
  margin-bottom: 10px;
  font-size: 18px;
  font-weight: bold;
  line-height: 1.2;
}
.newsTitle {
  color: rgb(0, 170, 231);
  outline: none !important;
  text-decoration: none;
}
.client-news-body-full-article {
  margin-top: 30px;
}
.list-group {
  display: flex;
  flex-direction: row;
  padding-left: 0;
  margin-bottom: -1em !important;
  border-radius: 0.25rem;
}
.client-news-body {
  margin-left: -25px;
}
.news-input {
  width: 141px;
  border: 1px solid #d6d1d1;
  border-radius: 0;
  height: 30px;
}
.news-input:focus {
  border: 1px solid rgb(0, 170, 231);
  outline: none;
}
.list-group-item {
  margin-left: 0px;
  position: relative;
  display: block;
  padding: 1.75rem 1.25rem !important;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0 !important;
}
`, "",{"version":3,"sources":["webpack://./src/components/ClientNewsArticles/clientNewsDetails.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;AAClB;AACA;EACE,yBAAyB;EACzB,kBAAkB;AACpB;AACA;EACE,gBAAgB;EAChB,mBAAmB;EACnB,eAAe;EACf,mBAAmB;EACnB,gBAAgB;AAClB;AACA;EACE,gBAAgB;EAChB,mBAAmB;EACnB,eAAe;EACf,iBAAiB;EACjB,gBAAgB;AAClB;AACA;EACE,uBAAuB;EACvB,wBAAwB;EACxB,qBAAqB;AACvB;AACA;EACE,gBAAgB;AAClB;AACA;EACE,aAAa;EACb,mBAAmB;EACnB,eAAe;EACf,8BAA8B;EAC9B,sBAAsB;AACxB;AACA;EACE,kBAAkB;AACpB;AACA;EACE,YAAY;EACZ,yBAAyB;EACzB,gBAAgB;EAChB,YAAY;AACd;AACA;EACE,kCAAkC;EAClC,aAAa;AACf;AACA;EACE,gBAAgB;EAChB,kBAAkB;EAClB,cAAc;EACd,mCAAmC;EACnC,sBAAsB;EACtB,sCAAsC;EACtC,2BAA2B;AAC7B","sourcesContent":[".home-height {\n  min-height: 67em;\n}\n.rightactionpane {\n  border: 5px solid #ededed;\n  padding-right: 0px;\n}\n.clientNewsTitle {\n  margin-top: 20px;\n  margin-bottom: 10px;\n  font-size: 26px;\n  font-weight: normal;\n  line-height: 1.2;\n}\n.clientNewsDate {\n  margin-top: 25px;\n  margin-bottom: 10px;\n  font-size: 18px;\n  font-weight: bold;\n  line-height: 1.2;\n}\n.newsTitle {\n  color: rgb(0, 170, 231);\n  outline: none !important;\n  text-decoration: none;\n}\n.client-news-body-full-article {\n  margin-top: 30px;\n}\n.list-group {\n  display: flex;\n  flex-direction: row;\n  padding-left: 0;\n  margin-bottom: -1em !important;\n  border-radius: 0.25rem;\n}\n.client-news-body {\n  margin-left: -25px;\n}\n.news-input {\n  width: 141px;\n  border: 1px solid #d6d1d1;\n  border-radius: 0;\n  height: 30px;\n}\n.news-input:focus {\n  border: 1px solid rgb(0, 170, 231);\n  outline: none;\n}\n.list-group-item {\n  margin-left: 0px;\n  position: relative;\n  display: block;\n  padding: 1.75rem 1.25rem !important;\n  background-color: #fff;\n  border: 1px solid rgba(0, 0, 0, 0.125);\n  border-radius: 0 !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
