import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { Section, Field } from "./DataSourceMetaData";
import { emptySplitApi } from "./emptyApiSlice";
import { Lookup_Item } from "./LookupMetadata";

export const apiSlice_Lookup = emptySplitApi.injectEndpoints({
  endpoints(build) {
    return {
      fetchLookupItems: build.query<
        Lookup_Item[],
        { lookup_id: number; datasource_id: number }
      >({
        query: (args) => {
          const { lookup_id, datasource_id } = args;

          return {url : `api/lookup/item/get/${lookup_id}/${datasource_id}`};
        },
      }),
      fetchLookupItemsMutation: build.mutation<
        Lookup_Item[],
        { lookup_id: number; datasource_id: number }
      >({
        query: (args) => {
          const { lookup_id, datasource_id } = args;
          return {url :`api/lookup/item/get/${lookup_id}/${datasource_id}`};
        },
      }),
    };
  },
});

export const { useFetchLookupItemsQuery, useFetchLookupItemsMutationMutation } =
  apiSlice_Lookup;
