import { ILookupData } from "../Popup/NewModal";
import axiosInstance from "./axiosInstance";

async function fetchRecord(lookup_id: number, datasource_id: any) {

  return await axiosInstance.get(`/api/lookup/item/get/${lookup_id}/${datasource_id}`).then((response) => {
    let key = lookup_id > 192 && lookup_id < 199 ? "id" : "ID";
    let lookup_item: ILookupData[] = response.data.map((row: any) => ({
      ID: row[key],
      Lookup_Item_Parent_ID: row["Lookup_Item_Parent_ID"],
      Parent_ID: row["Parent_ID"],
      SortOrder: row["SortOrder"],
      Value: row["Value"].trim(),
      isChecked: false,
    }));

    return lookup_item;
  });
}

export default fetchRecord;
