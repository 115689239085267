import React from 'react';

const UnauthorizedPage = () => {
return (
    <>
        <div className="error-container">
            <div className='well'>
                <h1 style={{ fontSize: "31px", color: "#777777", fontWeight: "lighter" }}>
                    Unauthorized to access the page</h1>
            </div>
        </div>
    </>
    
);
}

export default UnauthorizedPage;