import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { emptySplitApi } from "./emptyApiSlice";
import { _token } from "../components/service/apiCall";

export const InternalUserApiSlice = emptySplitApi.injectEndpoints({
  endpoints(build) {
    return {
      internalUserTracker: build.mutation({
        query: (body) => {
          console.log("body : ", body);
          return {
            url: `api/user/InternalUsegTracker`,
            method: "POST",
            data : body,
          };
        },
      }),
    };
  },
});
export const { useInternalUserTrackerMutation } = InternalUserApiSlice;
