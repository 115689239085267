import React, { useEffect, useState, useCallback } from "react";
import { useLocation, useParams } from "react-router-dom";
import { useFetchAllClientNewsMutation } from "../../store/ClientNewsApiSlice";
import Spinner from "react-bootstrap/Spinner";
import { format } from "date-fns";
import Filter from "../Dashboard/filter";
import TablePagination from "@mui/material/TablePagination";
import Pagination from "@mui/material/Pagination";
import "./clientNewsDetails.css";
import NewModal from "../Popup/NewModal";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { saveNewsData } from "../../store/ClientNewsApiSlice";
import "../Dashboard/InternalUserTracker.css";
import { ConditionPosition } from "ag-grid-community/dist/lib/filter/provided/simpleFilter";
import Login from "../Logout/Login";
// @ts-ignore
import { convert } from "html-to-text";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import DraggablePopover from "../Popups/Popup_MaterialUI";
import deleteCall from "../service/deleteCall";

const MoreClientNews = () => {
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(40);
  const [currentRecords, setCurrentRecords] = useState<any[]>([]);
  const [filteredRecords, setFilteredRecords] = useState("");
  const [activeArticle,setActiveArticle] = useState<boolean>(true);
  const [isAdminPage, setIsAdminPage] = useState<boolean>(false);
  const [newsArticleDel, setNewsArticleDel] = useState<HTMLButtonElement | null>(null);
  const [currId, setCurrId] = useState<number>(0);
  const location : any = useLocation();
  const dispatch = useAppDispatch();
  const accountType = useAppSelector((state) => state.userProfile.accountType);
  const [fetchAllClientNews, { data: newsData, isLoading, isSuccess, error }] =
    useFetchAllClientNewsMutation();

  const GetMoreClientNewsData = async () => {
    if (location.pathname.includes("news/list") && accountType && accountType === "Admin") {
      setIsAdminPage(true);
      const moreNews : any = await fetchAllClientNews({activeArticle : activeArticle, isAdmin : true});
      dispatch(saveNewsData(moreNews.data));
    } else if (location.pathname.includes("ClientNewsList")) {
      const moreNews : any = await fetchAllClientNews({activeArticle : activeArticle, isAdmin : false});
      dispatch(saveNewsData(moreNews.data));
    }
  };

  useEffect(() => {
    GetMoreClientNewsData();
  }, [activeArticle]);
  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
  };
  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const savedClientNews = useAppSelector(
    (state) => state.clientNews.searchNewsData
  );
  useEffect(() => {
    if (savedClientNews && savedClientNews.length > 0) {
      const indexOfLastRecord = page * rowsPerPage; // 1*20=20
      const indexOfFirstRecord = indexOfLastRecord - rowsPerPage + 1; //20-20+1 =1
      setCurrentRecords(savedClientNews);
      setCurrentRecords(
        savedClientNews.slice(indexOfFirstRecord - 1, indexOfLastRecord)
      );
    }
  }, [savedClientNews, page]);

  const handleSearchChange = (e) => {
    setFilteredRecords(e.target.value.toLowerCase());
  };
  const ActionComponentDisabled = () => <span />;

  const handleOpen = (event: React.MouseEvent<HTMLButtonElement>,ID:number) => {
    setCurrId(ID);
    setNewsArticleDel(event.currentTarget);
  }

  const handleClose = (event: React.MouseEvent<HTMLButtonElement>) => {
    setNewsArticleDel(null);
  }

  const deleteNewsArticle = () => {
    deleteCall(`/api/clientnews/delete/${currId}`);
    window.location.reload(); 
    //  setNewsArticleDel(null);

  }

  return (
    <div>
      {/* <NewModal /> */}
      <h2 style={{ margin: "20px 0px 25px 3rem" }}>News articles</h2>
      {accountType && accountType === "Admin" ? (
        <>
        <p className="internal-p" style={{ marginLeft: "3rem",display:"inline" }}>
          <a href="/admin/form/276" className="internal-anchor-link">
            Create news article 
          </a>
        </p>
        
        </>
      ) : (
        ""
      )}
      {isAdminPage &&
      <>
        <span> | </span>
        <button className="internal-anchor-link" 
          style={{border:"none", cursor:"pointer", paddingLeft:"0"}} 
          onClick={() => setActiveArticle(!activeArticle)}>
            {
          activeArticle ? "Expired news article" : "View active news articles"
          }
        </button>
      </>
      }
      

      <div
        style={{
          display: "flex",
          border: "5px solid rgb(237, 237, 237)",
          margin: "0 48px 0 41px",
          width:`${!activeArticle ? "80%" : ""}`
        }}

      >
       {activeArticle && <div
          className="col-md-2"
          style={{ paddingTop: "10px", paddingLeft: "0" }}
        >
          <Filter
            currentDataSource={276}
            currentSearchDataSource={276}
            currentDataSourceName={"news articles"}
          ></Filter>
        </div>}
        <div className={activeArticle ? "col-md-10" : "col-md-12"}>
          <div
            className="col-md-12"
            style={{
              borderLeft: `${activeArticle ? "5px solid rgb(237, 237, 237)" : "" }`,
              margin: " 0 0 2rem -30px",
            }}
          >
            {isLoading && (
              <div style={{ textAlign: "center", paddingTop: "200px", minHeight: "500px" }}>
                <Spinner animation="border" variant="primary" />
                <h6>Loading news data...</h6>
              </div>
            )}
            {error && <h6>Session expired...</h6>}
            {isSuccess && newsData && (
              <>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <div style={{ margin: "1rem" }}>
                    <label id="filter-label">Filter search results : </label>
                    <input
                      type="text"
                      className="news-input"
                      placeholder="Enter Search Terms"
                      onChange={handleSearchChange}
                    />
                  </div>
                  <div style={{ marginLeft: "6rem" }}>
                    <TablePagination
                      component="div"
                      count={savedClientNews && savedClientNews.length}
                      page={page - 1}
                      labelRowsPerPage=""
                      rowsPerPageOptions={[]}
                      onPageChange={handleChangePage}
                      rowsPerPage={rowsPerPage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                      ActionsComponent={ActionComponentDisabled}
                      style={{ marginTop: "1rem", marginRight: "24rem" }}
                    />
                    <Pagination
                      count={Math.ceil(savedClientNews.length / 40)}
                      color="primary"
                      page={page}
                      onChange={handleChange}
                      shape="rounded"
                      style={{ marginTop: "-3.5rem", marginLeft: "10rem" }}
                    />
                  </div>
                </div>
                <div>
                  {currentRecords && currentRecords.length == 0 ? (
                    <div>
                      <h5>No records found</h5>
                    </div>
                  ) : (
                    currentRecords
                      .filter(
                        (res) =>
                          (res.Name &&
                            res.Name.toLowerCase().includes(filteredRecords)) ||
                          (res.Value &&
                            res.Value.toLowerCase().includes(
                              filteredRecords
                            )) ||
                          (res.Body &&
                            res.Body.toLowerCase().includes(filteredRecords)) ||
                          format(new Date(res.Date), "dd/MM/yyyy")
                            .toString()
                            .includes(filteredRecords)
                      )
                      .map((item) => {
                        return (
                          <div key={item.ID}>
                            <ul className="list-group">
                              <li className="list-group-item client-news-body">
                                <h5>
                                  <a
                                    className="newsTitle"
                                    href={`/ClientNewsArticle/${item.ID}`}
                                    target="_blank"
                                  >
                                    <strong>{item.Name}</strong>
                                  </a>
                                  {activeArticle && isAdminPage && <FontAwesomeIcon
                                      style={{ padding: "6px", fontSize: "22px", cursor: "pointer" ,float:"right"}}
                                      onClick={(e) => handleOpen(e,item.ID)}
                                      icon={faXmark}
                                      size="xl"
                                    />}
                                </h5>
                                
                                <div>
                                  {format(new Date(item.Date), "dd/MM/yyyy")}
                                </div>
                                <div style={{ display: "flex" }}>
                                  <strong>Summary: </strong>
                                  <p
                                    style={{
                                      whiteSpace: "nowrap",
                                      overflow: "hidden",
                                      textOverflow: "ellipsis",
                                    }}
                                  >
                                    {item.Body !== null && (
                                      <span style={{ fontSize: "11px" }}>
                                        {convert(item.Body)}
                                      </span>
                                    )}
                                  </p>
                                  {item.Body == null && <span> ...</span>}
                                </div>
                                <div>
                                  <strong>Categories: </strong>
                                  {item.Value}
                                </div>
                                {isAdminPage && 
                                <>
                                  <div>
                                    <strong>Active: </strong>
                                    {item.IsActive === true ? "Yes" : "No"}
                                  </div>
                                </>
                                }
                              </li>
                            </ul>
                          </div>
                        );
                      })
                  )}
                  <div style={{ margin: " 3rem 2px 2.5px 0" }}>
                    <TablePagination
                      component="div"
                      count={savedClientNews && savedClientNews.length}
                      page={page - 1}
                      labelRowsPerPage=""
                      rowsPerPageOptions={[]}
                      onPageChange={handleChangePage}
                      rowsPerPage={rowsPerPage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                      ActionsComponent={ActionComponentDisabled}
                      style={{ marginRight: "69rem" }}
                    />
                    <Pagination
                      count={Math.ceil(savedClientNews.length / 40)}
                      color="primary"
                      page={page}
                      onChange={handleChange}
                      shape="rounded"
                      style={{ marginLeft: "-1rem", marginTop: "-3.5rem" }}
                    />
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
      {newsArticleDel && (
        <DraggablePopover
          title="Delete confirmation"
          anchorEl={newsArticleDel}
          popupBody={<p>Are you sure you want to delete this news article?</p>}
          buttonsArray={["Yes"]}
          // btnCallbacks={[datasourceRecordDelete]}
          btnCallbacks={[deleteNewsArticle]}
          onPopupClose={handleClose}
          keyName = {"NewArticle"}
        ></DraggablePopover>
      )}
    </div>
  );
};

export default MoreClientNews;
