import { ICellRendererParams } from 'ag-grid-community';
import React from 'react';

interface ISplitComponent extends ICellRendererParams {
      name: string;
      datasourcId: string;
}


export const SplitComponent = (props: ISplitComponent) => {
      const name = props.data[props.name];
      return (
            <>
                  {
                        name?.split(",").map((el: string) => (
                              <span style={{ display: "block" }}>{el}</span>
                        ))
                  }
            </>
      )
}

export const SplitWithTilde = (props: ISplitComponent) => {
      const name = props.data[props.name];
      return (
            <>
                  {
                        name?.split("~").map((el: string) => (
                              <span style={{ display: "block" }}>{el}</span>
                        ))
                  }
            </>
      )
}