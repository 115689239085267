import React from "react";

interface PPCardsProps {
  cardData: any;
}

const PPCards = (props: PPCardsProps) => {
  const { cardData } = props;
  // Methods :
  const cardBorderColor = (value: string) => {
    return { border: `1px solid ${value}`, borderBottomWidth: "15px" };
  };
  return (
    <div className="cardsContainer">
      <div className="row">
        <div className="card" style={cardBorderColor("green")}>
          <span> Positive recommendations </span>
          <span className="cardValue">{cardData.positiveRecommendations}</span>
        </div>
        <div className="card" style={cardBorderColor("orange")}>
          <span> Positive with restriction </span>
          <span className="cardValue">{cardData.positiveWithRestriction}</span>
        </div>
        <div className="card" style={cardBorderColor("red")}>
          <span> Negative recommendations </span>
          <span className="cardValue">{cardData.negativeRecommendations}</span>
        </div>
        <div className="card" style={cardBorderColor("deepskyblue")}>
          <span> Multiple recommendations </span>
          <span className="cardValue">{cardData.multipleRecommendations}</span>
        </div>
        <div className="card" style={cardBorderColor("darkgray")}>
          <span> No recommendations </span>
          <span className="cardValue">{cardData.noRecommendations}</span>
        </div>
        <div className="card" style={cardBorderColor("rgb(6, 82, 128)")}>
          <span> Ongoing / Planned </span>
          <span className="cardValue">{cardData.ongoingPlanned}</span>
        </div>
        <div className="card" style={cardBorderColor("black")}>
          <span> Suspended / Cancelled </span>
          <span className="cardValue">{cardData.suspendedCanceled}</span>
        </div>
      </div>
    </div>
  );
};

export default PPCards;
