// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.topBorder {
  border-top: 1px solid black;
}

.commentsContainer {
  padding-top: 5px;
  margin-bottom: 150px;
}

.commentsContainer .title {
  font-weight: bold;
  margin-bottom: 10px;
}

.topBorder .commentsBox {
  padding-left: 50px;
}

.commentsContainer .commentsBox {
  padding: 14px;
  font-size: 14px;
}

.commentsContainer .commentsBox .comment {
  padding: 14px !important;
  display: flex;
  justify-content: space-between;
}

.commentsContainer .commentsBox .comment .trash {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.commentsContainer .commentsBox .comment .icons {
  display: flex;
  justify-content: space-around;
  font-size: 18px !important;
}
`, "",{"version":3,"sources":["webpack://./src/components/Forms/Comments.css"],"names":[],"mappings":"AAAA;EACE,2BAA2B;AAC7B;;AAEA;EACE,gBAAgB;EAChB,oBAAoB;AACtB;;AAEA;EACE,iBAAiB;EACjB,mBAAmB;AACrB;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,aAAa;EACb,eAAe;AACjB;;AAEA;EACE,wBAAwB;EACxB,aAAa;EACb,8BAA8B;AAChC;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,yBAAyB;AAC3B;;AAEA;EACE,aAAa;EACb,6BAA6B;EAC7B,0BAA0B;AAC5B","sourcesContent":[".topBorder {\n  border-top: 1px solid black;\n}\n\n.commentsContainer {\n  padding-top: 5px;\n  margin-bottom: 150px;\n}\n\n.commentsContainer .title {\n  font-weight: bold;\n  margin-bottom: 10px;\n}\n\n.topBorder .commentsBox {\n  padding-left: 50px;\n}\n\n.commentsContainer .commentsBox {\n  padding: 14px;\n  font-size: 14px;\n}\n\n.commentsContainer .commentsBox .comment {\n  padding: 14px !important;\n  display: flex;\n  justify-content: space-between;\n}\n\n.commentsContainer .commentsBox .comment .trash {\n  display: flex;\n  flex-direction: column;\n  justify-content: flex-end;\n}\n\n.commentsContainer .commentsBox .comment .icons {\n  display: flex;\n  justify-content: space-around;\n  font-size: 18px !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
