import React, { useEffect, useState } from "react";
import { useAppSelector } from "../../../store/hooks";
import { formatDate } from "../../../utils/index";

const News = () => {
  const [expandedItems, setExpandedItems] = useState<any[]>([]);
  const state = useAppSelector((state: any) => state);
  const [newsData] = useState<any[]>(state.productProfile.allNewsData);
  const [showNews, setShowNews] = useState<boolean>(false);
  // console.log(newsData);
  const currRoleId = localStorage.getItem("CurrRoleId");
  const clientId = localStorage.getItem("ClienId");

  // Methods
  const toggleExpanded = (index: number) => {
    let tmp = [...expandedItems];
    if (tmp.includes(index)) {
      tmp.splice(tmp.indexOf(index), 1);
    } else {
      tmp.push(index);
    }
    setExpandedItems(tmp);
  };

  const getTextToShow = (text: string, index: number) => {
    if (text !== null && text !== "" && text !== undefined) {
      if (expandedItems.includes(index)) {
        return (
          <span
            dangerouslySetInnerHTML={{
              __html: text,
            }}
          ></span>
        );
      } else {
        const maxLength = 250;
        const truncatedText = text.slice(0, maxLength) + "...";
        return (
          <span
            dangerouslySetInnerHTML={{
              __html: truncatedText,
            }}
          ></span>
        );
      }
    } else {
      return <span>-</span>;
    }
  };

  const splitCategory = (category: string) => {
    if (category !== null && category !== "" && category !== undefined) {
      const categoryArray = category.split(",");
      return categoryArray;
    }
  };

  const checkSubscription = () => {
    let blockedRoleIds = [6, 7];
    let acceptedClientIds = [14, 15, 1];
    if (
      !blockedRoleIds.includes(Number(currRoleId)) &&
      acceptedClientIds.includes(Number(clientId))
    ) {
      setShowNews(true);
    } else setShowNews(false);
  };

  useEffect(() => {
    checkSubscription();
  }, [currRoleId, clientId]);

  return (
    <div className="newsContainer">
      {showNews ? (
        <ul className="list-group">
          {newsData.length > 0 ? (
            newsData.map((value: any, index: number) => (
              <li className="newSection" key={index}>
                <h4 className="newTitle">
                  <a
                    className="newTitleText"
                    href={`/ClientNewsArticle/${value.ID}?`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {value.Name}
                  </a>
                </h4>
                <div className="newDate">
                  {value.Dte && formatDate(value.Dte)}
                </div>

                <div className="newContent">
                  <div className="summary">
                    <b> Summary: </b>
                  </div>
                  <div className="newContentText">
                    {getTextToShow(value.Body, index)}
                  </div>
                  <div
                    onClick={() => toggleExpanded(index)}
                    className="expandBtn"
                  >
                    {expandedItems.includes(index)
                      ? "- Read Less"
                      : "+ Read More"}
                  </div>
                </div>

                {!value.Category.includes("PInsight") && (
                  <div className="newCategory">
                    <div className="category">
                      <b> Category: </b>
                      {splitCategory(value.Category) !== undefined &&
                        splitCategory(value.Category)[0]}
                    </div>
                  </div>
                )}
              </li>
            ))
          ) : (
            <div>
              <h4>No news found</h4>
            </div>
          )}
        </ul>
      ) : (
        <div>
          <div
            style={{
              backgroundColor: "#FF8F5E",
              color: "black",
              borderRadius: "4px",
              padding: '10px 15px',
              fontSize: '12px'
            }}
          >
            Your organization's subscription currently does not include
            product-related news.
          </div>
        </div>
      )}
    </div>
  );
};

export default News;
