import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const Container = styled.div`
  border-bottom: 1px solid #ccc;
  background-color: white;
  margin-left: -22px;
  margin-right: -25px;
`;

export const NavbarImage = styled.img`
  width: 18em;
  padding-bottom: 20px;
`;

export const FormWrapper = styled.div`
  padding: 35px;
`;

export const FormTitle = styled.h4`
  font-size: 18px;
  font-weight: bold;
`;

export const FormSection = styled.div`
  & table, th, td {
    border: none;
    padding: 5px;
  }

  & table tr:not(:last-child) td:first-child {
    width: 20%;
  }

  .required::before {
    content: " *";
    color: red;
  }

  .nonValid {
    color: red;
  }

  .helpButtonpage {
    border: none;
  }

  .helpPage:focus {
    outline: none !important;
    border: 1px solid rgb(0, 170, 231) !important;
  }
`;

export const Input = styled.input`
  width: 100%;
  line-height: 1.5rem;
  border: 1px solid #d5d5d5;
  color: #939192;
  outline: none;

  &[readonly] {
    background-color: #f5f5f5;
  }
`;

export const CheckboxInput = styled.input`
  line-height: 1.5rem;
`;

export const TextArea = styled.textarea`
  display: none;
`;

export const Button = styled.input`
  margin-right: -10px;
  width: 62px;
  height: 40px;
  background-color: rgb(0, 170, 231) !important;
  border: 1px solid rgb(0, 170, 231) !important;
  color: white !important;
  line-height: 24px;
  font-size: 12px;
  margin-left: 1.6em !important;
`;

export const CancelButton = styled.button`
  background-color: #00aae7;
  border: 1px solid rgb(0, 170, 231);
  padding-left: 6px;
  margin-left: 13px;
  width: 62px;
  height: 40px;
`;

export const CancelLink = styled(Link)`
  text-decoration: none;
  color: #ffffff;
`;
