import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import CloseIcon from "@mui/icons-material/Close";
import "./CommentModal.css";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import PostCall from "../../components/service/postCall";
import { getIdFieldName } from "../../utils";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert, { AlertProps } from "@mui/material/Alert";
import { useAppSelector } from "../../store/hooks";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
// import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { DatePicker, DatePickerProps } from "antd";
import Comments from "../Forms/Comments";
import dayjs from "dayjs";
import UpdateCall from "../service/updateCall";

interface CommentModallProp {
  open: boolean;
  handleClose: Function;
  clientList: [{ label: string; ID: number }];
  record_ID: number;
  commentTableName: string;
  dataSourceID: number;
  findComments: Function;
  commentModalView: string;
  commentList: any[];
}

const CommentModal = (props: CommentModallProp) => {
  const {
    open,
    handleClose,
    clientList,
    record_ID,
    commentTableName,
    dataSourceID,
    findComments,
    commentModalView,
    commentList,
  } = props;
  const state = useAppSelector((state) => state.userProfile);
  const [value, setValue] = useState<string>("all");
  const [comment, setComment] = useState<string>("");
  const [date, setDate] = useState<any>(null);
  const [dateDB, setDateDB] = useState<any>(null);
  const [clientName, setClientName] = useState<any>("IQVIA");
  const [clientId, setClientId] = useState<any>(1);
  const [updateComment, setUpdateComment] = useState<boolean>(false);
  const [updateRecordID, setUpdateRecordID] = useState<number>(1);
  const currRole = localStorage.getItem("CurrRoleId");
  const [currentModalView, setCurrentModalView] =
    useState<string>(commentModalView);
  const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
    props,
    ref
  ) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  const [openCommentSuccess, setOpenCommentSuccess] = React.useState(false);
  const handleCloseComment = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    setOpenCommentSuccess(false);
  };

  // Methods :
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue((event.target as HTMLInputElement).value);
    if (event.target.value !== "specific") {
      setClientId(null);
      setClientName("");
    }
  };

  const creationDate = (value: any) => {
    setDate(value);
    let year = value.$y;
    let month = value.$M + 1;
    let day = value.$D;
    let date = `${year}-${month}-${day} 23:00:00`;
    setDateDB(date);
  };

  const onInputSelectionChange = (event: any, newInputValue: any) => {
    setClientId(
      newInputValue !== null && newInputValue.ID ? newInputValue.ID : null
    );
    setClientName(
      newInputValue !== null && newInputValue.label ? newInputValue.label : ""
    );
  };

  const submitComment = async () => {
    if (comment === "" || (value === "specific" && clientId === null)) return;
    let audience_Type;
    if (value === "all") audience_Type = 1;
    else if (value === "internal") audience_Type = 2;
    else if (value === "specific") audience_Type = 0;

    if (currRole > 4 && currRole < 8) audience_Type = 0;

    let checkClientID;
    if (audience_Type === 0) checkClientID = clientId;
    else checkClientID = null;

    if (currRole > 4 && currRole < 8) checkClientID = clientId;

    console.log(
      audience_Type,
      Number(localStorage.getItem("CurrUserId")),
      record_ID,
      "clientid",
      checkClientID,
      comment
    );
    let idColumnName = getIdFieldName(dataSourceID);
    let createdBy = Number(localStorage.getItem("CurrUserId"));
    let response = await PostCall(
      `/api/createComment/${commentTableName}/${record_ID}/${createdBy}`,
      { audience_Type, checkClientID, comment, idColumnName, dateDB }
    );
    if (!!response) {
      setValue("all");
      setComment("");
      setClientName("IQVIA");
      setClientId(1);
      handleClose();
      setCurrentModalView(commentModalView);
      findComments(commentTableName);
    }
    if (!!response) {
      setOpenCommentSuccess(true);
    }
  };

  useEffect(() => {
    setCurrentModalView(commentModalView);
  }, [commentModalView]);

  const retrieveDataToUpdate = (item: any) => {
    // console.log(item, "retrieveDataToUpdate");
    if (!!item) {
      setComment(item.Comment);
      const dateObject = new Date(item.CreatedDate);
      // setDate(item.CreatedDate);
      let radioBtn = "";
      if (item.Audience_Type === 1) radioBtn = "all";
      else if (item.Audience_Type === 2) radioBtn = "internal";
      else if (item.Audience_Type === 0) radioBtn = "specific";
      setValue(radioBtn);
      setDateDB(item.CreatedDate);
      setUpdateRecordID(item.ID);
      if (item.Client_ID === null) {
        setClientName("IQVIA");
        setClientId(1);
      } else {
        setClientName(findClient(item.Client_ID));
        setClientId(item.Client_ID);
      }
      formatDate(item.CreatedDate);
      setUpdateComment(true);
      setCurrentModalView("add");
    }
  };

  const findClient = (id: number) => {
    for (let i = 0; i < clientList.length; i++) {
      if (clientList[i].ID === id) return clientList[i].label;
    }
  };

  const renderOptions = (props: React.HTMLAttributes<HTMLElement>,option: Partial<any>) => {
    return <li {...props} key = {option.ID}>{option.label}</li>
  }

  const formatDate = (date: string) => {
    const split = date.split("T");
    setDate(dayjs(split[0]));
  };

  const handleCloseCommentModal = () => {
    setCurrentModalView(commentModalView);
    setComment("");
    setDate(null);
    setClientName("IQVIA");
    setValue("all");
    setClientId(1);
    handleClose();
    setUpdateComment(false);
  };

  const updateCurrentComment = async () => {
    if (comment === "" || (value === "specific" && clientId === null)) return;
    let audience_Type;
    if (value === "all") audience_Type = 1;
    else if (value === "internal") audience_Type = 2;
    else if (value === "specific") audience_Type = 0;

    if (currRole > 4 && currRole < 8) audience_Type = 0;

    let checkClientID;
    if (audience_Type === 0) checkClientID = clientId;
    else checkClientID = null;

    if (currRole > 4 && currRole < 8) checkClientID = clientId;

    let idColumnName = getIdFieldName(dataSourceID);
    let createdBy = Number(localStorage.getItem("CurrUserId"));
    console.log(
      audience_Type,
      Number(localStorage.getItem("CurrUserId")),
      record_ID,
      "clientid",
      checkClientID,
      comment,
      idColumnName,
      createdBy,
      updateRecordID
    );

    let response = await UpdateCall(
      `/api/updateComment/${commentTableName}`,
      record_ID,
      {
        comment,
        createdBy,
        audience_Type,
        idColumnName,
        dateDB,
        checkClientID,
        updateRecordID,
      }
    );
    if (!!response) {
      handleCloseCommentModal();
      findComments(commentTableName);
    }
  };

  return (
    <>
      <Modal
        className="commentModal"
        show={open}
        onHide={() => handleCloseCommentModal()}
        
      >
        <Modal.Header>
          <Modal.Title>
            <span style={{ fontWeight: "bold", marginLeft:"24px", fontSize:"19px",  lineHeight: "1.5", marginBottom: "8px"}}>
              {currentModalView === "add"
                ? "Submit comment"
                : "Pricing comment"}
            </span>
          </Modal.Title>
          <CloseIcon
            sx={{ opacity: "0.5","&:hover":{opacity:"1.5",cursor: "pointer"},stroke:"black",strokeWidth:"2px",fontSize:"23px"}}
            onClick={() => handleCloseCommentModal()}
          />
        </Modal.Header>
        <Modal.Body style={{maxHeight:"250px",overflowX:"hidden",overflowY:"auto"}}>
          {currentModalView === "add" && (
            <div className="bodyContainer">
              {dataSourceID === 4 && (
                <div style={{ marginBottom: 15 }}>
                  <div>
                    <label style={{ fontWeight: 700, marginRight: 20 }}>
                      User
                    </label>
                    <label>{state.userProfileData.Email}</label>
                  </div>
                  <div className="dateContainer">
                    <label
                      style={{
                        fontWeight: 700,
                        marginRight: 20,
                        paddingTop: 15,
                      }}
                    >
                      Date
                    </label>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        placeholder={date === null ? "Date" : ""}
                        format="DD-MM-YYYY"
                        value={date}
                        onChange={creationDate}
                        className="datePickerComment"
                        slotProps={{
                          actionBar: {
                            actions: ["clear"],
                          },
                        }}
                        sx={{ borderRadius: 0 }}
                      />
                    </LocalizationProvider>
                  </div>
                </div>
              )}
              <FormControl>
                <FormLabel
                  className="title"
                  id="demo-row-radio-buttons-group-label"
                >
                  Comment visibility:
                </FormLabel>
                {Number(currRole) > 4 && Number(currRole) < 8 && (
                  <>
                    <label style={{ marginTop: "-25px", marginLeft: "135px" }}>
                      Only my colleagues
                    </label>
                  </>
                )}
                {!(Number(currRole) > 4 && Number(currRole) < 8) && (
                  <>
                    <RadioGroup
                      row
                      aria-labelledby="demo-row-radio-buttons-group-label"
                      name="row-radio-buttons-group"
                      value={value}
                      onChange={handleChange}
                    >
                      <FormControlLabel
                        value="all"
                        control={<Radio />}
                        label="All users"
                      />
                      <FormControlLabel
                        value="internal"
                        control={<Radio />}
                        label="IQVIA internal"
                      />
                      <FormControlLabel
                        value="specific"
                        control={<Radio />}
                        label="Specific client"
                      />
                    </RadioGroup>
                  </>
                )}
              </FormControl>
              {value === "specific" && (
                <div className="autocomplete">
                  <Autocomplete
                    className="autocomplete"
                    disablePortal
                    id="combo-box-demo"
                    options={clientList}
                    sx={{ width: 300 }}
                    renderInput={(params) => <TextField {...params} />}
                    value={clientName}
                    onChange={(e: any, newValue: string | null) =>
                      onInputSelectionChange(e, newValue)
                    }
                    renderOption={renderOptions}
                  />
                </div>
              )}
              <div className="comment mt-3">
                <textarea
                  id="outlined-multiline-static"
                  // multiline
                  // fullWidth
                  rows={4}
                  value={comment}
                  onChange={(e) => setComment(e.target.value)}
                  style={{width:"100%"}}
      
                />
              </div>
            </div>
          )}
          {currentModalView === "view" && (
            <div className="bodyContainer">
              {commentList.length > 0 ? (
                <>
                  <Comments
                    commentList={commentList}
                    commentTableName={commentTableName}
                    findComments={findComments}
                    view="pricingView"
                    clientList={clientList}
                    retrieveDataToUpdate={retrieveDataToUpdate}
                  />
                </>
              ) : (
                <div className="noComment">No comment available.</div>
              )}
            </div>
          )}
        </Modal.Body>
        {currentModalView === "add" && (
          <Modal.Footer>
            <span className="spanBtn" onClick={handleCloseCommentModal}>
              Cancel
            </span>
            |
            {updateComment ? (
              <span className="spanBtn" onClick={updateCurrentComment}>
                Save Comment
              </span>
            ) : (
              <span className="spanBtn" onClick={submitComment}>
                Add comment
              </span>
            )}
          </Modal.Footer>
        )}
      </Modal>
      <Snackbar
        open={openCommentSuccess}
        autoHideDuration={6000}
        onClose={handleCloseComment}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          onClose={handleCloseComment}
          severity="success"
          sx={{ width: "100%" }}
        >
          Comment added successfully
        </Alert>
      </Snackbar>
    </>
  );
};

export default CommentModal;
