import React, { useContext, useEffect, useState } from "react";
import DoubleArrowIcon from "@mui/icons-material/DoubleArrow";
//import InfoIcon from "@mui/icons-material/Info";
import { FaInfoCircle } from "react-icons/fa";
import { UserContext } from "../../Editor";
import { useAppSelector } from "../../../../store/hooks";
import Element, { EndPointElement } from "./Utility";

interface IClinicalDataEndpointProp {
  fieldInfo: any[];
  datasourceName: string;
  section: string;
}


const EndpointClinicalData = ({
    fieldInfo,
    datasourceName,
    section,
  }: IClinicalDataEndpointProp) => {

    const [field, setField] = useState<any[]>([]);
    const [endpoint, setEndpoint] = useState<any>([]);
    const [readLess, setReadLess] = useState<boolean>(true);
    const subPageDate = useAppSelector((state) => state.multiselect.subpageData);

    useEffect(() => {
        if (field.length == 0 && fieldInfo.length > 0) {
          setField(fieldInfo);
        }
      }, [fieldInfo]);
    
    useEffect(() => {
        setEndpoint(subPageDate["endpoint_clinical"]);
      }, [subPageDate]);

    return (
      <>
        {!!endpoint && endpoint.length > 0 && endpoint.filter((el:any,index:number) => index < 5 || !readLess).map((el: any, index: number) => (
          <div className="col-sm-12" style={{marginBottom:"50px"}}>
            <hr style={{color:"rgb(204, 204, 204)"}} />
            <b>
              {index + 1}. {el["Endpoint_ID"]}{el["AnalysisStage_ID"] != null ? ' - ' + el["AnalysisStage_ID"] : ''}
            </b>
            <>
              {!!field && field.length > 0 && field.map((val) => (
                <>
                  <Element
                    label={val.label}
                    fieldType={val.fieldTypeId}
                    value={el[val.name.split(".")[1]]}
                    datasourceName={datasourceName}
                    section={section}
                    description={val.descriptionClient}
                  />
                  {val.name.split(".")[1] == "AnalysisStage_Date" &&
                    !!el[el["ID"]] && el[el["ID"]].length > 0 && el[el["ID"]].map((x: any) => (
                      <>
                        {x[1] != '' && 
                          <EndPointElement label={x[0]} value={x[1]} />
                        }
                      </>
                    ))}
                </>
              ))}
            </>
          </div>
        ))}
        {
          endpoint?.length >= 5 &&
            <button type="button" 
                    style={{ 
                      border: "none", 
                      background: "none", 
                      color: "rgb(0, 170, 231)", 
                      cursor: "pointer",
                      float: "right",
                      marginTop : "10px"
                    }} 
                    onClick={() => setReadLess(!readLess)}
            >
                      {readLess ? "Show More..." : "Show Less..."}
            </button>
          }
      </>
    );
  };
  export default EndpointClinicalData;