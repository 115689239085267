// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.example-wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
}

#myGrid {
  flex: 1 1;
  width: 100%;
}

.ag-cell .ag-cell-wrapper .ag-cell-value {
  line-height: 2 !important;
}

.ag-header-row .ag-header-cell-sorted-asc,
.ag-header-row .ag-header-cell-sorted-desc,
.ag-header-row .ag-header-cell[aria-sort="ascending"],
.ag-header-row .ag-header-cell[aria-sort="descending"] {
  color: rgb(0, 170, 231) !important;
}
.ag-header-row .ag-header-cell .ag-sort-indicator-container {
  color: black !important;
}

.ag-theme-alpine {
  /* bright green, 10% opacity */
  --ag-selected-row-background-color: white !important;
}

.ag-root-wrapper {
  border: none !important;
}

.ag-paging-row-summary-panel {
  color: rgb(0, 170, 231) !important;
}

.ag-root-wrapper .ag-paging-panel {
  border-top: 2px solid black !important;
}

.ag-sort-indicator-icon .ag-sort-order {
  display: none;
}`, "",{"version":3,"sources":["webpack://./src/components/Table/styles.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,YAAY;AACd;;AAEA;EACE,SAAa;EACb,WAAW;AACb;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;;;;EAIE,kCAAkC;AACpC;AACA;EACE,uBAAuB;AACzB;;AAEA;EACE,8BAA8B;EAC9B,oDAAoD;AACtD;;AAEA;EACE,uBAAuB;AACzB;;AAEA;EACE,kCAAkC;AACpC;;AAEA;EACE,sCAAsC;AACxC;;AAEA;EACE,aAAa;AACf","sourcesContent":[".example-wrapper {\n  display: flex;\n  flex-direction: column;\n  height: 100%;\n}\n\n#myGrid {\n  flex: 1 1 0px;\n  width: 100%;\n}\n\n.ag-cell .ag-cell-wrapper .ag-cell-value {\n  line-height: 2 !important;\n}\n\n.ag-header-row .ag-header-cell-sorted-asc,\n.ag-header-row .ag-header-cell-sorted-desc,\n.ag-header-row .ag-header-cell[aria-sort=\"ascending\"],\n.ag-header-row .ag-header-cell[aria-sort=\"descending\"] {\n  color: rgb(0, 170, 231) !important;\n}\n.ag-header-row .ag-header-cell .ag-sort-indicator-container {\n  color: black !important;\n}\n\n.ag-theme-alpine {\n  /* bright green, 10% opacity */\n  --ag-selected-row-background-color: white !important;\n}\n\n.ag-root-wrapper {\n  border: none !important;\n}\n\n.ag-paging-row-summary-panel {\n  color: rgb(0, 170, 231) !important;\n}\n\n.ag-root-wrapper .ag-paging-panel {\n  border-top: 2px solid black !important;\n}\n\n.ag-sort-indicator-icon .ag-sort-order {\n  display: none;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
