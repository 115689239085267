import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface IUniversalState {
      redirectToAuth : boolean
}

const initialState : IUniversalState = {
      redirectToAuth : false
}

export const UniversalValueSlice = createSlice({
      name : "universalValue",
      initialState,
      reducers : {
            setRedirectToAuth : (state, action : PayloadAction<boolean>) => {
                  state.redirectToAuth = action.payload;
            }
      }
});

export const {setRedirectToAuth} = UniversalValueSlice.actions

export default UniversalValueSlice.reducer