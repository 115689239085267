import React, { ChangeEvent, useEffect, useState } from 'react';
import apiCall from '../../service/apiCall';

interface IProp {
      fieldDataSourceId: number;
      roleId: number;
      fieldSectionId: number;
      setFieldSectionId: (id: number) => void
}

interface Isection {
      "id": number;
      "name": string;
      "sortOrder": number;
      "isRemoved": boolean;
      "dateModified": Date;
      "collapseInViewMode": boolean;
      "visible": boolean;
      "customIdentifier": number;
      "parent_section_id": number
}

const SectionDropDown = ({ fieldDataSourceId, roleId, fieldSectionId, setFieldSectionId }: IProp) => {
      const [dropdownData, setDropdownData] = useState<{ label: string, value: number }[]>([]);
      useEffect(() => {
            const getSection = async () => {
                  const res: any = await apiCall(`/api/admin/field/sections/${fieldDataSourceId}`);
                  const data : Isection[] = res.data; 
                  const _dropdownData = data.map((el) => ({ label: el.name, value: el.id }));
                  setFieldSectionId(_dropdownData[0].value);
                  setDropdownData(_dropdownData);
            }
            getSection();
      }, [fieldDataSourceId]);

      const onHandleChange = (e: any) => {
            setFieldSectionId(Number(e.target.value));
      }
      return (<>
            <div>
                  <h5>Section</h5>
            </div>
            <div>
                  <select style ={{border:"1px solid #d5d5d5",padding:"3px 4px",height:"30px"}} value={fieldSectionId} onChange={onHandleChange}>
                        {
                              dropdownData.map((el) => (
                                    <option value={el.value}>{el.label}</option>
                              ))
                        }
                  </select>
                  
            </div>
            <br/>
      </>)
}

export default SectionDropDown;