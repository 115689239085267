import React, { useEffect } from "react";
import { useRouteMatch } from "react-router-dom";
import toastr from "toastr";
import "toastr/build/toastr.min.css";
import { useGetSwitchAccountMutation } from "../../store/AdminApiSlice";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { cal_sda } from "../Logout/Login";

interface userAccountIdentifierProp {
  recordID: number;
  roleId: number;
}

function AccountSwitch(props: userAccountIdentifierProp) {
  const routeMatch:any = useRouteMatch();

  let accountId = Number(routeMatch.params.recordID);
  let roleId = Number(routeMatch.params.roleId);

  const [getSwitchAccount, { isLoading }] = useGetSwitchAccountMutation();

  const GetAccountSwitchData = async (payload: any) => {
    const accountSwitchResult = await getSwitchAccount(payload);
  };

  useEffect(() => {
    GetAccountSwitchData({ accountId: accountId });
  }, []);

  useEffect(() => {
    if (!isLoading) {
      localStorage.setItem("CurrRoleId", roleId.toString());
      cal_sda()
    }
  }, [isLoading]);

  return <>{<div>Switching account...</div>}</>;
}

export default AccountSwitch;
