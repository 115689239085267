import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import apiCall from '../../service/apiCall';
import DoubleArrowIcon from "@mui/icons-material/DoubleArrow";
import { faAnglesRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface IfieldInfo {
      ID: number,
      Datasource: string,
      Section: string,
      Field: string,
      Description: string,
      DescriptionClient: string
}

const Instructions = () => {

      const urlparams: any = useParams();
      const [fieldInfo, setFieldInfo] = useState<IfieldInfo>();

      useEffect(() => {
            const FieldInfoFetch = async () => {
                  const res = await apiCall(`/api/admin/field/instructions/${urlparams.recordID}`);
                  const data = res.data;
                  if (data?.length > 0)
                        setFieldInfo(data[0]);
            }
            FieldInfoFetch();
      }, [])

      return (
            <div style={{ paddingLeft: "5%" }}>
                  {fieldInfo && <div className="col-sm-12 row" style={{ minHeight: "67em", width: "97%" }}>
                        <div className="col-sm-2" style={{ border: "5px solid #eDeDeD", borderRight: "none" }}>

                        </div>
                        <div className="col-sm-10" style={{ border: "5px solid #eDeDeD" }}>
                              <div>
                                    <br />
                                    <h2>Instructions</h2>
                                    <br />
                                    <br />
                                    <h4>{fieldInfo?.Datasource + " "}
                                          <FontAwesomeIcon style={{ fontSize: "18px" }} icon={faAnglesRight} />

                                          {" " + fieldInfo?.Section}
                                          <FontAwesomeIcon style={{ fontSize: "18px" }} icon={faAnglesRight} />
                                          {fieldInfo?.Field}</h4>
                              </div>
                              <br />
                              <div dangerouslySetInnerHTML={{ __html: fieldInfo?.DescriptionClient || "" }}>

                              </div>
                              <br />
                              <br />
                              <div>
                                    <a style={{
                                          color: "#00aae7",
                                          width: "100%",
                                          display: "block",
                                          fontSize: "12px",
                                    }} href={`/admin/form/264/${urlparams.recordID}`
                                    } rel="noopener" target="_blank" >
                                          Edit Instruction
                                    </a>

                              </div>
                        </div>
                  </div>}
            </div>
      )
}

export default Instructions;