import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import "./exportOption.css";
import { RiCloseLine } from "react-icons/ri";
import { DropDownOptions } from "./exportOption";
import apiCall from "../service/apiCall";
import { datasourceName, DataSourceToSearchDS } from "../service/colItems";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { IfilterState } from "../Dashboard/filter";
import { setExportModalShow } from "../../store/MultiSelectSlice";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import PostCall from "../service/postCall";
import { ToastrAlert } from "../ToastrAlert/ToastrAlert";

interface Iprop {
  datasource_id: number;
  datasource_name: string;
  currOption: number;
  setCurrOption: (val: number) => void;
}

export const _token = localStorage.getItem("token");
function ExportModal(props: Iprop) {
  const { datasource_id, datasource_name, currOption, setCurrOption } = props;
  const exportModalShow = useAppSelector(
    (state) => state.multiselect.exportModalShow
  );
  const [description, setDescription] = useState<string[]>([]);
  const [ColumnsCount, setColumnsCount] = useState(0);

  const [options, setOptions] = useState<string[]>([]);
  const defaultColumn = useAppSelector((state) => state.search.defaultColumn);
  const dispatch = useAppDispatch();
  const selectedLookupGlobe = useAppSelector(
    (state) => state.multiselect.selectedLookupGlobe
  );
  const [loading, setLoading] = useState(false);
  const IDs = useAppSelector((state) => state.search.currIds);
  // const handleClose = () => setShow(false);
  // const handleShow = () => setShow(true);

  useEffect(() => {
    (async () => {
      const id = DataSourceToSearchDS(datasource_id);
      const res = await apiCall(`/api/export/options/${id}`);
      const options_ = res.data.map((el: any) => el["Name"]);
      const description_ = res.data.map((el: any) => el["Description"]);
      setOptions(options_);
      setDescription(description_);
    })();
  }, [datasource_id]);

  // const exportOnClick = () => {
  //   setLoading(true);
  //   const keys = Object.keys(selectedLookupGlobe);
  //   let filterState: IfilterState = {};
  //   for (const key in keys) {
  //     // ['ClientId', 'DrugIDs', 'DeviceIDs', 'ManufacturerIDs', 'AgencyAbbreviationIDs', 'CountryIDs', 'DiseaseIDs']

  //     let newState = [...selectedLookupGlobe[keys[key]]];
  //     if (newState.length > 0) {
  //       let Ids = "";
  //       newState.forEach((element) => {
  //         Ids += element["Id"];
  //         Ids += ",";
  //       });
  //       Ids = Ids.substring(0, Ids.length - 1);
  //       if (Ids === "") {
  //         if (filterState.hasOwnProperty(keys[key]))
  //           delete filterState[keys[key]];
  //       } else {
  //         filterState[keys[key]] = Ids;
  //       }
  //     }
  //   }

  //   const data = {
  //     searchTerm: "",
  //     filterData: filterState,
  //     visibleColumns: defaultColumn.showColumnsNames,
  //     filteredIds: IDs,
  //   };

  //   const url =
  //     process.env.REACT_APP_API_URL +
  //     `/api/export/test/${datasource_id}/${currOption + 1}`;
  //   axios({
  //     url: url,
  //     method: "POST",
  //     headers: {
  //       Authorization: "Bearer " + _token,
  //     },
  //     responseType: "blob",
  //     data: data,
  //   }).then((resp: any) => {
  //     const href = URL.createObjectURL(resp.data);

  //     const link = document.createElement("a");

  //     link.href = href;

  //     const currentdate = new Date();
  //     const datetime =
  //       "_" +
  //       currentdate.getFullYear() +
  //       (currentdate.getMonth() + 1) +
  //       "" +
  //       currentdate.getDate() +
  //       "_" +
  //       currentdate.getHours() +
  //       +currentdate.getMinutes();

  //     link.setAttribute(
  //       "download",
  //       `${datasourceName[datasource_id]}_${datetime}.xlsx`
  //     );
  //     document.body.appendChild(link);
  //     link.click();

  //     document.body.removeChild(link);
  //     URL.revokeObjectURL(href);
  //     setLoading(false);
  //     dispatch(setExportModalShow(false));
  //   });
  // };

  const exportOnClick = () => {
    setLoading(true);
    const keys = Object.keys(selectedLookupGlobe);
    let exportType = "Regular Search";
    let pgName = "";
    let filterState: IfilterState = {};
    for (const key in keys) {
      // ['ClientId', 'DrugIDs', 'DeviceIDs', 'ManufacturerIDs', 'AgencyAbbreviationIDs', 'CountryIDs', 'DiseaseIDs']

      let newState = [...selectedLookupGlobe[keys[key]]];
      if (newState.length > 0) {
        let Ids = "";
        newState.forEach((element) => {
          Ids += element["Id"];
          Ids += ",";
        });
        Ids = Ids.substring(0, Ids.length - 1);
        if (Ids === "") {
          if (filterState.hasOwnProperty(keys[key]))
            delete filterState[keys[key]];
        } else {
          filterState[keys[key]] = Ids;
        }
      }
    }

    const data = {
      searchTerm: "",
      filterData: filterState,
      visibleColumns: defaultColumn.showColumnsNames,
      filteredIds: IDs,
    };

    
    const url =
      process.env.REACT_APP_API_URL +
      `/api/export/sample/${datasource_id}/${datasource_id == 20 && currOption == 0 ? 2 : datasource_id == 20 && currOption == 1 ? 1 :  currOption + 1}`;
      //  `/api/export/stream/data/test`
     // `/api/export/test/${datasource_id}/${currOption + 1}`;
    PostCall(url, data).then((resp: any) => {
      ToastrAlert("The export request is in progress. You will be notified by e-mail once it is completed", "info");

      // link.setAttribute(
      //   "download",
      //   `${datasourceName[datasource_id]}_${datetime}.xlsx`
      // );
      // document.body.appendChild(link);
      setLoading(false);
      // link.click();
      // fs.writeFileSync(`${datasourceName[datasource_id]}_${datetime}.xlsx`, resp.data);

      // document.body.removeChild(link);
      // URL.revokeObjectURL(href);
      if (window.location.href.indexOf("savedSearch") > -1)
        pgName = "Saved search page";
      else if (exportType == "Regular Search")
        pgName = "Home search page";
      else if (window.location.href.indexOf("pathway") > -1)
        pgName = "Pathway search page";

      PostCall(`/api/insert/insertUserDownloadDetails`, {
        datasource_id: datasource_id,
        datasource_name: datasource_name,
        download_type: exportType,
        pg_name: pgName + "^" + window.location.href + "^",
        file_name: "Report.xlsx"
      }).then((resp) => {
        // ToastrAlert("Record downloaded successfully", "success");
      });
    }).catch((x) => {
      console.log(x);
    });

  };

  const exportData = (e : any) => {
    e.stopPropagation();
    dispatch(setExportModalShow(false));
    exportOnClick();
   
  } 

  useEffect(() => {
    if (options[currOption]?.toLowerCase() === "current view") {
      let len = defaultColumn.showColumnsNames.length;
      setColumnsCount(len);
    } else if (options[currOption]?.toLowerCase() === "all fields") {
      apiCall(`/api/export/column/${datasource_id}/${2}`).then((res) => {
        setColumnsCount(Number(res.data));
      });
    } else if (options[currOption]?.toLowerCase() == "all fields including all related data sources"){
      apiCall(`/api/export/column/${datasource_id}/${4}`).then((res) => {
        setColumnsCount(Number(res.data));
      });
    }
  }, [currOption, exportModalShow]);

  return (
    <>
      {/* {show && */}
      <Modal
        show={exportModalShow}
        dialogClassName="modal-dialog-new"
        contentClassName="my-modal-new"
        // onHide={handleClose}
      >
        <Modal.Header style={{ backgroundColor: "#fafafa" }}>
          <Modal.Title>Export Wizard</Modal.Title>
          <button
            style={{
              background: "rgb(250, 250, 250)",
              marginTop: "5px",
              marginRight: "10px",
              float: "right",
              border: 0,
            }}
            onClick={(event) => {
              event.stopPropagation();
              dispatch(setExportModalShow(false));
            }}
          >
            <RiCloseLine style={{ fontSize: "28px" }} />
          </button>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3 ">
              <Form.Label className="col-md-4">Export Options</Form.Label>
              <Form.Select
                className="col-md-8"
                aria-label="options"
                onChange={(e) => setCurrOption(Number(e.target.value))}
              >
                <DropDownOptions options={options} currOption={currOption} />
              </Form.Select>
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label className="col-md-4">Description</Form.Label>
              <Form.Control
                className="col-md-8"
                style={{ backgroundColor: "#e9ecef", display: "inline" }}
                as="textarea"
                rows={5}
                readOnly
                id="description"
                value={description[currOption] ? description[currOption] : ""}
              ></Form.Control>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer
          style={{
            display: "inline",
            marginTop: "0px",
            backgroundColor: "#fafafa",
            marginLeft: "15px",
            marginRight: "15px",
          }}
        >
          <a
            style={{
              float: "right",
              color: "rgb(0, 170, 231)",
              //     paddingTop: "10px",
              paddingRight: "10px",
              cursor: "pointer",
              display: "flex"
            }}
            onClick={(e) => exportData(e)}
          >
            <span>Export</span>
            {loading && (
              <Box sx={{ display: "flex", marginLeft: "10px" }}>
                <CircularProgress size={"20px"} />
              </Box>
            )}
          </a>
        </Modal.Footer>
        <label style={{ float: "left", paddingLeft: "30px" }}>
          Total Columns: {ColumnsCount}
        </label>
      </Modal>
      {/* }
       */}
    </>
  );
}

export default ExportModal;
