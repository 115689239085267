import React, { useEffect } from "react";
import { useRouteMatch } from "react-router-dom";
import { format } from "date-fns";
import { useFetchSpecificClientNewsMutation } from "../../store/ClientNewsApiSlice";
import "./clientNewsDetails.css";

const ClientNewsDetails = () => {
  const routeMatch : any = useRouteMatch();
  let clientnewsId = Number(routeMatch.params.clientnewsId);
  const [fetchSpecificClientNews, { data }] =
    useFetchSpecificClientNewsMutation();

  const getSpecificClientNewsData = async (payload: any) => {
    const specificNews = await fetchSpecificClientNews(payload);
  };

  useEffect(() => {
    getSpecificClientNewsData({ clientnewsId: clientnewsId });
  }, []);
  return (
    <div
      className="rightactionpane col-md-12 home-height"
      style={{ marginTop: "-7px" }}
    >
      {data && (
        <div>
          {data.map((item:any) => {
            return (
              <div key={item.ID}>
                <h2 className="clientNewsTitle">{item.Name}</h2>
                <div style={{marginBottom:"5px",fontWeight:"bold"}}>
                  <a className="iqvia-color" href={`/admin/form/276/${item.ID}`}>Edit</a>
                  </div>
                <div>
                  <h4 className="clientNewsDate">
                    <b>{format(new Date(item.Date), "dd/MM/yyyy")}</b>
                  </h4>
                </div>
                <div>
                  <div
                    className="client-news-body-full-article"
                    dangerouslySetInnerHTML={{ __html: item.Body }}
                  ></div>
                </div>
              </div>
            );
          })}
        </div>
      )}
      <div className="client-news-body-full-article">
        <p>
          <a href="/ClientNewsList">Return to the News List</a>
        </p>
      </div>
    </div>
  );
};
export default ClientNewsDetails;
