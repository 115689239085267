import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import CloseIcon from "@mui/icons-material/Close";
import { useAppSelector } from "../../../store/hooks";
import Loader from "../../../utils/Loader";
import DiseaseTreeView from "./DiseaseTreeView";
import { Button } from "@mui/material";

interface FilterModalProp {
  open: boolean;
  handleClose: Function;
  title: string;
  modalDataSelector: number;
  recordName: string[];
  fetchRelatedData: Function;
}

const FilterModal = (props: FilterModalProp) => {
  const {
    open,
    handleClose,
    title,
    modalDataSelector,
    recordName,
    fetchRelatedData,
  } = props;
  const [data, setData] = useState<any[]>([]);
  const [isAllChecked, setIsAllChecked] = useState<boolean>(true);
  const [loading, setLoading] = useState<boolean>(false);
  const [isDisabled, setIsDisabled] = useState<boolean>(false);
  const countryList = useAppSelector((state) => state.productProfile.countryList);
  
  const relatedProducts = useAppSelector((state) => state.productProfile.relatedProducts);
  const diseaseHierarchy = useAppSelector((state) => state.productProfile.diseaseHierarchy);
  // console.log("state", state, recordName);

  // Methods :
  const closeModal = () => {
    setData([]);
    setIsAllChecked(true);
    handleClose();
  };

  const onSubmit = async () => {
    if (modalDataSelector === 1) {
      let recordName: string[] = [];
      let ids = "";
      for (let i = 0; i < data.length; i++) {
        if (data[i].IsSelected === 1) {
          ids += data[i].ID + ",";
          recordName.push(data[i].label.split("|")[0].trim());
        }
      }
      if (ids !== "") {
        if (ids.endsWith(",")) {
          ids = ids.slice(0, -1);
        }
        console.log('id', ids)
        await fetchRelatedData(ids, recordName);
      }
    }
    handleClose();
  };

  useEffect(() => {
    setLoading(true);
    if (modalDataSelector === 2) {
      setData(countryList);
    } else if (modalDataSelector === 1) {
      let tmp = [...relatedProducts];
      tmp = tmp.map((obj) => {
        const name = obj.label.split("|")[0].trim();
        console.log(name, "name", recordName.includes(name));
        if (recordName.includes(name)) {
          return { ...obj, IsSelected: 1 };
        } else {
          return { ...obj, IsSelected: 0 };
        }
      });
      setData(tmp);
    } else if (modalDataSelector === 3) {
      setData(diseaseHierarchy);
    }
    setLoading(false);
  }, [modalDataSelector]);

  const checkCheckbox = (value: any) => {
    if (modalDataSelector === 1 || modalDataSelector === 2) {
      if (value.IsSelected === 1) return true;
      else return false;
    }
  };

  const handleCheckbox = (value: any) => {
    if (modalDataSelector === 1 || modalDataSelector === 2) {
      let tempData = [...data];
      tempData = tempData.map((item: any) => {
        if (item.ID === value.ID) {
          return { ...item, IsSelected: item.IsSelected === 1 ? 0 : 1 };
        }
        return item;
      });
      console.log('tempData', tempData)
      setData(tempData);
    }
  };

  const selectAllCheckbox = () => {
    if (modalDataSelector === 1 || modalDataSelector === 2) {
      let tempData = [...data];
      tempData = tempData.map((item: any) => {
        return { ...item, IsSelected: isAllChecked ? 0 : 1 };
      });
      setData(tempData);
      setIsAllChecked(!isAllChecked);
    }
  };

  return (
    <div className="modalContainer">
      <Modal
        
        className="diseaseTreeViewModal"
        show={open}
        onHide={() => closeModal()}
        centered
      >
        <Modal.Header style={{backgroundColor:"rgb(237,237,237)"}}>
          <Modal.Title>{title}</Modal.Title>
          <CloseIcon
            style={{ cursor: "pointer" }}
            onClick={() => closeModal()}
          />
        </Modal.Header>
        <Modal.Body>
          {!loading ? (
            <>
              {modalDataSelector !== 3 ? (
                <div className="modalBodyFilters">
                  <div className="allContent">
                    <input
                      type="checkbox"
                      checked={isAllChecked}
                      onChange={() => selectAllCheckbox()}
                    />
                    <span style={{ marginLeft: 2 }}>All</span>
                  </div>
                  <div className="modalContent row">
                    {data.map((item: any, index: number) => (
                      <label className="modalItem col-4" key={index}>
                        <input
                          checked={checkCheckbox(item)}
                          type="checkbox"
                          onChange={() => handleCheckbox(item)}
                        />
                        {modalDataSelector === 1 && (
                          <span style={{ marginLeft: 5 }}>
                            {item.label.split("|")[0]}
                          </span>
                        )}
                        {modalDataSelector === 2 && (
                          <span style={{ marginLeft: 5 }}>{item.name}</span>
                        )}
                      </label>
                    ))}
                  </div>
                </div>
              ) : (
                <DiseaseTreeView data={data} />
              )}
            </>
          ) : (
            <Loader />
          )}
        </Modal.Body>
        <Modal.Footer >
          {/* <span className="spanBtn" onClick={() => closeModal()}>
          Cancel
        </span>
        <span>|</span> */}
          {modalDataSelector === 1 && (
            <Button
              style={{ cursor: "pointer" }}
              onClick={() => onSubmit()}
              disabled={isDisabled}
            >
              Search for related drug
            </Button>
          )}
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default FilterModal;
