import axiosInstance from './axiosInstance';

const exportCall = async (url: string, data: any, fileName: string) => {
      const headers = { Accept: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" };
      const resp = await axiosInstance.post(
            process.env.REACT_APP_API_URL+url,
            data,
            {responseType: "blob",headers : headers},
      );
      const href = URL.createObjectURL(new Blob([resp.data]));
      const link = document.createElement("a");
      link.href = href;
      link.setAttribute(
            "download",
            fileName
      );
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(href);
};

export const exportGetCall = async (url: string,  fileName: string) => {
      const headers = { Accept: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" };
      const resp = await axiosInstance.get(
            process.env.REACT_APP_API_URL+url,
            {responseType: "blob",headers : headers}
      );
      const href = URL.createObjectURL(new Blob([resp.data]));
      const link = document.createElement("a");
      link.href = href;
      link.setAttribute(
            "download",
            fileName
      );
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(href);
};

export default exportCall;