// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `    .dosage-head {
      background-color:#d3d3d3;
      text-align: center;
    }

    .dosage-table {
      border: 1px solid grey;
      table-layout: fixed;
      text-align: center;
      font-size: 12px;
      color: #393939;
      line-height: 1.5;
      font-weight: 400;
    }
    
    .dosage-head-fields {
      border: 1px solid grey;
      /* background-color: lightgrey; */
      overflow: hidden;
      
    }

    .dosage-fields {
      width: 3.3em;
      padding: 5px 1px !important;
    }`, "",{"version":3,"sources":["webpack://./src/components/Forms/SubPages/Views/style.css"],"names":[],"mappings":"IAAI;MACE,wBAAwB;MACxB,kBAAkB;IACpB;;IAEA;MACE,sBAAsB;MACtB,mBAAmB;MACnB,kBAAkB;MAClB,eAAe;MACf,cAAc;MACd,gBAAgB;MAChB,gBAAgB;IAClB;;IAEA;MACE,sBAAsB;MACtB,iCAAiC;MACjC,gBAAgB;;IAElB;;IAEA;MACE,YAAY;MACZ,2BAA2B;IAC7B","sourcesContent":["    .dosage-head {\n      background-color:#d3d3d3;\n      text-align: center;\n    }\n\n    .dosage-table {\n      border: 1px solid grey;\n      table-layout: fixed;\n      text-align: center;\n      font-size: 12px;\n      color: #393939;\n      line-height: 1.5;\n      font-weight: 400;\n    }\n    \n    .dosage-head-fields {\n      border: 1px solid grey;\n      /* background-color: lightgrey; */\n      overflow: hidden;\n      \n    }\n\n    .dosage-fields {\n      width: 3.3em;\n      padding: 5px 1px !important;\n    }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
