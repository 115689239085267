// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.text {
    width: 50%;
    margin: auto;
}
  
.top {
    /* margin: auto; */
    margin-top: 4%;
    width: 50%;
    border: 2px ridge black;
    background-color: #f1f1f1;

}
  
.buttonStyle {
    height: 15px;    
    width: 30px;
    font-size: 15px;
    background: white;
    color: #00aae7;    
    display: inline-block;
    padding: 3px;
    margin: 0px;
    border: 0px;    
}

.buttonStyle :hover {
    color: white;
    background: #00a9e741;
    
}

h3 {
    text-align: center;
}
  
.row {
    padding-left: 5%;
}

.modal-scroll {
    height: 300px;
    
    overflow-x: hidden;
    
}

.ms-item {
    color: #00aae7 !important;
    padding-left: 2px;
    font-size: 11px;
    cursor: pointer;
}

.ms-item:hover {
    text-decoration: underline;
}`, "",{"version":3,"sources":["webpack://./src/components/Popup/MultiSelectModal.css"],"names":[],"mappings":"AAAA;IACI,UAAU;IACV,YAAY;AAChB;;AAEA;IACI,kBAAkB;IAClB,cAAc;IACd,UAAU;IACV,uBAAuB;IACvB,yBAAyB;;AAE7B;;AAEA;IACI,YAAY;IACZ,WAAW;IACX,eAAe;IACf,iBAAiB;IACjB,cAAc;IACd,qBAAqB;IACrB,YAAY;IACZ,WAAW;IACX,WAAW;AACf;;AAEA;IACI,YAAY;IACZ,qBAAqB;;AAEzB;;AAEA;IACI,kBAAkB;AACtB;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI,aAAa;;IAEb,kBAAkB;;AAEtB;;AAEA;IACI,yBAAyB;IACzB,iBAAiB;IACjB,eAAe;IACf,eAAe;AACnB;;AAEA;IACI,0BAA0B;AAC9B","sourcesContent":[".text {\n    width: 50%;\n    margin: auto;\n}\n  \n.top {\n    /* margin: auto; */\n    margin-top: 4%;\n    width: 50%;\n    border: 2px ridge black;\n    background-color: #f1f1f1;\n\n}\n  \n.buttonStyle {\n    height: 15px;    \n    width: 30px;\n    font-size: 15px;\n    background: white;\n    color: #00aae7;    \n    display: inline-block;\n    padding: 3px;\n    margin: 0px;\n    border: 0px;    \n}\n\n.buttonStyle :hover {\n    color: white;\n    background: #00a9e741;\n    \n}\n\nh3 {\n    text-align: center;\n}\n  \n.row {\n    padding-left: 5%;\n}\n\n.modal-scroll {\n    height: 300px;\n    \n    overflow-x: hidden;\n    \n}\n\n.ms-item {\n    color: #00aae7 !important;\n    padding-left: 2px;\n    font-size: 11px;\n    cursor: pointer;\n}\n\n.ms-item:hover {\n    text-decoration: underline;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
