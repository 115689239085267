import React, { useState, useEffect } from "react";
import Loader from "../../../utils/Loader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDiamond } from "@fortawesome/free-solid-svg-icons";
import Tooltip, { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
interface MilestonesTimelineChartProps {
  data: any[];
}

const MilestonesTimelineChart = (props: MilestonesTimelineChartProps) => {
  const { data } = props;
  const [chartData, setChartData] = useState<any[]>([]);
  const [minYear, setMinYear] = useState<number>(0);
  const [maxYear, setMaxYear] = useState<number>(0);
  const [isLoading, setIsLoading] = useState(false);

  const initialData = () => {
    setIsLoading(true);
    let minY = 3000;
    let maxY = 0
    const arr = data.reduce((acc, curr) => {
      if (
        curr.category === "Trial-1" ||
        curr.category === "Trial-2" ||
        curr.category === "Trial-3" ||
        curr.category === "Trial-4" ||
        curr.category === "Trial-5"
      ) {
        if (curr.label === "Start" || curr.label === "End") {
          let label: string = "";
          let description: string = "";
          if (curr.label === "Start") description = curr.info;
          if (curr.label === "End") label = curr.info;

          if (new Date(curr.value).getFullYear() < minY) {
            minY = new Date(curr.value).getFullYear();
          }
          if (new Date(curr.value).getFullYear() > maxY) {
            maxY = new Date(curr.value).getFullYear();
          }
          const trialName = curr.category;
          if (!acc[trialName]) {
            acc[trialName] = {
              x: trialName,
              y: [],
              label,
              description,
              tooltipTitle: label,
            };
          }
          acc[trialName].y.push(formatDate(curr.value));
          if (acc[trialName].label === "") acc[trialName].label = label;
          if (acc[trialName].description === "")
            acc[trialName].description = description;
          if (acc[trialName].tooltipTitle === "")
            acc[trialName].tooltipTitle = label;
        }
      } else if (
        curr.category === "Approval" &&
        (curr.label === "FDA" || curr.label === "EMA") &&
        !acc[curr.label + " approval"]
      ) {
        if (new Date(curr.value).getFullYear() < minY) {
          minY = new Date(curr.value).getFullYear();
        }
        if (new Date(curr.value).getFullYear() > maxY) {
          maxY = new Date(curr.value).getFullYear();
        }
        const trialName = curr.label + " approval";
        acc[trialName] = {
          x: trialName,
          // y: [formatDate(curr.value), addDate(curr.value)],
          y: [formatDate(curr.value)],
          label: trialName,
          description: curr.label + " approval",
          tooltipTitle: "Regulatory approval",
        };
      } else if (
        curr.category == "HTA" &&
        (curr.label == "HAS" || curr.label == "NICE" || curr.label == "G-BA") &&
        !acc[curr.label]
      ) {
        if (new Date(curr.value).getFullYear() < minY) {
          minY = new Date(curr.value).getFullYear();
        }
        if (new Date(curr.value).getFullYear() > maxY) {
          maxY = new Date(curr.value).getFullYear();
        }
        const trialName = curr.label;
        acc[trialName] = {
          x: trialName,
          // y: [formatDate(curr.value), addDate(curr.value)],
          y: [formatDate(curr.value)],
          label: trialName,
          description: curr.info,
          tooltipTitle: "HTA recommandation",
        };
      }
      return acc;
    }, []);

    const trial = Object.values(arr);

    const trialOrder = ["Trial-1", "Trial-2", "Trial-3", "Trial-4", "Trial-5"];
    const approvalOrder = ["EMA approval", "FDA approval"];
    const trials: any[] = [];
    const approvals: any[] = [];
    const others: any[] = [];

    trial.forEach((obj: any) => {
      if (trialOrder.includes(obj.x)) {
        trials.push(obj);
      } else if (approvalOrder.includes(obj.x)) {
        approvals.push(obj);
      } else {
        others.push(obj);
      }
    });

    trials.sort((a, b) => trialOrder.indexOf(a.x) - trialOrder.indexOf(b.x));
    approvals.sort(
      (a, b) => approvalOrder.indexOf(a.x) - approvalOrder.indexOf(b.x)
    );

    const reorganizedArray = trials.concat(approvals, others);

    // console.log(reorganizedArray, "reorganizedArray");

    setMinYear(minY);
    setMaxYear(maxY + 1);
    setChartData(reorganizedArray);
    setIsLoading(false);
  };

  useEffect(() => {
    initialData();
  }, []);

  const formatDate = (date: string) => {
    const dateArray = date.split("T")[0].split("-");
    const res = `${dateArray[0]}-${dateArray[1]}-${dateArray[2]}`;
    return new Date(res).getTime();
  };

  const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: "#f5f5f9",
      color: "rgba(0, 0, 0, 0.87)",
      maxWidth: 220,
      fontSize: theme.typography.pxToRem(12),
      border: "1px solid #dadde9",
    },
  }));

  const recommendations = (value: string) => {
    if (value === null) return "darkgray";
    else {
      if (value.includes("Trial")) return "#065280";
      else if (value.includes("FDA") || value.includes("EMA")) return "#00aae7";
      else if (value.includes("Positive w. restrictions")) return "orange";
      else if (value.includes("Negative")) return "red";
      else if (value.includes("Positive")) return "darkgreen";
      else if (value.includes("Multiple")) return "deepskyblue";
      else return "darkgray";
    }
  };

  const addMargin = (obj: any, timestamp: number) => {
    if (obj.y.length === 2) {
      let startYear = new Date(obj.y[0]).getFullYear();
      let month = new Date(obj.y[0]).getMonth() * 2;
      let marginLeft: number = 0;
      if (obj.y[0] !== minYear) marginLeft = (startYear - minYear) * 55 + month;

      if (obj.y[0] === timestamp)
        return {
          marginLeft,
        };
    } else {
      let startYear = new Date(obj.y[0]).getFullYear();
      let month = new Date(obj.y[0]).getMonth() * 2;
      let marginLeft = (startYear - minYear) * 55 + month;
      return {
        marginLeft,
      };
    }
  };

  const calculateWidth = (arr: any) => {
    let startYear = new Date(arr[0]).getFullYear();
    let endYear = new Date(arr[1]).getFullYear();
    let month = new Date(arr[1]).getMonth() * 2;
    return (endYear - startYear) * 50 + 5 + month;
  };

  const addDate = (date: string) => {
    let day = new Date(date).getDate();
    let month = new Date(date).getMonth() + 1;
    let year = new Date(date).getFullYear();
    // const lastDayOfMonth = new Date(year, month, 0).getDate();
    // const lastDayOfYear = new Date(year, 11, 31).getDate();
    // // console.log(day, month, year, 'date', date)
    // if (day === lastDayOfMonth) {
    //   if (month === 12) {
    //     return `${1}/${1}/${year + 1}`;
    //   } else {
    //     return `${1}/${month + 1}/${year}`;
    //   }
    // } else if (day === lastDayOfYear && month === 12) {
    //   return `${1}/${1}/${year + 1}`;
    // } else {
    //   return `${day + 1}/${month}/${year}`;
    // }
    return `${day}/${month}/${year}`;
  };


  return !isLoading && chartData.length > 0 ? (
    <div className="milestonesTimelineChart">
      <div className="title">
        <h4 style={{fontSize:"1.2em"}}> Selected disease: </h4>
        {data[0] && data[0].info && (
          <h4 style={{ marginLeft: 5,fontSize:"1.2em" }}> {data[0].info} </h4>
        )}
      </div>
      <div></div>

      <div className="newChartTimeline">
        <div className="chartYears">
          {Array.from({ length: maxYear - minYear + 1 }, (_, index) => (
            <span key={minYear + index}>{minYear + index}</span>
          ))}
        </div>
      </div>

      <div className="chart">
        <div>
          {chartData.map((item: any, index: number) => (
            <div className="chartRow" key={index}>
              <div className="rowTitleTimeline"> {item.label} </div>
              <div
                className="rowContentTimeline"
                style={
                  index === 0
                    ? { borderTop: "1px solid #e9e9e9" }
                    : index === chartData.length - 1
                    ? { borderBottom: "1px solid #e9e9e9" }
                    : {}
                }
              >
                {item.y.map((item2: any, index2: number) => (
                  <React.Fragment key={item.label + "_" + index2}>
                    {index2 === 1 && (
                      <div
                        className="chartLine"
                        style={{
                          backgroundColor: "#065280",
                          width: calculateWidth(item.y),
                          height: 10,
                          marginTop: "2.5px",
                          marginLeft: "-5px",
                          marginRight: "-5px",
                        }}
                      ></div>
                    )}
                    <div
                      key={item.label + "_" + index2}
                      style={addMargin(item, item2)}
                    >
                      <HtmlTooltip
                        title={
                          <div className="htmlTooltipTimeline">
                            <Typography color="inherit" className="title">
                              {item.tooltipTitle}
                            </Typography>
                            <div className="toolTip">
                              <div>{item.description}</div>
                              <div style={{ fontWeight: "bold", marginTop: 5 }}>
                                {addDate(item2)}
                              </div>
                            </div>
                          </div>
                        }
                      >
                        <div>
                          <FontAwesomeIcon
                            icon={faDiamond}
                            style={{
                              color: recommendations(
                                item.x.includes("Trial")
                                  ? item.x
                                  : item.description
                              ),
                              fontSize: "16px",
                            }}
                          />
                          <div style={{ marginRight: "-47px" }}>
                            {" "}
                            {addDate(item2)}{" "}
                          </div>
                        </div>
                      </HtmlTooltip>
                    </div>
                  </React.Fragment>
                ))}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  ) : (
    <Loader />
  );
};

export default MilestonesTimelineChart;
