import React, { useContext, useState, useEffect } from "react";
import "./pricing.css";
//import InfoIcon from "@mui/icons-material/Info";
import { FaInfoCircle } from "react-icons/fa";
import DoubleArrowIcon from "@mui/icons-material/DoubleArrow";
import { UserContext } from "../Forms/Editor";
import { formatDate, calculateAmnogPercentage } from "../../utils";
import Loader from "../../utils/Loader";
import apiCall from "../service/apiCall";
import PricingChart from "./PricingChart";
import PricingTable from "./PricingTable";
interface PricingViewProps {
  values: any;
  datasourceName: string;
  pricingCompareRecord?: any;
  title?: string;
}

const PricingView = (props: PricingViewProps) => {
  const { values, datasourceName, pricingCompareRecord, title } = props;
  const userContext = useContext(UserContext);
  const [tableData, setTableData] = useState<any[]>([]);
  const [tableAdditionalField, setTableAdditionalField] =
    useState<boolean>(false);
  const [recordIdData, setRecordIdData] = useState({});
  const [loading, setLoading] = useState<boolean>(false);
  const [view, setView] = useState<string>("Graph");
  const [manufacturerPrice, setManufacturerPrice] = useState<any[]>([]);
  const [retailPrice, setRetailPrice] = useState<any[]>([]);
  const [hospitalPrice, setHospitalPrice] = useState<any[]>([]);
  const [dateList, setDateList] = useState<any[]>([]);
  const [listPrice, setListPrice] = useState<any[]>([]);
  const [amnogPrice, setAmnogPrice] = useState<any[]>([]);
  const [amnogPriceChange, setAmnogPriceChange] = useState<any[]>([]);
  const [amnogPriceChangePercentage, setAmnogPriceChangePercentage] = useState<
    any[]
  >([]);

 // const [selectedDiv, setSelectedDiv] = useState("");

  // console.log(
  //   values,
  //   "values",
  //   pricingCompareRecord,
  //   "pricingCompareRecord",
  //   datasourceName,
  //   "datasourceName"
  // );

  const fetchData = async () => {
    if (Object.entries(values).length > 0) {
      setLoading(true);
      const response = await apiCall(
        `/api/getPricingChartInfo/${values.Pricing_ID}`
      );
      // console.log(response, "response!!!!");
      if (Object.entries(response.data).length > 0) {
        if (response.data.recordData.length > 0) {
          setRecordIdData(response.data.recordData[0]);
        }
        if (response.data.chartData.length > 0) {
          formatDataForChart(sortDataAsc(response.data.chartData));
          setTableData(sortDataDesc(response.data.chartData));
        }
        setLoading(false);
      } else {
        setLoading(true);
      }
    }
  };

  useEffect(() => {
    fetchData();
  }, [values]);

  const sortDataAsc = (data: any[]) => {
    return data.sort((a: any, b: any) => {
      return new Date(a.Date).getTime() - new Date(b.Date).getTime();
    });
  };

  const sortDataDesc = (data: any[]) => {
    let res = data.sort((a: any, b: any) => {
      return new Date(b.Date).getTime() - new Date(a.Date).getTime();
    });
    return res;
  };

  const formatDataForChart = (item: any) => {
    // console.log(item, 'item');
    const months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    let tmpListPrice: number[] = [];
    let tmpManufacturerPrice: number[] = [];
    let tmpRetailPrice: number[] = [];
    let tmpHospitalPrice: number[] = [];
    let tmpDateList: string[] = [];
    let tmpAmnogPrice: number[] = [];
    let tmpAmnogPriceChange: number[] = [];
    let tmpAmnogPriceChangePercentage: any[] = [];

    for (let i = 0; i < item.length; i++) {
      let month = months[new Date(item[i].Date).getMonth()];
      let year = new Date(item[i].Date).getFullYear();

      tmpDateList.push(`${month} ${year}`);
      tmpListPrice.push(item[i].LP ? item[i].LP : null);
      tmpManufacturerPrice.push(item[i].MSP ? item[i].MSP : null);
      tmpRetailPrice.push(
        item[i].RetailPharmacyPrice ? item[i].RetailPharmacyPrice : null
      );
      tmpHospitalPrice.push(
        item[i].PriceToHospital ? item[i].PriceToHospital : null
      );

      tmpAmnogPrice.push(
        item[i].AMNOG_Ex_Manufacturer ? item[i].AMNOG_Ex_Manufacturer : null
      );

      tmpAmnogPriceChange.push(
        item[i].AMNOG_Rebate ? item[i].AMNOG_Rebate : null
      );

      // if (
      //   item[i].MSP != null &&
      //   item[i].AMNOG_Rebate != null &&
      //   item[i].MSP > 0
      // ) {
      tmpAmnogPriceChangePercentage.push(
        item[i].AMNOG_Rebate
          ? calculateAmnogPercentage(item[i].AMNOG_Rebate, item[i].MSP)
          : null
      );
      // }
    }

    setListPrice(tmpListPrice);
    setManufacturerPrice(tmpManufacturerPrice);
    setRetailPrice(tmpRetailPrice);
    setHospitalPrice(tmpHospitalPrice);
    setDateList(tmpDateList);
    for (let x of tmpAmnogPrice) {
      if (typeof x === "number") {
        setAmnogPrice(tmpAmnogPrice);
        setTableAdditionalField(true);
        break;
      }
    }

    for (let x of tmpAmnogPriceChange) {
      if (typeof x === "number") {
        setAmnogPriceChange(tmpAmnogPriceChange);
        setTableAdditionalField(true);
        break;
      }
    }

    for (let x of tmpAmnogPriceChangePercentage) {
      if (typeof x === "number") {
        setAmnogPriceChangePercentage(tmpAmnogPriceChangePercentage);
        setTableAdditionalField(true);
        break;
      }
    }
  };

  const showInfoIcon = (field: string, description?: string) => {
    return (
      <FaInfoCircle
        onClick={() => {
          userContext.showAlert(
            <>
              <span style={{ fontSize: "70%", paddingLeft: "2px" }}>
                {datasourceName}
                <DoubleArrowIcon
                  style={{ fontSize: "18px", paddingBottom: "2px" }}
                />
                General information
                <DoubleArrowIcon
                  style={{
                    strokeWidth: "5",
                    fontSize: "18px",
                    paddingBottom: "2px",
                  }}
                />
                {field}
              </span>
            </>,
            "info",
            description,
            "https://google.com",
            true
          );
        }}
        style=
        {{
           fontSize: "12px", marginLeft: field=="Source" ? "49" :"3px", cursor: "pointer",marginRight: field == "Source" ? "-16px" :"",marginTop: field=="Source" ? "-34px" : "",
        }}
      />
    );
  };

  const displayContent = (
    field: string,
    description: any,
    value: string,
    compareRecord?: string
  ) => {
    return (
      <div className="content">
        <span className="contentTitle" style={{marginLeft: field=="Source" ? "39px" : ""}}>
          {field}
          {showInfoIcon(field, description)}
        </span>
        <span style={{ display: "flex", flexDirection: "column", marginLeft: value  == "Price points: LauerTaxe and Rote Liste; Launch date: LauerTaxe; AMNOG Rebate: LauerTaxe" ? "17px" : "" }}>
          <span className="contentValue"> {value} </span>
          {Object.entries(pricingCompareRecord).length > 0 && (
            <span className="contentValue" style={{ marginTop: 10 }}>
              {compareRecord}
            </span>
          )}
        </span>
      </div>
    );
  };

  // console.log('values', values, 'recordIdData', recordIdData);

  return Object.entries(values).length > 0 &&
    !loading &&
    Object.entries(recordIdData).length > 0 ? (
    <>
      {!!title && <div className="pricingTitle">{title}</div>}
      <div className="pricingContainer">
        <div className="row">
          <div className="col-lg-3 col-12 generalInformation">
            <div className="title">
              <h4> General information </h4>
            </div>
            <div className="contents">
              {displayContent(
                "Corporation (PI)",
                "No Instructions available.",
                values.Corporation,
                pricingCompareRecord.Corporation
              )}
              {displayContent(
                "Manufacturer (PI)",
                "No Instructions available.",
                values.Manufacturer,
                pricingCompareRecord.Manufacturer
              )}
              <div className="content">
                <span className="contentTitle">
                  Parallel import
                  {showInfoIcon(
                    "Parallel import",
                    "This box is ticked if parallel import applies."
                  )}
                </span>
                <span style={{ display: "flex", flexDirection: "column" }}>
                  <span className="contentValue">
                    <input
                      checked={values.ParallelImport}
                      readOnly
                      type="checkbox"
                    />
                  </span>
                  {Object.entries(pricingCompareRecord).length > 0 && (
                    <span className="contentValue">
                      <input
                        checked={pricingCompareRecord.ParallelImport}
                        readOnly
                        type="checkbox"
                      />
                    </span>
                  )}
                </span>
              </div>
              {displayContent(
                "National code",
                "This is the national code for the country. For example FR for France.",
                values.National_Code,
                pricingCompareRecord.National_Code
              )}
              <div className="content">
                <span className="contentTitle">
                  Launch date
                  {showInfoIcon(
                    "Launch date",
                    "This date is the launch date for the pack size that was on the market first in the presented country."
                  )}
                </span>
                <span style={{ display: "flex", flexDirection: "column" }}>
                  <span className="contentValue">
                    {formatDate(values.LaunchDate)}
                  </span>
                  {Object.entries(pricingCompareRecord).length > 0 && (
                    <span className="contentValue" style={{ marginTop: 10 }}>
                      {formatDate(pricingCompareRecord.LaunchDate)}
                    </span>
                  )}
                </span>
              </div>

              {displayContent(
                "Currency",
                "The currency used in the pricing record.",
                recordIdData.Currency,
                pricingCompareRecord.Currency
              )}
              {displayContent(
                "Size",
                "This field provides the pack size for the pack size that is most often used. ",
                values.PackSize,
                pricingCompareRecord.PackSize
              )}
              {displayContent(
                "Unit (pack)",
                "This field indicates the type of units in each pack : Capsule, Tablet, Vials Syringes",
                recordIdData.Unit_Pack,
                pricingCompareRecord.Unit_Pack
              )}
              {displayContent(
                "Dose per unit",
                "This field describes the dose per unit.",
                values.DosePerUnit,
                pricingCompareRecord.DosePerUnit
              )}
              {displayContent(
                "Unit (dose)",
                "This field indicates the type of units for each pill/vial : mg, mL, Unit",
                recordIdData.Unit_Dose,
                pricingCompareRecord.Unit_Dose
              )}
              {displayContent(
                "Source",
                "This field provides the source from which the price was taken. For example; BNF, Vidal, Rote Liste..",
                values.Source,
                pricingCompareRecord.Source
              )}
              {displayContent(
                "Prescription status ",
                "This field provides the prescription status of the drug in this country.",
                values.PrescriptionStatus,
                pricingCompareRecord.PrescriptionStatus
              )}
              {displayContent(
                "Reimbursement status",
                "This field provides the reimbursement status of the drug in this country.",
                values.ReimbursementStatus,
                pricingCompareRecord.ReimbursementStatus
              )}
              {displayContent(
                "Reimbursement status details",
                "This field provides any additional details about the reimbursement status of the drug.",
                values.ReimbursementStatusDetails,
                pricingCompareRecord.ReimbursementStatusDetails
              )}
            </div>
          </div>
          <div className="col-lg-9 col-12 centralContent">
            <div>
              <span style={{ fontWeight: 700 }}>Price points </span>
              <span>({values.TimepointsAvailable})</span>
            </div>
            <div className="views">
              {/* <span
                className={`section-divs ${
                  selectedDiv === "div1" ? " selected" : undefined
                }`}
                onClick={() => {
                  setSelectedDiv("div1");
                  setView("Graph");
                }}
              > */}
              <span className="iqvia-color" onClick={() => setView("Graph")}>
                Graph
              </span>
              <span>|</span>
              <span className="iqvia-color" onClick={() => setView("Table")}>
                Table
              </span>
            </div>
            {view === "Graph" && (
              <PricingChart
                listPrice={listPrice}
                manufacturerPrice={manufacturerPrice}
                retailPrice={retailPrice}
                hospitalPrice={hospitalPrice}
                dateList={dateList}
                amnogPrice={amnogPrice}
                amnogPriceChange={amnogPriceChange}
                amongPriceChangePercentage={amnogPriceChangePercentage}
              />
            )}
            {view === "Table" && (
              <PricingTable
                tableData={tableData}
                additionalField={tableAdditionalField}
              />
            )}
          </div>
          {/* <div className="col-lg-3 col-12">!</div> */}
        </div>
      </div>
    </>
  ) : (
    <Loader />
  );
};

export default PricingView;
