import React, { useEffect, useState } from "react";
import Chart from "react-apexcharts";
import { IfilterState } from "../Dashboard/filter";
import apiCall, { _userId } from "../service/apiCall";
import PostCall from "../service/postCall";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import {
  saveSearchData,
  setFilterState,
  setInitiateSearch,
  setIsSearchGridLoading,
} from "../../store/SearchSlice";
import { useGetPageMutation } from "../../store/SearchResultApiSlice";
import { Breadcrumbs, Link, Typography } from "@mui/material";
import {
  setPathwayFilter,
  setLevel,
  setPathwayData,
  setSearchParams,
  setSearchParamsState,
  setChangeFilter,
  setColumnsData,
  setMaxLevel,
} from "../../store/pathwaySlice";
import { IselectedLookup } from "../interfaces/multiselect";
import { setSelectedLookupGlobe } from "../../store/MultiSelectSlice";
import "./charts.css";
import { Spinner } from "reactstrap";
import Cookies from "js-cookie";

interface chartProp {
  ds_id: number;
  setSelectedCriteria: any;
  setPathwaySearch: any;
  pathwaySearch?: boolean;
  pathwayLevel: string[];
  pathwayFixed: boolean;
  pathwayPending: boolean;
  setPathwayPending: (val: boolean) => void;
  pathwayFilterLevel: number;
  setPathwayFilterLevel: (val: number) => void;
  currpathwayList: string[];
  defaultColumn: any;
}

export interface IPathway {
  [num: string]: IchartOptions;
}

interface IchartOptions {
  Category: string[];
  Count: number[];
}

interface Idata {
  [key: string]: string;
}

// const pathway_level = [
//   "PrimaryTherapeuticAreaIDs",
//   "PrimaryDiseaseIDs",
//   "DrugIDs",
//   "DrugIDs",
//   "CountryIDs",
//   "AssessmentStatusIDs",
// ];

const searchParamsKeys_p1 = [
  "TherapeuticAreaIDs",
  "DiseaseIDs",
  "DrugIDs",
  "DrugIDs",
  "CountryIDs",
  "AssessmentStatusIDs",
];

const searchParamsKeys_p2 = [
  "DiseaseIDs",
  "DrugIDs",
  "DrugIDs",
  "CountryIDs",
  "AssessmentStatusIDs",
];

interface IColumns {
  [key: string]: string[];
}

export default function PathwayChart(prop: chartProp) {
  const {
    ds_id,
    setSelectedCriteria,
    setPathwaySearch,
    pathwaySearch,
    pathwayLevel,
    pathwayFixed,
    pathwayPending,
    setPathwayPending,
    pathwayFilterLevel,
    setPathwayFilterLevel,
    currpathwayList,
    defaultColumn,
  } = prop;
  const pathwayData = useAppSelector((state) => state.pathway.pathwayData);
  const [searchParamsKeys, setsearchParamsKeys] = useState<string[]>([]);
  const [count, setCount] = useState<number[]>([]);
  const [categories, setCategories] = useState<string[]>([]);
  const dispatch = useAppDispatch();

  const columns = useAppSelector((state) => state.pathway.columnsData);
  //const defaultColumn = useAppSelector((state) => state.search.defaultColumn);
  const dataSourceId = useAppSelector(
    (state) => state.search.selectedDatasourceId
  );
  const pathway_filter = useAppSelector((state) => state.pathway.pathwayFilter);
  const [t_id, setT_id] = useState<string>("");
  const level = useAppSelector((state) => state.pathway.level);
  const maxLevel = useAppSelector((state) => state.pathway.maxLevel);
  const searchParams = useAppSelector((state) => state.pathway.searchParams);
  const [Info, setInfo] = useState<any>({});
  const pathwayRule = useAppSelector((state) => state.pathway.pathwayRule);
  const searchParamsState = useAppSelector(
    (state) => state.pathway.searchParamsState
  );
  const selectedLookupGlobe = useAppSelector(
    (state) => state.multiselect.selectedLookupGlobe
  );
  const dataCount = [1184, 83, 37, 16, 15, 14, 12, 11, 5, 5, 10];
  const data = {
    series: [
      {
        name: "Count of records",
        data: count,
      },
    ],
    options: {
      chart: {
        type: "bar",
        id: "basic-bar",
        height: 350,

        events: {
          click(event: any, chartContext: any, config: any) {
            // console.log("dataPointIndex",config.dataPointIndex);
            // console.log("seriesIndex",config.seriesIndex);
            // console.log(categories[config.dataPointIndex]);
            let len = searchParamsKeys.length;
            let data: Idata = {};
            let d_id = ds_id;
            if (pathwayRule === "pathway-c-1") {
              d_id = 2;
              len = 4;
            } else if (pathwayRule === "pathway-r-1") {
              d_id = 3;
              len = 5;
            }

            if (
              (dataSourceId === 2 && level + 1 === 4) ||
              (dataSourceId === 3 && level + 1 === 5)
            )
              return;
            if (config.dataPointIndex < 0 || len == level + 1) {
              return;
            }

            for (let i = 0; i < level; i++) {
              data[`level_${i}`] = pathway_filter[i];
            }

            data["UserID"] = Number(_userId).toString();
            data[`level_${level}`] = categories[config.dataPointIndex];
            let arr = [...pathway_filter];

            arr.push(categories[config.dataPointIndex]);

            const npf = pathway_filter.filter((x, i) => i <= level - 1);

            dispatch(
              setPathwayFilter([...npf, categories[config.dataPointIndex]])
            );

            //let params = {...searchParams};
            const p_key: string = `${d_id}_${level + 1}`;

            PostCall(
              `/api/pathway/get/search/${ds_id}/${pathwayRule}/${level}`,
              data
            ).then((resp) => {
              if (dataSourceId == 2) {
                if (level + 1 == 1) {
                  dispatch(setChangeFilter(1));
                } else if (level + 1 == 2) {
                  dispatch(setChangeFilter(2));
                }
              }

              if (
                currpathwayList[level] !==
                  "HTAs by ATC 4th Level Description" &&
                currpathwayList[level] !== "Trials by ATC 4th level description"
                //   &&
                // currpathwayList[level] !== "MAs by ATC 4th level description"
              ) {
                let newSelectedData: IselectedLookup[] = [
                  {
                    Id: Number(resp.data),
                    Value: categories[config.dataPointIndex],
                  },
                ];

                let newSelectedglobeData = {
                  ...selectedLookupGlobe,
                  [searchParamsKeys[level]]: newSelectedData,
                };

                dispatch(setSelectedLookupGlobe(newSelectedglobeData));
              }
              // if ( currpathwayList[level] !== "MAs by ATC 4th level description"){
              //   console.log()
              // }

              setT_id(resp.data);

              let params = { ...searchParams };
              params[searchParamsKeys[level]] = String(resp.data);

              dispatch(
                setSearchParamsState({ ...searchParamsState, [p_key]: params })
              );

              PostCall(
                `/api/pathway/new/${d_id}/${pathwayRule}/${level + 1}`,
                params
              ).then((res) => {
                let newCount: number[] = [];
                let newGroup: string[] = [];
                let val = res.data;

                if (val.length) {
                  val.forEach((element: any) => {
                    newGroup.push(element["GrpName"]);
                    newCount.push(element["RecordCount"]);
                  });
                } else {
                  const keys = Object.keys(res.data);
                  for (let i = 0; i < 10; i++) {
                    if (val[keys[i]]) {
                      newGroup.push(keys[i]);
                      newCount.push(val[keys[i]]);
                    }
                  }
                }

                dispatch(
                  setPathwayData({
                    ...pathwayData,
                    [p_key]: { Category: newGroup, Count: newCount },
                  })
                );

                dispatch(setLevel(level + 1));
                dispatch(setMaxLevel(level + 1));

                setCount(newCount);
                setCategories(newGroup);

                onSearch(d_id, params, resp.data);
                dispatch(
                  setSearchParams({
                    ...searchParams,
                    [searchParamsKeys[level]]: String(resp.data),
                  })
                );
                setPathwaySearch(false);
                setPathwayPending(true);
              }) .catch((error) => {
                console.log("Error", error);
              });
            });
          },
        },
        toolbar: {
          show: true,
          tools: {
            download: false, // <== line to add
          },
        },
        zoom: {
          enabled: true,
        },
      },
      plotOptions: {
        bar: {
          horizontal: true,
          //borderRadius: 0,

          dataLabels: {
            position: "top",
          },
        },
      },
      dataLabels: {
        enabled: true,
        style: {
          colors: [
            function ({ seriesIndex, dataPointIndex, w }: any) {
              const max = Math.max(...count);
              if (70 > max / count[dataPointIndex]) {
                return "white";
              } else {
                // "rgb(0, 170, 231)";
                return "rgb(0, 170, 231)";
              }
            },
          ],
        },
        offsetX: -15
      },
      xaxis: {
        categories: categories,
        // axisBorder: { show: true },
        // axisTicks: { show: false },
        // labels: { show: false },
      },

      yaxis: {
        labels: {
          style:{fontFamily:"Noto Sans",fontSize:"13px"},
          minWidth: "50px",
          maxWidth: "100px",
        },
      },

      colors: ["rgb(0, 170, 231)"],
      grid: {
        yaxis: {
          lines: {
            show: false,
          },
        },
        xaxis: {
          lines: {
            show: true, //or just here to disable only x axis grids
          },
        },
      },
    },
  };

  const onSearch = (
    d_id: number,
    searchParams_: any,
    id?: any,
    lvl?: number
  ) => {
    let lvl_ = level;
    if (lvl) lvl_ = lvl;

    let filterState: IfilterState = {};
    const keys = Object.keys(searchParams_);

    if (keys.length > 0) {
      for (let i = 0; i < lvl_ + 1; i++) {
        if (!filterState[keys[i]]) {
          filterState[keys[i]] = searchParams_[keys[i]];
        } else {
          filterState[keys[i]] =
            filterState[keys[i]] + "," + searchParams_[keys[i]];
        }
      }
    }

    if (id != 0 && id) {
      filterState[searchParamsKeys[level]] = String(id);
    }

    setSelectedCriteria(filterState);
    dispatch(setFilterState(filterState));
    // dispatch setInitiateSearch and setIsSearchGridLoading
    dispatch(setInitiateSearch(true));
    dispatch(setIsSearchGridLoading(true));
    initiateSearchResult(filterState, d_id);
  };

  const initiateSearchResult = (
    filterState: IfilterState,
    dataSourceId: number
  ) => {
    GetSearchData({
      searchTerm: "",
      datasourceId: dataSourceId,
      filterData: filterState,
      visibleColumns: columns[ds_id],
      pathway: true,
    });
  };

  const [getPage] = useGetPageMutation();

  const GetSearchData = async (payload: any) => {
    const searchResult = await getPage(payload);
    dispatch(saveSearchData(searchResult));
    setPathwaySearch(true);
  };

  useEffect(() => {
    setCount([]);
    setCategories([]);

    let s_p_keys = searchParamsKeys_p1;
    if (pathwayRule === "pathway-2" && ds_id === 1) {
      s_p_keys = searchParamsKeys_p2;
    }
    setsearchParamsKeys(s_p_keys);

    let d_id = ds_id;
    if (pathwayRule === "pathway-c-1") d_id = 2;
    else if (pathwayRule === "pathway-r-1") d_id = 3;

    if (d_id !== 0 && pathwayRule) {
      const keys = Object.keys(pathwayData);
      const p_key: string = `${d_id}_${level}`;

      if (keys.length > 0 && keys.includes(String(p_key))) {
        setCount(pathwayData[p_key].Count);
        setCategories(pathwayData[p_key].Category);
        setPathwayPending(true);
        if (level == 0) dispatch(setSearchParams({}));
      } else {
        if (level == 0) {
          dispatch(setPathwayFilter([]));
          dispatch(setSearchParams({}));
          PostCall(`/api/pathway/new/${d_id}/${pathwayRule}/${level}`, {})
            .then((repsonse) => {
              let newCount: number[] = [];
              let newGroup: string[] = [];

              repsonse?.data.forEach((element: any) => {
                newGroup.push(element["GrpName"]);
                newCount.push(element["RecordCount"]);
              });

              dispatch(
                setPathwayData({
                  ...pathwayData,
                  [p_key]: { Category: newGroup, Count: newCount },
                })
              );

              setCount(newCount);
              setCategories(newGroup);
              setPathwayPending(true);
            })
            .catch((error) => {
              console.log("Error", error);
            });
        }
      }
    }
  }, [ds_id]);

  useEffect(() => {
    if (pathwayFilterLevel != 7 && pathwayFilterLevel <= maxLevel) {
      const p_key: string = `${ds_id}_${pathwayFilterLevel}`;
      //const n_pf = pathway_filter.filter((el,index) => index < pathwayFilterLevel);
      setCount(pathwayData[p_key].Count);
      setCategories(pathwayData[p_key].Category);

      // dispatch(setPathwayFilter(n_pf));
      // setPathwayPending(true);
      dispatch(setLevel(pathwayFilterLevel));
      if (pathwayFilterLevel === 0) {
        dispatch(setInitiateSearch(false));
      }
      if (pathwayFilterLevel !== 0) {
        onSearch(ds_id, searchParamsState[p_key], 0, pathwayFilterLevel);
        dispatch(setSearchParams(searchParamsState[p_key]));
      }
      removeFilters(pathwayFilterLevel);
      dispatch(setSearchParams({}));
    }
  }, [pathwayFilterLevel]);

  useEffect(() => {
    dispatch(setColumnsData({ ...columns, [ds_id]: defaultColumn }));
  }, [defaultColumn]);

  const removeFilters = (lvl: number) => {
    let selectedLookupGlobe_ = { ...selectedLookupGlobe };
    const newObj = searchParamsKeys.filter((el, i) => i >= lvl);
    newObj.forEach((el) => delete selectedLookupGlobe_[el]);
    dispatch(setSelectedLookupGlobe(selectedLookupGlobe_));
  };

  const levelChange = (lvl: number) => {
    if (level - 1 == lvl) {
      return;
    }

    if (ds_id === 2) {
      if (lvl === 0) {
        dispatch(setChangeFilter(1));
      }
    }

    let d_id = ds_id;
    if (pathwayRule === "pathway-c-1") d_id = 2;
    else if (pathwayRule === "pathway-r-1") d_id = 3;
    const p_key: string = `${d_id}_${lvl + 1}`;
    setCount(pathwayData[p_key].Count);
    setCategories(pathwayData[p_key].Category);
    onSearch(d_id, searchParamsState[p_key]);
    dispatch(setLevel(lvl + 1));
    setPathwayFilterLevel(7);

    // let p_filter = [...pathway_filter].slice(0,lvl+1);
    // dispatch(setPathwayFilter(p_filter));
    dispatch(setSearchParams(searchParamsState[p_key]));
    removeFilters(lvl + 1);
  };

  return (
    <>
      {pathwayPending ? (
        <>
          {!!pathway_filter && pathway_filter.length > 0 && level - 1 >= 0 && (
            <>
              <div className="row" style={{ paddingLeft: "15px" }}>
                <p>pathway : </p>
                <Breadcrumbs aria-label="breadcrumb">
                  {pathway_filter
                    .filter((x, i) => i <= level - 1)
                    .map((crumb, idx) => (
                      <Link
                        key={idx}
                        className={crumb}
                        color={
                          level - 1 == idx ? "inherit" : "rgb(0, 170, 231)"
                        }
                        //value = {idx}
                        onClick={(e) => levelChange(idx)}
                      >
                        {crumb}
                      </Link>
                    ))}
                </Breadcrumbs>
              </div>
            </>
          )}

          <div id="chart" style={{ paddingLeft: "10%" }}>
            <Chart
              options={data.options}
              series={data.series}
              type="bar"
              height={350}
              width={700}
            />
          </div>
        </>
      ) : (
        <>
          <div style={{ paddingLeft: "480px", minHeight: "300px" }}>
            <Spinner
              style={{ alignContent: "center" }}
              size="sm"
              variant="Info"
            />
            <span style={{ padding: "5px" }}>Loading</span>{" "}
          </div>
        </>
      )}
    </>
  );
}
