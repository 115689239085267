import React from 'react';
import ConstructionIcon from '@mui/icons-material/Construction';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import HomeIcon from '@mui/icons-material/Home';

const RecordNotFound = () => {
return (
    <div className='well' style={{  padding: '4%'}}>
        <h1 className="grey"><SearchOutlinedIcon className="blue bigger-125" fontSize='large' />Record Not Found</h1>
        <hr />
        <h4 className="grey" style={{ textAlign: 'left'}}>
            The record you are searching for could not be found, or you have not been granted access. Please contact the system administrator.
        </h4>
        <hr />
        <div className='center'>
            <a href="javascript:history.back()" className="btn btn-grey" style={{ borderRadius: "0px", color: "#FFF"}}>
                <ArrowBackIcon style={{ marginBottom: "6px"}} />
                &nbsp;Go Back
            </a>
            &nbsp;
            <a href="/" className="btn btn-primary" style={{ borderRadius: "0px", color: "#FFF", backgroundColor: "#478fca"}}>
                <HomeIcon style={{ marginBottom: "6px"}} />
                &nbsp;Return to the Home Page
            </a>
        </div>
    </div>
);
}

export default RecordNotFound;