import React, { useEffect, useState } from "react";
import TreeView from "@mui/lab/TreeView";
import TreeItem from "@mui/lab/TreeItem";
import { styled } from "@mui/material/styles";
import CropSquareIcon from "@mui/icons-material/CropSquare";
import CheckBoxOutlinedIcon from "@mui/icons-material/CheckBoxOutlined";
import OpenInFullOutlinedIcon from "@mui/icons-material/OpenInFullOutlined";
import CircularProgress from "@mui/material/CircularProgress";
import Loader from "../../../utils/Loader";

interface DiseaseTreeViewProps {
  data: any[];
}

const DiseaseTreeView = (props: DiseaseTreeViewProps) => {
  const { data } = props;
  const [treeData, setTreeData] = useState<any[]>();
  const [searchTerm, setSearchTerm] = useState("");
  const [expandAll, setExpandAll] = useState(false);
  const [selectAll, setSelectAll] = useState(false);
  const [expanded, setExpanded] = useState<string[]>([]);
  const [idList, setIdList] = useState<string[]>([]);
  const [expandLoad, setExpandLoad] = useState<boolean>(false);
  const [dataByLevel, setDataByLevel] = useState<any>({
    therapy: [],
    disease: [],
    subtype1: [],
    subtype2: [],
    subtype3: [],
    subtype4: [],
  });
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    setLoading(true);
    setTreeData(convertDataToTree(data, null, 0, "", true));
    setSelectAll(true);
    retrieveAllIds(data);
    setLoading(false);
  }, [data]);

  const CustomTreeItem = styled(TreeItem)(({ theme, level }) => ({
    marginLeft: `${level * 45}px`, // Adjust the indentation calculation here
  }));

  const convertDataToTree = (
    data: any,
    parentId = null,
    level = 0,
    searchTerm = "",
    checked = true
  ) => {
    const treeNodes = data.filter(
      (node: any) =>
        node.ParentID === parentId &&
        node.Name.toLowerCase().includes(searchTerm.toLowerCase())
    );

    // console.log("data", treeNodes, level);

    return treeNodes.map((node: any, index: number) => (
      <CustomTreeItem
        key={node.ID}
        nodeId={node.ID.toString()}
        label={
          <label>
            {checked || node.checked ? (
              <CheckBoxOutlinedIcon
                style={{
                  color: "blue",
                }}
              />
            ) : (
              <CropSquareIcon
                style={{
                  color: "blue",
                }}
              />
            )}
            {node.Name}
          </label>
        }
        level={level}
      >
        {convertDataToTree(data, node.ID, level + 1, searchTerm, checked)}
      </CustomTreeItem>
    ));
  };

  const handleSearch = (event: any) => {
    setSearchTerm(event.target.value);
    setTreeData(convertDataToTree(data, null, 0, event.target.value));
  };

  const handleExpandCollapseAll = () => {
    setExpandLoad(true);
    setExpandAll(!expandAll);
    setExpanded(expandAll ? [] : idList);
    setExpandLoad(false);
  };

  const handleSelectUnselectAll = (value: string) => {
    if (value === "false") {
      setSelectAll(false);
      setTreeData(convertDataToTree(data, null, 0, searchTerm, false));
    } else {
      setSelectAll(true);
      setTreeData(convertDataToTree(data, null, 0, searchTerm, true));
    }
  };

  const retrieveAllIds = (data: any) => {
    let ids: string[] = [];
    let arrays: string[][] = [[], [], [], [], [], []];

    let pushedAny = true;

    while (pushedAny) {
      pushedAny = false;

      data.forEach((item: any) => {
        const { ID, ParentID } = item;
        // All IDs for expand element
        if (!ids.includes(String(ID))) ids.push(String(ID));

        // IDs for each label
        if (ParentID === null && !arrays[0].includes(String(ID))) {
          arrays[0].push(String(ID)); // Push to array1
          pushedAny = true;
        } else {
          for (let i = 1; i < arrays.length; i++) {
            if (
              arrays[i - 1].some(
                (parentItem: any) => String(parentItem) === String(ParentID)
              ) &&
              !arrays[i].includes(String(ID))
            ) {
              arrays[i].push(String(ID)); // Push to subsequent arrays
              pushedAny = true;
              break;
            }
          }
        }
      });
    }

    // console.log("arrays", arrays);

    setIdList(ids);
    setDataByLevel({
      therapy: arrays[0],
      disease: arrays[1],
      subtype1: arrays[2],
      subtype2: arrays[3],
      subtype3: arrays[4],
      subtype4: arrays[5],
    });
  };

  const expandByLabel = (label: string) => {
    let tmp: string[] = [];
    if (label === "therapy") {
      tmp = [];
    } else if (label === "disease") {
      tmp.push(...dataByLevel.therapy);
    } else if (label === "subtype1") {
      tmp.push(...dataByLevel.therapy, ...dataByLevel.disease);
    } else if (label === "subtype2") {
      tmp.push(
        ...dataByLevel.therapy,
        ...dataByLevel.disease,
        ...dataByLevel.subtype1
      );
    } else if (label === "subtype3") {
      tmp.push(
        ...dataByLevel.therapy,
        ...dataByLevel.disease,
        ...dataByLevel.subtype1,
        ...dataByLevel.subtype2
      );
    } else if (label === "subtype4") {
      tmp.push(
        ...dataByLevel.therapy,
        ...dataByLevel.disease,
        ...dataByLevel.subtype1,
        ...dataByLevel.subtype2,
        ...dataByLevel.subtype3
      );
    }
    // console.log("tmp", tmp);
    setExpanded(tmp);
  };

  return !loading ? (
    <div style={{ padding: "8px 12px",overflowY:"Scroll",width:"950px",maxWidth:"1000px",height:"250px"}}>
      <div className="categorieContainer">
        <span
          className="therapy"
          style={{ cursor: "pointer" }}
          onClick={() => expandByLabel("therapy")}
        >
          {" "}
          Therapy{" "}
        </span>
        <span
          className="disease"
          style={{ cursor: "pointer" }}
          onClick={() => expandByLabel("disease")}
        >
          {" "}
          Primary disease{" "}
        </span>
        <span
          style={{ marginLeft: 30, cursor: "pointer" }}
          className="subtype1"
          onClick={() => expandByLabel("subtype1")}
        >
          Disease subtype 1{" "}
        </span>
        <span
          style={{ marginLeft: 40, cursor: "pointer" }}
          className="subtype2"
          onClick={() => expandByLabel("subtype2")}
        >
          Disease subtype 2{" "}
        </span>
        <span
          style={{ marginLeft: 40, cursor: "pointer" }}
          className="subtype3"
          onClick={() => expandByLabel("subtype3")}
        >
          Disease subtype 3{" "}
        </span>
        <span
          style={{ marginLeft: 90, cursor: "pointer" }}
          className="subtype4"
          onClick={() => expandByLabel("subtype4")}
        >
          Disease subtype 4{" "}
        </span>
      </div>
      <div className="treeContainer">
        <div className="inputTree">
          <input
            type="text"
            placeholder="Filter"
            value={searchTerm}
            onChange={handleSearch}
          />
        </div>
        <div className="filter">
          <div style={{ marginLeft: 30, marginBottom: 5 }}>
            {selectAll ? (
              <CheckBoxOutlinedIcon
                onClick={() => handleSelectUnselectAll("false")}
                style={{
                  color: "blue",
                }}
              />
            ) : (
              <CropSquareIcon
                onClick={() => handleSelectUnselectAll("true")}
                style={{
                  color: "blue",
                }}
              />
            )}
            <span>All</span>
          </div>
          <div className="expandIcon">
            {!expandLoad ? (
              <OpenInFullOutlinedIcon
                style={{ fontSize: 14, cursor: "pointer" }}
                onClick={handleExpandCollapseAll}
              />
            ) : (
              <CircularProgress size={14} />
            )}
          </div>
        </div>
        {treeData && treeData.length > 0 ? (
          <TreeView
            aria-label="file system navigator"
            sx={{ height: 400, flexGrow: 1, maxWidth: 600, overflowY: "auto" }}
            expanded={expanded}
          >
            {treeData}
          </TreeView>
        ) : (
          <div>No items found</div>
        )}
      </div>
    </div>
  ) : (
    <Loader />
  );
};

export default DiseaseTreeView;
