import React, { useEffect } from "react";

const InternalServerErrorPage = (error: any) => {
    useEffect(() => {
        document.title = "Error - IQVIA HTAA";
    }, []);
  return (
      <>
          <div className="error-container">
              <div className='well'>
                  <h1 style={{ textAlign: 'left',fontSize: "31px", color: "#777777", fontWeight: "lighter" }}>
                  Internal server error</h1>
                  <p>Something went wrong on our end. Please try again later.</p>
              </div>
          </div>
      </>
      
  );
  }

export default InternalServerErrorPage;
