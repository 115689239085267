import React, { useEffect, useState } from "react";
import axios from "axios";
import { ILookupData } from "../Popup/NewModal";
import axiosInstance from "./axiosInstance";

const _token = sessionStorage.getItem("token");

async function fetchComplexLookup(
  lookup_id: number,
  datasource_id: any,
  Country_ID: number,
  Drug_ID: number,
  Disease_ID? : string,
  CountryIds?: number[],
  DrugIds?:number[],
  PricingIds?:number[]
) {

  const data = {
    Country_ID: Country_ID,
    Drug_ID: Drug_ID,
    Disease_ID: Disease_ID,
    CountryIds: CountryIds,
    DrugIds: DrugIds,
    PricingIds: PricingIds
  }
  const response = await axiosInstance.post(`/api/lookup/${lookup_id}/${datasource_id}`,data) ;

  let key = lookup_id > 192 && lookup_id < 199 ? "id" : "ID";
  console.log(response.data);
  let lookup_item: ILookupData[] = response.data.map((row: any) => ({
    ID: row[key],
    Lookup_Item_Parent_ID: row["Lookup_Item_Parent_ID"],
    Parent_ID: row["Parent_ID"],
    SortOrder: row["SortOrder"],
    Value: row["Value"],
    isChecked: false,
  }));

  return lookup_item;
}

export default fetchComplexLookup;
