import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { formatDate, addSpaceBetweenWords } from "../../../utils/index";
import { useAppSelector } from "../../../store/hooks";

interface GeneralInformationProps {
  recordName: string[];
}

const GeneralInformation = (props: GeneralInformationProps) => {
  // const [GeneralInformationData] = useState(useAppSelector((state) => state.productProfile.drugInfo));
  const state = useAppSelector((state) => state)
  const [GeneralInformationData] = useState(state.productProfile.drugInfo);
  const { recordName } = props;

  // console.log(GeneralInformationData);

  // Methods :
  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
      backgroundColor: " #ECECEC",
    },
    // hide last border
    "&:last-child td, &:last-child th": {
      border: 0,
    },
  }));

  const StyledTableCellThead = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "white",
      color: theme.palette.common.black,
      borderTop: "1px solid #f4f4f4",
      borderBottom: "1px solid #000",
      fontWeight: 700,
      fontSize: 14,
    },
  }));

  const StyledTableCellBody = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      fontSize: 15,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 13,
    },
  }));

  return (
    <div className="GeneralInformation">
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 700 }} aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCellThead width="255px">Parameter</StyledTableCellThead>
              {recordName.map((name: string, index: number) => (
                <React.Fragment key={index}>
                  {GeneralInformationData.length >= index + 1 && (
                    <StyledTableCellThead>
                      Details of {name}
                    </StyledTableCellThead>
                  )}
                </React.Fragment>
              ))}
              {/* <StyledTableCellThead>
                Details of {recordName[0]}
              </StyledTableCellThead> */}
            </TableRow>
          </TableHead>
          <TableBody>
            <React.Fragment>
              <StyledTableRow>
                <StyledTableCellBody>
                  <span style={{ fontWeight: 700, fontSize: 13 }}>
                    Corporation
                  </span>
                </StyledTableCellBody>
                {GeneralInformationData.map((item: any, key: number) => (
                  <StyledTableCellBody key={key}>
                    {item.Corporation}
                  </StyledTableCellBody>
                ))}
              </StyledTableRow>
              <StyledTableRow>
                <StyledTableCellBody>
                  <span style={{ fontWeight: 700, fontSize: 13 }}>
                    Molecule name
                  </span>
                </StyledTableCellBody>
                {GeneralInformationData.map((item: any, key: number) => (
                  <StyledTableCellBody key={key}>
                    {item.MoleculeName}
                  </StyledTableCellBody>
                ))}
              </StyledTableRow>
              <StyledTableRow>
                <StyledTableCellBody>
                  <span style={{ fontWeight: 700, fontSize: 13 }}>Brand name</span>
                </StyledTableCellBody>
                {GeneralInformationData.map((item: any, key: number) => (
                  <StyledTableCellBody key={key}>
                    {item.Brand}
                  </StyledTableCellBody>
                ))}
              </StyledTableRow>
              <StyledTableRow>
                <StyledTableCellBody>
                  <span style={{ fontWeight: 700, fontSize: 13 }}>
                    Drug type
                  </span>
                </StyledTableCellBody>
                {GeneralInformationData.map((item: any, key: number) => (
                  <StyledTableCellBody key={key}>
                    {item.DrugType}
                  </StyledTableCellBody>
                ))}
              </StyledTableRow>
              <StyledTableRow>
                <StyledTableCellBody>
                  <span style={{ fontWeight: 700, fontSize: 13 }}>ATC code</span>
                </StyledTableCellBody>
                {GeneralInformationData.map((item: any, key: number) => (
                  <StyledTableCellBody key={key}>
                    {item.ATC}
                  </StyledTableCellBody>
                ))}
              </StyledTableRow>
              <StyledTableRow>
                <StyledTableCellBody>
                  <span style={{ fontWeight: 700, fontSize: 13 }}>
                    First launch date
                  </span>
                </StyledTableCellBody>
                {GeneralInformationData.map((item: any, key: number) => (
                  <StyledTableCellBody key={key}>
                    {formatDate(item.FirstLaunchDate)}
                  </StyledTableCellBody>
                ))}
              </StyledTableRow>
            </React.Fragment>
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default GeneralInformation;
