import axiosInstance from "./axiosInstance";

export const postAttachement = async (url : string, formData: any) => {
      const data = formData;
      const response =  await axiosInstance.post(process.env.REACT_APP_API_URL + url,data,{
            headers: {
                  "Content-Type": "multipart/form-data",
            },
      }) 
      if (response && response.status && response.status === 201) {
            return response
      }
      return response;
}