import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFilter,
  faFlask,
  faEarthAmericas,
  faSitemap,
  faInfo,
  faRoad,
  faSquarePlus,
  faBuildingColumns,
  faSquareH,
  faChartColumn,
  faChartLine,
  faNewspaper,
} from "@fortawesome/free-solid-svg-icons";
import NewspaperIcon from '@mui/icons-material/Newspaper';
import GeneralInformation from "./Components/GeneralInformation";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import CircularProgress from "@mui/material/CircularProgress";
import NewspaperSharpIcon from '@mui/icons-material/NewspaperSharp';
import News from "./Components/News";
import ClinicalData from "./Components/ClinicalData";
import RegulatoryData from "./Components/RegulatoryData";
import FilterModal from "./Components/FilterModal";
import Milestones from "./Components/Milestones";
import HTASummary from "./Components/HTASummary";
import HTAAnalysis from "./Components/HTAAnalysis";
import Pricing from "./Components/Pricing";
import PricingAnalysis from "./Components/PricingAnalysis";

interface ProductProfilContentProps {
  FiltersList: any[];
  SummaryList: any[];
  recordName: string[];
  fetchRelatedData: Function;
}

const ProductProfilContent = (props: ProductProfilContentProps) => {
  const { FiltersList, SummaryList, recordName, fetchRelatedData } = props;

  const state = useAppSelector((state: any) => state);
  const [relatedProducts] = useState<any[]>(
    state.productProfile.relatedProducts
  );
  const [currentView, setCurrentView] = useState<string>("General information");
  const [showModal, setShowModal] = useState<boolean>(false);
  const [modalTitle, setModalTitle] = useState<string>("");
  const [modalDataSelector, setModalDataSelector] = useState<number>(0);

  // State Management :
  const dispatch = useAppDispatch();

  // Methods :
  const getIcon = (value: any) => {
    return <FontAwesomeIcon icon={value.icon} name={value.name} />;
  };
  const getNewsIcon = () => {
    return <NewspaperSharpIcon style={{marginLeft:"-2px",fontSize:"16px"}}></NewspaperSharpIcon>
  };

  const changeView = (value: string) => {
    setCurrentView(value);
  };

  const selectFilter = (value: string) => {
    console.log(value);
    if (value === "Country") {
      console.log("Country");
      setModalTitle("Country");
      setShowModal(true);
      setModalDataSelector(2);
    } else if (value === "Related products") {
      console.log("Related products");
      setModalTitle("Related products");
      setShowModal(true);
      setModalDataSelector(1);
    } else if (value === "Disease hierarchy") {
      console.log("Disease hierarchy");
      setModalTitle("Disease hierarchy");
      setShowModal(true);
      setModalDataSelector(3);
    }
  };

  // create a switch function that take a string and return the corresponding icon
  const getIconFromName = (name: string) => {
    switch (name) {
      case "Related products":
        return getIcon({ icon: faFlask, name: "Related products" });
      case "Country":
        return getIcon({ icon: faEarthAmericas, name: "Country" });
      case "Disease hierarchy":
        return getIcon({ icon: faSitemap, name: "Disease hierarchy" });
      case "General information":
        return getIcon({ icon: faInfo, name: "General information" });
      case "Milestones":
        return getIcon({ icon: faRoad, name: "Milestones" });
      case "Clinical":
        return getIcon({ icon: faSquarePlus, name: "Clinical" });
      case "Regulatory":
        return getIcon({ icon: faBuildingColumns, name: "Regulatory" });
      case "HTA summary":
        return getIcon({ icon: faSquareH, name: "HTA summary" });
      case "HTA analysis":
        return getIcon({ icon: faChartColumn, name: "HTA analysis" });
      case "Pricing":
        return getIcon({ icon: faChartLine, name: "Pricing" });
      case "Pricing analysis":
        return getIcon({ icon: faChartLine, name: "Pricing analysis" });
      case "News":
        return getIcon({ icon: NewspaperIcon, name: "News" });
      case "Filters":
        return getIcon({ icon: faFilter, name: "News" });
      default:
        return null;
    }
  };

  return (
    <div className="productProfilContentContainer">
      <div className="row productProfilContent">
        <div className="col-lg-2 col-12 ">
          <div className="title">
            <span className="icons">{getIconFromName("Filters")}</span>
            <span className="sectionName">Filters</span>
          </div>
          {FiltersList.map((value: any, index: number) => {
            return (
              <React.Fragment key={index}>
                {value.name !== "Related products" ? (
                  <div
                    className="section filterSection"
                    onClick={() => selectFilter(value.name)}
                  >
                    <span className="icons iqvia-color">
                      {getIconFromName(value.name)}
                    </span>
                    <span className="sectionName iqvia-color">
                      {value.name}
                    </span>
                  </div>
                ) : value.name === "Related products" &&
                  relatedProducts.length > 0 ? (
                  <div
                    className="section filterSection"
                    onClick={() => selectFilter(value.name)}
                  >
                    <span className="icons iqvia-color">
                      {getIconFromName(value.name)}
                    </span>
                    <span className="sectionName iqvia-color">
                      {value.name}
                    </span>
                  </div>
                ) : null}
              </React.Fragment>
            );
          })}

          <div
            className="title"
            style={
              FiltersList.length > 0 ? { borderTop: "1px solid #e9e9e9" } : {}
            }
          >
            <span className="sectionName">Summary</span>
          </div>
          {SummaryList.filter(x => x.id).map((value: any, index: number) => {
            return (
              <div
                key={index}
                className={
                  currentView === value.name
                    ? "section selectedSection"
                    : "section notSelectedSection iqvia-color"
                }
                onClick={() => changeView(value.name)}
                style={
                  SummaryList.length === index + 1
                    ? { borderBottom: "1px solid #e9e9e9" }
                    : {}
                }
              >
                <span className="icons" style={{fontSize:"Medium"}}>{getIconFromName(value.name)}</span>
                <span className="sectionName">{value.name}</span>
                <span>
                  {value.loadingStatus && (
                    <CircularProgress
                      size={15}
                      sx={{
                        marginLeft: "5px",
                      }}
                    />

                  )}
                </span>
              </div>
            );
          })}
        </div>
        <div className="col-lg-10 col-12">
          {currentView === "General information" && (
            <GeneralInformation recordName={recordName} />
          )}

          {currentView === "Milestones" && <Milestones />}

          {currentView === "Clinical" && <ClinicalData />}

          {currentView === "Regulatory" && <RegulatoryData />}

          {currentView === "HTA summary" && <HTASummary />}

          {currentView === "HTA analysis" && <HTAAnalysis />}

          {currentView === "Pricing" && <Pricing />}

          {currentView === "Pricing analysis" && <PricingAnalysis />}

          {currentView === "News" && <News />}
        </div>
      </div>
      <FilterModal
        open={showModal}
        handleClose={() => {
          setShowModal(false);
          setModalDataSelector(0);
        }}
        title={modalTitle}
        modalDataSelector={modalDataSelector}
        recordName={recordName}
        fetchRelatedData={fetchRelatedData}
      />
    </div>
  );
};

export default ProductProfilContent;
