import { createSlice } from "@reduxjs/toolkit";
import { PayloadAction } from "@reduxjs/toolkit";
import { emptySplitApi } from "./emptyApiSlice";
import { _token } from '../components/service/apiCall';

interface ProductProfileState{
    drugData: any[],
    userSUbscription: any[],
    drugInfo: object,
    clinicalData: any[],
    regulatoryData: any[],
    countryList: any[],
    pricingData: any[],
    diseaseHierarchy: any[],
    htaSummaryData: any[],
    allNewsData: any[],
    milestoneData: any[],
    relatedProducts: any[],
};

const initialState: ProductProfileState = {
    drugData: [],
    userSUbscription: [],
    drugInfo: {},
    clinicalData: [],
    regulatoryData: [],
    countryList: [],
    pricingData: [],
    diseaseHierarchy: [],
    htaSummaryData: [],
    allNewsData: [],
    milestoneData: [],
    relatedProducts: [],
};
  
export const ProductProfileSlice = createSlice({
    name: "ProductProfile",
    initialState,
    reducers: {
        setDrugData: (state: ProductProfileState, action: PayloadAction<any>) => {
            state.drugData = action.payload;
        },
        setUserSUbscription: (state: ProductProfileState, action: PayloadAction<any>) => {
            state.userSUbscription = action.payload;
        },
        setDrugInfo: (state: ProductProfileState, action: PayloadAction<any>) => {
            state.drugInfo = action.payload;
        },
        setClinicalData: (state: ProductProfileState, action: PayloadAction<any>) => {
            state.clinicalData = action.payload;
        },
        setRegulatoryData: (state: ProductProfileState, action: PayloadAction<any>) => {
          state.regulatoryData = action.payload;
        },
        setCountryList: (state: ProductProfileState, action: PayloadAction<any>) => {
          state.countryList = action.payload;
        },
        setPricingData: (state: ProductProfileState, action: PayloadAction<any>) => {
          state.pricingData = action.payload;
        },
        setDiseaseHierarchy: (state: ProductProfileState, action: PayloadAction<any>) => {
          state.diseaseHierarchy = action.payload;
        },
        setHtaSummaryData: (state: ProductProfileState, action: PayloadAction<any>) => {
          state.htaSummaryData = action.payload;
        },
        setAllNewsData: (state: ProductProfileState, action: PayloadAction<any>) => {
          state.allNewsData = action.payload;
        }, 
        setMilestoneData: (state: ProductProfileState, action: PayloadAction<any>) => {
          state.milestoneData = action.payload;
        },
        setRelatedProducts: (state: ProductProfileState, action: PayloadAction<any>) => {
          state.relatedProducts = action.payload;
        }
    }
});

export const {
    setDrugData,
    setUserSUbscription,
    setDrugInfo,
    setClinicalData,
    setRegulatoryData,
    setCountryList,
    setPricingData,
    setDiseaseHierarchy,
    setHtaSummaryData,
    setAllNewsData,
    setMilestoneData,
    setRelatedProducts
} = ProductProfileSlice.actions;

export default ProductProfileSlice.reducer;


//api endpoints
export const productProfileApiSlice = emptySplitApi.injectEndpoints({
    endpoints(build) {
      return {
        fetchDrugData: build.mutation({
            query: () => ({
                url: `api/product-profile/GetDrugs`,
                method: "GET",
            }),
        }),
        fetchUserSubscription: build.mutation({
            query: () => {
              return {
                url: "api/product-profile/GetUserSubscription",
                method: "GET",
              };
            },
        }),
        fetchDrugInfo: build.mutation({
          query: ({drugID}: any) => {
              console.log("drugID", drugID)
              return {
                url: `api/product-profile/GetDrugInfo/${drugID}`,
                method: "GET",
              };
            },
        }),
        //GetCountries/:drugID
        fetchCountries: build.mutation({
          query: ({drugID}: any) => {
            return {
              url: `api/product-profile/GetCountries/${drugID}`,
              method: "GET",
            };
          },
        }),
        fetchDiseaseHierarchy: build.mutation({
          query: ({drugID}: any) => {
            return {
              url: `api/product-profile/GetDiseaseHierarchy/${drugID}`,
              method: "GET",
            };
          },
        }),
        searchClinicalData: build.mutation({
          query: ({drugID}: any) => {
            return {
              url: `api/product-profile/SearchClinicalData/${drugID}`,
              method: "GET",
            };
          },
        }),
        getPricingRecordByDrugIds: build.mutation({
          query: ({drugID}: any) => {
            return {
              url: `api/product-profile/GetPricingRecordByDrugIds/${drugID}`,
              method: "GET",
            };
          },
        }),
        searchHTA: build.mutation({
          query: ({drugID}: any) => {
            return {
              url: `api/product-profile/SearchHTA/${drugID}`,
              method: "GET",
            };
          },
        }),
        searchRegulatory: build.mutation({
          query: ({drugID}: any) => {
            return {
              url: `api/product-profile/SearchRegulatory/${drugID}`,
              method: "GET",
            };
          },
        }),
        getMilestoneData: build.mutation({
          query: ({drugIDs, diseaseIDs, countryIDs}: any) => {
            const body ={ drugIDs: drugIDs,
            diseaseIDs: diseaseIDs,
            countryIDs: countryIDs
          };
            return {
              url: `api/product-profile/GetMilestoneData`,
              body,
              method: "POST",
            };
          },
        }),
        getAllNews: build.mutation({
          query: ({drugIDs}: any) => {
            return {
              url: `api/product-profile/GetAllNews/${drugIDs}`,
              method: "GET",
            };
          },
        }),
      }
    }
});

export const {
    useFetchDrugDataMutation,
    useFetchUserSubscriptionMutation,
    useFetchDrugInfoMutation,
    useFetchCountriesMutation,
    useFetchDiseaseHierarchyMutation,
    useSearchClinicalDataMutation,
    useGetPricingRecordByDrugIdsMutation,
    useSearchRegulatoryMutation,
    useGetMilestoneDataMutation,
    useGetAllNewsMutation,
    useSearchHTAMutation,

  } = productProfileApiSlice;

