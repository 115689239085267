
import React, { useEffect,useState, useMemo } from "react";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { useGetSavedSearchAlertActiveDataMutation} from "../../store/AdminApiSlice";
import { storeSavedSearchAlertData } from "../../store/SavedSearchSlice";
import { GridReadyEvent, ColDef } from "ag-grid-community";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { setLoading } from "../../store/SavedSearchSlice";
import Spinner from "react-bootstrap/Spinner";
import TableGrid from "../Table/TableGrid";
import "./AdminSavedSearch.css";

const { useRef } = React;


const AdminSavedSearchAlert = () => {
  const dispatch = useAppDispatch();
  const resultGridRef:any = useRef();
  const searchData = useAppSelector((state) => state.savedSearch.searchData);
  const isLoading = useAppSelector((state) => state.savedSearch.loading);
  const [rowData, setRowData] = useState<any[]>();
  const [columnDefs, setColumnDefs] = useState<ColDef[]>([]);

  const dataSourceId = useAppSelector(
    (state) => state.search.selectedDatasourceId
  );


  const defaultColDef = useMemo(
    () => ({
      sortable: false,
    }),
    []
  );
  const exportCurrentView = () => {
    //if(resultGridRef.current != null)
    resultGridRef.current.exportCurrentview;
  };
  const [getSavedSearchAlertActiveData, {data: SavedSearchActiveData}] = useGetSavedSearchAlertActiveDataMutation();
  const GetSavedSearchActiveData = async () => {
    const searchResult:any = await getSavedSearchAlertActiveData({});
    dispatch(setLoading(true));
    dispatch(
      storeSavedSearchAlertData({
        data: searchResult.data,
        datasourceId: dataSourceId,
      })
    );
  };

  useEffect(() => {
    GetSavedSearchActiveData();
  }, []);

return (
<div className="main-container col-sm-12" style={{borderTop:"none",borderRight:"none"}}>
  <div></div>
  <div id="leftActionPane" className="col-md-2 leftactionpane home-height" style={{display:"none"}}></div>
  <div id="rightActionPane" className="rightactionpane col-md-12 home-height">
    <div className="right-action-pane-inner-content">
    <h2 style={{fontSize:"26px",fontWeight:"normal"}}>Manage Saved Searches</h2>
    <p><a style={{color:"rgb(0, 170, 231)"}} href="/AdminSavedSearchDeactive">View Deactivated Saved Searches</a></p>
    <div className="grid-wrapper">
<div className="AdminSavedSearch">      
<div style={{ width: "100%", height: 500 }} className="ag-theme-alpine">
  {isLoading && (
    <>
      <Spinner animation="border" variant="primary" color="info" />
      <h6>Loading data...</h6>
    </>
  )}
  {!isLoading && (
    <TableGrid
    tableName={"SavedSearchAlert"}
    ref={resultGridRef}
    rowData={searchData.rowData}
    columnDefs={searchData.colDef}
    isLoading={isLoading}
    pageSize={10}
  />
  )}
</div>
</div>
    </div>
  </div>
</div>
</div>

  );
};
export default AdminSavedSearchAlert;