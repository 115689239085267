import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { useFetchClientNewsMutation } from "../../store/ClientNewsApiSlice";
import Spinner from "react-bootstrap/Spinner";
import { format } from "date-fns";
import "./clientNews.css";
import DraggablePopover from "../Popups/Popup_MaterialUI";
import ClientNewsPopupContent from "./clientNewsPopupContent";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import Login from "../Logout/Login";
// @ts-ignore
import { convert } from "html-to-text";

// interface clientNewsProps {
//   recent: boolean;
// }

const ClientNews = () => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );
  const [popupNewsItem, setPopupNewsItem] = React.useState(null);

  const onClickHandler = (
    event: React.MouseEvent<HTMLButtonElement>,
    newsItem
  ) => {
    setAnchorEl(event.currentTarget);
    setPopupNewsItem(newsItem);
  };

  //onPopupClose

  const handleClose = () => {
    setAnchorEl(null);
  };

  const userId = sessionStorage.getItem("userid");

  const [fetchClientNews, { data, isLoading, isSuccess, error }] =
    useFetchClientNewsMutation();

  const GetClientNewsData = async () => {
    const searchResult = await fetchClientNews();
  };

  useEffect(() => {
    GetClientNewsData();
  }, []);

  let dataSourceId = useAppSelector(
    (state) => state.search.selectedDatasourceId
  );

  return (
    <div>
      <div>
        <div
          className={
            dataSourceId >= 1 && dataSourceId < 5 ? "news--title" : "news-title"
          }
        >
          <h5>
            <b>News articles</b>
          </h5>
        </div>
        {isLoading && (
          <>
            <Spinner animation="border" variant="primary" color="info" />
            <h6>Loading data...</h6>
          </>
        )}
        {error && <h6>Session expired...</h6>}
        {isSuccess && data && (
          <div>
            {data &&
              data.map((item) => {
                return (
                  <div key={item.ID}>
                    <ul>
                      <li>
                        <span
                          className="client-news-title"
                          style={{ cursor: "pointer" }}
                          onClick={(event) => {
                            onClickHandler(event, item);
                          }}
                        >
                          {item.Name}
                        </span>
                        <section>
                          <div style={{ display: "flex" }}>
                            {format(new Date(item.Date), "dd/MM/yyyy")}
                            <p
                              style={{
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                              }}
                            >
                              {item.Body !== null && (
                                <span style={{ fontSize: "11px" }}>
                                  -{convert(item.Body)}
                                </span>
                              )}
                            </p>
                            {item.Body == null && <span> -...</span>}
                          </div>
                        </section>
                      </li>
                    </ul>
                  </div>
                );
              })}
            <div style={{ marginBottom: "3rem" }}>
              <a href="/ClientNewsList" style={{ color: "rgb(0, 170, 231)" }}>
                More articles
              </a>
            </div>
          </div>
        )}
      </div>
      {anchorEl && popupNewsItem && (
        <DraggablePopover
          title=""
          anchorEl={anchorEl}
          popupBody={<ClientNewsPopupContent newsItem={popupNewsItem} />}
          buttonsArray={[]}
          btnCallbacks={[]}
          onPopupClose={handleClose}
        ></DraggablePopover>
      )}
    </div>
  );
};

export default ClientNews;
