// import * as WeatherForecasts from './WeatherForecasts';
// import * as Counter from './Counter';
// import * as AutocompleteSearch from './AutocompleteSearch';

// // The top-level state object
// export interface ApplicationState {
//     counter: Counter.CounterState | undefined;
//     weatherForecasts: WeatherForecasts.WeatherForecastsState | undefined;
//     autocompleteSearch: AutocompleteSearch.AutocompleteSearchState | undefined;
// }

// // Whenever an action is dispatched, Redux will update each top-level application state property using
// // the reducer with the matching name. It's important that the names match exactly, and that the reducer
// // acts on the corresponding ApplicationState property type.
// export const reducers = {
//     counter: Counter.reducer,
//     weatherForecasts: WeatherForecasts.reducer,
//     autocompleteSearch: AutocompleteSearch.reducer
// };

// // This type can be used as a hint on action creators so that its 'dispatch' and 'getState' params are
// // correctly typed to match your store.
// export interface AppThunkAction<TAction> {
//     (dispatch: (action: TAction) => void, getState: () => ApplicationState): void;
// }

import { Action, configureStore, Reducer, ThunkAction } from "@reduxjs/toolkit";
import { createBrowserHistory } from "history";
import { connectRouter, routerMiddleware } from "connected-react-router";
import searchResultReducer from "./SearchResultSlice";
import autocompleteSearchReducer from "./AutocompleteSearchSlice";
import userProfileReducer from "./UserProfileApiSlice";
import { emptySplitApi } from "./emptyApiSlice";
import searchReducer from "./SearchSlice";
import multiSelectReducer from "./MultiSelectSlice";
import savedSearchReducer from "./SavedSearchSlice";
import clientNewsReducer from "./ClientNewsApiSlice";
import pathwayReducer from "./pathwaySlice";
import productProfileReducer from "./ProductProfileSlice";
import auditLogReducer from "./AuditLogChangeApiSlice";
import universalValueReducer from "./UniversalValueSlice";

//export const history = createBrowserHistory();
const baseUrl = document
  .getElementsByTagName("base")[0]
  .getAttribute("href") as string;
export const history = createBrowserHistory({ basename: baseUrl });

const store = configureStore({
  reducer: {
    searchResult: searchResultReducer,
    autocompleteSearch: autocompleteSearchReducer,
    userProfile: userProfileReducer,
    search: searchReducer,
    multiselect: multiSelectReducer,
    savedSearch: savedSearchReducer,
    clientNews: clientNewsReducer,
    pathway: pathwayReducer,
    productProfile: productProfileReducer,
    audiLogChange: auditLogReducer,
    UniversalValue : universalValueReducer,
    [emptySplitApi.reducerPath]: emptySplitApi.reducer,
    router: connectRouter(history) as Reducer,
  },
  middleware: (getDefaultMiddleware) => {
    return getDefaultMiddleware({
      immutableCheck: false,
      serializableCheck: false,
    })
      .concat(emptySplitApi.middleware)
      .prepend(routerMiddleware(history));
  },
});
export default store;

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;

export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
