export const  GetDatasourceName = (_dsID: number) => {
    switch (_dsID) {
      case 1:
        return 'HTARecord';
      case 2:
        return 'ClinicalData';
      case 3:
        return 'RegulatoryWatch';
      case 4:
        return 'Pricing';
      case 5:
        return 'Policy';
      case 6:
        return 'Agency';
      case 7:
        return 'Country';
      case 20:
        return 'DrugData';
      case 23:
        return 'Agreement';
      case 25:
        return 'ReimbursementStatus';
      case 27:
        return 'TreatmentCost';
      case 200:
        return 'Home/ClientNewsArticle';
    }
  };

  export interface AutocompleteSearchDto {
    searchTerm: string;
    category: number;
    userid: number;
    roleid: number;
  }

  export default GetDatasourceName