import { EditorState } from 'draft-js';
import React, { useContext, useEffect, useState } from 'react';
import { Field } from '../../../store/DataSourceMetaData';
import apiCall from '../../service/apiCall';
import { FormFieldType } from '../../Shared/Utility';
import { IForm, UserContext } from '../Editor';
import FieldView from '../FieldView';

interface IDynamicField {
      fieldId: number;
      datasourcename: string;
      section: string;
      values: IForm;
      datasourceId: number;
      fieldChanged: (name?: string, value?: any, obj?: any, last_index_check?: boolean) => void;
      RichtextFieldChanged: (name: string, value: EditorState) => void;
      fieldCriteriaDatasourceID : number;
      dynamicField : Field;
}

const DynamicFieldForm = ({ fieldId, datasourcename, section, values, datasourceId, fieldChanged, RichtextFieldChanged,fieldCriteriaDatasourceID,dynamicField }: IDynamicField) => {

      return (
            <FieldView
                  field={dynamicField}
                  datasourcename={datasourcename}
                  section={section}
                  values={values}
                  datasourceId={fieldCriteriaDatasourceID}
                  fieldChanged={fieldChanged}
                  RichtextFieldChanged={RichtextFieldChanged}
            />
      )
}

export default DynamicFieldForm;


// interface IRenderFieldProp {
//       field : Field;
//       values : IForm;
//       fieldChanged: (name?: string, value?: any, obj?: any, last_index_check?: boolean) => void;
// }
// const RenderField = ({field,values,fieldChanged} :IRenderFieldProp) => {
//       switch(field.fieldTypeId){
//             case FormFieldType.Checkbox:
//                   return (
//                         <input
//                           type="checkbox"
//                           id={field.name}
//                           checked={values[field.name]}
//                           value={values[field.name]}
//                           onChange={(e) => {
//                               fieldChanged(field.name, e.target.checked);
//                           }}
//                           style={{marginTop: "2px",width: "16px",height: "16px",cursor:"pointer" }}
//                         ></input>
//                       );
//       }
// }



const DropdownFunc = () => {
      
}