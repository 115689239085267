import React, {useEffect, useState} from 'react';


const CustomHeader = ({ api, column }) => {
    const [checked, setChecked] = useState(false);
  
    const onChange = (e) => {
      setChecked(e.target.checked);
      if (e.target.checked) {
        api.forEachNodeAfterFilter(node => node.setSelected(true));
      } else {
        api.forEachNode(node => node.setSelected(false));
      }
    };
  
    useEffect(() => {
      const onSelectionChanged = () => {
        const allNodesSelected = api.getModel().rootNode.childrenAfterFilter.every(node => node.isSelected());
        setChecked(allNodesSelected);
      };
  
      api.addEventListener('selectionChanged', onSelectionChanged);
      return () => api.removeEventListener('selectionChanged', onSelectionChanged);
    }, [api]);
  
    return (
      <input
        type="checkbox"
        checked={checked}
        onChange={onChange}
      />
    );
  };

  export default CustomHeader;