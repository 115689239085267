import React, { useState } from "react";
import { useAppSelector } from "../../../store/hooks";
import TableGrid from "../../Table/TableGrid";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowUpRightFromSquare } from "@fortawesome/free-solid-svg-icons";
import { addDate } from "../../../utils/index";
import { AgGridReact } from "ag-grid-react";

const RegulatoryData = () => {
  const state = useAppSelector((state: any) => state);
  const [rowData] = useState<any[]>(state.productProfile.regulatoryData);
  const [showMoreList, setShowMoreList] = useState<number[]>([]);

  const toggleShowMore = (id: number) => {
    let tmp = [...showMoreList];
    if (tmp.includes(id)) {
      tmp.splice(tmp.indexOf(id), 1);
    } else {
      tmp.push(id);
    }
    setShowMoreList(tmp);
  };

  const CellRenderer = (param: any) => {
    if (param.value.length < 200) {
      return <div>{param.value}</div>;
    }
    const textToShow = showMoreList.includes(param.rowIndex)
      ? param.value
      : param.value.slice(0, 200) + "...";

    return (
      <div>
        <div>{textToShow}</div>
        {!showMoreList.includes(param.rowIndex) && (
          <div
            onClick={() => toggleShowMore(param.rowIndex)}
            className="show-btn iqvia-color"
          >
            + Show More
          </div>
        )}
        {showMoreList.includes(param.rowIndex) && (
          <div
            onClick={() => toggleShowMore(param.rowIndex)}
            className="show-btn iqvia-color"
          >
            - Show Less
          </div>
        )}
      </div>
    );
  };

  const columnDefs = [
    {
      headerName: "Approval date",
      field: "PubDate",
      cellRenderer: (params: any) => {
        const dateArray =
          params.value !== null && params.value !== undefined
            ? addDate(params.value)
            : "-";
        return (
          <span
            style={{
              textAlign: "center",
              width: "100%",
              display: "inline-block",
            }}
          >
            {" "}
            {dateArray}
          </span>
        );
      },
      maxWidth: 180,
    },
    {
      headerName: "Regulatory body",
      field: "RegulatorLIValue",
      wrapText: true,
      autoHeight: true,
      maxWidth: 180,
    },
    {
      headerName: "Disease",
      field: "DiseaseNames",
      wrapText: true,
      autoHeight: true,
      maxWidth: 180,
      cellStyle: {
        wordBreak: "break-word",
      },
    },
    {
      headerName: "Orphan designation",
      field: "isOrphan",
      wrapText: true,
      autoHeight: true,
      cellRenderer: (params: any) => {
        return params.value ? "Yes" : "No";
      },
      maxWidth: 180,
    },
    {
      headerName: "Full indication as per regulatory body",
      field: "FullIndication",
      wrapText: true,
      autoHeight: true,
      cellRendererFramework: CellRenderer,
      minWidth: 280,
      cellStyle: {
        wordBreak: "break-word",
      },
    },
    {
      headerName: "",
      field: "RegulatoryWatch_ID",
      cellRendererFramework: (params: any) => (
        <a
          href={`${process.env.REACT_APP_REDIRECT_URI}/view/3/${params.value}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          <FontAwesomeIcon icon={faArrowUpRightFromSquare} />
        </a>
      ),
      maxWidth: 50,
      width: 50,
      minWidth: 50,
    },
  ];

  return (
    <div className="regulatoryDataContainer">
      {rowData.length > 0 ? (
        <div
          className="ag-theme-alpine"
          style={{ height: "600px", width: "100%" }}
        >
          <TableGrid
            tableName={"ProductProfile"}
            columnDefs={columnDefs}
            rowData={rowData}
            isLoading={false}
            pageSize={10}
          />
        </div>
      ) : (
        <div
          className="ag-theme-alpine"
          style={{ height: "200px", width: "90%", marginTop: 10 }}
        >
          <AgGridReact
            rowData={rowData}
            columnDefs={columnDefs}
            overlayNoRowsTemplate={"No data available in table"}
          />
        </div>
      )}
    </div>
  );
};

export default RegulatoryData;
