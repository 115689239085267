import { createSlice } from "@reduxjs/toolkit";
import { PayloadAction } from "@reduxjs/toolkit";
import { emptySplitApi } from "./emptyApiSlice";

export interface UserAccountModel {
  ID: string;
  Name: string;
  Role_ID: number;
  Client_ID: number;
  Client_Group_ID: string;
}

export interface UserModel {
  ID: number;
  Name: string;
  Email: string;
  Role: string;
  Role_ID: number;
  Client: string;
  Initials: string;
  Client_ID: number;
  Client_Group_ID: string;
  DataSourceIDs: Array<number>;
  UserAccounts: Array<UserAccountModel>;
}
export interface UserProfileState {
  userProfileData: UserModel;
  accountId: number | null;
  clientId: number | null;
  userAccountData: Array<any>;
  accountType: string | null;
  userAccessData: object | null;
  isValid: boolean;
  currentRole: number | null;
  currentUserId: number | null;
  currentClientName: string;
  savedSearchCount: number;
  selectionCount: number;
  subscribedDs: string;
}

const initialState: UserProfileState = {
  userProfileData: {
    ID: 0,
    Name: "",
    Email: "",
    Role: "",
    Role_ID: 0,
    Client: "",
    Initials: "",
    Client_ID: 0,
    Client_Group_ID: "",
    DataSourceIDs: [],
    UserAccounts: [],
  },
  userAccountData: [],
  accountId: null,
  clientId: null,
  accountType: "",
  userAccessData: null,
  isValid: false,
  currentRole: null,
  currentUserId: null,
  currentClientName: "",
  savedSearchCount: 0,
  selectionCount: 0,
  subscribedDs: ""
};

export const UserProfileSlice = createSlice({
  name: "UserProfile",
  initialState,
  reducers: {
    saveUserProfileData: (
      state: UserProfileState,
      action: PayloadAction<any>
    ) => {
      state.userProfileData = action.payload;
    },
    saveAccountId: (state: UserProfileState, action: PayloadAction<number>) => {
      state.accountId = action.payload;
    },
    saveClientId: (state: UserProfileState, action: PayloadAction<number>) => {
      state.clientId = action.payload;
    },
    saveUserAccessData: (
      state: UserProfileState,
      action: PayloadAction<any[]>
    ) => {
      state.userAccessData = action.payload[0];
    },
    saveUserAccountData: (
      state: UserProfileState,
      action: PayloadAction<any>
    ) => {
      state.userAccountData = action.payload.data;
    },

    saveAccountType: (state: UserProfileState, action: PayloadAction<any>) => {
      const accountInfo = action.payload;
      // console.log("accountInfo :", accountInfo);

      const accType = accountInfo.AccountName.toString().split("-")[0].trim();
      //console.log("accType : ", accType);
      state.accountType = accType;
    },

    setIsValid: (state: UserProfileState, action: PayloadAction<boolean>) => {
      state.isValid = action.payload;
    },
    saveCurrentClientName: (
      state: UserProfileState,
      action: PayloadAction<string>
    ) => {
      state.currentClientName = action.payload;
    },
    saveCurrentRole: (
      state: UserProfileState,
      action: PayloadAction<number>
    ) => {
      state.currentRole = action.payload;
    },
    saveCurrentUserId: (
      state: UserProfileState,
      action: PayloadAction<number>
    ) => {
      state.currentUserId = action.payload;
    },
    saveSavedSearchCount: (
      state: UserProfileState,
      action: PayloadAction<number>
    ) => {
      state.savedSearchCount = action.payload;
    },
    savedSelectionCount: (
      state: UserProfileState,
      action: PayloadAction<number>
    ) => {
      state.selectionCount = action.payload;
    },
    saveSubscribedDs: (
      state: UserProfileState,
      action: PayloadAction<string>
    ) => {
      state.subscribedDs = action.payload;
    },
    saveUser: (state: UserProfileState, action: PayloadAction<any>) => {
      state.userProfileData.Email = action.payload.email;
      state.userProfileData.Name =
        action.payload.first_name + " " + action.payload.last_name;
      state.userProfileData.ID = action.payload.userid;
      state.userProfileData.Client_ID = action.payload.clientid;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  saveUserProfileData,
  saveAccountId,
  saveClientId,
  saveAccountType,
  saveUserAccountData,
  saveUserAccessData,
  setIsValid,
  saveCurrentClientName,
  saveCurrentRole,
  saveCurrentUserId,
  saveSavedSearchCount,
  savedSelectionCount,
  saveUser,
  saveSubscribedDs,
} = UserProfileSlice.actions;

export default UserProfileSlice.reducer;

export const userApiSlice = emptySplitApi.injectEndpoints({
  endpoints(build:any) {
    return {
      fetchUserProfile: build.mutation({
        query: ({ userEmail }: any) => ({
          url: `user/${userEmail}`,
          method: "GET",
        }),
      }),
      fetchUserAccountId: build.mutation({
        query: ({ userId, roleId, clientId }: any) => ({
          url: `api/user/GetAccountId/${userId}/${roleId}/${clientId}`,
          method: "GET",
        }),
      }),
      fetchUserAccessDetails: build.mutation({
        query: (args: any) => {
          const { userId } = args;
          return {
            url: `api/user/LatestUserAccess/${userId}`,
            method: "GET",
          };
        },
      }),

      sendSupportEmail: build.mutation({
        query: (body: any) => {
          return {
            url: `api/email/send`,
            method: "POST",
            data : body,
          };
        },
      }),
    };
  },
});

export const {
  useFetchUserProfileMutation,
  useFetchUserAccountIdMutation,
  useFetchUserAccessDetailsMutation,
  useSendSupportEmailMutation,
} = userApiSlice;
