import { EditorState } from "draft-js";
import React, { useContext } from "react";
import { Field } from "../../../../store/DataSourceMetaData";
import FieldView from "../../FieldView";
//import InfoIcon from "@mui/icons-material/Info";
import { FaInfoCircle } from "react-icons/fa";
import { UserContext } from "../../Editor";
import DoubleArrowIcon from "@mui/icons-material/DoubleArrow";
import { RiDeleteBinLine } from "react-icons/ri";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import {
  setSelectedLookupGlobe,
} from "../../../../store/MultiSelectSlice";
import apiCall from "../../../service/apiCall"

interface IReimbursementStatus_ProductStrengthForm {
  field: Field;
  values: any;
  fieldChanged: (name?: string, value?: string, obj?: any) => void;
  childRows: Field[];
  validateCriteria: (field: Field) => boolean;
  RichtextFieldChanged: (name: string, value: EditorState) => void;
  datasourcename: string;
  section: any;
  datasourceId: number;
  masterFieldTypes: number[];
  openModal: (ID: number, Name: string, title: string) => void;
}

export const ReimbursementStatus_ProductStrengthFields = [
  "ReimbursementStatus_ProductStrength.ProductFormulation",
  "ReimbursementStatus_ProductStrength.AdministrationRoute",
  "ReimbursementStatus_ProductStrength.Strength",
  "ReimbursementStatus_ProductStrength.Status",
];

export const ReimbursementStatus_ProductStrengthDateFields = [
  "ReimbursementStatus_ProductStrength.StartDate",
  "ReimbursementStatus_ProductStrength.EndDate",
];

export const group2Info = [{
  label : "Reimbursement start date",
  datasource : "Reimbursment status",
  section: "intervention",
  description : "Data entry instructions : Select the reimbursement start date for each dose/unit, if available."
}, {
  label : "Reimbursement end date",
  datasource : "Reimbursment status",
  section: "intervention",
  description : "Data entry instructions : Select the reimbursement end date for each dose/unit, if available/ applicable."
}]


interface Iprop {
      datasourcename: string,
      section: string,
      label: string,
      description: any,
      values: any,
      fId: number
}

const TableDescription = `
    <label>Data entry instructions : Select the reimbursement status</label>
      <table style="width:100%;border: 1px solid black;border-collapse: collapse;">
          <tr style="border: 1px solid black;border-collapse: collapse;">
            <td style="border: 1px solid black;border-collapse: collapse;">Fully reimbursed</td>
            <td style="border: 1px solid black;border-collapse: collapse;"> All dose/units fully reimbursed for all indications/populations as per MA</td>
          </tr>
          <tr style="border: 1px solid black;border-collapse: collapse;">
            <td style="border: 1px solid black;border-collapse: collapse;">Not reimbursed</td>
            <td style="border: 1px solid black;border-collapse: collapse;">Drug not reimbursed</td>
          <tr/>
          <tr style="border: 1px solid black;border-collapse: collapse;">
            <td style="border: 1px solid black;border-collapse: collapse;">Reimbursed with conditions</td>
            <td style="border: 1px solid black;border-collapse: collapse;">Drug reimbursed under certain conditions/with restrictions (not all dose/units are reimbursed; population, indication or any other restrictions) </td>          
          <tr>          
          <tr style="border: 1px solid black;border-collapse: collapse;"> 
            <td style="border: 1px solid black;border-collapse: collapse;">Withdrawn by manufacturer</td>
            <td style="border: 1px solid black;border-collapse: collapse;">Drug previously reimbursed but was pulled from the market at the request of MA holder</td>
          </tr>
      </table>`
 

const LabelIcon = ({
  datasourcename,
  section,
  label,
  description,
  values,
  fId}:Iprop
) => {
  const userContext = useContext(UserContext);
  return (
    <>
      <FaInfoCircle
        onClick={() => {
          const showAlertFunc = async() => {
          const res = await apiCall(`/api/admin/fetch/description/${fId}/${values["Status_ID"] || 1}/${0}/${'write'}`);
          userContext.showAlert(
            <>
              <span style={{ fontSize: "70%", paddingLeft: "2px" }}>
                {datasourcename}
                <DoubleArrowIcon
                  style={{ fontSize: "18px", paddingBottom: "2px" }}
                />
                {section}
                <DoubleArrowIcon
                  style={{
                    strokeWidth: "5",
                    fontSize: "18px",
                    paddingBottom: "2px",
                  }}
                />
                {label}
              </span>
            </>,
            "info",
            res.data,
            `/admin/instructions/${fId}`,
            true
          );
        }
        showAlertFunc()
        }}
        style={{
          fontSize: "12px",
          marginLeft: "3px",
          cursor: "pointer",
        }}
      />
    </>
  );
};

const ReimbursementStatus_ProductStrengthForm = ({
  field,
  values,
  fieldChanged,
  childRows,
  validateCriteria,
  RichtextFieldChanged,
  datasourcename,
  section,
  datasourceId,
  masterFieldTypes,
  openModal,
}: IReimbursementStatus_ProductStrengthForm) => {
  const dispatch = useAppDispatch();

  const updateProductStrength = (obj: any, index: number) => {
    const productStrength = [...values[field.name]];
    productStrength[index] = obj;
    fieldChanged(field.name, "", productStrength);
  };
  const selectedLookupGlobe = useAppSelector(
    (state) => state.multiselect.selectedLookupGlobe
  );

  const deleteProductStrength = (pro_index: number) => {
    let _selectedLookupGlobe = {...selectedLookupGlobe};

    for ( let i = pro_index; i < values[field.name].length-1 ; i++) {
      _selectedLookupGlobe[`ReimbursementStatus_ProductStrength.AdministrationRoute_${i}`] =
          _selectedLookupGlobe[`ReimbursementStatus_ProductStrength.AdministrationRoute_${i+1}`];
    }

    const productStrengthLength = values[field.name].length - 1;
    delete _selectedLookupGlobe[`ReimbursementStatus_ProductStrength.AdministrationRoute_${productStrengthLength}`];

    dispatch(setSelectedLookupGlobe(_selectedLookupGlobe));
    if (values[field.name].length === 1) {
      fieldChanged(field.name, "", []);
      // setTimeout(() => {
      //   fieldChanged(field.name, "", [{}]);
      // }, 20);
      
    } else {
      const strength: any[] = [...values[field.name]];
      fieldChanged(field.name, "", [
        ...strength.filter((item: any, xi: number) => xi != pro_index),
      ]);
    }
  };

  return (
    <>
      {values[field.name]?.length > 0 && (
        <>
        <br />
        <div className="col-sm-12 row">
          <div className="col-sm-4">
            <div className="col-sm-10" style={{ float: "right" }}>
              <div style={{ float: "right" }}>
                <label>Product dosage: </label>
              </div>
            </div>
          </div>
          <div className="col-sm-8 row">
            <div className="col-sm-3">
              <label>Formulation</label>
              <LabelIcon
                datasourcename={"Reimbursement status"}
                section={"intervention"}
                label={"Product formulation(s)"}
                description={
                  "Data entry instructions : No Data entry instructions available."
                }
                values={values}
                fId = {childRows.filter(
                  (x) =>
                    x.name ==
                    "ReimbursementStatus_ProductStrength.ProductFormulation"
                ).map(({id}) => id)[0]}
              />
            </div>
            <div className="col-sm-3" style={{ padding: "0px" }}>
              <label>Route of administration </label>
              <LabelIcon
                datasourcename={"Reimbursement status"}
                section={"intervention"}
                label={"Route of administration"}
                description={
                  "Data entry instructions : No Data entry instructions available."
                }
                values={values}
                fId = {childRows.filter(
                  (x) =>
                    x.name ==
                    "ReimbursementStatus_ProductStrength.AdministrationRoute"
                ).map(({id}) => id)[0]}
              />
            </div>
            <div className="col-sm-3">
              <label>Dose(Unit)</label>
              <LabelIcon
                datasourcename={"Reimbursement status"}
                section={"intervention"}
                label={"Dose(Unit)"}
                description={
                  "Data entry instructions : This is a free text field. Make sure to fill in both, dose and unit, e.g. 300 mg; 100 mg/ml; 0.5 mg/ 10 ml; 200/200/200 mg (for fixed dose combinations)"
                }
                values={values}
                fId = {childRows.filter(
                  (x) =>
                    x.name ==
                    "ReimbursementStatus_ProductStrength.Strength"
                ).map(({id}) => id)[0]}
              />
            </div>
            <div className="col-sm-3">
              <label>Reimbursed </label>
              <LabelIcon
                datasourcename={"Reimbursement status"}
                section={"intervention"}
                label={"Product formulation(s)"}
                description={TableDescription}
                values={values}
                fId = {childRows.filter(
                  (x) =>
                    x.name ==
                    "ReimbursementStatus_ProductStrength.ProductFormulation"
                ).map(({id}) => id)[0]}
              />
            </div>
          </div>
        </div>
        </>
      )}
      {values[field.name] &&
        values[field.name].map((el: any, index: number) => (
          <>
            <div className="col-sm-12 row">
              <div className="col-sm-4">
                <div
                  className="col-sm-10"
                  style={{ float: "right", paddingTop: "25px" }}
                >
                  <div style={{ float: "right" }}>
                    <label>{index + 1}</label>
                  </div>
                </div>
              </div>
              <div className="col-sm-8 row">
                {childRows
                  .filter((x) => ReimbursementStatus_ProductStrengthFields.includes(x.name))
                  .map((row) => (
                    <div className="col-sm-3">
                      <FieldView
                        field={row}
                        values={values}
                        fieldChanged={fieldChanged}
                        RichtextFieldChanged={RichtextFieldChanged}
                        datasourcename={datasourcename}
                        section={section.name}
                        datasourceId={datasourceId}
                        parentObject={values[field.name][index]}
                        setObj={updateProductStrength}
                        index={index}
                        hideTitleLabel={true}
                      />
                    </div>
                  ))}
              </div>
            </div>
            <div>
              {childRows
                .filter(
                  (x) => x.name == "ReimbursementStatus_ProductStrength.Dosing"
                )
                .map((row) => (
                  <div style={{ paddingLeft: "90px" }}>
                    <FieldView
                      field={row}
                      values={values}
                      fieldChanged={fieldChanged}
                      RichtextFieldChanged={RichtextFieldChanged}
                      datasourcename={datasourcename}
                      section={section.name}
                      datasourceId={datasourceId}
                      parentObject={el}
                      setObj={updateProductStrength}
                      index={index}
                    />
                    <label
                      style={{
                        color: "rgb(51, 122, 183)",
                        marginRight: "0px",
                        float: "right",
                        marginTop: "-45px",
                        cursor:"pointer",
                        zIndex:10,
                        position:"relative",
                        fontSize:"20px",
                      }}
                      onClick = {() => deleteProductStrength(index)}
                    >
                      <RiDeleteBinLine />
                    </label>
                  </div>
                ))}

              <div className="col-sm-12 row" style={{ marginLeft: "290px" }}>
              <div
                        className="col-sm-2"
                        style={{ padding: "25px 0px 0px 0px" }}
                      >
                        <label>{"Reimbursement start date"}</label>
                        <LabelIcon
                          datasourcename={"Reimbursement status"}
                          section={"intervention"}
                          label={"Reimbursement start date"}
                          description={"Test"}
                          values={values}
                          fId = {childRows.filter(
                            (x) =>
                              x.name ==
                              "ReimbursementStatus_ProductStrength.StartDate"
                          ).map(({id}) => id)[0]}
                        />
                      </div>
                      <div className="col-sm-2">
                        <FieldView
                          field={childRows.find(
                            (x) =>
                              x.name ==
                              "ReimbursementStatus_ProductStrength.StartDate"
                          ) || childRows[0]}
                          values={values}
                          fieldChanged={fieldChanged}
                          RichtextFieldChanged={RichtextFieldChanged}
                          datasourcename={datasourcename}
                          section={section.name}
                          datasourceId={datasourceId}
                          parentObject={values[field.name][index]}
                          setObj={updateProductStrength}
                          index={index}
                          hideTitleLabel={true}
                        />
                      </div>
                      <div
                        className="col-sm-2"
                        style={{ padding: "25px 0px 0px 0px" }}
                      >
                        <label>{"Reimbursement end date"}</label>
                        <LabelIcon
                          datasourcename={"Reimbursement status"}
                          section={"intervention"}
                          label={"Reimbursement end date"}
                          description={"Test"}
                          values={values}
                          fId = {childRows.filter(
                            (x) =>
                              x.name ==
                              "ReimbursementStatus_ProductStrength.EndDate"
                          ).map(({id}) => id)[0]}
                        />
                      </div>
                      <div className="col-sm-2">
                        <FieldView
                          field={childRows.find(
                            (x) =>
                              x.name ==
                              "ReimbursementStatus_ProductStrength.EndDate"
                          ) || childRows[0]}
                          values={values}
                          fieldChanged={fieldChanged}
                          RichtextFieldChanged={RichtextFieldChanged}
                          datasourcename={datasourcename}
                          section={section.name}
                          datasourceId={datasourceId}
                          parentObject={values[field.name][index]}
                          setObj={updateProductStrength}
                          index={index}
                          hideTitleLabel={true}
                        />
                      </div>
                {/* {childRows
                  .filter((x) => ReimbursementStatus_ProductStrengthDateFields.includes(x.name))
                  .map((row, i: number) => (
                    <>
                      <div
                        className="col-sm-2"
                        style={{ padding: "25px 0px 0px 0px" }}
                      >
                        <label>{group2Info[i].label}</label>
                        <LabelIcon
                          datasourcename={"Reimbursement status"}
                          section={"intervention"}
                          label={"Reimbursement start date"}
                          description={"Test"}
                          values={values}
                          fId = {childRows.filter(
                            (x) =>
                              x.name ==
                              "ReimbursementStatus_ProductStrength.StartDate"
                          ).map(({id}) => id)[0]}
                        />
                      </div>
                      <div className="col-sm-2">
                        <FieldView
                          field={row}
                          values={values}
                          fieldChanged={fieldChanged}
                          RichtextFieldChanged={RichtextFieldChanged}
                          datasourcename={datasourcename}
                          section={section.name}
                          datasourceId={datasourceId}
                          parentObject={values[field.name][index]}
                          setObj={updateProductStrength}
                          index={index}
                          hideTitleLabel={true}
                        />
                      </div>
                      <div
                        className="col-sm-2"
                        style={{ padding: "25px 0px 0px 0px" }}
                      >
                        <label>{group2Info[i].label}</label>
                        <LabelIcon
                          datasourcename={group2Info[i].datasource}
                          section={group2Info[i].section}
                          label={group2Info[i].label}
                          description={group2Info[i].description}
                          values={values}
                          fId = {childRows.filter(
                            (x) =>
                              ReimbursementStatus_ProductStrengthDateFields.includes(x.name)
                          ).map(({id}) => id)[0]}
                        />
                      </div>
                      <div className="col-sm-2">
                        <FieldView
                          field={row}
                          values={values}
                          fieldChanged={fieldChanged}
                          RichtextFieldChanged={RichtextFieldChanged}
                          datasourcename={datasourcename}
                          section={section.name}
                          datasourceId={datasourceId}
                          parentObject={values[field.name][index]}
                          setObj={updateProductStrength}
                          index={index}
                          hideTitleLabel={true}
                        />
                      </div>
                    </>
                  ))} */}
              </div>
              <div
                className="col-sm-11"
                style={{ marginLeft: "100px", float: "right" }}
              >
                <hr style={{ border: "none", borderTop: "1px dotted black" }} />
              </div>
            </div>
          </>
        ))}
    </>
  );
};
export default ReimbursementStatus_ProductStrengthForm;
