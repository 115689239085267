import toastr from "toastr";
import "toastr/build/toastr.min.css";
import "./ToastrStyle.css";
export const ToastrAlert = (msg: string, type: string) => {
  toastr.options = {
    positionClass: "toast-top-full-width",
    hideDuration: 300,
    timeOut: 60000,
  };
  toastr.clear();
  if (type == "warning") setTimeout(() => toastr.warning(msg), 300);
  else if (type == "success") setTimeout(() => toastr.success(msg), 300);
  else if (type == "error") setTimeout(() => toastr.error(msg), 300);
  else if (type == "info") setTimeout(() => toastr.info(msg), 300);
  else setTimeout(() => toastr.success(msg), 300);
};
