// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.form-input {
  border-radius: 30px !important;
  padding: 15px !important;
  height: 10px;
  width: 100vw;
  border: 1px solid gray;
  font-size: 11px;
}

.form-input:focus {
  outline: none !important;
  border: 1px solid deepskyblue !important;
}
.form-button {
  background-color: deepskyblue;
  border-color: 1px solid deepskyblue;
  border-radius: 30px;
  color: white;
  height: 32px;
  width: 420px;
  font-size: 15px;
  border-bottom: 1px solid gray;
  border-top: 1px solid deepskyblue;
  border-left: 1px solid deepskyblue;
  border-right: 1px solid gray;
}
`, "",{"version":3,"sources":["webpack://./src/components/Logout/Login.css"],"names":[],"mappings":"AAAA;EACE,8BAA8B;EAC9B,wBAAwB;EACxB,YAAY;EACZ,YAAY;EACZ,sBAAsB;EACtB,eAAe;AACjB;;AAEA;EACE,wBAAwB;EACxB,wCAAwC;AAC1C;AACA;EACE,6BAA6B;EAC7B,mCAAmC;EACnC,mBAAmB;EACnB,YAAY;EACZ,YAAY;EACZ,YAAY;EACZ,eAAe;EACf,6BAA6B;EAC7B,iCAAiC;EACjC,kCAAkC;EAClC,4BAA4B;AAC9B","sourcesContent":[".form-input {\n  border-radius: 30px !important;\n  padding: 15px !important;\n  height: 10px;\n  width: 100vw;\n  border: 1px solid gray;\n  font-size: 11px;\n}\n\n.form-input:focus {\n  outline: none !important;\n  border: 1px solid deepskyblue !important;\n}\n.form-button {\n  background-color: deepskyblue;\n  border-color: 1px solid deepskyblue;\n  border-radius: 30px;\n  color: white;\n  height: 32px;\n  width: 420px;\n  font-size: 15px;\n  border-bottom: 1px solid gray;\n  border-top: 1px solid deepskyblue;\n  border-left: 1px solid deepskyblue;\n  border-right: 1px solid gray;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
