import React, { useState, useEffect } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import PPCards from "./PPCards";
import { useAppSelector } from "../../../store/hooks";
import { CardObj, defineCardData } from "../../../utils/index";
import MilestonesScatterChart from "./MilestonesScatterChart";
import MilestonesTimelineChart from "./MilestonesTimelineChart";

const Milestones = () => {
  const state = useAppSelector((state: any) => state);
  const [htaData] = useState<any[]>(state.productProfile.htaSummaryData);
  const [data] = useState<any[]>(state.productProfile.milestoneData);
  const [cardData, setCardData] = useState<CardObj>({
    positiveRecommendations: 0,
    positiveWithRestriction: 0,
    negativeRecommendations: 0,
    multipleRecommendations: 0,
    noRecommendations: 0,
    ongoingPlanned: 0,
    suspendedCanceled: 0,
  });

  const calculateRecommendation = () => {
    // console.log('htaData', htaData)
    let res = defineCardData(htaData);

    setCardData({
      positiveRecommendations: res.positiveRecommendations,
      positiveWithRestriction: res.positiveWithRestriction,
      negativeRecommendations: res.negativeRecommendations,
      multipleRecommendations: res.multipleRecommendations,
      noRecommendations: res.noRecommendations,
      ongoingPlanned: res.ongoingPlanned,
      suspendedCanceled: res.suspendedCanceled,
    });
  };

  useEffect(() => {
    calculateRecommendation();
  }, [htaData]);

  return (
    <div className="milestoneContainer">
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography>
            Key clinical, market approval and market access milestones
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          {data.length > 0 ? (
            <MilestonesTimelineChart data={data} />
          ) : (
            <div style={{ marginTop: 50, textAlign: "center", marginBottom: 50 }}>
              <strong style={{ fontSize: 20 }}>No Data Available.</strong>{" "}
            </div>
          )}
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography>All market access milestones</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <div>
            <PPCards cardData={cardData} />
          </div>
          <div>
            <MilestonesScatterChart data={htaData} />
          </div>
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

export default Milestones;
