import React, { useState } from "react";
import { FaAngleDoubleUp } from "react-icons/fa";
import "./SectionView.css";

const ScrollButton = () => {
  const [visible, setVisible] = useState(false);

  const toggleVisible = () => {
    const scrolled = document.documentElement.scrollTop;
    if (scrolled > 300) {
      setVisible(true);
    } else if (scrolled <= 300) {
      setVisible(false);
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
      /* you can also use 'auto' behaviour 
		in place of 'smooth' */
    });
  };

  window.addEventListener("scroll", toggleVisible);

  return (
    <div className="newscroll"
      style={{
        position: "fixed",
        //   width: "100%",
        left: "95%",
        borderRadius: "2px",
        bottom: "180px",
        height: "40px",
        fontSize: "20px",
        zIndex: 1,
        cursor: "pointer",
        background: "rgb(0, 170, 231)",
        color: "white",
        margin: "4px 0px 0px 8px",
      }}
      onClick={scrollToTop}
    >
      <FaAngleDoubleUp
        style={{ display: visible ? "inline" : "none" }}
      />
    </div>
  );
};

export default ScrollButton;
