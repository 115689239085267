import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { IDoesFilterPassParams, IFilterParams } from "ag-grid-community";
import Spinner from "react-bootstrap/Spinner";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { setSelectedColumnFiters, setFilterColumnTypes, setColumnFilters } from "../../store/SearchSlice";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faRefresh } from "@fortawesome/free-solid-svg-icons";
import { useGetColumnFilterValuesMutation } from "../../store/SearchResultApiSlice";

interface MultiColumnFilterParams extends IFilterParams {
  datasourcId?: number;
  columnType?: string | null;
  closeFilterPopup?: Function | null;
}
export default forwardRef((props: MultiColumnFilterParams, ref) => {
  const [filterText, setFilterText] = useState<string | undefined>("");
  const [selectedFilters, setSelectedFilters] = useState<any[]>([]);
  const [allFilters, setAllFilters] = useState<any[]>([]);

  const { api, colDef, column, columnApi, context, columnType, datasourcId = 0 } =
    props;

  const isDate = columnType && columnType === "QHTASearch_mRenderDate";

  const dispatch = useAppDispatch();
  const selectedColFilters = useAppSelector((state) => state.search.selectedColumnFilter);
  const allColumnfilterValues = useAppSelector(
    (state) => state.search.columnFilterData
  );
  const columnfilterValues = useAppSelector(
    (state) => state.search.columnFilterData[column.colId]
  );

  const filterColumnTypes = useAppSelector((state) => state.search.filterColumnTypes);
  const currentAccountId = useAppSelector(
    (state) => state.userProfile.accountId
  );
  const [getColumnFilterValues, { isLoading: isColumnLoading }] = useGetColumnFilterValuesMutation();
  const GetColumFilterForSearch = async (payload: any) => {
    const colFilter = await getColumnFilterValues(payload);
    const values = colFilter.data[column.colId];
    // console.log('values:', values);
    const filterValues = {...allColumnfilterValues, [column.colId]: values};
    setAllFilters(values);
    dispatch(setColumnFilters({data: filterValues, from: "SearchTable"}));
  }

  useEffect(()=> {
    // console.log('useEffect multicolFilters')
    //fetch columnfilterValues here
    GetColumFilterForSearch({
      accountID: currentAccountId,
      datasourceId: datasourcId,
      columns: [`${column.colId}`],
      colFieldRenderMethod: [`${columnType}`]
    });
    //set selected values
    if(selectedColFilters.hasOwnProperty(column.colId))
      setSelectedFilters(selectedColFilters[column.colId]);
  },[]);

  useEffect(() => {
    if (allFilters && allFilters.length == 0) {
      // if(datasourcId && datasourcId <= 250){
      setAllFilters(columnfilterValues);
      // }
    }
  }, [columnfilterValues]);

  const addOneDay = (date: Date) => {
    date.setDate(date.getDate() + 1);

    return date;
  };

  useImperativeHandle(ref, () => {
    return {
      isFilterActive() {
        return selectedFilters.length > 0;
      },

      doesFilterPass(params: IDoesFilterPassParams) {
        const { node } = params;

        if (filterText == "~") {
          setTimeout(() => {
            setFilterText("");
          }, 500);
        }
        if (selectedFilters.length == 0) return true;
        
        let passed = true;
        function convertDateFormat(dateString: string) {
          const [day, month, year] = dateString.split('/');
          return `${year}-${month}-${day}`;
      }
        if(isDate){
          passed=selectedFilters.some((x) => {
            const dateString = x !== null ? convertDateFormat(x) : null;
             return dateString == node.data[column.colId]
            });
          }else{
          passed = selectedFilters.some((x) => {
            if (x === null || x === '') {
              return node.data[ column.colId ] === '' || node.data[ column.colId ] === null;
            }
            else if (typeof(x) == "string"){
              const value = node.data[ column.colId ]?.replace(/(\r\n|\n|\r)/gm, " ")
              // .replace(/\s+/g, '');
              const y = x
              // .replace(/\s+/g, '');
              if (value == y)
                return true
            } 
            return typeof(x)!= "boolean" && !isNaN(x) ? x == node.data[column.colId] : x === node.data[column.colId]});
        }
        return passed;     
    },

      getModel() {
        if (!this.isFilterActive()) {
          return null;
        }

        let filterVal = "";

        filterVal =
          filterText == "true" || filterText == "false"
            ? JSON.parse(filterText.toLowerCase())
            : filterText?.trim();

        return { value: filterVal };
      },

      setModel(model: any) {
        setFilterText(model == null ? null : model.value);
      },
    };
  });

  const onChange = (value: any) => {
    console.log(value);
    setFilterText(value);
  };

  const [selectedFiltersData, setSelectedFilterData] = useState({});
  const [filtersTypes, setFilterTypes] = useState({});

  useEffect(() => {
    if(allFilters.length > 0 && selectedFilters.length === allFilters.length){
      setCheckSelectAll(true);
    }
    if([1,2,3,4,6,20,25].includes(datasourcId)){
      let selectedFilterData = {...selectedColFilters};
      let filterTypes = {...filterColumnTypes};
      if(selectedFilters.length == 0){
        delete selectedFilterData[column.colId];
        delete filterTypes[column.colId];
      }else{
        selectedFilterData = {...selectedColFilters, [column.colId]: selectedFilters};
        filterTypes = {...filterTypes, [column.colId]: columnType};
      }
      setSelectedFilterData(selectedFilterData);
      setFilterTypes(filterTypes);
      // dispatch(setSelectedColumnFiters(selectedFilterData));
    }
    
    ![1,2,3,4,6,20,25].includes(datasourcId) && props.filterChangedCallback();
  }, [filterText, selectedFilters]);

  const applyFilter = () => {
    if([1,2,3,4,6,20,25].includes(datasourcId)){
      // let selectedFilterData = {...selectedColFilters};
      // let filterTypes = {...filterColumnTypes};
      // if(selectedFilters.length == 0){
      //   delete selectedFilterData[column.colId];
      //   delete filterTypes[column.colId];
      // }else{
      //   selectedFilterData = {...selectedColFilters, [column.colId]: selectedFilters};
      //   filterTypes = {...filterTypes, [column.colId]: columnType};
      // }
      dispatch(setFilterColumnTypes(filtersTypes));
      dispatch(setSelectedColumnFiters(selectedFiltersData));
    }
    props.closeFilterPopup && props.closeFilterPopup();
  }
  const [checkSelectAll, setCheckSelectAll] = useState(false);

  const [blankCheck, setBlankCheck] = useState(false);

  const closeFilter = () => {
    props.closeFilterPopup && props.closeFilterPopup();
  };

  return (
    <>
    {isColumnLoading && (
      <>
        <Spinner animation="border" variant="primary" />
        <h6>Loading filters....</h6>
      </>
    )}
    {!isColumnLoading && allFilters && (
      <div style={{ padding: 4, width: 200, backgroundColor: "white" }}>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <button
          style={{
            margin: "2px 0px",
            color: "white",
            backgroundColor: "rgb(0, 170, 231)",
            border: "1px solid rgb(0, 170, 231)",
            borderRadius: "5px",
            lineHeight: "20px",
          }}
          onClick={() => {
            setCheckSelectAll(false);
            setBlankCheck(false);
            setSelectedFilters([]);
          }}
        >
          Clear
        </button>
        {
          [1,2,3,4,6,20,25].includes(datasourcId) && (
            <button
              style={{
                margin: "2px 0px",
                color: "white",
                backgroundColor: "rgb(0, 170, 231)",
                border: "1px solid rgb(0, 170, 231)",
                borderRadius: "5px",
                lineHeight: "20px",
              }}
              onClick={applyFilter}
            >
              Apply
            </button>
          )
        }
        {
          ![1,2,3,4,6,20,25].includes(datasourcId) && (
            <button
              style={{
                margin: "2px 0px",
                color: "white",
                backgroundColor: "rgb(0, 170, 231)",
                border: "1px solid rgb(0, 170, 231)",
                borderRadius: "5px",
                lineHeight: "20px",
              }}
              onClick={closeFilter}
            >
              Close
            </button>
          )
        }
        
      </div>
      <div>
        <input
          style={{ margin: "4 0 4 0" }}
          type="text"
          value={filterText}
          onChange={(e) => onChange(e.target.value)}
          placeholder="search..."
          width="90%"
        />
        <FontAwesomeIcon
          onClick={() => {
            onChange("");
            setCheckSelectAll(false);
            setBlankCheck(false);
            setSelectedFilters([]);
          }}
          style={{
            fontSize: "14px",
            padding: "0px 5px",
            cursor: "pointer",
          }}
          icon={faRefresh as IconProp}
        />
      </div>
      <div style={{ marginTop: 20 }}>
        <ul>
          <li>
            <input
              type="checkbox"
              checked={checkSelectAll}
              onChange={(e) => {
                setCheckSelectAll(e.target.checked);
                if (e.target.checked) {
                  const filteredArr = allFilters.filter(
                    (x) =>
                      !filterText ||
                      (filterText &&
                        x &&
                        x
                          .toString()
                          .toLowerCase()
                          .indexOf(filterText.toLowerCase()) >= 0)
                  );

                  setSelectedFilters(
                    filteredArr.map((val) => {
                      if (val === null || val === "" || val === "[Blank]") {
                        setBlankCheck(true);
                        return null;
                      } else {
                        return val == "true" || val == "false"
                          ? JSON.parse(val.toLowerCase())
                          : val;
                      }
                    })
                  );
                } else {
                  setBlankCheck(false);
                  setSelectedFilters([]);
                }
              }}
            />
            Select all
          </li>
          {allFilters &&
            allFilters
              .filter(
                (x) =>
                  !filterText ||
                  (filterText &&
                    x &&
                    x
                      .toString()
                      .toLowerCase()
                      .indexOf(filterText.toLowerCase()) >= 0) 
              )
              .map((x) => (
                <li>
                  {" "}
                  <input
                    type="checkbox"
                    value={x !== null ? x.toString() : x}
                    checked={x === null || x === "" ? (blankCheck) : (selectedFilters &&
                      selectedFilters.some((f) => {
                        if (isDate) {
                          return f == x;
                        } else {
                          if (f === null || f === "") return x == "[Blank]";
                          else if(typeof(f) == "boolean") return f === x;
                          return f.toString().trim() == x.trim();
                        }

                      }))
                    }
                    onChange={(e) => {
                      const val = e.target.getAttribute("value");
                      let filterVal: any;
                      filterVal =
                        val == "true" || val == "false"
                          ? JSON.parse(val.toLowerCase())
                          : val;
                      filterVal = typeof (filterVal) == "string" ? filterVal.trim() : filterVal
                      if (e.target.checked) {
                        let sf = [...selectedFilters];
                        if (val == null || val == "" || val == "[Blank]") {
                          sf.push(null);
                          sf.push("");
                          setBlankCheck(!blankCheck);
                        } else {
                          sf.push(filterVal);
                        }
                        setSelectedFilters(sf);
                      } else {
                        setCheckSelectAll(false);
                        if (val === null || val === "" || val === "[Blank]") {
                          setSelectedFilters(
                            selectedFilters.filter((s) => s !== null && s !== "")
                          );
                          setBlankCheck(!blankCheck);
                        } else {
                          setSelectedFilters(
                            selectedFilters.filter((s) => s != filterVal)
                          );
                        }
                      }
                    }}
                  ></input>{" "}
                  {x === null || x === "" ? "[Blank]" : `${x}`}
                </li>
              ))}
        </ul>
      </div>
    </div>
    )}
    </>
  );
});
