import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDiamond } from "@fortawesome/free-solid-svg-icons";
import { recommendationsColors, formatDate } from "../../../utils/index";
import Tooltip, { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import Loader from "../../../utils/Loader";
import { useAppSelector } from "../../../store/hooks";
import { addDate } from "../../../utils/index";

interface MilestonesScatterChartProps {
  data: any;
}

const MilestonesScatterChart = (props: MilestonesScatterChartProps) => {
  const { data } = props;

  const state = useAppSelector((state: any) => state);
  const [searchHTAData] = useState<any[]>(state.productProfile.htaSummaryData);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [chartObj, setChartObj] = useState<any>({});
  const [minYear, setMinYear] = useState<number>(0);
  const [maxYear, setMaxYear] = useState<number>(0);
  const [styleElement, setStyleElement] = useState<any>({
    agencyWidth: 0,
    yearFontSize: 0,
    marginBetweenYear: 0,
    agencyFontSize: 0,
    positiveSpaceBetweenDots: 0,
    negativeSpaceBetweenDots: 0,
    widthYear: 0,
  });

  const initialData = () => {
    setIsLoading(true);
    let obj: any = {};
    let min = 3000;
    let maxY = 0;

    let tmp = [...data];

    let sortedData = tmp.sort(
      (a: any, b: any) =>
        new Date(a.Decision).getTime() - new Date(b.Decision).getTime()
    );

    for (let i = 0; i < sortedData.length; i++) {
      if (
        sortedData[i].Decision !== null &&
        sortedData[i].Decision !== undefined &&
        sortedData[i].AgencyAbbreviation !== "EMA" &&
        sortedData[i].AgencyAbbreviation !== "FDA" &&
        sortedData[i].AgencyAbbreviation !== "PMDA" &&
        (sortedData[i].FinalRecommendation_ID === 205 ||
          sortedData[i].FinalRecommendation_ID === 206 ||
          sortedData[i].FinalRecommendation_ID === 207 ||
          sortedData[i].FinalRecommendation_ID === 208 ||
          sortedData[i].FinalRecommendation_ID === 3162) &&
        (sortedData[i].HTAStatus_ID == 137 || sortedData[i].HTAStatus_ID == 138)
      ) {
        if (new Date(sortedData[i].Decision).getFullYear() < min) {
          min = new Date(sortedData[i].Decision).getFullYear();
        }
        if (new Date(sortedData[i].Decision).getFullYear() > maxY) {
          maxY = new Date(sortedData[i].Decision).getFullYear();
        }

        if (!obj[sortedData[i].AgencyAbbreviation]) {
          obj[sortedData[i].AgencyAbbreviation] = [];
        }

        obj[sortedData[i].AgencyAbbreviation].push({ ...sortedData[i] });
        if (obj[sortedData[i].AgencyAbbreviation] !== undefined) {
          for (
            let j = 0;
            j < obj[sortedData[i].AgencyAbbreviation].length;
            j++
          ) {
            if (
              !!obj[sortedData[i].AgencyAbbreviation][j + 1] &&
              obj[sortedData[i].AgencyAbbreviation][j + 1].Decision ===
                obj[sortedData[i].AgencyAbbreviation][j].Decision
            ) {
              // remove latest ID
              if (
                obj[sortedData[i].AgencyAbbreviation][j].ID >
                obj[sortedData[i].AgencyAbbreviation][j + 1].ID
              )
                obj[sortedData[i].AgencyAbbreviation].splice(j, 1);
              else obj[sortedData[i].AgencyAbbreviation].splice(j + 1, 1);
            }
          }
        }
      }
    }

    setMinYear(min);
    setMaxYear(maxY + 1);

    if (maxY + 1 - min <= 10) {
      setStyleElement({
        agencyWidth: 18,
        agencyFontSize: 13,
        yearFontSize: 13,
        positiveSpaceBetweenDots: 3,
        negativeSpaceBetweenDots: 4,
        marginBetweenYear: 45,
        widthYear: 28,
      });
    } else if (maxY + 1 - min <= 15) {
      setStyleElement({
        agencyWidth: 15,
        agencyFontSize: 12,
        yearFontSize: 12,
        positiveSpaceBetweenDots: 3,
        negativeSpaceBetweenDots: 5,
        marginBetweenYear: 35,
        widthYear: 26,
      });
    } else if (maxY + 1 - min <= 20) {
      setStyleElement({
        agencyWidth: 13,
        agencyFontSize: 11,
        yearFontSize: 11,
        positiveSpaceBetweenDots: 3,
        negativeSpaceBetweenDots: 6,
        marginBetweenYear: 20,
        widthYear: 24,
      });
    } else if (maxY + 1 - min <= 30) {
      setStyleElement({
        agencyWidth: 10,
        agencyFontSize: 10,
        yearFontSize: 10,
        marginBetweenYear: 15,
        positiveSpaceBetweenDots: 3,
        negativeSpaceBetweenDots: 6,
        widthYear: 21,
      });
    }

    const sortedObject = Object.keys(obj)
      .sort()
      .reduce((sorted: any, key: any) => {
        sorted[key] = obj[key];
        return sorted;
      }, {});

    // console.log(obj, "obj", sortedObject, "sortedObject");

    setChartObj(sortedObject);
    setIsLoading(false);
  };

  useEffect(() => {
    initialData();
  }, [data]);

  const additionalData = (label: string, date: string) => {
    let obj: any = {};
    for (let i = 0; i < searchHTAData.length; i++) {
      if (
        searchHTAData[i].AgencyAbbreviation === label &&
        searchHTAData[i].Decision === date
      ) {
        obj["agency"] = searchHTAData[i].AgencyName;
        obj["primaryDisease"] = searchHTAData[i].PrimaryDiseaseNames;
        break;
      }
    }
    return obj;
  };

  const checkYear = (timestamp: any, obj: any) => {
    let currentYearArray = chartObj[obj].filter(
      (date: any) =>
        new Date(date.Decision).getFullYear() ===
        new Date(timestamp).getFullYear()
    );
    let previousYear = new Date(timestamp);
    previousYear.setFullYear(previousYear.getFullYear() - 1);

    let previousYearArray = chartObj[obj].filter(
      (date: any) =>
        new Date(date.Decision).getFullYear() === previousYear.getFullYear()
    );

    let nextYear = new Date(timestamp);
    nextYear.setFullYear(nextYear.getFullYear() + 1);

    let nextYearArray = chartObj[obj].filter(
      (date: any) =>
        new Date(date.Decision).getFullYear() === nextYear.getFullYear()
    );

    let marginBigArray = 0;
    if (
      (currentYearArray.length > 2 && previousYearArray.length > 2) ||
      (currentYearArray.length > 2 && nextYearArray.length > 2)
    ) {
      marginBigArray = styleElement.marginBetweenYear - 13;
    }
    if (
      (currentYearArray.length > 2 && previousYearArray.length > 3) ||
      (currentYearArray.length > 2 && nextYearArray.length > 3)
    ) {
      marginBigArray = styleElement.marginBetweenYear - 17;
    }
    if (
      (currentYearArray.length > 2 && previousYearArray.length > 4) ||
      (currentYearArray.length > 2 && nextYearArray.length > 4)
    ) {
      marginBigArray = styleElement.marginBetweenYear - 20;
    }
    if (
      (currentYearArray.length > 2 && previousYearArray.length > 5) ||
      (currentYearArray.length > 2 && nextYearArray.length > 5)
    ) {
      marginBigArray = styleElement.marginBetweenYear - 25;
    }

    for (let i = 0; i < chartObj[obj].length; i++) {
      if (chartObj[obj][i].Decision === timestamp) {
        let index = chartObj[obj].indexOf(chartObj[obj][i]);
        let currentYear = new Date(timestamp).getFullYear();
        let month = new Date(timestamp).getMonth() + 1;
        if (index !== 0) {
          let previoustimestamp = chartObj[obj][index - 1].Decision;
          let previousYear = new Date(previoustimestamp).getFullYear();
          let previousMonth = new Date(previoustimestamp).getMonth();
          let diffMonth =
            month - previousMonth > 0
              ? month - previousMonth
              : 12 - month - previousMonth;
          if (currentYear === previousYear) {
            return currentYearArray.length < 3 ? diffMonth : -5;
          } else {
            let diffYear = currentYear - previousYear;
            let yearSpace = diffYear * styleElement.widthYear;
            let calculateSpace =
              diffYear <= 2
                ? (styleElement.widthYear / 2 +
                    styleElement.marginBetweenYear) *
                    diffYear +
                  diffMonth
                : (styleElement.widthYear + styleElement.marginBetweenYear) *
                  diffYear;
            diffMonth;
            let margin =
              marginBigArray === 0
                ? calculateSpace
                : (currentYear - previousYear) * marginBigArray +
                  diffMonth +
                  yearSpace;
            return margin;
          }
        } else if (currentYear !== minYear) {
          let diffYear = currentYear - minYear;
          let yearSpace = diffYear * styleElement.widthYear;
          return (
            diffYear * styleElement.marginBetweenYear + month + 5 + yearSpace
          );
        } else if (currentYear === minYear) {
          return month + 5;
        }
      }
    }
  };

  const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: "#f5f5f9",
      color: "rgba(0, 0, 0, 0.87)",
      maxWidth: 220,
      fontSize: theme.typography.pxToRem(12),
      border: "1px solid #dadde9",
    },
  }));

  return !isLoading ? (
    <div>
      <div className="newChart">
        <div className="chartYears">
          <div
            className="emptySpace"
            style={{ minWidth: styleElement.agencyWidth + 1 + "%" }}
          ></div>
          <div className="years">
            {Array.from({ length: maxYear - minYear + 1 }, (_, index) => (
              <span
                key={minYear + index}
                className="year"
                style={{
                  marginRight: styleElement.marginBetweenYear + "px",
                  fontSize: styleElement.yearFontSize + "px",
                }}
              >
                {minYear + index}
              </span>
            ))}
          </div>
        </div>

        <div className="chartContainer">
          <div className="chartBox">
            <div className="chartTitle" style={{ width: "100%" }}>
              {Object.entries(chartObj).map(([key, values], index1) => (
                <div className="chartElement" key={key}>
                  <div
                    className="rowTitle"
                    style={{
                      minWidth: styleElement.agencyWidth + "%",
                      fontSize: styleElement.agencyFontSize + "px",
                    }}
                  >
                    {key}
                  </div>
                  <div
                    className="rowContent"
                    style={
                      index1 === 0
                        ? {
                            borderTop: "1px solid #e9e9e9",
                            borderBottom: "1px solid #e9e9e9",
                          }
                        : {
                            borderBottom: "1px solid #e9e9e9",
                          }
                    }
                  >
                    {values.map((item: any, index: number) => (
                      <div
                        key={index + "_" + item.ID}
                        style={{
                          marginLeft: checkYear(item.Decision, key),
                          zIndex: 10,
                        }}
                      >
                        <HtmlTooltip
                          title={
                            <div className="htmlTooltipTimeline">
                              <Typography color="inherit" className="title">
                                {item.AgencyName
                                  ? item.AgencyName
                                  : item.AgencyNames}
                              </Typography>
                              {item.FinalRecommendationName ? (
                                <div className="toolTip">
                                  <FontAwesomeIcon
                                    icon={faDiamond}
                                    style={{
                                      color: recommendationsColors(
                                        item.FinalRecommendationName
                                      ),
                                      fontSize: "16px",
                                    }}
                                  />
                                  <span style={{ marginLeft: 5 }}>
                                    {item.FinalRecommendationName} for{" "}
                                    <span style={{ fontWeight: "bold" }}>
                                      {item.PrimaryDiseaseNames}{" "}
                                    </span>
                                    on {addDate(item.Decision)}
                                  </span>
                                </div>
                              ) : (
                                <span className="toolTip">
                                  {" "}
                                  Decision of {formatDate(item.Decision)}{" "}
                                </span>
                              )}
                            </div>
                          }
                        >
                          <FontAwesomeIcon
                            icon={faDiamond}
                            style={{
                              color: recommendationsColors(
                                item.FinalRecommendationName
                              ),
                              fontSize: "13px",
                            }}
                          />
                        </HtmlTooltip>
                      </div>
                    ))}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  ) : (
    <Loader />
  );
};

export default MilestonesScatterChart;
