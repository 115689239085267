import React,{useState,useEffect} from 'react';
import { ICellRendererParams } from 'ag-grid-community';
import { RiDeleteBinLine } from "react-icons/ri";
import Modal from 'react-bootstrap/Modal';
import CloseIcon from "@mui/icons-material/Close";
import { useUpdateSavedSearchMutation } from '../SavedSearchAlertApiSlice';
import { useGetSavedSearchAlertActiveDataMutation } from '../AdminApiSlice';
import { useAppDispatch, useAppSelector } from "../hooks";
import { SavedSearchData, storeSavedSearchAlertData } from '../SavedSearchSlice';

interface LinkCellRendererParams extends ICellRendererParams {
  recordIdField: string;
  isSavedSearch? :boolean;
}

export const AdminSavedSearchDelete = (props: ICellRendererParams) => {
  const dispatch = useAppDispatch();
  const [deleteID, setDeleteID] = useState("");
  const [show, setShow] = useState(false);
  const searchData = useAppSelector((state) => state.savedSearch.searchData);

  const dataSourceId = useAppSelector(
    (state) => state.search.selectedDatasourceId
  );
  const [getSavedSearchAlertActiveData,{data:SavedSearchData}] = useGetSavedSearchAlertActiveDataMutation();

  
  const GetSavedSearchActiveData = async () => {
    const searchResult = await getSavedSearchAlertActiveData();
  };
  const [updateSavedSearch] = useUpdateSavedSearchMutation();
  const updateSavedSearchData = async () => {
    if (deleteID){
      const saveSearchData = await updateSavedSearch({
        record_id: Number(deleteID),
      });
      let _searchData : SavedSearchData= { rowData : [],colDef: []};
      let _rowData :any[]= [...searchData.rowData]
      _searchData.rowData = _rowData.filter((el) => el.ID  != deleteID)
      _searchData.colDef = searchData.colDef;
      dispatch(storeSavedSearchAlertData({
        data: _searchData.rowData,
        datasourceId: dataSourceId,
      }));
    }
  };
  const handleClickDelete = (id) => {
    setDeleteID(id);
    setShow(true);
  };
  const handleDeleteItem = () => {
    updateSavedSearchData();
    GetSavedSearchActiveData();
    setShow(false);
  };
  useEffect(() => {
      GetSavedSearchActiveData();
    }, []);
  const handleClose = () => setShow(false);
  return (
    <span>
        {<RiDeleteBinLine style={{fontSize:"medium"}} onClick={(e) => {e.preventDefault();handleClickDelete(props.data[props.recordIdField]);}}></RiDeleteBinLine>}
      {show == true && (
        <Modal show={open} style={{border:"1px solid rgba(0, 0, 0, 0.2)"}} onHide={handleClose} centered>
          <Modal.Header style={{backgroundColor: "#FAFAFA"}}>
            <Modal.Title style={{fontSize:"19px",fontWeight:"bold"}}>Delete confirmation</Modal.Title>
            <CloseIcon className="Close-icon" onClick={handleClose} />
          </Modal.Header>
          <Modal.Body>
            {SavedSearchData &&
              SavedSearchData
                .filter((obj) => obj.ID == deleteID)
                .map((el) => (
                  <>
                    {el.ClientID == null ? (
                      <div key={el.ID} className="bodyTop">
                        <p>
                        Are you sure you want to delete this saved search?
                        </p>
                      </div>
                    ) : (
                      <div key={el.ID} className="bodyTop">
                        <p>
                        Are you sure you want to delete this group search? This will affect all the users of the group.
                        </p>
                      </div>
                    )}
                  </>
                ))}
          </Modal.Body>
          <Modal.Footer style={{ cursor: "pointer",backgroundColor: "#FAFAFA"}}>
            <span className="spanBtn" style={{color:"rgb(0, 170, 231)"}} onClick={handleDeleteItem}>
              Yes
            </span>
            <span>|</span>
            <span className="spanBtn" style={{color:"rgb(0, 170, 231)"}} onClick={handleClose}>
              Cancel
            </span>
          </Modal.Footer>
        </Modal>
      )}
    </span>
  );
};
