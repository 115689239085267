import React, { useState, useEffect, useMemo, useCallback } from "react";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import "./styles.css";
import { GridReadyEvent, ColDef } from "ag-grid-community";
import { storeSavedSearchData } from "../../store/SavedSearchSlice";
import { setLoading } from "../../store/SavedSearchSlice";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import Spinner from "react-bootstrap/Spinner";
import SaveIcon from "@mui/icons-material/Save";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import VerticalSplitIcon from "@mui/icons-material/VerticalSplit";
import { useFetchToptenRecordsMutation } from "../../store/SavedSearchAlertApiSlice";

interface TableProps {
  sourceId: number;
  userId: number;
  role: number;
  sourceName: string;
}

const TableData = ({ sourceId, userId, role, sourceName }: TableProps) => {
  const dispatch = useAppDispatch();
  const searchData = useAppSelector((state) => state.savedSearch.searchData);
  const isLoading = useAppSelector((state) => state.savedSearch.loading);
  const [rowData, setRowData] = useState<any[]>();
  const [columnDefs, setColumnDefs] = useState<ColDef[]>([]);

  const accountType = useAppSelector((state) => state.userProfile.accountType);

  const defaultColDef = useMemo(
    () => ({
      sortable: true,
      // filter: true,
    }),
    []
  );
  const [fetchToptenRecords] = useFetchToptenRecordsMutation();
  const GetToptenRecords = async () => {
    const toptenRecordsData = await fetchToptenRecords({
      datasource_id: sourceId,
    });

    dispatch(setLoading(true));
    dispatch(
      storeSavedSearchData({
        data: toptenRecordsData.data,
        datasourceId: sourceId,
        accountType: accountType,
      })
    );
  };
  useEffect(() => {
    GetToptenRecords();
  }, [sourceId, accountType]);

  const onGridReady = (params: GridReadyEvent) => {
    setRowData(searchData.rowData);
    setColumnDefs(searchData.colDef);
  };

  useEffect(() => {
    onGridReady();
  }, [searchData]);

  return (
    <div className="grid-wrapper">
      <div style={{ width: "100%", height: 500 }} className="ag-theme-alpine">
        <h4 className="text-left">
          Most recently updated {sourceName} records:
        </h4>

        {isLoading && (
          <>
            <Spinner animation="border" variant="primary" color="info" />
            <h6>Loading data...</h6>
          </>
        )}
        {!isLoading && (
          <AgGridReact
            rowData={rowData}
            columnDefs={columnDefs}
            onGridReady={onGridReady}
            rowSelection="multiple"
            animateRows={true}
            suppressRowClickSelection={true}
            defaultColDef={defaultColDef}
            pagination={false}
            paginationPageSize={100}
          />
        )}
      </div>
    </div>
  );
};

export default TableData;
