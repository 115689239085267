import React from "react";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";

const Loader = () => {
  return (
    <div
      style={{
        height: "300px",
        textAlign: "center",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
      }}
    >
      <Box sx={{ display: "flex", textAlign: "center" }}>
        <CircularProgress />
      </Box>
    </div>
  );
};

export default Loader;
