import React, { memo, useState, useEffect } from 'react';
import DragHandleIcon from '@mui/icons-material/DragHandle';
import HelpIcon from '@mui/icons-material/Help';
import WarningIcon from '@mui/icons-material/Warning';
import './Policy_PotentialImplications.css';
import { useAppSelector } from '../../../../store/hooks';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import WarningAmber from '@mui/icons-material/WarningAmber';

interface Policy_PotentialImplicationsProps {
    fieldInfo: any;
};

const Policy_PotentialImplications = ({fieldInfo}: Policy_PotentialImplicationsProps) => {

    const [field, setField] = useState<any[]>([]);
    const subPageDate = useAppSelector((state) => state.multiselect.subpageData);
    const [potentialImplications, setPotentialImplications] = useState<any>([]);
    

    useEffect(() => {
        setPotentialImplications(subPageDate["potential_implications"]);
    }, [subPageDate]);
    console.log('Policy fieldInfo: ', fieldInfo);
    console.log('potentialImplications: ', potentialImplications);

    return(
        <>
        {potentialImplications && potentialImplications.length > 0 && (
            <div className='potential-implications'>
            <table>
                <thead>
                    <tr style={{color:"white",backgroundColor: "#457CB3"}}>
                        <td width="150px">Type of impact</td>
                        <td>Level</td>
                        <td>Explanatory Note</td>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>{'Reimbursement, restrictions'}</td>
                        <td>{getImpactIcon(potentialImplications[0].ReimbursementRestrictions_level)}</td>
                        <td>
                        <label
                            dangerouslySetInnerHTML={{ __html: potentialImplications[0].ExplanatoryNote01 }}
                        ></label>
                        </td>
                    </tr>
                    <tr>
                        <td>{'Pricing'}</td>
                        <td>{getImpactIcon(potentialImplications[0].Pricings_level)}</td>
                        <td>
                        <label
                            dangerouslySetInnerHTML={{ __html: potentialImplications[0].ExplanatoryNote02 }}
                        ></label>
                        </td>
                    </tr>
                    <tr>
                        <td>{'Timimg'}</td>
                        <td>{getImpactIcon(potentialImplications[0].Timimg_level)}</td>
                        <td>
                        <label
                            dangerouslySetInnerHTML={{ __html: potentialImplications[0].ExplanatoryNote03 }}
                        ></label>
                        </td>
                    </tr>
                    <tr>
                        <td>{'Submission complexity'}</td>
                        <td>{getImpactIcon(potentialImplications[0].SubmissionComplexity_level)}</td>
                        <td>
                        <label
                            dangerouslySetInnerHTML={{ __html: potentialImplications[0].ExplanatoryNote04 }}
                        ></label>
                        </td>
                    </tr>
                    <tr>
                        <td>{'Spill-over within/ outside region'}</td>
                        <td>{getImpactIcon(potentialImplications[0].SpillOver_level)}</td>
                        <td>
                        <label
                            dangerouslySetInnerHTML={{ __html: potentialImplications[0].ExplanatoryNote05 }}
                        ></label>
                        </td>
                    </tr>
                    <tr>
                        <td>{'Other impact (if applicable)'}</td>
                        <td>{getImpactIcon(potentialImplications[0].OtherImpact_level)}</td>
                        <td>
                        <label
                            dangerouslySetInnerHTML={{ __html: potentialImplications[0].ExplanatoryNote06 }}
                        ></label>
                        </td>
                    </tr>
                </tbody>
            </table>
            <div id='legends' style={{marginTop:"3px", marginLeft:"-11px",borderBottom: "1px solid gray"}}>
                <label>Legend:</label>
                <label><WarningAmberIcon sx={{ color: 'red'}} style={{fontSize: "Small"}}></WarningAmberIcon> High impact</label>
                <label><WarningAmberIcon sx={{ color: 'orange' }} style={{fontSize: "Small"}}></WarningAmberIcon> Medium impact</label>
                <label><WarningAmberIcon color="success" style={{fontSize: "Small"}} ></WarningAmberIcon> Low impact</label>
                <label><HelpIcon color="primary" style={{fontSize: "Small"}}></HelpIcon> Unclear</label>
                <label><DragHandleIcon color="action" style={{fontSize:"Small"}}></DragHandleIcon> No impact</label>
            </div>
        </div>
        )}
        </>        
    );
}

const getImpactIcon = (level: string) => {
    switch(level) {
        case 'Low impact':
            return <WarningIcon color="success" ></WarningIcon>;
        case 'Medium impact':
            return <WarningIcon sx={{ color: 'orange' }}></WarningIcon>;
        case 'High impact':
            return <WarningIcon sx={{ color: 'red' }}></WarningIcon>;
        case 'Unclear':
            return <HelpIcon color="primary"></HelpIcon> ;
        case 'No impact':
            return <DragHandleIcon color="action"></DragHandleIcon>;
        default:
            return <DragHandleIcon color="action"></DragHandleIcon>;
    }
};

export default memo(Policy_PotentialImplications);