import React, { useState, useEffect } from "react";
import "./ProductProfile.css";
import InputGroup from "react-bootstrap/InputGroup";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRefresh } from "@fortawesome/free-solid-svg-icons";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import ProductProfilContent from "./ProductProfilContent";
import apiCall from "../service/apiCall";
import {
  useFetchDrugInfoMutation,
  useFetchUserSubscriptionMutation,
  useSearchClinicalDataMutation,
  useSearchRegulatoryMutation,
  useFetchCountriesMutation,
  useGetPricingRecordByDrugIdsMutation,
  useFetchDiseaseHierarchyMutation,
  useSearchHTAMutation,
  useGetAllNewsMutation,
  useGetMilestoneDataMutation,
  setClinicalData,
  setDrugInfo,
  setRegulatoryData,
  setCountryList,
  setPricingData,
  setDiseaseHierarchy,
  setHtaSummaryData,
  setAllNewsData,
  setMilestoneData,
  setRelatedProducts,
} from "../../store/ProductProfileSlice";
import { useAppSelector, useAppDispatch } from "../../store/hooks";
import Loader from "../../utils/Loader";
import { setSuperDisease } from "../../store/MultiSelectSlice";
import { margin } from "@mui/system";

const ProductProfile = () => {
  const [inputValue, setInputValue] = useState({
    label: "",
  });
  const [open, setOpen] = useState(false);
  const [inputplaceHolder, setInputplaceHolder] = useState(true);
  const [showContent, setShowContent] = useState<boolean>(false);
  const [options, setOptions] = useState<any[]>([]);
  const [recordName, setRecordName] = useState<string[]>([]);
  const [inputFocus, setInputFocus] = useState<boolean>(false);
  const [relatedProductsLoading, setRelatedProductsLoading] =
    useState<boolean>(false);
  const [milestoneIsLoading, setMilestoneIsLoading] = useState<boolean>(false);
  const [generalInfoLoading, setGeneralInfoLoading] = useState<boolean>(false);
  const [clinicalLoading, setClinicalLoading] = useState<boolean>(false);
  const [regulatoryLoading, setRegulatoryLoading] = useState<boolean>(false);
  const [htaSummaryLoading, setHtaSummaryLoading] = useState<boolean>(false);
  const [pricingLoading, setPricingLoading] = useState<boolean>(false);
  const [newsLoading, setNewsLoading] = useState<boolean>(false);
  const [subscriptionAvailable, setSubscriptionAvailable] = useState<number[]>(
    []
  );

  // State Management :
  const dispatch = useAppDispatch();
  const userID = useAppSelector((state) => state.userProfile.currentUserId);

  // Methods :
  const fetchOptionList = async () => {
    // let res = await fetchDrugData();
    let res = await apiCall("/api/product-profile/GetDrugs");
    console.log(res, "res");
    if (res.data && res.data.length > 0) {
      setOptions(res.data);
    }
  };
  useEffect(() => {
    fetchOptionList();
  }, []);

  const handleInputChange = async (event: any, value: any) => {
    // console.log(value, "value");
    if (value === "") {
      clearInput();
    } else {
      setInputValue({ ...inputValue, label: value });
      setOpen(!!value);
      dispatch(setRelatedProducts([]));
      let moleculeName: string = "";
      let id: any;
      for (let x of options) {
        if (x.label === value) {
          if (x.label.includes("|")) {
            let item = x.label.split("|");
            let temp: any[] = [];
            temp.push(item[0].trim());
            moleculeName = item[1].trim();
            console.log(moleculeName, "moleculeName");
            setRecordName(temp);
          }
          id = x.ID;
          setOpen(false);
        }
      }

      // Find other objects with the same moleculeName
      if (moleculeName !== "") {
        const otherObjects = options.filter((option) => {
          return option.label.includes(moleculeName);
        });
        console.log(otherObjects);
        if (otherObjects.length > 1) {
          dispatch(setRelatedProducts(otherObjects));
        }
      }

      if (!!id) {
        setShowContent(true);
        await fetchAllData(id);
      }
    }
  };

  const resetState = () => {
    dispatch(setDrugInfo({}));
    dispatch(setClinicalData([]));
    dispatch(setMilestoneData([]));
    dispatch(setRegulatoryData([]));
    dispatch(setCountryList([]));
    dispatch(setDiseaseHierarchy([]));
    dispatch(setSuperDisease([]));
    dispatch(setPricingData([]));
    dispatch(setHtaSummaryData([]));
    dispatch(setAllNewsData([]));
  };

  const startLoading = () => {
    setGeneralInfoLoading(true);
    resetState();
    setClinicalLoading(true);
    setRegulatoryLoading(true);
    setHtaSummaryLoading(true);
    setPricingLoading(true);
    setNewsLoading(true);
    setMilestoneIsLoading(true);
  };

  const fetchAllData = async (id: number) => {
    startLoading();
    console.log(id, "x.ID");
    await getDrugInfo(String(id));
    await getDiseaseHierarchy(String(id));
    await getCountries(String(id));
    await getUserSubscription();
    setGeneralInfoLoading(false);
    await getHtaSummaryData(String(id));
    await getClinicalData(String(id));
    await getRegulatoryData(String(id));
    await getPricingData(String(id));
    await getAllNewsData(String(id));
    await getMilestoneDataFunc(String(id));
  };

  const handleInputClick = () => {
    setOpen(false);
    setInputplaceHolder(false);
  };

  const fetchRelatedData = async (id: string, recordNameList: any[]) => {
    setShowContent(false);
    setRelatedProductsLoading(true);
    startLoading();
    setRecordName(recordNameList);
    await getDrugInfo(id);
    await getDiseaseHierarchy(id);
    await getCountries(id);
    await getUserSubscription();
    setGeneralInfoLoading(false);
    setShowContent(true);
    await getHtaSummaryData(id);
    await getClinicalData(id);
    await getRegulatoryData(id);
    await getPricingData(id);
    await getAllNewsData(id);
    await getMilestoneDataFunc(id);
    setRelatedProductsLoading(false);
  };

  const clearInput = () => {
    setInputValue({ ...inputValue, label: "" });
    setOpen(false);
    setInputplaceHolder(true);
    setShowContent(false);
  };

  const FiltersList = [
    { id: 1, name: "Related products" },
    { id: 2, name: "Country" },
    { id: 3, name: "Disease hierarchy" },
  ];

  const SummaryList = [
    {
      id: 1,
      name: "General information",
      loadingStatus: generalInfoLoading,
    },
    subscriptionAvailable.includes(1) && { id: 2, name: "Milestones", loadingStatus: milestoneIsLoading },
    subscriptionAvailable.includes(2) && { id: 3, name: "Clinical", loadingStatus: clinicalLoading },
    subscriptionAvailable.includes(3) && { id: 4, name: "Regulatory", loadingStatus: regulatoryLoading },
    subscriptionAvailable.includes(1) && { id: 5, name: "HTA summary", loadingStatus: htaSummaryLoading },
    subscriptionAvailable.includes(1) && { id: 6, name: "HTA analysis", loadingStatus: htaSummaryLoading },
    subscriptionAvailable.includes(4) && { id: 7, name: "Pricing", loadingStatus: pricingLoading },
    subscriptionAvailable.includes(4) && { id: 8, name: "Pricing analysis", loadingStatus: pricingLoading },
    { id: 9, name: "News", loadingStatus: newsLoading },
  ];

  const [fetchDrugInfo] = useFetchDrugInfoMutation();
  const getDrugInfo = async (payload: string) => {
    let res = await fetchDrugInfo({ drugID: payload });
    if (!!res.data && res.data.length > 0) dispatch(setDrugInfo(res.data));
    console.log(res, "getDrugInfo");
  };

  const [fetchUserSubscription] = useFetchUserSubscriptionMutation();
  const getUserSubscription = async () => {
    console.log(userID);
    let res = await fetchUserSubscription(userID);
    console.log(res, "getUserSubscription");
    if (!!res.data && res.data.length > 0) setSubscriptionAvailable(res.data);
  };

  const [searchClinicalData] = useSearchClinicalDataMutation();
  const getClinicalData = async (payload: string) => {
    let res = await searchClinicalData({ drugID: payload });
    if (!!res.data && res.data.length > 0) dispatch(setClinicalData(res.data));
    console.log(res, "searchClinicalData");
    setClinicalLoading(false);
  };

  const [searchRegulatory] = useSearchRegulatoryMutation();
  const getRegulatoryData = async (payload: string) => {
    let res = await searchRegulatory({ drugID: payload });
    if (!!res.data && res.data.length > 0)
      dispatch(setRegulatoryData(res.data));
    console.log(res, "searchRegulatory");
    setRegulatoryLoading(false);
  };

  const [fetchCountries] = useFetchCountriesMutation();
  const getCountries = async (payload: string) => {
    let res = await fetchCountries({ drugID: payload });
    if (!!res.data && res.data.length > 0) dispatch(setCountryList(res.data));
    console.log(res, "fetchCountries");
  };

  const [getPricingRecordByDrugIds] = useGetPricingRecordByDrugIdsMutation();
  const getPricingData = async (payload: string) => {
    let res = await getPricingRecordByDrugIds({ drugID: payload });
    if (!!res.data && res.data.length > 0) dispatch(setPricingData(res.data));
    console.log(res, "getPricingRecordByDrugIds");
    setPricingLoading(false);
  };

  const [fetchDiseaseHierarchy] = useFetchDiseaseHierarchyMutation();
  const getDiseaseHierarchy = async (payload: string) => {
    let res = await fetchDiseaseHierarchy({ drugID: payload });
    if (!!res.data && res.data.length > 0)
      dispatch(setDiseaseHierarchy(res.data));
    dispatch(setSuperDisease(res.data));
    console.log(res, "fetchDiseaseHierarchy");
  };

  const [searchHTA] = useSearchHTAMutation();
  const getHtaSummaryData = async (payload: string) => {
    let res = await searchHTA({ drugID: payload });
    if (!!res.data && res.data.length > 0)
      dispatch(setHtaSummaryData(res.data));
    console.log(res, "searchHTA");
    setHtaSummaryLoading(false);
  };

  const [getAllNews] = useGetAllNewsMutation();
  const getAllNewsData = async (payload: string) => {
    let res = await getAllNews({ drugIDs: payload });
    // console.log(res, "sortedDataNews");
    if (!!res.data && res.data.length > 0) {
      const sortedData = [...res.data];
      sortedData.sort((a: any, b: any) => b.ID - a.ID);
      dispatch(setAllNewsData(sortedData));
      console.log(res, "sortedDataNews");
    }
    setNewsLoading(false);
  };

  const [getMilestoneData] = useGetMilestoneDataMutation();
  const getMilestoneDataFunc = async (
    drugId: string,
    diseaseId?: string,
    countryId?: string
  ) => {
    let res = await getMilestoneData({
      drugIDs: drugId,
      diseaseIDs: diseaseId ? diseaseId : "",
      countryIDs: countryId ? countryId : "",
    });
    if (!!res.data && res.data.length > 0) dispatch(setMilestoneData(res.data));
    console.log(res, "getMilestoneData");
    setMilestoneIsLoading(false);
  };
  const renderOptions = (props: React.HTMLAttributes<HTMLElement>,option: Partial<any>) => {
    return <li {...props} key = {option.ID}>{option.label}</li>
  }


  return (
    <div className="product-profile-container">
      <div className="searchInput">
        <InputGroup className="mb-3" style={{marginTop:"-4px"}}>
          <InputGroup.Text id="search">Product profile of</InputGroup.Text>
          <Autocomplete
            className={
              inputFocus
                ? "searchAutocompleteExtraStyle searchAutocomplete inputFocus"
                : "searchAutocompleteExtraStyle searchAutocomplete"
            }
            disablePortal
            id="combo-box-demo"
            options={options}
            onFocus={() => {
              setInputFocus(true);
            }}
            onBlur={() => {
              setInputFocus(false);
            }}
            open={open}
            value={inputValue.label}
            onInputChange={handleInputChange}
            // style={{marginTop:"1px",textAlign:"center"}}
            renderInput={(params) => (
              <TextField 
                {...params}
                label={
                  inputplaceHolder
                    ? options.length > 0
                      ? "Search a product"
                      : "Loading..."
                    : " "
                }
                InputLabelProps={{
                  style: {
                    marginTop: "-6px",
                    color:"gray",
                    opacity: "0.5",
                  } }}
                variant="outlined"
                onClick={handleInputClick}
              />
            )}
            renderOption={renderOptions}
          />
          <FontAwesomeIcon
            onClick={clearInput}
            style={{
              fontSize: "14px",
              padding: "14px 15px",
              cursor: "pointer",
              height: "11px"
            }}
            icon={faRefresh as IconProp}
          />
        </InputGroup>
      </div>

      {showContent && !generalInfoLoading && (
        <ProductProfilContent
          FiltersList={FiltersList}
          SummaryList={SummaryList}
          recordName={recordName}
          fetchRelatedData={fetchRelatedData}
        />
      )}

      {generalInfoLoading && <Loader />}
    </div>
  );
};

export default ProductProfile;
