// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sizeColor {
      color: #00aae7;
      font-size: small;
}

.custom-scroll {
      max-height: 112px;
      overflow-y: auto;
      overflow-x: hidden;
}`, "",{"version":3,"sources":["webpack://./src/components/Forms/form.css"],"names":[],"mappings":"AAAA;MACM,cAAc;MACd,gBAAgB;AACtB;;AAEA;MACM,iBAAiB;MACjB,gBAAgB;MAChB,kBAAkB;AACxB","sourcesContent":[".sizeColor {\n      color: #00aae7;\n      font-size: small;\n}\n\n.custom-scroll {\n      max-height: 112px;\n      overflow-y: auto;\n      overflow-x: hidden;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
