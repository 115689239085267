import React, { useContext, useEffect, useState } from "react";
import { useAppSelector } from "../../../../store/hooks";
import apiCall from "../../../service/apiCall";
import { UserContext } from "../../Editor";
import Element from "./Utility";
import DoubleArrowIcon from "@mui/icons-material/DoubleArrow";
//import InfoIcon from "@mui/icons-material/Info";
import { FaInfoCircle } from "react-icons/fa";

interface IDrugData_BrandLicense {
  fieldInfo: any[];
  datasourceName: string;
  section: string;
}

const DrugData_BrandLicense = ({
  fieldInfo,
  datasourceName,
  section,
}: IDrugData_BrandLicense) => {

  const userContext = useContext(UserContext);
  const [field, setField] = useState<any[]>([]);
  const [brandLicesnse, setBrandLicesnse] = useState<any[]>([]);
  const subPageDate = useAppSelector((state) => state.multiselect.subpageData);
  
  useEffect(() => {
    if (field.length == 0 && fieldInfo.length > 0) {
      setField(fieldInfo);
    }
  }, [fieldInfo]);

  useEffect(() => {
    setBrandLicesnse(subPageDate["brandlicense_drugdata"]);
  }, [subPageDate]);

  const fieldRBrand = field.filter((x) => x.name === "DrugData_BrandLicense.RegionalDrug_ID_Text");
  const fieldCorporation = field.filter((x) => x.name === "DrugData_BrandLicense.Corporation_ID");
  const fieldStartDate = field.filter((x) => x.name === "DrugData_BrandLicense.StartDate");
  const fieldEndDate = field.filter((x) => x.name === "DrugData_BrandLicense.EndDate");
  const fieldGeography = field.filter((x) => x.name === "DrugData_BrandLicense.DrugData_BrandLicense_Geography");
  const fieldDealType = field.filter((x) => x.name === "DrugData_BrandLicense.DealType_ID");

  const renderInfoIcon = (fieldObj: any) => {
    console.log(fieldObj)
    return (
        <>
            <FaInfoCircle
                onClick={() => {
                userContext.showAlert(
                    <>
                    <span style={{ fontSize: "70%", paddingLeft: "2px" }}>
                        {datasourceName}
                        <DoubleArrowIcon
                        style={{ fontSize: "18px", paddingBottom: "2px" }}
                        />
                        {section}
                        <DoubleArrowIcon
                        style={{
                            strokeWidth: "5",
                            fontSize: "18px",
                            paddingBottom: "2px",
                        }}
                        />
                    {fieldObj[0].label}
                    </span>
                    </>,
                    "info",
                    fieldObj[0].descriptionClient? fieldObj[0].descriptionClient : "",
                    "https://google.com",
                    true
                );
                }}
                style={{
                fontSize: "12px",
                marginLeft: "3px",
                cursor: "pointer",
                }}
            />
        </>
    );
  };

  return (
    <>
      <hr />
      <b>Additional brands/license partners by region </b>

      <div className="row">
        <div className="col-sm-12 row top-padding" style={{marginTop:"10px"}}>
            <div className="col-sm-2" style={{paddingLeft: "4em"}}><label className=""></label>Regional brand {renderInfoIcon(fieldRBrand)}</div>
            <div className="col-sm-2" style={{paddingLeft: "1em"}}>Corporation / license partner {renderInfoIcon(fieldCorporation)}</div>
            <div className="col-sm-2" style={{paddingLeft: "7em"}}><label className=""></label>Start date {renderInfoIcon(fieldStartDate)}</div>
            <div className="col-sm-2" style={{paddingLeft: "7em"}}>End date {renderInfoIcon(fieldEndDate)}</div>
            <div className="col-sm-2" style={{paddingLeft: "6em"}}>Geography {renderInfoIcon(fieldGeography)}</div>
            <div className="col-sm-2" style={{paddingLeft: "6em"}}>Deal type {renderInfoIcon(fieldDealType)}</div>
        </div>
      </div>

      {!!brandLicesnse && brandLicesnse.length > 0 && brandLicesnse.map((el: any, index: number) => (
            <>
            <br />
            <div className="col-sm-12">
                <hr />
                <div className="col-sm-12 row form-group">
                    {field.filter((x) => x.name.split('.')[1] === "RegionalDrug_ID_Text").length > 0 &&
                        field.filter((x) => x.name.split('.')[1] === "RegionalDrug_ID_Text").map((val) => (
                            <div className="col-sm-2" style={{ paddingTop: "10px",paddingLeft:"2em" }}>
                                
                                <label>{el[val.name.split(".")[1]]}</label>
                            </div>
                    ))}
                    {field
                        .filter((x) => x.name.split('.')[1] === "Corporation_ID").length > 0 &&
                        field
                        .filter((x) => x.name.split('.')[1] === "Corporation_ID").map((val) => (
                            <div className="col-sm-2" style={{ paddingTop: "10px",paddingLeft:"3em" }}>
                                <label>{el[val.name.split(".")[1]]}</label>
                            </div>
                    ))}
                    {field
                        .filter((x) => x.name.split('.')[1] === "StartDate").length > 0 &&
                        field
                        .filter((x) => x.name.split('.')[1] === "StartDate").map((val) => (
                            <div className="col-sm-2" style={{ paddingTop: "10px",paddingLeft:"6em" }}>
                                <label>
                                  {(el[val.name.split('.')[1]] != '' && el[val.name.split('.')[1]] != null 
                                    && el[val.name.split('.')[1]] != undefined) ? new Date(el[val.name.split('.')[1]]).toLocaleDateString('en-GB') : ''}</label>
                            </div>
                    ))}
                    {field
                        .filter((x) => x.name.split('.')[1] === "EndDate").length > 0 &&
                        field
                        .filter((x) => x.name.split('.')[1] === "EndDate").map((val) => (
                            <div className="col-sm-2" style={{ paddingTop: "10px",paddingLeft:"7em" }}>
                                <label>
                                  {(el[val.name.split('.')[1]] != '' && el[val.name.split('.')[1]] != null 
                                    && el[val.name.split('.')[1]] != undefined) ? new Date(el[val.name.split('.')[1]]).toLocaleDateString('en-GB') : ''}</label>
                            </div>
                    ))}
                    {field
                        .filter((x) => x.name.split('.')[1] === "DrugData_BrandLicense_Geography").length > 0 &&
                        field
                        .filter((x) => x.name.split('.')[1] === "DrugData_BrandLicense_Geography").map((val) => (
                            <div className="col-sm-2" style={{ paddingTop: "10px",paddingLeft:"7em" }}>
                                {el[val.name.split('.')[1]] != null ? String(el[val.name.split('.')[1]]).split(",")
                                    .map((element) => (
                                        <>
                                        <label>{element}</label>
                                        <br />
                                        </>
                                )) : ''}
                            </div>
                    ))}
                    {field
                        .filter((x) => x.name.split('.')[1] === "DealType_ID").length > 0 &&
                        field
                        .filter((x) => x.name.split('.')[1] === "DealType_ID").map((val) => (
                            <div className="col-sm-2" style={{ paddingTop: "10px",paddingLeft:"8em" }}>
                                <label>{el[val.name.split(".")[1]]}</label>
                            </div>
                    ))}
                    <div className="col-sm-1" style={{ float: "right", marginLeft: "auto", marginRight: "-12px" }}>
                        <div
                            style={{
                            padding: "10px",
                            borderLeft: "1px dotted gray",
                            }}
                        >
                            <label>{index + 1}</label>
                        </div>
                    </div>
                    {brandLicesnse.length == 0 && (
                        <div
                            className="sol-sm-12"
                            style={{
                            backgroundColor: "whitesmoke",
                            textAlign: "center",
                            height: "55px",
                            }}
                        >
                            No brand/license available
                        </div>
                    )}
                </div>
            </div>
        </>
    ))}

      {/* <div className="row">
        {field.map((val) => (
          <div className="col-sm-2">
            <label>
              {val.Label}

              {showInfoIcon && (
                <FaInfoCircle
                  onClick={() => {
                    userContext.showAlert(
                      <>
                        <span style={{ fontSize: "70%", paddingLeft: "2px" }}>
                          {datasourceName}
                          <DoubleArrowIcon
                            style={{
                              fontSize: "18px",
                              paddingBottom: "2px",
                            }}
                          />
                          {section}
                          <DoubleArrowIcon
                            style={{
                              strokeWidth: "5",
                              fontSize: "18px",
                              paddingBottom: "2px",
                            }}
                          />
                          {val.Label}
                        </span>
                      </>,
                      "info",
                      val.Description ? val.Description : "",
                      "https://google.com",
                      true
                    );
                  }}
                  style={{
                    fontSize: "12px",
                    marginLeft: "3px",
                    cursor: "pointer",
                  }}
                />
              )}
            </label>
          </div>
        ))}
      </div>

      {!!brandLicesnse && brandLicesnse.length > 0 && brandLicesnse.map((el, index) => (
        <div className="row">
          {field.map((val) => (
            <div className="col-sm-2">
              {!!val.Name && val.Name.split(".")[1] == "DrugData_BrandLicense_Geography" && (
                <>
                  {!!el[val.Name.split(".")[1]] && el[val.Name.split(".")[1]].split(",").map((x: string) => (
                    <label style={{ display: "block" }}>{x}</label>
                  ))}
                </>
              )}
              {!!val.Name && val.Name.split(".")[1] !==
                "DrugData_BrandLicense_Geography" && (
                <label>{el[val.Name.split(".")[1]]}</label>
              )}
            </div>
          ))}
          <div
            className="col-sm-1"
            style={{ float: "right", marginLeft: "auto", marginRight: "-12px" }}
          >
            <div
              style={{
                padding: "10px",
                borderLeft: "1px dotted gray",
              }}
            >
              <label>{index + 1}</label>
            </div>
          </div>
          <hr style={{ width: "98%", margin: "0px" }} />
        </div>
      ))}
      {brandLicesnse.length == 0 && (
        <div
          className="sol-sm-12"
          style={{
            backgroundColor: "whitesmoke",
            textAlign: "center",
            height: "55px",
          }}
        >
          No brand/license available
        </div>
      )} */}
    </>
  );
};

export default DrugData_BrandLicense;
