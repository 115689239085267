import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import CloseIcon from "@mui/icons-material/Close";
import { format } from "date-fns";
import "./clientNews.css";

const ClientNewsPopupContent = ({ newsItem }) => {
  return (
    <div>
      {newsItem && (
        <div key={newsItem.ID}>
          <div>
            <h4 style={{ marginLeft: "22px", fontWeight: "bold" }}>{newsItem.Name}</h4>
          </div>
          <br/>
          <br/>
          <div>
            <h4 style={{ marginLeft: "22px", fontWeight: "bold" }}>
              {format(new Date(newsItem.Date), "dd/MM/yyyy")}
            </h4>
            <div
              style={{ marginLeft: "22px" }}
              dangerouslySetInnerHTML={{ __html: newsItem.Body }}
            ></div>
          </div>
        </div>
      )}
    </div>
  );
};
export default ClientNewsPopupContent;
