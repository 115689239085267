import React, { useEffect, useState } from "react";
import { Route } from "react-router";
import { BrowserRouter, Switch, Redirect } from "react-router-dom";
import Layout from "./components/Layout";
import Home from "./components/Home";
// import Counter from './components/Counter';
// import FetchData from './components/FetchData';
import Auth from "./components/Auth";
import Form from "./components/Forms/Form";
import "./custom.css";
import { ErrorBoundary } from "react-error-boundary";
import axios from "axios";
import HTAASupport from "./components/Help/EmailSupport";
import ContactUs from "./components/Help/ContactUs";
import AdminLanding from "./components/Admin/Landing";
import AdminGrid from "./components/Table/AdminGrid";
import AccountSwitch from "./components/Dashboard/AccountSwitch";
import Dashboard from "./components/Dashboard/dashboard";
import Logout from "./components/Logout/Logout";
import Login from "./components/Logout/Login";
import MoreClientNews from "./components/ClientNewsArticles/moreClientNews";
import ClientNewsDetails from "./components/ClientNewsArticles/clientNewsDetails";
import "./content/shotgun/fonts/noto-sans-v14-700.woff2";
import "./content/shotgun/fonts/noto-sans-v14-700italic.woff2";
import "./content/shotgun/fonts/noto-sans-v14-italic.woff2";
import "./content/shotgun/fonts/noto-sans-v14-regular.woff2";
import CompareRecords from "./components/Dashboard/CompareRecords";
import "bootstrap/dist/css/bootstrap.min.css";
import NewModal from "./components/Popup/NewModal";
import WorkInProgressPage from "./components/WorkInProgressPage";
import ProductProfile from "./components/ProductProfile/ProductProfile";
import InternalServerErrorPage from "./components/ErrorPages/internalServerError";
import UnauthorizedPage from "./components/ErrorPages/Unauthorized";
import AdminSavedSearchAlert from "./components/Admin/AdminSavedSearchAlert";
import AdminSavedSearchDeactive from "./components/Admin/AdminSavedSearchDeactive";
import Instructions from "./components/Forms/Admin/Instructions";
import AutoLagoutTimer from "./AutoLagoutTimer";


function getToken() {
  const tokenString = localStorage.getItem("token");
  if (!tokenString) return null;
  return tokenString;
}

const LayoutDiv = () => {
  const roleId = localStorage.getItem("CurrRoleId");
  return (
    <Layout>
      <Route exact path="/" component={Home} >
        <AutoLagoutTimer ComposedClass={Home} />
      </Route>
      <Route path="/cb" component={Auth} > <AutoLagoutTimer ComposedClass={Auth} /></Route>
      <Route path="/form/:dataSourceID/:recordID?" component={Form} > <AutoLagoutTimer ComposedClass={Form} /></Route>
      <Route path="/view/:dataSourceID/:recordID" component={Form} > <AutoLagoutTimer ComposedClass={Form} /></Route>
      <Route path="/ViewVersion/:dataSourceID/:recordID/:versionID" component={Form} > <AutoLagoutTimer ComposedClass={Form} /></Route>
      <Route path="/admin/form/:dataSourceID/:recordID?" render = {() => (Number(roleId) === 1 ?  (<Form></Form>) : (<Redirect to="/Unauthorized" />))}><AutoLagoutTimer ComposedClass={Form} /></Route>
      <Route path="/admin/confirm/:dataSourceID/:recordID?" component={Form} />
      <Route path="/admin/fieldcriteria/:dataSourceID/:fieldCriteriaDatasourceID/:fieldCriteriaID/:recordID?" render = {() => (Number(roleId) === 1 ?  (<Form/>) : (<Redirect to="/Unauthorized" />))}><AutoLagoutTimer ComposedClass={Form} /></Route>
      <Route path="/news/list" render = {() => (Number(roleId) === 1 ?  (<MoreClientNews />) : (<Redirect to="/Unauthorized" />))}><AutoLagoutTimer ComposedClass={MoreClientNews} /></Route>
      <Route path="/admin/instructions/:recordID" render = {() => (Number(roleId) === 1 ?  (<Instructions />) : (<Redirect to="/Unauthorized" />))}><AutoLagoutTimer ComposedClass={Instructions} /></Route>
      {/* <Route
        path="/admin/list/:dataSourceID/:recordID?"
        component={AdminLanding}
      /> */}
      <Route path="/admin/list/:dataSourceID/:recordID?" render = {() => (Number(roleId) === 1 ?  (<AdminLanding />) : (<Redirect to="/Unauthorized" />))}><AutoLagoutTimer ComposedClass={AdminLanding} /></Route>
      <Route path="/admin/clientgroup/create/:dataSourceID/:clientID/:recordID?" render = {() => (Number(roleId) === 1 ?  (<Form/>) : (<Redirect to="/Unauthorized" />))}><AutoLagoutTimer ComposedClass={Form} /></Route>
      <Route path="/admin/lookupItem/create/:dataSourceID/:lookupID/:recordID?" render = {() => (Number(roleId) === 1 ?  (<Form/>) : (<Redirect to="/Unauthorized" />))}><AutoLagoutTimer ComposedClass={Form} /></Route>
      <Route
        path="/reports/home"
        render={(props) => <AdminGrid dataSourceID={270} recordID={0} isAdminData={false} Title={""} />}
      ></Route>
      <Route path="/accountSwitch/:recordID/:roleId?" component={AccountSwitch} ><AutoLagoutTimer ComposedClass={AccountSwitch} /></Route>
      <Route path="/savedSearch/:savedSearchId" component={Dashboard} ><AutoLagoutTimer ComposedClass={Dashboard} /></Route>
      <Route path="/savedSelection/:savedSelectionId" component={Dashboard} ><AutoLagoutTimer ComposedClass={Dashboard} /></Route>
      <Route path="/help" component={ContactUs} />
      <Route
        path="/:dataSourceID/CompareRecordsViews"
        component={CompareRecords}
      ><AutoLagoutTimer ComposedClass={CompareRecords} /></Route>
      {/* <Route path='/help2/:emailId' component={HTAASupport} /> */}
      <Route path="/LoggedOut" component={Logout} />
      <Route path="/Login" component={Login} ><AutoLagoutTimer ComposedClass={Login} /></Route>
      <Route path="/AdminSavedSearchAlert" render = {() => (Number(roleId) === 1 ?  (<AdminSavedSearchAlert />) : (<Redirect to="/Unauthorized" />))}><AutoLagoutTimer ComposedClass={AdminSavedSearchAlert} /></Route>
      <Route path="/AdminSavedSearchDeactive" render = {() => (Number(roleId) === 1 ? (<AdminSavedSearchDeactive />) : (<Redirect to="/Unauthorized" />))}><AutoLagoutTimer ComposedClass={AdminSavedSearchDeactive} /></Route>
      <Route path="/ClientNewsList" component={MoreClientNews} ><AutoLagoutTimer ComposedClass={MoreClientNews} /></Route>
      <Route
        path="/ClientNewsArticle/:clientnewsId?"
        component={ClientNewsDetails}
      ><AutoLagoutTimer ComposedClass={ClientNewsDetails} /></Route>
      <Route path="/Search/:searchName" component={Home} ><AutoLagoutTimer ComposedClass={Home} /></Route>
      <Route path="/WorkInProgress" component={WorkInProgressPage} />
      <Route path="/profiler/profile" component={ProductProfile} ><AutoLagoutTimer ComposedClass={ProductProfile} /></Route>
      <Route path="/error!" component = {InternalServerErrorPage} />
      <Route path="/Unauthorized" component = {UnauthorizedPage} />
    </Layout>
  );
};

function MyFallbackComponent({ error, resetErrorBoundary }:any) {
  return (
    <div role="alert">
      <p>Something went wrong:</p>
      <pre>{error.message}</pre>
      <button onClick={resetErrorBoundary}>Try again</button>
    </div>
  );
}

function App() {
  const token = getToken();


  return (
    <React.Fragment>
      <ErrorBoundary
        FallbackComponent={MyFallbackComponent}
        onError={(error, error_info) => {
          var browserName = (function (agent) {
            switch (true) {
              case agent.indexOf("edge") > -1:
                return "MS Edge";
              case agent.indexOf("edg/") > -1:
                return "Edge ( chromium based)";
              case agent.indexOf("opr") > -1:
                return "Opera";
              case agent.indexOf("chrome") > -1:
                return "Chrome";
              case agent.indexOf("trident") > -1:
                return "MS IE";
              case agent.indexOf("firefox") > -1:
                return "Mozilla Firefox";
              case agent.indexOf("safari") > -1:
                return "Safari";
              default:
                return "other";
            }
          })(window.navigator.userAgent.toLowerCase());
          axios({
            method: "post",
            url: process.env.REACT_APP_API_URL + "/api/logerror",
            data: {
              date_time: Date(),
              err_msg: String((error as Error).stack),
              error_info: String((error as Error).message),
              browser_name: browserName,
            },
            headers: {
              "Content-Type": "application/json",
            },
          }).then((response) => {
            console.log(response);
          });
        }}
        onReset={() => {
          // reset the state of your app
        }}
      >
        <LayoutDiv />
        <NewModal/>
        
      </ErrorBoundary>
    </React.Fragment>
  );
}

export default App;
