// import { Action, Reducer } from 'redux';

// // -----------------
// // STATE - This defines the type of data maintained in the Redux store.

// export interface AutocompleteSearchState {
//     searchText: AutocompleteDataType;
// }

// interface AutocompleteDataType {
//     Item1: number,
//     Item2: string,
//     Item3: string,
//     Item4: string,
//     Item5: number,
//     Item6: number,
//     Item7: string
// };

// // -----------------
// // ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// // They do not themselves have any side-effects; they just describe something that is going to happen.
// // Use @typeName and isActionType for type detection that works even after serialization/deserialization.

// export interface SaveSearchTextAction { 
//     type: 'SAVE_SEARCH_TEXT',
//     searchText: string
// }

// // Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// // declared type strings (and not any other arbitrary string).
// export type KnownAction = SaveSearchTextAction;

// // ----------------
// // ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// // They don't directly mutate state, but they can have external side-effects (such as loading data).

// export const actionCreators = {
//     saveSearchText: (searchText: string) => ({ type: 'SAVE_SEARCH_TEXT', searchText } as SaveSearchTextAction)
// };

// // ----------------
// // REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.

// export const reducer: Reducer<AutocompleteSearchState> = (state: AutocompleteSearchState | undefined, incomingAction: Action): AutocompleteSearchState => {
//     if (state === undefined) {
//         return { searchText: {} };
//     }

//     const action = incomingAction as KnownAction;
//     switch (action.type) {
//         case 'SAVE_SEARCH_TEXT':
//             console.log('searchText: ', action.searchText);
//             return { searchText: action.searchText };
        
//         default:
//             return state;
//     }
// };

import { createSlice } from '@reduxjs/toolkit'
import { PayloadAction } from '@reduxjs/toolkit'

export interface AutocompleteDataType {
    Item1: number,
    Item2: string,
    Item3: string,
    Item4: string,
    Item5: number,
    Item6: number,
    Item7: string
};

export interface AutocompleteSearchState {
    searchText: AutocompleteDataType | null,
    selectedSearchTerm: string,
    selectedDataSourceID: number,
    selectedDataSource: string,
    openForm: boolean
}

const initialState: AutocompleteSearchState = {
  searchText: null,
  selectedSearchTerm: '',
  selectedDataSourceID: 200,
  selectedDataSource:'All',
  openForm: false
}

export const AutocompleteSearchSlice = createSlice({
  name: 'AutocompleteSearch',
  initialState,
  reducers: {
    saveSearchText: (state, action:PayloadAction<AutocompleteDataType>) => {
        state.searchText = action.payload;
    },
    saveSelectedSearchTerm: (state, action:PayloadAction<string>) => {
        state.selectedSearchTerm = action.payload;
    },
    saveSelectedDataSource: (state, action:PayloadAction<{dataSourceID:number, dataSource: string}>) => {
        state.selectedDataSourceID = action.payload.dataSourceID;
        state.selectedDataSource = action.payload.dataSource;
    },
    openForm: (state, action:PayloadAction<boolean>) => {
        state.openForm = action.payload;
    }
  },
})

// Action creators are generated for each case reducer function
export const { saveSearchText, saveSelectedSearchTerm, saveSelectedDataSource, openForm } = AutocompleteSearchSlice.actions

export default AutocompleteSearchSlice.reducer


// ---------- AutocompleteSearchApiSlice
import { createApi, fetchBaseQuery} from '@reduxjs/toolkit/query/react';
import { emptySplitApi } from './emptyApiSlice';

export const autocompleteApiSlice = emptySplitApi.injectEndpoints({
    // reducerPath: 'api',
    // baseQuery: fetchBaseQuery({
    //     baseUrl: 'http://localhost:57237/api',
    // }),
    endpoints(build) {
        return{
            // fetchAutocompleteResult: build.mutation({
            //   query: ({ inputText }) => ({
            //     url: `/home/GetAutoCompleteData?format=json&searchTerm=${inputText}&category=1`,
            //     method: 'GET',
            //   }),
            // }),
            fetchAutocompleteResult: build.mutation({
              query: (body) => {
                console.log('Body: ', body);
                return ({
                url: 'api/search/GetAutocompleteData',
                method: 'POST',
                data : body
              })},
            }),           
        }
    },
});

export const { useFetchAutocompleteResultMutation } = autocompleteApiSlice;