import React, { useEffect, useState } from "react";
import { useAppSelector } from "../../../../store/hooks";
import Element from "./Utility";


interface IArmsAndCohortsProp {
  fieldInfo: any[];
  datasourceName: string;
  section: string;
}
const ArmsAndCohorts = ({
  fieldInfo,
  datasourceName,
  section,
}: IArmsAndCohortsProp) => {
  const [field, setField] = useState<any[]>([]);
  const [armsAndCohort, setArmsAndCohort] = useState<any>([]);
  const subPageDate = useAppSelector((state) => state.multiselect.subpageData);

  useEffect(() => {
    if (field.length == 0 && fieldInfo.length > 0 && subPageDate) {
      let vals: string[];
      if (subPageDate["arms_and_cohort_clinical"] != undefined && subPageDate["arms_and_cohort_clinical"].length > 0) {
        vals = Object.keys(
          subPageDate["arms_and_cohort_clinical"]
            ? subPageDate["arms_and_cohort_clinical"][0]
            : {}
        );
        vals = vals.map((x) => x.toLocaleLowerCase());
      }

      const fields = fieldInfo.filter((x) => {
        const y = String(x.name).split(".")[2]
          ? String(x.name).split(".")[2]
          : String(x.name).split(".")[1];
        if (vals != undefined && vals.length > 0) {
          if (!!y) return vals.includes(y.toLocaleLowerCase());
        }
      });
      setField(fields);
    }
  }, [fieldInfo, subPageDate]);

  useEffect(() => {
    setArmsAndCohort(subPageDate["arms_and_cohort_clinical"]);
    //console.log(subPageDate["coa_instrument"]);
  }, [subPageDate]);

  return (
    <>
      {!!armsAndCohort && armsAndCohort.length > 0 && armsAndCohort.map((el: any, index: number) => (
        <>
        <br />
        <hr className="hr-sub-color" />
        <div className="col-sm-12">
          <b>Arm {index + 1}</b>
          <>
            {field.length && field.map((val) => (
              <>
              {
                 el[String(val.name).split(".")[1]] &&
                <>
                 {val.name && String(val.name).split(".")[2] && (
                  <>
                    {String(el[String(val.name).split(".")[1]])
                      .split("||")
                      .map((el, index) => (
                        <>
                          {el != "null" && (
                            <Element
                              label={"Cohorts " + String(index + 1)}
                              fieldType={val.fieldTypeId}
                              value={el}
                              datasourceName={datasourceName}
                              section={section}
                              description={val.description}
                            />
                          )}
                        </>
                      ))}
                  </>
                )}
                { 
                !val.name.includes("ClinicalData_ArmsCohorts") &&
                  <Element
                    label={val.name.split('.')[1] == "Name" || val.name.split('.')[1] == "OutcomeResultID" ? val.label + ' ' + String(index+1) : val.label}
                    fieldType={val.fieldTypeId}
                    value={el[String(val.name).split(".")[1]]}
                    datasourceName={datasourceName}
                    section={section}
                    description={val.description}
                  />
                }
                </>
              }
               
              </>
            ))}
          </>
        </div>
        </>
      ))}
    </>
  );
};

export default ArmsAndCohorts;
