import React,{useState,useEffect} from 'react';
import { ICellRendererParams } from "ag-grid-community";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import EmailIcon from "@mui/icons-material/Email";
import Modal from 'react-bootstrap/Modal';
import CloseIcon from "@mui/icons-material/Close";
import { useAppDispatch, useAppSelector } from "../hooks";
import { useGetSavedSearchAlertActiveDataMutation, useUpdateSavedSearchAlertEmptyMutation, useUpdateSavedSearchAlertFillMutation,  } from '../AdminApiSlice';
import { SavedSearchData,storeSavedSearchAlertData } from '../SavedSearchSlice';

interface LinkCellRendererParams extends ICellRendererParams {
  recordIdField: string;
  isSavedSearch? :boolean;
  IsSubscribed? :string;
  DatasourceID?:string
}


const AdminSavedSearchEmail = (props: ICellRendererParams) => {
  const dispatch = useAppDispatch();
  const [updateID, setupdateID] = useState("");
  const [show, setShow] = useState(false);
  const user_id = localStorage.getItem("CurrUserId");

  const searchData = useAppSelector((state) => state.savedSearch.searchData);

  const dataSourceId = useAppSelector(
    (state) => state.search.selectedDatasourceId);


  const [getSavedSearchAlertActiveData,{data:SavedSearchData}] = useGetSavedSearchAlertActiveDataMutation();
  const GetSavedSearchActiveData = async () => {
    const searchResult = await getSavedSearchAlertActiveData();
  };
  const [updateSavedSearchAlertEmpty] = useUpdateSavedSearchAlertEmptyMutation();
  const [updateSavedSearchAlertFill] = useUpdateSavedSearchAlertFillMutation();
  const getSavedSearchDataEmpty = async () => {
    if (updateID){
      const saveSearchData = await updateSavedSearchAlertEmpty({
        record_id: Number(updateID),
      });
      let _searchData : SavedSearchData= { rowData : [],colDef: []};
      let _rowData :any[]= [...searchData.rowData]
      const new_rowData = _rowData.map((el) =>{
        if(el.ID == updateID){
          el = {...el,["IsSubscribed"] : false};
        }
        return el
      }
      )
      _searchData.colDef = searchData.colDef;
      dispatch(storeSavedSearchAlertData({
        data: new_rowData,
        datasourceId: dataSourceId,
      }));
    }
  };
  const getSavedSearchAlertFill = async () => {
    if (updateID){
      const saveSearchData = await updateSavedSearchAlertFill({
        record_id: Number(updateID),
        user_id: Number(user_id),
      });
      let _searchData : SavedSearchData= { rowData : [],colDef: []};
      let _rowData :any[]= [...searchData.rowData]
      const new_rowData = _rowData.map((el) =>{
        if(el.ID == updateID){
          el = {...el,["IsSubscribed"] : true};
        }
        return el
      }
      )
      _searchData.colDef = searchData.colDef;
      dispatch(storeSavedSearchAlertData({
        data: new_rowData,
        datasourceId: dataSourceId,
      }));
    }
  };
  const handleClose = () => setShow(false);
  const handleClickEmailFull = (id:number) => {
    setupdateID(id);
    setShow(true);
  };
  const handleClickEmailEmpty = (id:number) => {
    setupdateID(id);
    setShow(true);
  };
  const handleEmailEmpty = () => {
    getSavedSearchDataEmpty();
    GetSavedSearchActiveData();
    setShow(false);
  };
  const handleEmailFull = () => {
    getSavedSearchAlertFill();
    GetSavedSearchActiveData();
    setShow(false);
  };
  useEffect(() => {
    GetSavedSearchActiveData();
  }, []);
  return (
    <>
    {
      props.isSavedSearch && ((props.data[props.DatasourceID] == 1 || props.data[props.DatasourceID] == 3) && props.data[props.IsSubscribed] == true) && <EmailIcon onClick={(e) => {e.preventDefault();handleClickEmailEmpty(props.data[props.recordIdField]);}} />
    }
    {
      props.isSavedSearch && ((props.data[props.IsSubscribed] == false || props.data[props.IsSubscribed] == null) && (props.data[props.DatasourceID] == 1 || props.data[props.DatasourceID] == 3)) && <MailOutlineIcon onClick={(e) => {e.preventDefault();handleClickEmailFull(props.data[props.recordIdField]);}} />
    }
    {show == true && (
        <Modal show={open} style={{border:"1px solid rgba(0, 0, 0, 0.2)"}} onHide={handleClose} centered>
          <Modal.Header style={{backgroundColor: "#FAFAFA"}}>
            <Modal.Title style={{fontSize:"19px",fontWeight:"bold"}}>
              {SavedSearchData && SavedSearchData
                .filter((obj1:any) => obj1.ID == updateID)
                .map((el1:any) => 
                (
                <>
                {
                el1.IsSubscribed == true ? ("Email unsubscription confirmation") : ("Email subscription confirmation")
                }
                </>
                ))
              }
            </Modal.Title>
            <CloseIcon className="Close-icon" onClick={handleClose} />
          </Modal.Header>
          <Modal.Body>
            {SavedSearchData &&
              SavedSearchData
                .filter((obj:any) => obj.ID == updateID)
                .map((el:any) => (<>
                {
                  el.IsSubscribed == true ? 
                  (
                    <div key={el.ID} className="bodyTop">
                      <p>
                      You have successfully unsubscribed for email alerts for this search. 
                      You will no longer receive email alerts.
                      </p>
                    </div>
                  ) 
                  : 
                  (
                    <div key={el.ID} className="bodyTop">
                      <p>
                        You have successfully subscribed for email alerts for this search. 
                        Whenever records are updated or when there are new records matching your search, 
                        then you will receive an email alert.
                      </p>
                    </div>
                  )
                }</>
              ))
            }
          </Modal.Body>
          <Modal.Footer style={{ cursor: "pointer",backgroundColor: "#FAFAFA"}}>
          {SavedSearchData && SavedSearchData
                .filter((obj2:any) => obj2.ID == updateID)
                .map((el2:any) => (
                <>
                {
                el2.IsSubscribed == true ? (<span className="spanBtn" style={{color:"rgb(0, 170, 231)"}} onClick={handleEmailEmpty}>Ok</span>) : (<span className="spanBtn" style={{color:"rgb(0, 170, 231)"}} onClick={handleEmailFull}>Ok</span>)
                }
                </>))
              }
          </Modal.Footer>
        </Modal>
      )}

    </>
  );
};
export default AdminSavedSearchEmail;
