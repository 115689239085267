import { Field } from "../../../../store/DataSourceMetaData";
import apiCall from "../../../service/apiCall";

export const htaExtrapolationFields : Field[] = [
      {
        "id": 3871,
        "name": "CurveFits_ID",
        "sectionId": 94,
        "sortOrder": 7,
        "label": "Curve fits examined?",
        "description": "",
        "descriptionClient": "",
        "required": false,
        "readOnly": false,
        "visible": true,
        "is_removed": false,
        "issummary": false,
        "relateddatatype": 0,
        "displaySource": "",
        "displayidname": "",
        "displayfieldname": "",
        "fieldinfo": "",
        "category_id": 0,
        "masterFieldId": 0,
        "parentFieldId": 0,
        "externallyvisible": true,
        "fieldTypeId": 7,
        "lookupId": 40,
        filter_field_id: 0,
        result_Field_id: 0,
      },
      {
        "id": 3872,
        "name": "ManufacturersBasecase_ID",
        "sectionId": 94,
        "sortOrder": 8,
        "label": "Manufacturers basecase",
        "description": "",
        "descriptionClient": "",
        "required": false,
        "readOnly": false,
        "visible": true,
        "is_removed": false,
        "issummary": false,
        "relateddatatype": 0,
        "displaySource": "",
        "displayidname": "",
        "displayfieldname": "",
        "fieldinfo": "",
        "category_id": 0,
        "masterFieldId": 0,
        "parentFieldId": 0,
        "externallyvisible": true,
        "fieldTypeId": 4,
        "lookupId": 40,
        filter_field_id: 0,
        result_Field_id: 0,
      },
      {
        "id": 3873,
        "name": "ExtrapRWEProvided_ID",
        "sectionId": 94,
        "sortOrder": 9,
        "label": "RWE provided to support extrapolation?",
        "description": "",
        "descriptionClient": "",
        "required": false,
        "readOnly": false,
        "visible": true,
        "is_removed": false,
        "issummary": false,
        "relateddatatype": 0,
        "displaySource": "",
        "displayidname": "",
        "displayfieldname": "",
        "fieldinfo": "",
        "category_id": 0,
        "masterFieldId": 0,
        "parentFieldId": 0,
        "externallyvisible": true,
        "fieldTypeId": 10,
        "lookupId": 0,
        filter_field_id: 0,
        result_Field_id: 0,
      },
      {
        "id": 3875,
        "name": "IndependentBasecase_ID",
        "sectionId": 94,
        "sortOrder": 11,
        "label": "Independent review basecase",
        "description": "",
        "descriptionClient": "",
        "required": false,
        "readOnly": false,
        "visible": true,
        "is_removed": false,
        "issummary": false,
        "relateddatatype": 0,
        "displaySource": "",
        "displayidname": "",
        "displayfieldname": "",
        "fieldinfo": "",
        "category_id": 0,
        "masterFieldId": 0,
        "parentFieldId": 0,
        "externallyvisible" : true,
        "fieldTypeId": 4,
        "lookupId": 40,
        filter_field_id: 0,
        result_Field_id: 0,
      },
      {
        "id": 3876,
        "name": "PayerBasecase_ID",
        "sectionId": 94,
        "sortOrder": 12,
        "label": "Payer plausible basecase",
        "description": "",
        "descriptionClient": "",
        "required": false,
        "readOnly": false,
        "visible": true,
        "is_removed": false,
        "issummary": false,
        "relateddatatype": 0,
        "displaySource": "",
        "displayidname": "",
        "displayfieldname": "",
        "fieldinfo": "",
        "category_id": 0,
        "masterFieldId": 0,
        "parentFieldId": 0,
        "externallyvisible": true,
        "fieldTypeId": 4,
        "lookupId": 40,
        filter_field_id: 0,
        result_Field_id: 0,
      },
      {
        "id": 3877,
        "name": "ExTreatment",
        "sectionId": 94,
        "sortOrder": 13,
        "label": "Treatment switching adjustment performed?",
        "description": "",
        "descriptionClient": "",
        "required": false,
        "readOnly": false,
        "visible": true,
        "is_removed": false,
        "issummary": false,
        "relateddatatype": 0,
        "displaySource": "",
        "displayidname": "",
        "displayfieldname": "",
        "fieldinfo": "",
        "category_id": 0,
        "masterFieldId": 0,
        "parentFieldId": 0,
        "externallyvisible": true,
        "fieldTypeId": 10,
       "lookupId":0,
       filter_field_id: 0,
       result_Field_id: 0,
      },
      {
        "id": 3878,
        "name": "Extrapolation_AdjustmentMethods_ID",
        "sectionId": 94,
        "sortOrder": 14,
        "label": "Adjustment methods examined",
        "description": "",
        "descriptionClient": "",
        "required": false,
        "readOnly": false,
        "visible": true,
        "is_removed": false,
        "issummary": false,
        "relateddatatype": 0,
        "displaySource": "",
        "displayidname": "",
        "displayfieldname": "",
        "fieldinfo": "",
        "category_id": 0,
        "masterFieldId": 0,
        "parentFieldId": 0,
        "externallyvisible": true,
        "fieldTypeId": 7,
        "lookupId": 39,
        filter_field_id: 0,
        result_Field_id: 0,
      },
      {
        "id": 3879,
        "name": "TreatmentManufacturersBasecase_ID",
        "sectionId": 94,
        "sortOrder": 15,
        "label": "Manufacturers base case method",
        "description": "",
        "descriptionClient": "",
        "required": false,
        "readOnly": false,
        "visible": true,
        "is_removed": false,
        "issummary": false,
        "relateddatatype": 0,
        "displaySource": "",
        "displayidname": "",
        "displayfieldname": "",
        "fieldinfo": "",
        "category_id": 0,
        "masterFieldId": 0,
        "parentFieldId": 0,
        "externallyvisible": true,
        "fieldTypeId": 4,
        "lookupId": 39,
        filter_field_id: 0,
        result_Field_id: 0,
      },
      {
            "id": 4000,
            "name": "OtherComments",
            "sectionId": 94,
            "sortOrder": 15,
            "label": "Other comments",
            "description": "",
            "descriptionClient": "",
            "required": false,
            "readOnly": false,
            "visible": true,
            "is_removed": false,
            "issummary": false,
            "relateddatatype": 0,
            "displaySource": "",
            "displayidname": "",
            "displayfieldname": "",
            "fieldinfo": "",
            "category_id": 0,
            "masterFieldId": 0,
            "parentFieldId": 0,
            "externallyvisible": true,
            "fieldTypeId": 48,
            "lookupId": 39,
            filter_field_id: 0,
            result_Field_id: 0,
          }
    ];

export const getLookupValue = async(id : number) => {
      const res = await apiCall(`/api/sub-page/lookupItem/${id}`);
      if(res.data){
            return res.data
      return ""
      }
}

export const sortArray = (a:any, b:any) =>{
  const digitRegex : any = /^\d/;
  const alphabetRegex : any = /^[a-zA-Z]/;
  const symbolRegex : any = /^[^\w\s]/;
  const AValue : string = a.Value?.replace(/[&\/\\#,+()$~%.'":*?<>{}]/g,'_');
  const BValue : string = b.Value?.replace(/[&\/\\#,+()$~%.'":*?<>{}]/g,'_');
  
  const scoreA =  symbolRegex.test(AValue) * 100 || digitRegex.test(AValue) * 1 || alphabetRegex.test(AValue) * 10;
  const scoreB =  symbolRegex.test(BValue) * 100 || digitRegex.test(BValue) * 1 || alphabetRegex.test(BValue) * 10;
  
  if (scoreA !== scoreB) {
    return scoreA - scoreB;
  }
  
  if (AValue < BValue) {
    return -1;
  } else if (AValue > BValue) {
    return 1;
  }
  
  return 0;
}