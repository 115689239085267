import { emptySplitApi } from "./emptyApiSlice";

export const AdminApiSlice = emptySplitApi.injectEndpoints({
  endpoints(build) {
    return {
      getAdminPage: build.mutation({
        query: (body) => {
          return {
            url: `api/admin/getPage`,
            method: "POST",
            data : body,
          };
        },
      }),
      getSwitchAccount: build.mutation<any, { accountId: number }>({
        query: (args) => {
          const { accountId } = args;
          return {
            url: `api/user/GetSwitchUserAccount/${accountId}`,
            method: "GET",
          };
        },
      }),
      getSavedSearchAlertActiveData: build.mutation({
        query: () => {
          return {
            url: `api/admin/savedSearchAlertActiveData`,
            method: "GET",
          };
        },
      }),
      getSavedSearchAlertDeactiveData: build.mutation({
        query: () => {
          return {
            url: `api/admin/savedSearchAlertDeactiveData`,
            method: "GET",
          };
        },
      }),
      updateSavedSearchAlertFill: build.mutation<any, { user_id : any,record_id: number }>({
        query: (args: { user_id: any,record_id: any; }) => {
          const { user_id,record_id } = args;
          return {
            url: `api/admin/updateSavedSearchAlertEmailFilled/${user_id}/${record_id}`,
            method: "PUT",
          };
        },
      }),
      updateSavedSearchAlertEmpty: build.mutation<any, { record_id: number }>({
        query: (args: { record_id: any; }) => {
          const { record_id } = args;
          console.log("args : ",args);
          return {
            url: `api/admin/updateSavedSearchAlertEmailEmpty/${record_id}`,
            method: "PUT",
          };
        },
      }),
    };
  },
});

export const { useGetAdminPageMutation, useGetSwitchAccountMutation, useGetSavedSearchAlertActiveDataMutation,useGetSavedSearchAlertDeactiveDataMutation,useUpdateSavedSearchAlertEmptyMutation,useUpdateSavedSearchAlertFillMutation } =
  AdminApiSlice;
