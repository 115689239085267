// import { createApi } from '@reduxjs/toolkit/query/react';
import { emptySplitApi } from "./emptyApiSlice";
import { SearchResultData } from "./SearchResultSlice";
import { _token } from '../components/service/apiCall';

export const SearchResultApiSlice = emptySplitApi.injectEndpoints({
  endpoints(build: any) {
    return {
      fetchSearchResult: build.mutation({
        query: (body: any) => {
          return {
            url: "api/search/OpenSearch",
            method: "POST",
            data : body,
          };
        },
      }),
      getPage: build.mutation({
        query: (body: any) => {
          return {
            url: "api/search/getPage",
            method: "POST",
            data : body,
          };
        },
      }),
      getPageSelection: build.mutation({
        query: (body: any) => {
          console.log("Body: ", body);
          return {
            url: "api/search/getPageSelection",
            method: "POST",
            data : body,
          };
        },
      }),
      getEditColumnsData: build.mutation({
        query: (params: any) => {
          const {dsId, roleID}= params
          return {
            url: `api/search/GetEditColumns/${dsId}/${roleID}`,
            method: "GET",
          };
        },
      }),

      //For fetching the record id values:
      getRecordIdData: build.mutation({
        query: (params: any) => {
          const {searchId}= params
          return {
            url: `api/user/GetRecordIdsearch/${searchId}`,
            method: "GET",
          };
        },
      }),

      getDefaultColumns: build.mutation({
        query: (params: any) => {
          const {accountid, dsId, searchDSId, roleID}= params
          return {
            url: `api/search/GetDefaultColumns/${accountid}/${dsId}/${searchDSId}/${roleID}`,
            method: "GET",
          };
        },
      }),

      setUserPreference: build.mutation({
        query: (body: any) => {
          return {
            url: "api/search/SetUserPreference",
            method: "POST",
            data : body,
          };
        },
      }),

      getColumnFilterValues: build.mutation({
        query: (body: any) => {
          return {
            url: "api/search/GetColumnFilterValues",
            method: "POST",
            data: body,
          };
        },
      }),

      // getSearchedIdToExport: build.mutation({
      //   query: (params: any) => {
      //     const {accountId, dsId, columns}= params
      //     return {
      //       url: `api/search/SearchedIdToExport/${accountId}/${dsId}/${columns}`,
      //       method: "GET",
      //     };
      //   },
      // }),

      getFilteredValuesForColumnFIlter: build.mutation({
        query: (body: any) => {
          return {
            url: "api/search/GetFilteredValuesForColumnFIlter",
            method: "POST",
            data : body,
          };
        }, 
      })
    };
  },
});

export const {
  useFetchSearchResultMutation,
  useGetPageMutation,
  useGetEditColumnsDataMutation,
  useGetDefaultColumnsMutation,
  useSetUserPreferenceMutation,
  useGetRecordIdDataMutation,
  useGetColumnFilterValuesMutation,
  // useGetSearchedIdToExportMutation,
  useGetFilteredValuesForColumnFIlterMutation,
} = SearchResultApiSlice;
