import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  ILookupGlobe,
  ILookupGlobeSpecial,
  IselectedLookup,
  IselectedLookupSpecial,
  IselectedLookupGlobe,
  IselectedLookupGlobeSpecial,
} from "../components/interfaces/multiselect";
import { ILookupData, ILookupDataSpecial } from "../components/Popup/NewModal";
import { AppThunk } from "../store";

export interface ImultiSelectState {
  lookupGlobe: ILookupGlobe;
  lookupGlobeSpecial: ILookupGlobeSpecial;
  lookupData: ILookupData[];
  lookupDataSpecial: ILookupDataSpecial[];
  filterAll: boolean;
  selectedData: IselectedLookup[];
  selectedDataSpecial: IselectedLookupSpecial[];
  modalShow: boolean;
  lookupKey: string;
  lookupId: number;
  selectedLookupGlobe: IselectedLookupGlobe;
  selectedLookupGlobeSpecial: IselectedLookupGlobeSpecial;
  title: string;
  SuperDisease: any;
  FilteredNodes: any;
  checkedState: string[];
  lookupSource: any;
  drugCombination: [];
  endpointResultOption: any;
  endpointResultList: any;
  pricingTitleSource: {};
  exportModalShow: boolean;
  subpageData : IsubpageData;
  recordVersionId: number;
  indicationId: number;
  loeBrandName: ILookupData[]
  datasource_id : number;
  fieldListAudit: string;
  selectedLookupGlobeDbVal: IselectedLookupGlobe;
  noRecordFound : boolean;
  formValues :any;
  isForm: boolean;
}

export interface IDictionary {
  [key:string] : number
}


interface IsubpageData {
  [key :string] : any,
}

export const initialState: ImultiSelectState = {
  lookupGlobe: {},
  lookupGlobeSpecial: {},
  lookupData: [],
  lookupDataSpecial: [],
  filterAll: false,
  selectedData: [],
  selectedDataSpecial: [],
  modalShow: false,
  lookupKey: "",
  lookupId: 0,
  selectedLookupGlobe:{},
  selectedLookupGlobeSpecial: {},
  title: "",
  SuperDisease: [],
  FilteredNodes : [],
  checkedState: [],
  lookupSource: [],
  drugCombination: [],
  endpointResultOption: [],
  endpointResultList: [],
  pricingTitleSource: {},
  exportModalShow : false,
  subpageData: {},
  recordVersionId: 0,
  indicationId: 0,
  loeBrandName: [],
  datasource_id: 1,
  fieldListAudit: "",
  selectedLookupGlobeDbVal: {},
  noRecordFound: false,
  formValues:{},
  isForm: true,
};

export const multiSelectSlice = createSlice({
  name: "multiselect",
  initialState,
  reducers: {
    setLookupGlobe: (state, action: PayloadAction<ILookupGlobe>) => {
      state.lookupGlobe = action.payload;
    },
    setLookupGlobeSpecial: (state, action: PayloadAction<ILookupGlobeSpecial>) => {
      state.lookupGlobeSpecial = action.payload;
    },
    setLookupData: (state, action: PayloadAction<ILookupData[]>) => {
      state.lookupData = action.payload;
    },
    setLookupDataSpecial: (state, action: PayloadAction<ILookupDataSpecial[]>) => {
      state.lookupDataSpecial = action.payload;
    },
    setFilterAll: (state, action: PayloadAction<boolean>) => {
      state.filterAll = action.payload;
    },
    setSelectedDataState: (state, action: PayloadAction<IselectedLookup[]>) => {
      state.selectedData = action.payload;
    },
    setSelectedDataStateSpecial: (state, action: PayloadAction<IselectedLookupSpecial[]>) => {
      state.selectedDataSpecial = action.payload;
    },
    setModalShow: (state, action: PayloadAction<boolean>) => {
      state.modalShow = action.payload;
    },
    setLookupKey: (state, action: PayloadAction<string>) => {
      state.lookupKey = action.payload;
    },
    setLookupId: (state, action: PayloadAction<number>) => {
      state.lookupId = action.payload;
    },
    setSelectedLookupGlobe: (
      state,
      action: PayloadAction<IselectedLookupGlobe>
    ) => {
      state.selectedLookupGlobe = action.payload;
    },
    setSelectedLookupGlobeSpecial: (
      state,
      action: PayloadAction<IselectedLookupGlobeSpecial>
    ) => {
      state.selectedLookupGlobeSpecial = action.payload;
    },
    setModalTitle: (state, action: PayloadAction<string>) => {
      state.title = action.payload;
    },
    setSuperDisease: (state, action: PayloadAction<any>) => {
      state.SuperDisease = action.payload;
    },
    setFilteredNodes: (state, action: PayloadAction<any>) => {
      state.FilteredNodes = action.payload;
    },
    setCheckedState: (state, action: PayloadAction<string[]>) => {
      state.checkedState = action.payload;
    },
    setLookupSource: (state, action: PayloadAction<any>) => {
      state.lookupSource = action.payload;
    },
    setDrugCombination: (state, action: PayloadAction<any>) => {
      state.drugCombination = action.payload;
    },
    setEndpointResultOption: (state, action: PayloadAction<any>) => {
      state.endpointResultOption = action.payload;
    },
    setEndpointResultList: (state, action: PayloadAction<any>) => {
      state.endpointResultList = action.payload;
    },
    setPricingTileSource: (state, action: PayloadAction<any>) => {
      state.endpointResultList = action.payload;
    },
    setExportModalShow: (state, action: PayloadAction<boolean>) => {
      state.exportModalShow = action.payload;
    },
    setSubpageData: (state, action: PayloadAction<IsubpageData>) => {
      state.subpageData = action.payload;
    },
    setLoeBrandName: (state, action: PayloadAction<any>) => {
      state.loeBrandName = action.payload;
    },
    setRecordVersionId: (state, action: PayloadAction<any>) => {
      state.recordVersionId = action.payload.version_id;
      state.indicationId = action.payload.indication_id;
    },
    setDataSourceId: (state,action: PayloadAction<number>) => {
      state.datasource_id = action.payload;
    },
    setFieldListAudit: (state, action: PayloadAction<string>) => {
      state.fieldListAudit = action.payload;
    },
    setSelectedLookupGlobeDbVal: (state, action: PayloadAction<IselectedLookupGlobe>) => {
      state.selectedLookupGlobeDbVal = action.payload;
    },
    setNoRecordFound: (state, action: PayloadAction<boolean>) => {
      state.noRecordFound = action.payload;
    },
    setFormValues : (state, action: PayloadAction<any>) => {
      state.formValues = action.payload;
    },
    setIsForm: (state, action: PayloadAction<boolean>) => {
      state.isForm = action.payload;
    },
  },
});

export const {
  setLookupGlobe,
  setLookupGlobeSpecial,
  setLookupData,
  setLookupDataSpecial,
  setFilterAll,
  setSelectedDataState,
  setSelectedDataStateSpecial,
  setModalShow,
  setLookupKey,
  setLookupId,
  setSelectedLookupGlobe,
  setSelectedLookupGlobeSpecial,
  setModalTitle,
  setSuperDisease,
  setFilteredNodes,
  setCheckedState,
  setLookupSource,
  setDrugCombination,
  setEndpointResultOption,
  setEndpointResultList,
  setPricingTileSource,
  setExportModalShow,
  setSubpageData,
  setLoeBrandName,
  setRecordVersionId,
  setDataSourceId,
  setFieldListAudit,
  setSelectedLookupGlobeDbVal,
  setNoRecordFound,
  setFormValues,
  setIsForm
} = multiSelectSlice.actions;

export const showMultiSelectPopup =
  (ID: number, msg: boolean, lookupKey: string, title: string, isForm?: boolean): AppThunk =>
  (dispatch, getState) => {
    // console.log(ID, msg, lookupKey, title, "!!!!!!!!!!!!!!!!!!!!!!")
    const selectedLookupGlobe = getState().multiselect.selectedLookupGlobe;
    const selectedLookupGlobeSpecial = getState().multiselect.selectedLookupGlobeSpecial;
    const lookupGlobe = getState().multiselect.lookupGlobe;
    const lookupGlobeSpecial = getState().multiselect.lookupGlobeSpecial;

    dispatch(setLookupKey(lookupKey));
    dispatch(setLookupId(ID));
    dispatch(
      setSelectedDataState(
        lookupKey in selectedLookupGlobe ? selectedLookupGlobe[lookupKey] : []
      )
    );
    dispatch(
      setSelectedDataStateSpecial(
        lookupKey in selectedLookupGlobeSpecial ? selectedLookupGlobeSpecial[lookupKey] : []
      )
    );
    // if(ID !== 427) {
    //       console.log("dans le if")
    dispatch(
      setLookupData(lookupKey in lookupGlobe ? lookupGlobe[lookupKey] : [])
    );
    dispatch(
      setLookupDataSpecial(lookupKey in lookupGlobeSpecial ? lookupGlobeSpecial[lookupKey] : [])
    );
    // }
    dispatch(setModalShow(msg));
    dispatch(setModalTitle(title));
    dispatch(setIsForm(!!isForm));
  };

export default multiSelectSlice.reducer;
