// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* .modal .modal-dialog {
  max-width: 700px !important;
}

.modal .modal-dialog .modal-header,
.modal .modal-dialog .modal-footer {
  background-color: #fafafa !important;
}

.modal .modal-dialog .modal-header .modal-title {
  padding: 20px;
  font-weight: 700;
}

.modal .modal-dialog .modal-footer .spanBtn {
  color: #3bbeed;
}

.modal .modal-dialog .modal-body .bodyTop {
  margin-top: 10px;
  text-align: center;
}

.modal .modal-dialog .modal-body .bodyMiddle {
  margin-top: 15px;
  padding: 20px;
}

.modal .modal-dialog .modal-body .bodyMiddle .associate,
.modal .modal-dialog .modal-body .bodyMiddle .dueDate,
.modal .modal-dialog .modal-body .bodyMiddle .reviewer,
.modal .modal-dialog .modal-body .bodyMiddle .dueDateReview,
.modal .modal-dialog .modal-body .bodyMiddle .comment {
  display: flex;
  justify-content: space-between;
}

.modal .modal-dialog .modal-body .bodyMiddle .associate span,
.modal .modal-dialog .modal-body .bodyMiddle .dueDate span,
.modal .modal-dialog .modal-body .bodyMiddle .reviewer span,
.modal .modal-dialog .modal-body .bodyMiddle .comment span {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.modal .modal-dialog .modal-body .bodyMiddle .associate .autocomplete,
.modal .modal-dialog .modal-body .bodyMiddle .reviewer .autocomplete {
  background-color: #fafafa !important;
}

.modal .modal-dialog .modal-body .bodyMiddle .dueDate .MuiFormControl-root,
.modal
  .modal-dialog
  .modal-body
  .bodyMiddle
  .dueDateReview
  .MuiFormControl-root,
.modal .modal-dialog .modal-body .bodyMiddle .comment .MuiFormControl-root {
  width: 100% !important;
}

.modal .modal-dialog .modal-footer span {
  cursor: pointer;
} */
`, "",{"version":3,"sources":["webpack://./src/components/Popup/AssignModal.css"],"names":[],"mappings":"AAAA;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;GAgEG","sourcesContent":["/* .modal .modal-dialog {\n  max-width: 700px !important;\n}\n\n.modal .modal-dialog .modal-header,\n.modal .modal-dialog .modal-footer {\n  background-color: #fafafa !important;\n}\n\n.modal .modal-dialog .modal-header .modal-title {\n  padding: 20px;\n  font-weight: 700;\n}\n\n.modal .modal-dialog .modal-footer .spanBtn {\n  color: #3bbeed;\n}\n\n.modal .modal-dialog .modal-body .bodyTop {\n  margin-top: 10px;\n  text-align: center;\n}\n\n.modal .modal-dialog .modal-body .bodyMiddle {\n  margin-top: 15px;\n  padding: 20px;\n}\n\n.modal .modal-dialog .modal-body .bodyMiddle .associate,\n.modal .modal-dialog .modal-body .bodyMiddle .dueDate,\n.modal .modal-dialog .modal-body .bodyMiddle .reviewer,\n.modal .modal-dialog .modal-body .bodyMiddle .dueDateReview,\n.modal .modal-dialog .modal-body .bodyMiddle .comment {\n  display: flex;\n  justify-content: space-between;\n}\n\n.modal .modal-dialog .modal-body .bodyMiddle .associate span,\n.modal .modal-dialog .modal-body .bodyMiddle .dueDate span,\n.modal .modal-dialog .modal-body .bodyMiddle .reviewer span,\n.modal .modal-dialog .modal-body .bodyMiddle .comment span {\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n}\n\n.modal .modal-dialog .modal-body .bodyMiddle .associate .autocomplete,\n.modal .modal-dialog .modal-body .bodyMiddle .reviewer .autocomplete {\n  background-color: #fafafa !important;\n}\n\n.modal .modal-dialog .modal-body .bodyMiddle .dueDate .MuiFormControl-root,\n.modal\n  .modal-dialog\n  .modal-body\n  .bodyMiddle\n  .dueDateReview\n  .MuiFormControl-root,\n.modal .modal-dialog .modal-body .bodyMiddle .comment .MuiFormControl-root {\n  width: 100% !important;\n}\n\n.modal .modal-dialog .modal-footer span {\n  cursor: pointer;\n} */\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
