import React, { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { useFetchSearchResultMutation } from '../../store/SearchResultApiSlice';
import { saveSearchResultData } from '../../store/SearchResultSlice';
import AdditionalFilters from '../AdditionalFilterModule/AdditionalFilters';
import SearchResutlTable from './SearchResultTable';
import { createHeaderCell, HeadCell, SearchDTO, formatHeaderCell } from './utility';


function SearchResult(props: any) {
    const dispatch = useAppDispatch();
    const selectedSearchTerm = useAppSelector((state) => state.autocompleteSearch.selectedSearchTerm);
    const dataSourceID = useAppSelector((state) => state.autocompleteSearch.selectedDataSourceID);
    // const { data, error, isLoading } = useFetchSearchResultQuery({ selectedSearchTerm , dataSourceID });
    const [fetchSearchResult, { data, error, isLoading }] = useFetchSearchResultMutation();
    console.log("Error: ",error);
    const [rows, setRows] = React.useState<any[]>([]);
    const [headerCell, setHeaderCell] = React.useState<HeadCell[]>([]);

    useEffect(() => {
        console.log('UseEffect Search')
        const payload: SearchDTO = {
            searchterm: selectedSearchTerm,
            searchkey: selectedSearchTerm,
            datasourceid: dataSourceID,
            userid: 1,
            roleid: 1,
            layoutname: 'Default',
          };
        fetchSearchResultData(payload);
    },[]);

    const fetchSearchResultData = async (payload: SearchDTO) => {
        await fetchSearchResult(payload);
    };

    useEffect(() => {
        console.log('isLoading: ', isLoading);
        console.log('data: ', data);
        if(data && data.length>0){
            setRows(data || []);
            setHeaderCell(formatHeaderCell(data[0]));
        }
    },[isLoading]);

    useEffect(() => {       
        console.log('rows: ', rows);
        console.log('headerCell: ', headerCell);
    },[rows, headerCell]);

    // useEffect(() => {
    //     console.log('error: ', error);
    //     console.log('data: ', data);
    // },[error, data]);

    // useEffect(() => {
    //     console.log("SearchResult data:", data);
    //     if(data){
    //         dispatch(saveSearchResultData(data));
    //         setRows(data.Item1|| []);
    //         setHeaderCell(createHeaderCell(data.Item2));
    //     }
        
    // },[isLoading]);

    return(
        <>
        {isLoading && <div>Searching data....</div>}
        {/* {!isLoading && headerCell.length > 0 && <AdditionalFilters headerCells={headerCell} />} */}
        {!isLoading && headerCell.length > 0 && <SearchResutlTable rows={rows} headerCell={headerCell}></SearchResutlTable>}
        
        </>       
    );
}

export default SearchResult;
