// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.modal-dialog-new {    
    min-width: 600px;
}

.my-modal-new {     
      border-radius: 0px;
      max-height: 380.2px;
} 
 `, "",{"version":3,"sources":["webpack://./src/components/Export/exportOption.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;AACpB;;AAEA;MACM,kBAAkB;MAClB,mBAAmB;AACzB","sourcesContent":[".modal-dialog-new {    \n    min-width: 600px;\n}\n\n.my-modal-new {     \n      border-radius: 0px;\n      max-height: 380.2px;\n} \n "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
